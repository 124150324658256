import React, { PropsWithChildren } from 'react';
import { useAppSelector } from '../../store';
import {
  selectIsAuthenticated,
  selectIsRoleBackoffice,
} from '../../store/user/slice';
import { isInBackofficePath } from '../../utils';
import { B2B_HOME } from '../../utils/b2b-routes-constants';
import { HOME } from '../../utils/routes-constants';

const isInBackoffice = isInBackofficePath();

function AppGuard({ children }: PropsWithChildren) {
  const isRoleBackoffice = useAppSelector(selectIsRoleBackoffice);
  const isAuthenticated = useAppSelector(selectIsAuthenticated);

  if (!isAuthenticated) return <>{children}</>;

  if (isInBackoffice && !isRoleBackoffice) {
    // Throw B2B user back to B2B part of the app
    window.location.replace(`${window.location.origin}${B2B_HOME}`);
    return null;
  }

  if (!isInBackoffice && isRoleBackoffice) {
    // Throw BO user back to BO part of the app
    window.location.replace(`${window.location.origin}${HOME}`);
    return null;
  }

  return <>{children}</>;
}

export default AppGuard;
