import React from 'react';
import { Alert, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik, FormikHelpers } from 'formik';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import AddIcon from '../../../icons/AddIcon';
import { fieldOf } from '../../../store/api';
import PhoneField from '../../../components/Common/FormikFields/PhoneField';
import {
  AddAuthorizedPersonRequest,
  useAddAuthorizedPerson,
  useAuthorizedPersonSchema,
} from './api';

const initialValues: AddAuthorizedPersonRequest = { fullPhoneNumber: '' };

const AddAuthorizedPersonPage = () => {
  const { t } = useTranslation();
  const { successSnack, apiErrorSnack } = useSnacks();

  const addAuthorizedPerson = useAddAuthorizedPerson();
  const schema = useAuthorizedPersonSchema();

  const onSubmit = (
    values: AddAuthorizedPersonRequest,
    { resetForm }: FormikHelpers<AddAuthorizedPersonRequest>,
  ) => {
    addAuthorizedPerson.mutate(values, {
      onSuccess: () => {
        successSnack(t('b2b.myCompany.authorizedPersons.snacks.added'));
        resetForm();
      },
      onError: err => apiErrorSnack(err),
    });
  };

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {() => (
        <Form>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              maxWidth: 800,
              gap: 2,
            }}
          >
            <Alert severity="info">
              {t('b2b.myCompany.authorizedPersons.formInfoTooltip')}
            </Alert>
            <PhoneField
              sx={{ maxWidth: 300 }}
              label={t('b2b.myCompany.phoneNumber')}
              name={fieldOf<AddAuthorizedPersonRequest>('fullPhoneNumber')}
            />
            <Button
              disabled={addAuthorizedPerson.isPending}
              variant="contained"
              size="medium"
              color="secondary"
              startIcon={<AddIcon fill="white" />}
              type="submit"
              sx={{ mt: 4, alignSelf: 'flex-start' }}
            >
              {t('b2b.buttons.addNew')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddAuthorizedPersonPage;
