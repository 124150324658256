import React, { useState } from 'react';
import { Alert, Box, Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import PinInput from 'react-pin-input';
import LoadingButton from '../../../../components/Common/LoadingButton';
import {
  B2B_CREATE_ACCOUNT,
  B2B_HOME,
} from '../../../../utils/b2b-routes-constants';
import { useAppDispatch } from '../../../../store';
import { sendCode } from '../../../../store/user/thunk';
import LoginWrapper from './LoginWrapper';

const EnterCodePage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isErroneous, setIsErroneous] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [code, setCode] = useState<string>('');

  const onSubmit = async (value: string) => {
    setIsLoading(true);

    try {
      const data = await dispatch(sendCode(value)).unwrap();
      if (data.isNewUser) {
        navigate(B2B_CREATE_ACCOUNT);
      } else {
        navigate(B2B_HOME);
      }
    } catch (e) {
      setIsErroneous(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onPinChange = (value: string) => {
    setCode(value);
  };

  const onPinComplete = (value: string) => {
    setCode(value);
    onSubmit(value);
  };

  return (
    <LoginWrapper>
      <form
        onSubmit={() => onSubmit(code)}
        style={{
          maxWidth: '320px',
          width: '320px',
          position: 'relative',
        }}
      >
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography variant="h5">{t('b2b.login.enterCode')}</Typography>
            <Box sx={{ marginTop: '36px', marginBottom: '36px' }}>
              <PinInput
                length={6}
                initialValue=""
                type="numeric"
                inputMode="number"
                inputStyle={{
                  borderColor: '#E1DED9',
                  borderRadius: '6px',
                  width: '40px',
                  height: '46px',
                  margin: '4px',
                }}
                focus
                inputFocusStyle={{ borderColor: '#00A399' }}
                onChange={onPinChange}
                onComplete={onPinComplete}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
            </Box>
            <LoadingButton
              sx={{ width: '180px' }}
              type="submit"
              endIcon={<ArrowForward />}
              disabled={code.length !== 6}
              loading={isLoading}
            >
              {t('b2b.login.continue')}
            </LoadingButton>
            <Button
              sx={{ mt: 1 }}
              variant="text"
              size="medium"
              color="secondary"
              component={Link}
              to=".."
            >
              {t('cancel')}
            </Button>
          </Box>
          {isErroneous && (
            <Alert
              severity="error"
              variant="outlined"
              sx={{ mt: 2, position: 'absolute', width: '100%' }}
            >
              {t('b2b.login.error')}
            </Alert>
          )}
        </>
      </form>
    </LoginWrapper>
  );
};

export default EnterCodePage;
