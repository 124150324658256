import moment from 'moment/moment';
import { dateTimeString } from '../../entities';
import { useGetVehiclesAvailability } from './api';

type EditingPeriod = {
  startDateTime: dateTimeString;
  endDateTime: dateTimeString;
};

export function useVehicleAvailability(
  registrationNumber?: string,
  startDateTime?: dateTimeString,
  endDateTime?: dateTimeString,
  editingPeriod?: EditingPeriod,
) {
  const { data: vehicleAvailabilityItems } = useGetVehiclesAvailability({
    registrationNumber,
  });

  const reservedPeriods =
    vehicleAvailabilityItems?.items
      ?.flatMap(it => it.reservedPeriods)
      ?.filter(it => {
        return (
          !editingPeriod ||
          (it.startDateTime !== editingPeriod?.startDateTime &&
            it.endDateTime !== editingPeriod?.endDateTime)
        );
      }) ?? [];

  const isAvailabilityConflict =
    (!!startDateTime &&
      !!endDateTime &&
      reservedPeriods.some(
        period =>
          moment(startDateTime).isSameOrBefore(
            moment.utc(period.endDateTime),
          ) &&
          moment(endDateTime).isSameOrAfter(moment.utc(period.startDateTime)),
      )) ??
    false;

  return {
    isAvailabilityConflict,
    reservedPeriods,
  };
}
