/* eslint-disable react/prop-types */
// Disabled due to MUI Select having trouble validating defaultValue prop
import React from 'react';
import {
  Box,
  CircularProgress,
  FormControl,
  FormHelperText,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { SelectProps } from '@mui/material/Select/Select';
import { useTranslation } from 'react-i18next';
import { WarningAmberRounded } from '@mui/icons-material';

export interface SelectItem {
  value: string;
  label: string;
}

type Props = Omit<SelectProps<string>, 'onChange'> & {
  items: SelectItem[];
  onChange: (value: string) => void;
  displayEmpty?: boolean;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  initialItem?: SelectItem;
};

const Select = ({
  items,
  onChange,
  displayEmpty,
  placeholder,
  error,
  helperText,
  loading,
  initialItem,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const onSelectChange = (event: SelectChangeEvent<unknown>) => {
    onChange(event.target.value as string);
  };

  const renderValue = (value: string) =>
    !value ? (
      <Typography
        component="span"
        fontWeight={400}
        fontSize="inherit"
        color="#8E8C89"
      >
        {placeholder}
      </Typography>
    ) : (
      items.find((item: SelectItem) => item.value === value)?.label ??
      initialItem?.label
    );

  return (
    <FormControl error={error} fullWidth>
      <MuiSelect
        {...props}
        variant="outlined"
        size="small"
        value={props.value}
        defaultValue={props.defaultValue ?? initialItem?.value}
        onChange={onSelectChange}
        displayEmpty={displayEmpty || !!placeholder}
        sx={{ marginTop: 1 }}
        renderValue={placeholder ? renderValue : undefined}
        error={error}
      >
        {displayEmpty && <MenuItem value="">{t('all')}</MenuItem>}
        {initialItem && !items.some(it => it.value === initialItem.value) && (
          <MenuItem value={initialItem.value}>{initialItem.label}</MenuItem>
        )}
        {items.map(({ value, label }: SelectItem) => (
          <MenuItem key={value} value={value}>
            {label}
          </MenuItem>
        ))}
      </MuiSelect>
      {loading ? (
        <CircularProgress
          size={24}
          style={{
            position: 'absolute',
            // Account for a 8px margin, then center by assuming 40px is the select height
            top: 'calc(8px + (40px - 24px) / 2)',
            // Pixel-pushed to have the select down caret be in the middle of the loader
            left: 'calc(100% - 31px)',
            transform: 'translateY(-50%)',
          }}
        />
      ) : null}
      {helperText && (
        <FormHelperText sx={{ color: error ? '#D4002A' : null, marginLeft: 0 }}>
          <Box display="flex" alignItems="center" gap={0.5} component="span">
            {error && <WarningAmberRounded />}
            <Typography variant="body2" component="span">
              {helperText}
            </Typography>
          </Box>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Select;
