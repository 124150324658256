import React, { ReactNode } from 'react';
import { Box, Button, Paper, Typography } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from '../../store';
import { closeModal } from '../../store/modal/slice';
import ModalHeader from './ModalHeader';
import ModalFooter from './ModalFooter';

interface Props {
  title: string;
  action: () => void;
  content?: ReactNode;
  confirmButtonProps?: ButtonProps;
}

const ConfirmationModal = ({
  title,
  action,
  content,
  confirmButtonProps,
}: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    action();
    dispatch(closeModal());
  };

  return (
    <Paper sx={{ width: '100%', maxWidth: 568 }}>
      <ModalHeader title={title} />
      <Box sx={{ padding: 4, textAlign: 'center' }}>
        {content ? content : <Typography>{t('modal.areYouSure')}</Typography>}
      </Box>
      <ModalFooter>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleSubmit}
          sx={{ pr: 3, pl: 3 }}
          {...confirmButtonProps}
        >
          {t('modal.confirm')}
        </Button>
      </ModalFooter>
    </Paper>
  );
};

export default ConfirmationModal;
