import React, { PropsWithChildren } from 'react';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { isInBackofficePath } from '../../../utils';

const isBackoffice = isInBackofficePath();
let stripePromise: Promise<Stripe | null> | null;
if (!isBackoffice) {
  stripePromise =
    process.env.REACT_APP_STRIPE_PK != null
      ? loadStripe(process.env.REACT_APP_STRIPE_PK)
      : null;
}

function StripeProvider({ children }: PropsWithChildren) {
  if (!stripePromise) return <>{children}</>;
  return (
    <Elements
      stripe={stripePromise}
      options={{
        mode: 'setup',
        currency: 'eur',
        paymentMethodCreation: 'manual',
        appearance: {
          /*...*/
        },
      }}
    >
      {children}
    </Elements>
  );
}

export default StripeProvider;
