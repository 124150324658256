import { AxiosResponse } from 'axios';
import baseApi from '../../services/apiService';
import {
  Vehicle,
  VehicleActionLog,
  VehicleActions,
  VehicleListItem,
  VehicleModel,
  VehicleRemoveResponse,
} from '../../entities/Vehicle';
import {
  CreateVehicleParams,
  GetVehiclesParams,
  UpdateVehicleParams,
} from '../vehicle/queries';
import { PaginatedItems, PaginatedRequestParams } from './index';

export const vehiclesApi = {
  getVehicle: (vehicleId: string) =>
    baseApi.get<Vehicle>(`/api/vehicles/${vehicleId}`),
  updateVehicle: (vehicleId: string, params: UpdateVehicleParams) =>
    baseApi.put<UpdateVehicleParams, AxiosResponse<Vehicle>>(
      `/api/vehicles/${vehicleId}`,
      params,
    ),
  createVehicle: (params: CreateVehicleParams) =>
    baseApi.post<Vehicle>(`/api/vehicles`, params),
  deleteVehicle: (vehicleId: string) =>
    baseApi.post<VehicleRemoveResponse>(`/api/vehicles/${vehicleId}/remove`),
  getAllVehicles: (params?: GetVehiclesParams) =>
    baseApi.get<PaginatedItems<VehicleListItem>>('/api/vehicles', { params }),
  getVehiclesModels: () => baseApi.get<VehicleModel[]>('/api/vehicle-models'),
  getVehicleActions: (id: string) =>
    baseApi.get<VehicleActions>(`/api/vehicles/${id}/actions`),
  getVehicleActionLogs: (id: string, params: PaginatedRequestParams) =>
    baseApi.get<PaginatedItems<VehicleActionLog>>(
      `/api/vehicles/${id}/action-logs`,
      { params },
    ),
  executeVehicleAction: (id: string) =>
    baseApi.post<VehicleActionLog>(`/api/vehicle-actions/${id}/execute`),
};
