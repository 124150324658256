import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VerificationStatusType } from '../../entities/Customer';

function VerificationStatus({ status }: { status: VerificationStatusType }) {
  const { t } = useTranslation();
  switch (status) {
    case 'verified':
      return (
        <Typography>
          {t('customers.verification.statusType.verified')}
        </Typography>
      );
    case 'pending':
      return (
        <Typography>
          {t('customers.verification.statusType.pending')}
        </Typography>
      );
    case 'failed':
      return (
        <Typography>{t('customers.verification.statusType.failed')}</Typography>
      );
    default:
      return null;
  }
}

export default VerificationStatus;
