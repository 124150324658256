import { useQuery } from '@tanstack/react-query';
import api, { PaginatedRequestParams } from '../api';

export function useGetBonusCodeCustomers({
  id,
  params,
}: {
  id: string;
  params: PaginatedRequestParams;
}) {
  return useQuery({
    queryKey: ['bonus-codes', id, params],
    queryFn: async () => (await api.getBonusCodeCustomers(id, params)).data,
  });
}
