import { createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { jwtDecode } from 'jwt-decode';
import {
  BoardMemberRequest,
  CreateBusinessAccountFormSchema,
} from '../../b2b/features/auth/CreateAccount/api';
import { RootState } from '../index';
import { getCode, sendCode, signIn, tradeToken } from './thunk';

interface UserInfoState {
  isOnboarded: boolean;
  authToken?: string;
  accountCreationStepperData: AccountCreationStepperData;
}

export interface AccountCreationStepperData {
  activeStepIdx: number;
  skipped: number[];
  accountFormData?: CreateBusinessAccountFormSchema;
  paymentMethodId?: string;
  customerId?: string;
  isInvoiceEnabled: boolean;
  isInvoiceFormValid: boolean;
  invoiceFormData?: BoardMemberRequest;
}

const initialState: UserInfoState = {
  authToken: undefined,
  isOnboarded: false,
  accountCreationStepperData: {
    customerId: undefined,
    activeStepIdx: 0,
    skipped: [],
    paymentMethodId: undefined,
    isInvoiceEnabled: false,
    isInvoiceFormValid: false,
  },
};

const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setOnboarded: (state, action) => {
      state.isOnboarded = action.payload;
    },
    customerIdChanged: (state, action) => {
      state.accountCreationStepperData.customerId = action.payload;
    },
    activeStepIdxChanged: (state, action) => {
      state.accountCreationStepperData.activeStepIdx = action.payload;
    },
    paymentMethodIdChanged: (state, action) => {
      state.accountCreationStepperData.paymentMethodId = action.payload;
    },
    accountFormDataChanged: (state, action) => {
      state.accountCreationStepperData.accountFormData = action.payload;
    },
    invoiceFormDataChanged: (state, action) => {
      state.accountCreationStepperData.invoiceFormData = action.payload;
    },
    skippedChanged: (state, action) => {
      state.accountCreationStepperData.skipped = action.payload;
    },
    invoiceEnabledChanged: (state, action) => {
      state.accountCreationStepperData.isInvoiceEnabled = action.payload;
    },
    invoiceFormValidChanged: (state, action) => {
      state.accountCreationStepperData.isInvoiceFormValid = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(signIn.pending, state => ({
      ...state,
      authToken: undefined,
    }));
    builder.addCase(signIn.fulfilled, (state, action) => ({
      ...state,
      authToken: action.payload.token,
      isOnboarded: true,
    }));
    builder.addCase(signIn.rejected, state => ({
      ...state,
      authToken: undefined,
    }));
    builder.addCase(getCode.pending, state => ({
      ...state,
      authToken: undefined,
    }));
    builder.addCase(getCode.fulfilled, (state, action) => ({
      ...state,
      authToken: action.payload.token,
    }));
    builder.addCase(getCode.rejected, state => ({
      ...state,
      authToken: undefined,
    }));
    builder.addCase(sendCode.fulfilled, (state, action) => ({
      ...state,
      isOnboarded: !action.payload.isNewUser,
      authToken: action.payload.token,
    }));
    builder.addCase(tradeToken.fulfilled, (state, action) => ({
      ...state,
      authToken: action.payload.token,
    }));
  },
});

export interface JwtPayload {
  roles: Role[];
}

type Role =
  | 'PUBLIC_ACCESS'
  | 'ROLE_BACKOFFICE'
  | 'ROLE_TOKEN'
  | 'ROLE_REPRESENTATIVE'
  | 'ROLE_AUTHORIZED_PERSON';

export const selectIsAuthenticated = createSelector(
  [(state: RootState) => state.userReducer.authToken],
  authToken => {
    return !!authToken;
  },
);

export const selectRoles = createSelector(
  [(state: RootState) => state.userReducer.authToken],
  authToken => {
    if (!authToken) return [];
    try {
      const body = jwtDecode<JwtPayload>(authToken);
      return body.roles;
    } catch (e) {
      return [];
    }
  },
);

export const selectIsRoleRepresentative = createSelector(
  [selectRoles],
  roles => {
    return roles.includes('ROLE_REPRESENTATIVE');
  },
);

export const selectIsRoleBackoffice = createSelector([selectRoles], roles => {
  return roles.includes('ROLE_BACKOFFICE');
});

export const {
  setAuthToken,
  setOnboarded,
  activeStepIdxChanged,
  paymentMethodIdChanged,
  accountFormDataChanged,
  skippedChanged,
  customerIdChanged,
  invoiceEnabledChanged,
  invoiceFormValidChanged,
  invoiceFormDataChanged,
} = userSlice.actions;
export const { reducer } = userSlice;
