import React, { PropsWithChildren, useState } from 'react';
import {
  Box,
  Divider,
  FormControlLabel,
  Popover,
  Switch,
  Typography,
} from '@mui/material';
import { shouldStartMockApi, toggleMockApiFlag } from '../../utils/dev-utils';

const DevMenu: React.FC<PropsWithChildren> = ({ children }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  if (process.env.REACT_APP_DEVTOOLS !== 'true') {
    return <>{children}</>;
  }

  function handleClick(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  function handleMockApiToggle() {
    toggleMockApiFlag();
    window.location.reload();
  }

  const open = Boolean(anchorEl);
  const isUsingMockApi = shouldStartMockApi();

  return (
    <>
      <div onClick={handleClick}>{children}</div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box style={{ padding: '1em' }}>
          {/*eslint-disable-next-line @docusaurus/no-untranslated-text*/}
          <Typography>🔧 Development options</Typography>
          <Divider />
          <FormControlLabel
            control={
              <Switch
                readOnly
                defaultChecked={isUsingMockApi}
                onChange={handleMockApiToggle}
              />
            }
            // eslint-disable-next-line @docusaurus/no-untranslated-text
            label="Use mock API (MSW)"
          />
        </Box>
      </Popover>
    </>
  );
};

export default DevMenu;
