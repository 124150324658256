import * as Yup from 'yup';
import baseApi from '../../../services/apiService';

export const apiPaths = {
  accept: '/api/acceptances/confirm' as const,
};

export const acceptInviteApi = {
  accept: (code: string) =>
    baseApi.post<null, null, ConfirmAcceptanceRequest>(apiPaths.accept, {
      code,
    }),
};

export interface ConfirmAcceptanceRequest {
  code: string;
}

export const acceptCodeSchema = Yup.string()
  .matches(/^[A-Za-z0-9]*$/, 'b2b.acceptInvite.invalidCharacters')
  .required();
