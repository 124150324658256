import React, { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
} from '@mui/material';
import { generatePath, Link } from 'react-router-dom';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import MoreIcon from '../../../icons/MoreIcon';
import { B2B_COMPANY_EDIT_COST_GROUP } from '../../../utils/b2b-routes-constants';
import DeleteIcon from '../../../icons/DeleteIcon';
import { openModal } from '../../../store/modal/slice';
import { ModalType } from '../../../utils/modal-utils';
import { useAppDispatch } from '../../../store';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import EditIcon from '../../../icons/EditIcon';
import { formatMoney } from '../../../utils';
import {
  useDeleteCostGroup,
  useGetMyCostGroups,
  useGetPaymentMethods,
} from './api';

type Props = {
  readOnly?: boolean;
} & TableContainerProps;

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FAF9F8',
  },
}));

function CostGroups({ readOnly = false, ...tableContainerProps }: Props) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { successSnack, apiErrorSnack } = useSnacks();
  const { data: costGroups } = useGetMyCostGroups({});
  const { data: paymentMethods } = useGetPaymentMethods();
  const deleteCostGroup = useDeleteCostGroup();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedId, setSelectedId] = useState<string>('');
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = (
    id: string,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleDelete = () => {
    dispatch(
      openModal({
        type: ModalType.DELETE_CONFIRMATION_MODAL,
        props: {
          title: 'b2b.wallet.costGroup.deleteModalTitle',
          action: () => {
            if (!selectedId) return;
            deleteCostGroup.mutate(selectedId, {
              onSuccess: async () => {
                setAnchorEl(null);
                successSnack(t('b2b.wallet.costGroup.snacks.deleted'));
              },
              onError: e => apiErrorSnack(e),
            });
          },
        },
      }),
    );
  };
  const handleMenuClose = () => setAnchorEl(null);

  return costGroups && costGroups.items.length > 0 ? (
    <>
      <TableContainer {...tableContainerProps}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('b2b.wallet.costGroup.name')}</TableCell>
              <TableCell>{t('b2b.wallet.costGroup.paymentMethod')}</TableCell>
              <TableCell>
                {t('b2b.wallet.costGroup.dailyCompensatedAmount')}
              </TableCell>
              {!readOnly && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {costGroups.items.map(item => (
              <StyledTableRow key={item.id}>
                <TableCell sx={{ fontWeight: 600 }}>{item.name}</TableCell>
                <TableCell>
                  {
                    paymentMethods?.find(it => it.id === item.paymentMethod.id)
                      ?.title
                  }
                </TableCell>
                <TableCell>{formatMoney(item.dailyAmount)}</TableCell>
                {!readOnly && (
                  <TableCell>
                    <IconButton
                      onClick={e => handleMenuClick(item.id, e)}
                      aria-controls={menuOpen ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={menuOpen ? 'true' : undefined}
                    >
                      <MoreIcon />
                    </IconButton>
                  </TableCell>
                )}
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          component={Link}
          to={generatePath(B2B_COMPANY_EDIT_COST_GROUP, {
            id: selectedId,
          })}
        >
          <ListItemIcon>
            <EditIcon />
          </ListItemIcon>
          <ListItemText>{t('edit')}</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText>{t('remove')}</ListItemText>
        </MenuItem>
      </Menu>
    </>
  ) : null;
}

export default CostGroups;
