import React from 'react';

interface Props {
  fill?: string;
}
const ActiveStepIcon = ({ fill = '#00A399' }: Props) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="8" cy="8" r="7" stroke={fill} strokeWidth="2" />
  </svg>
);

export default ActiveStepIcon;
