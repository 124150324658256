import React from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Block } from '@mui/icons-material';
import { TabHeader } from '../../Common/TabHeader';
import { SecondaryButton } from '../../Common/SecondaryButton';
import NotificationsIcon from '../../../icons/NotificationsIcon';
import DeleteIcon from '../../../icons/DeleteIcon';

interface Props {
  onSendNotification: () => void;
  onBlockCustomer: () => void;
  onDeleteCustomer: () => void;
  onAddBonus: () => void;
  header: string;
}
export const CustomersTabHeader = ({
  onSendNotification,
  onDeleteCustomer,
  onBlockCustomer,
  onAddBonus,
  header,
}: Props) => {
  const { t } = useTranslation();

  return (
    <TabHeader text={header}>
      <SecondaryButton
        startIcon={<NotificationsIcon />}
        onClick={onSendNotification}
      >
        {t('customers.buttons.sendNotification')}
      </SecondaryButton>
      <SecondaryButton sx={{ ml: 2 }} startIcon={<Add />} onClick={onAddBonus}>
        {t('customers.buttons.addBonus')}
      </SecondaryButton>
      <SecondaryButton
        sx={{ ml: 2 }}
        startIcon={<Block />}
        onClick={onBlockCustomer}
      >
        {t('customers.buttons.block')}
      </SecondaryButton>
      <SecondaryButton
        sx={{ ml: 2 }}
        startIcon={<DeleteIcon />}
        onClick={onDeleteCustomer}
      >
        {t('customers.buttons.delete')}
      </SecondaryButton>
    </TabHeader>
  );
};
