import React from 'react';

const ReservationsIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.74661 9.14996C8.12529 9.14996 7.62161 9.65364 7.62161 10.275C7.62161 10.8963 8.12529 11.4 8.74661 11.4H8.75335C9.37467 11.4 9.87835 10.8963 9.87835 10.275C9.87835 9.65364 9.37467 9.14996 8.75335 9.14996H8.74661Z"
      fill="#33302F"
    />
    <path
      d="M4.09573 10.275C4.09573 9.65364 4.59941 9.14996 5.22073 9.14996H5.22747C5.84879 9.14996 6.35247 9.65364 6.35247 10.275C6.35247 10.8963 5.84879 11.4 5.22747 11.4H5.22073C4.59941 11.4 4.09573 10.8963 4.09573 10.275Z"
      fill="#33302F"
    />
    <path
      d="M5.22073 12.15C4.59941 12.15 4.09573 12.6536 4.09573 13.275C4.09573 13.8963 4.59941 14.4 5.22073 14.4H5.22747C5.84879 14.4 6.35247 13.8963 6.35247 13.275C6.35247 12.6536 5.84879 12.15 5.22747 12.15H5.22073Z"
      fill="#33302F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 0.75C5.41421 0.75 5.75 1.08579 5.75 1.5V1.87502L10.25 1.87501V1.5C10.25 1.08579 10.5858 0.75 11 0.75C11.4142 0.75 11.75 1.08579 11.75 1.5V1.875L12.1251 1.875C13.989 1.87501 15.5 3.38605 15.5 5.24998V13.875C15.5 15.739 13.989 17.25 12.125 17.25H3.875C2.01104 17.25 0.5 15.739 0.5 13.875V5.25003C0.5 3.38607 2.01104 1.87504 3.875 1.87503L4.25 1.87502V1.5C4.25 1.08579 4.58579 0.75 5 0.75ZM4.25 3.75V3.37502L3.875 3.37503C2.83947 3.37503 2 4.2145 2 5.25003V6.06747H14V5.24998C14 4.21446 13.1606 3.37501 12.125 3.375L11.75 3.375V3.75C11.75 4.16421 11.4142 4.5 11 4.5C10.5858 4.5 10.25 4.16421 10.25 3.75V3.37501L5.75 3.37502V3.75C5.75 4.16421 5.41421 4.5 5 4.5C4.58579 4.5 4.25 4.16421 4.25 3.75ZM2 13.875V7.56747H14V13.875C14 14.9106 13.1605 15.75 12.125 15.75H3.875C2.83947 15.75 2 14.9106 2 13.875Z"
      fill="#33302F"
    />
  </svg>
);

export default ReservationsIcon;
