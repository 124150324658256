import React from 'react';

const AvatarIcon = () => (
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_20_3071)">
      <circle cx="20" cy="20" r="19.5" fill="white" stroke="#E1DED9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 15.8333C17.5 14.4526 18.6193 13.3333 20 13.3333C21.3807 13.3333 22.5 14.4526 22.5 15.8333C22.5 17.214 21.3807 18.3333 20 18.3333C18.6193 18.3333 17.5 17.214 17.5 15.8333ZM20 11.6666C17.6988 11.6666 15.8333 13.5321 15.8333 15.8333C15.8333 18.1345 17.6988 20 20 20C22.3012 20 24.1667 18.1345 24.1667 15.8333C24.1667 13.5321 22.3012 11.6666 20 11.6666ZM16.6667 20.8333C15.5616 20.8333 14.5018 21.2723 13.7204 22.0537C12.939 22.8351 12.5 23.8949 12.5 25C12.5 26.3807 13.6193 27.5 15 27.5H25C26.3807 27.5 27.5 26.3807 27.5 25C27.5 23.8949 27.061 22.8351 26.2796 22.0537C25.4982 21.2723 24.4384 20.8333 23.3333 20.8333H16.6667ZM15 25.8333C14.5398 25.8333 14.1667 25.4602 14.1667 25C14.1667 24.3369 14.4301 23.701 14.8989 23.2322C15.3677 22.7634 16.0036 22.5 16.6667 22.5H23.3333C23.9964 22.5 24.6323 22.7634 25.1011 23.2322C25.5699 23.701 25.8333 24.3369 25.8333 25C25.8333 25.4602 25.4602 25.8333 25 25.8333H15Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_20_3071">
        <rect width="40" height="40" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AvatarIcon;
