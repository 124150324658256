import { MenuItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { MoreOptionsButton } from '../../Common/MoreOptionsButton';

interface Props {
  onSendNotification: () => void;
  onBlockCustomer: () => void;
  onDeleteCustomer: () => void;
  onAddBonus: () => void;
}
export const CustomersTableDropdown = ({
  onSendNotification,
  onDeleteCustomer,
  onBlockCustomer,
  onAddBonus,
}: Props) => {
  const { t } = useTranslation();

  return (
    <MoreOptionsButton>
      <MenuItem key={0} onClick={onSendNotification}>
        {t('customers.buttons.sendNotification')}
      </MenuItem>
      <MenuItem key={1} onClick={onBlockCustomer}>
        {t('customers.buttons.block')}
      </MenuItem>
      <MenuItem key={2} onClick={onDeleteCustomer}>
        {t('customers.buttons.delete')}
      </MenuItem>
      <MenuItem key={3} onClick={onAddBonus}>
        {t('customers.buttons.addBonus')}
      </MenuItem>
    </MoreOptionsButton>
  );
};
