import React from 'react';
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSnacks } from '../../../../components/Common/AvisSnackbarProvider';
import { useGetPaymentMethods } from '../api';
import { useModal } from '../../../../components/Modal/hooks';
import { StyledTableRow } from '../../../../components/Common/TableWrapper';
import { formatDateTime } from '../../../../utils/datetime-utils';
import AddIcon from '../../../../icons/AddIcon';
import { ModalType } from '../../../../utils/modal-utils';
import NoPaymentMethodIcon from '../../../../icons/NoPaymentMethodIcon';
import { B2B_COMPANY_MANAGE_PAYMENT_METHODS } from '../../../../utils/b2b-routes-constants';
import { NoDataPlaceholder } from '../../../../components/Common/NoDataPlaceholder';
import PaymentMethodDetails from '../../../../components/Common/PaymentMethodDetails';

type Props = {
  readOnly?: boolean;
} & TableContainerProps;

function PaymentMethods({ readOnly = false, ...tableContainerProps }: Props) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { errorSnack } = useSnacks();
  const { open } = useModal();
  const { data: paymentMethods } = useGetPaymentMethods();

  const handleAdd = () => {
    open({
      type: ModalType.ADD_PAYMENT_METHOD,
    });
  };

  const handleDefault = (methodId: string) => {
    errorSnack('TODO ' + methodId);
  };

  function handleNoDataAdd() {
    if (!location.pathname.includes(B2B_COMPANY_MANAGE_PAYMENT_METHODS)) {
      navigate(B2B_COMPANY_MANAGE_PAYMENT_METHODS);
    }
    handleAdd();
  }

  return paymentMethods && paymentMethods.length > 0 ? (
    <TableContainer {...tableContainerProps}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('b2b.wallet.paymentMethods.methodTitle')}</TableCell>
            <TableCell>{t('b2b.wallet.paymentMethods.expires')}</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paymentMethods.map(item => (
            <StyledTableRow key={item.id}>
              <TableCell>
                <PaymentMethodDetails paymentMethod={item} />
              </TableCell>
              <TableCell>
                {item.expires ? formatDateTime(item.expires, 'MM/yy') : '-'}
              </TableCell>
              <TableCell align="right">
                {item.default ? (
                  <Chip
                    label={t('b2b.wallet.paymentMethods.default')}
                    color="primary"
                  />
                ) : !readOnly ? (
                  <Chip
                    label={t('b2b.wallet.paymentMethods.makeDefault')}
                    clickable
                    onClick={() => handleDefault(item.id)}
                    variant="outlined"
                  />
                ) : null}
              </TableCell>
            </StyledTableRow>
          ))}
          {!readOnly && (
            <StyledTableRow>
              <TableCell align="center" colSpan={3}>
                <Button
                  onClick={handleAdd}
                  variant="contained"
                  size="medium"
                  color="secondary"
                  startIcon={<AddIcon />}
                >
                  {t('b2b.wallet.paymentMethods.manage.addButton')}
                </Button>
              </TableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <NoDataPlaceholder
      icon={<NoPaymentMethodIcon />}
      title={t('b2b.wallet.noData')}
      buttonTitle={t('b2b.wallet.buttons.addPaymentMethod')}
      onClick={handleNoDataAdd}
    />
  );
}

export default PaymentMethods;
