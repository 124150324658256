import {
  Action,
  AvailableVehicleListItem,
  GetAvailableVehiclesParams,
  GetReservationParams,
  GetVehiclesAvailabilityParams,
  ReservationDetails,
  ReservationInvoiceLink,
  ReservationListItem,
  ReservationPhoto,
  ReservationTrajectoryItem,
} from '../../entities/Reservation';
import baseApi from '../../services/apiService';
import { VehicleAvailabilityItem } from '../../entities/Vehicle';
import {
  CollectDebtBody,
  CreateMaintenanceReservationBody,
  CreateReservationInvoiceRequest,
  UpdateMaintenanceReservationBody,
  UpdateReservationBody,
} from '../../components/Reservations/api';
import { PaginatedItems, PaginatedRequestParams } from './index';

export const apiPaths = {
  reservations: '/api/reservations',
  reservation: (id: string) => `/api/reservations/${id}`,
  updateReservation: (id: string) => `/api/reservations/${id}/update`,
  createMaintenanceReservation: '/api/reservations/maintenance/create',
  updateMaintenanceReservation: (id: string) =>
    `/api/reservations/${id}/maintenance/change`,
  cancelMaintenanceReservation: (id: string) =>
    `/api/reservations/${id}/maintenance/cancel`,
  startReservation: (id: string) => `/api/reservations/${id}/backoffice-start`,
  endReservation: (id: string) => `/api/reservations/${id}/backoffice-end`,
  collectReservationDebt: (id: string) =>
    `/api/reservations/${id}/backoffice-collect-debt`,
  vehiclesAvailability: '/api/reservations/vehicles-availability',
  availableVehicles: '/api/reservations/available-vehicles',
  actions: (id: string) => `/api/reservations/${id}/actions`,
  trajectory: (id: string) => `/api/reservations/${id}/trajectory`,
  parkingPhotos: (id: string) => `/api/reservations/${id}/parking-photos`,
  invoices: (id: string) => `/api/reservations/${id}/download-invoices`,
  createInvoice: (id: string) =>
    `/api/reservations/${id}/backoffice-create-invoice`,
};

export const reservationsApi = {
  getReservations: (params: GetReservationParams) =>
    baseApi.get<PaginatedItems<ReservationListItem>>(apiPaths.reservations, {
      params,
    }),
  getReservation: (id: string) =>
    baseApi.get<ReservationDetails>(apiPaths.reservation(id)),
  updateReservation: (id: string, body: UpdateReservationBody) =>
    baseApi.post<ReservationDetails>(apiPaths.updateReservation(id), body),
  createMaintenanceReservation: (body: CreateMaintenanceReservationBody) =>
    baseApi.post<ReservationDetails>(
      apiPaths.createMaintenanceReservation,
      body,
    ),
  updateMaintenanceReservation: (
    id: string,
    body: UpdateMaintenanceReservationBody,
  ) =>
    baseApi.post<ReservationDetails>(
      apiPaths.updateMaintenanceReservation(id),
      body,
    ),
  cancelMaintenanceReservation: (id: string) =>
    baseApi.post<ReservationDetails>(apiPaths.cancelMaintenanceReservation(id)),
  startReservation: (id: string) =>
    baseApi.post<ReservationDetails>(apiPaths.startReservation(id)),
  endReservation: (id: string) =>
    baseApi.post<ReservationDetails>(apiPaths.endReservation(id)),
  collectReservationDebt: (id: string, body: CollectDebtBody) =>
    baseApi.post<ReservationDetails>(apiPaths.collectReservationDebt(id), body),
  getVehiclesAvailability: (params: GetVehiclesAvailabilityParams) =>
    baseApi.get<PaginatedItems<VehicleAvailabilityItem>>(
      apiPaths.vehiclesAvailability,
      { params },
    ),
  getAvailableVehicles: (params?: GetAvailableVehiclesParams) =>
    baseApi.get<PaginatedItems<AvailableVehicleListItem>>(
      apiPaths.availableVehicles,
      { params },
    ),
  getReservationActions: (id: string, params: PaginatedRequestParams) =>
    baseApi.get<PaginatedItems<Action>>(apiPaths.actions(id), {
      params,
    }),
  getReservationTrajectory: (id: string) =>
    baseApi.get<ReservationTrajectoryItem[]>(apiPaths.trajectory(id)),
  getReservationPhotos: (id: string) =>
    baseApi.get<ReservationPhoto[]>(apiPaths.parkingPhotos(id)),
  getReservationInvoiceLinks: (id: string) =>
    baseApi.get<ReservationInvoiceLink[]>(apiPaths.invoices(id)),
  createReservationInvoice: (
    id: string,
    body: CreateReservationInvoiceRequest,
  ) => baseApi.post<ReservationDetails>(apiPaths.createInvoice(id), body),
};
