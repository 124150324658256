import React from 'react';
import { ImageList, ImageListItem, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalHeader from '../Modal/ModalHeader';
import { useGetReservationPhotos } from './api';

type Props = {
  reservationId: string;
};

function ReservationPhotosModal({ reservationId }: Props) {
  const { t } = useTranslation();
  const { data: photos } = useGetReservationPhotos(reservationId);
  return (
    <Paper
      sx={{ width: '100%', maxWidth: 568, maxHeight: '80vh', overflow: 'auto' }}
    >
      <ModalHeader title={t('reservations.modal.photos.title')} />
      {photos && (
        <ImageList sx={{ padding: 4, textAlign: 'center' }} cols={1}>
          {photos.map(({ image }) => (
            <ImageListItem
              key={`rsrv-photo-${image.substring(image.length - 6)}`}
            >
              <img
                src={`data:image/jpeg;base64,${image}`}
                alt="Reservation parking photo"
              />
            </ImageListItem>
          ))}
        </ImageList>
      )}
    </Paper>
  );
}

export default ReservationPhotosModal;
