import React from 'react';
import { ButtonProps } from '@mui/material/Button/Button';
import { Button, LinkProps, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

type Props = {
  to?: string;
} & ButtonProps &
  LinkProps;

function LinkButton({ to, ...props }: Props) {
  return (
    <Button
      variant="outlined"
      component={to ? Link : Typography}
      sx={{
        typography: 'body2',
        paddingY: 0,
        height: '100%',
      }}
      to={to}
      {...props}
    />
  );
}

export default LinkButton;
