import React from 'react';
import { Box } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import AvisLogo from '../../../../icons/AvisLogo';
import CreateAccountStepper from './CreateAccountStepper';

const CreateAccountPage = () => {
  const isOnboarded = useAppSelector(state => state.userReducer.isOnboarded);
  const hasToken = useAppSelector(state => !!state.userReducer.authToken);

  if (isOnboarded) {
    return <Navigate to="/b2b/home" />;
  }
  if (!hasToken) {
    return <Navigate to="/b2b/login" />;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <AvisLogo />
      <Box
        sx={{
          display: 'flex',
          maxWidth: '520px',
        }}
      >
        <CreateAccountStepper />
      </Box>
    </Box>
  );
};

export default CreateAccountPage;
