import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { PageHeader } from '../../../components/Common/PageHeader';
import { NoDataPlaceholder } from '../../../components/Common/NoDataPlaceholder';
import NoPaymentMethodIcon from '../../../icons/NoPaymentMethodIcon';
import { DetailsCard } from '../../../components/Common/DetailsCard';
import { DetailRow } from '../../../components/Common/DetailRow';
import {
  B2B_COMPANY_MANAGE_COST_GROUPS,
  B2B_COMPANY_MANAGE_PAYMENT_METHODS,
} from '../../../utils/b2b-routes-constants';
import { secondaryButtonStyle } from '../../../components/Common/SecondaryButton';
import TableWrapper from '../../../components/Common/TableWrapper';
import EditIcon from '../../../icons/EditIcon';
import CostGroups from './CostGroups';
import { useGetMyCostGroups } from './api';
import PaymentMethods from './payment-methods/PaymentMethods';

const WalletPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: costGroups } = useGetMyCostGroups({});

  const mainCardContentBusiness = [
    <DetailRow
      key="companyName"
      title={t('b2b.wallet.balance.title')}
      content="0,00€"
    />,
    <DetailRow
      key="registrationNumber"
      title={t('b2b.wallet.monthlyLimit')}
      content="-"
    />,
    <DetailRow
      key="kmkrNumber"
      title={t('b2b.wallet.usedLimit')}
      content="-"
    />,
    <DetailRow
      key="address"
      title={t('b2b.wallet.freeLimit')}
      content="-"
      showDivider={false}
    />,
  ];

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <PageHeader text={t('b2b.drawer.wallet')}></PageHeader>

      <Box height="100%">
        <Typography variant="h4" sx={{ mb: 2 }}>
          {t('b2b.wallet.balance.title')}
        </Typography>

        <DetailsCard content={mainCardContentBusiness}></DetailsCard>

        <TableWrapper
          title={t('b2b.wallet.paymentMethods.title')}
          titleButton={
            <Button
              startIcon={<EditIcon />}
              component={Link}
              style={secondaryButtonStyle}
              to={B2B_COMPANY_MANAGE_PAYMENT_METHODS}
            >
              {t('b2b.wallet.paymentMethods.manageButton')}
            </Button>
          }
        >
          <PaymentMethods readOnly />
        </TableWrapper>

        <TableWrapper
          title={t('b2b.wallet.costGroup.title')}
          titleButton={
            <Button
              startIcon={<EditIcon />}
              component={Link}
              style={secondaryButtonStyle}
              to={B2B_COMPANY_MANAGE_COST_GROUPS}
            >
              {t('b2b.wallet.costGroup.buttons.manage')}
            </Button>
          }
        >
          {costGroups && costGroups.items.length > 0 ? (
            <CostGroups readOnly />
          ) : (
            <NoDataPlaceholder
              icon={<NoPaymentMethodIcon />}
              title={t('b2b.wallet.costGroup.noData')}
              buttonTitle={t('b2b.wallet.costGroup.add')}
              onClick={() => navigate(B2B_COMPANY_MANAGE_COST_GROUPS)}
            />
          )}
        </TableWrapper>
      </Box>
    </Box>
  );
};

export default WalletPage;
