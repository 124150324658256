import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Pagination } from '@mui/material';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { TabHeader } from '../../Common/TabHeader';
import { DetailsCard } from '../../Common/DetailsCard';
import { DetailRow } from '../../Common/DetailRow';
import { PaginatedRequestParams } from '../../../store/api';
import { DATE_TIME_WITH_SECONDS } from '../../../utils/datetime-utils';
import { useGetReservationActions } from '../api';

const ReservationDetailsActions = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();

  const [reservationParams, setReservationParams] =
    useState<PaginatedRequestParams>({
      page: 1,
      limit: 5,
    });

  const { data: actions } = useGetReservationActions(
    reservationId,
    reservationParams,
  );

  return (
    <>
      <TabHeader text={t('reservations.tabs.history')}></TabHeader>
      <Box height="100%">
        {actions?.items.map(action => {
          const { created, type } = action;

          const content = [
            <DetailRow
              key="type"
              title={t('reservations.history.type')}
              content={t('reservations.history.action.' + type)}
              showDivider={false}
            />,
            <DetailRow
              key="type"
              title={t('reservations.history.created')}
              content={moment(created).format(DATE_TIME_WITH_SECONDS)}
              showDivider={false}
            />,
          ];
          return <DetailsCard key={created + '' + type} content={content} />;
        })}
        {actions?.pagination.totalPages &&
          actions?.pagination.totalPages > 1 && (
            <Pagination
              count={actions?.pagination.totalPages}
              page={actions?.pagination.currentPage}
              onChange={(event, page) =>
                setReservationParams({ ...reservationParams, page: page })
              }
            />
          )}
      </Box>
    </>
  );
};

export default ReservationDetailsActions;
