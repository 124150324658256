import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from '@mui/material';
import {
  GridColDef,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { PaginatedTable } from '../Common/PaginatedTable';
import { DATE_ONLY } from '../../utils/datetime-utils';
import InvoicesToolbar from './InvoicesToolbar';
import { mockedInvoicesData } from './invoicesTableData';

const InvoicesTable = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [rows, setRows] = useState<GridRowsProp>([]);
  const [totalItems, setTotalItems] = useState(0);

  const fetchInvoices = useCallback(
    debounce(() => {
      // TODO: To be implemented when API is ready
    }, 300),
    [],
  );

  useEffect(() => {
    const {
      items,
      pagination: { totalItems },
    } = mockedInvoicesData;

    setRows(items);
    setTotalItems(totalItems);
  }, []);

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'date',
        headerName: t('invoices.date'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value ? moment(params.value).format(DATE_ONLY) : '',
      },
      {
        field: 'receiver',
        headerName: t('invoices.receiver'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: 'invoiceNumber',
        headerName: t('invoices.invoiceNumber'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: 'amount',
        headerName: t('invoices.amount'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value ? `${params.value} €` : '',
      },
      {
        field: 'status',
        headerName: t('invoices.status'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
    ],
    [],
  );

  return (
    <PaginatedTable
      rows={rows}
      columns={columns}
      totalItems={totalItems}
      isLoading={false}
      fetchRows={fetchInvoices}
      Toolbar={InvoicesToolbar}
      onRowClick={(params: GridRowParams) => navigate(params.row.id)}
    />
  );
};

export default InvoicesTable;
