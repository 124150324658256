import * as React from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@mui/icons-material/Email';
import { Form, Formik } from 'formik';
import { useSnacks } from '../../../../components/Common/AvisSnackbarProvider';
import InputField from '../../../../components/Common/FormikFields/InputField';
import { tradeToken } from '../../../../store/user/thunk';
import { useAppDispatch } from '../../../../store';
import { fieldOf } from '../../../../store/api';
import { customerIdChanged } from '../../../../store/user/slice';
import CheckboxField from '../../../../components/Common/FormikFields/CheckboxField';
import {
  AccountStepLayout,
  AccountStepLayoutWrapper,
  NextStepButton,
} from './CreateAccountStepper';
import { useAccountStepper } from './useAccountStepper';
import {
  businessAccountInitialState,
  businessAccountValidationSchema,
  CreateBusinessAccountFormSchema,
  useCreateBusinessCustomer,
} from './api';

const CompanyInfoStep = () => {
  const { t } = useTranslation();
  const { nextStep, accountFormData, setAccountFormData, customerId } =
    useAccountStepper();
  const createBusinessCustomer = useCreateBusinessCustomer();
  const { apiErrorSnack } = useSnacks();
  const dispatch = useAppDispatch();

  async function handleSubmit(values: CreateBusinessAccountFormSchema) {
    if (customerId) {
      nextStep();
      return;
    }
    setAccountFormData(values);
    try {
      const customerResponse = await createBusinessCustomer.mutateAsync(values);
      dispatch(customerIdChanged(customerResponse.id));
      await dispatch(tradeToken()).unwrap();
      nextStep();
    } catch (e) {
      apiErrorSnack(e);
    }
  }

  return (
    <Formik
      initialValues={accountFormData || businessAccountInitialState}
      validationSchema={businessAccountValidationSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ submitForm }) => (
        <AccountStepLayoutWrapper>
          <AccountStepLayout
            nextStepButton={<NextStepButton onClick={submitForm} />}
            content={
              <Form>
                <Grid container direction="column" rowGap={2}>
                  <Grid item>
                    <InputField
                      name={fieldOf<CreateBusinessAccountFormSchema>(
                        'companyName',
                      )}
                      label={t(`b2b.createAccount.companyName`)}
                      disabled={!!customerId}
                    />
                  </Grid>
                  <Grid item container columnSpacing={2}>
                    <Grid item md={6} xs={12}>
                      <InputField
                        name={fieldOf<CreateBusinessAccountFormSchema>(
                          'registrationNumber',
                        )}
                        label={t(`b2b.createAccount.registrationNumber`)}
                        disabled={!!customerId}
                      />
                    </Grid>
                    <Grid item md={6} xs={12} sx={{ mt: { xs: 2, md: 0 } }}>
                      <InputField
                        name={fieldOf<CreateBusinessAccountFormSchema>(
                          'vatNumber',
                        )}
                        label={t(`b2b.createAccount.kmkrNumber`)}
                        disabled={!!customerId}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <InputField
                      name={fieldOf<CreateBusinessAccountFormSchema>('address')}
                      label={t(`b2b.createAccount.address`)}
                      placeholder={t('b2b.createAccount.addressPlaceholder')}
                      helperText={t('b2b.createAccount.addressHelperText')}
                      disabled={!!customerId}
                    />
                  </Grid>
                  <Grid item>
                    <InputField
                      name={fieldOf<CreateBusinessAccountFormSchema>('email')}
                      label={t(`b2b.createAccount.email`)}
                      startAdornment={<EmailIcon />}
                      disabled={!!customerId}
                    />
                  </Grid>
                  <Grid item>
                    <Grid item xs={12}>
                      <CheckboxField
                        name={fieldOf<CreateBusinessAccountFormSchema>(
                          'emailMarketing',
                        )}
                        label={t('b2b.createAccount.agreeNews')}
                        disabled={!!customerId}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CheckboxField
                        name={fieldOf<CreateBusinessAccountFormSchema>(
                          'agreeTerms',
                        )}
                        label={t('b2b.createAccount.agreeTerms')}
                        disabled={!!customerId}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            }
          />
        </AccountStepLayoutWrapper>
      )}
    </Formik>
  );
};

export default CompanyInfoStep;
