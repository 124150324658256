import React, { useEffect, useState } from 'react';
import { Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import GoogleMapReact from 'google-map-react';
import { useParams } from 'react-router-dom';
import { TabHeader } from '../../Common/TabHeader';
import { useGetReservation, useGetReservationTrajectory } from '../api';
import { handleRideTrajectoryMapChange } from '../../../utils/google-maps-utils';

function ReservationTrajectory() {
  const { reservationId } = useParams();
  const { t } = useTranslation();
  const [map, setMap] = useState<google.maps.Map | undefined>(undefined);
  const [listener, setListener] = useState<google.maps.MapsEventListener>();

  const { data: reservation } = useGetReservation(reservationId);
  const isActive = reservation?.details.status.type === 'active';

  const {
    data: trajectory,
    isLoading,
    isError,
  } = useGetReservationTrajectory(reservationId, isActive ? 5000 : undefined);

  useEffect(() => {
    if (!map || !trajectory) return;
    setListener(
      handleRideTrajectoryMapChange(map, trajectory, isActive, listener),
    );
    return () => {
      if (listener) google.maps.event.removeListener(listener);
    };
  }, [trajectory]);

  return (
    <>
      <TabHeader text={t('reservations.tabs.trajectory')} />
      {isLoading ? (
        <Alert severity="info">{t('loading')}</Alert>
      ) : isError ? (
        <Alert severity="error">{t('error.failedToLoadPage')}</Alert>
      ) : !trajectory || trajectory.length === 0 ? (
        <Alert severity="warning">
          {t('reservations.trajectoryTab.noData')}
        </Alert>
      ) : (
        <Box height="100%">
          <GoogleMapReact
            options={{
              clickableIcons: false,
              fullscreenControl: false,
            }}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API as string,
            }}
            center={{
              lng: trajectory[0].location.coordinates[0],
              lat: trajectory[0].location.coordinates[1],
            }}
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map }) => {
              setMap(map);
              setListener(
                handleRideTrajectoryMapChange(map, trajectory, isActive),
              );
            }}
          />
        </Box>
      )}
    </>
  );
}

export default ReservationTrajectory;
