import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';
import { useMemo } from 'react';
import baseApi from '../../../services/apiService';
import {
  fieldOf,
  PaginatedItems,
  PaginatedRequestParams,
} from '../../../store/api';
import { useFullPhoneNumberValidator } from '../../../utils/yup';

export const apiPaths = {
  myCompany: '/api/business-customers/me',
  authorizedPersons: '/api/authorized-persons',
};

export const myCompanyApi = {
  getMyCompany: () => baseApi.get<CompanyResponse>(apiPaths.myCompany),
  editMyCompany: (body: EditMyCompanyRequest) =>
    baseApi.post(apiPaths.myCompany, body),
  getAuthorizedPersons: (params: PaginatedRequestParams) =>
    baseApi.get<PaginatedItems<AuthorizedPerson>>(apiPaths.authorizedPersons, {
      params,
    }),
  addAuthorizedPerson: (data: AddAuthorizedPersonRequest) =>
    baseApi.post(apiPaths.authorizedPersons, data),
  deleteAuthorizedPerson: (id: string) =>
    baseApi.delete(`${apiPaths.authorizedPersons}/${id}`),
};

export const companyResponseSchema = Yup.object({
  companyName: Yup.string().required(),
  registrationNumber: Yup.string(),
  vatNumber: Yup.string(),
  address: Yup.string(),
  representative: Yup.object({
    id: Yup.string().required(),
    name: Yup.string().required(),
    areaCode: Yup.string(),
    phoneNumber: Yup.string().required(),
  }).required(),
  email: Yup.string(),
  created: Yup.string(),
  verified: Yup.boolean(),
  contractNumber: Yup.string(),
  emailMarketing: Yup.boolean(),
});

export const editMyCompanyRequestSchema = Yup.object({
  vatNumber: Yup.string(),
  address: Yup.string(),
  email: Yup.string(),
  representativeId: Yup.string(),
  emailMarketing: Yup.boolean(),
  acceptTerms: Yup.boolean(),
});

export type CompanyResponse = Yup.InferType<typeof companyResponseSchema>;

export function useAuthorizedPersonSchema() {
  const phoneValidator = useFullPhoneNumberValidator();
  return useMemo(
    () =>
      Yup.object({
        [fieldOf<AddAuthorizedPersonRequest>('fullPhoneNumber')]:
          phoneValidator.required(),
      }),
    [phoneValidator],
  );
}

export interface AddAuthorizedPersonRequest {
  fullPhoneNumber: string;
}

export interface AuthorizedPerson {
  id?: string;
  name?: string;
  areaCode: string;
  phoneNumber: string;
  email?: string;
  acceptance: AuthorizedPersonAcceptance;
}

export interface AuthorizedPersonAcceptance {
  status: 'confirmed' | 'unconfirmed';
}

export type EditMyCompanyRequest = Yup.InferType<
  typeof editMyCompanyRequestSchema
>;

const MY_COMPANY_KEY = 'my-company';
const AUTHORIZED_PERSONS_KEY = 'authorized-persons';

export function useGetMyCompany() {
  return useQuery({
    queryKey: [MY_COMPANY_KEY],

    queryFn: async () => {
      return (await myCompanyApi.getMyCompany()).data;
    },
  });
}

export function useEditMyCompany() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (request: EditMyCompanyRequest) => {
      return (await myCompanyApi.editMyCompany(request)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [MY_COMPANY_KEY] }),
  });
}

export function useGetAuthorizedPersons(params: PaginatedRequestParams) {
  return useQuery({
    queryKey: [AUTHORIZED_PERSONS_KEY, params],
    queryFn: async () => {
      return (await myCompanyApi.getAuthorizedPersons(params)).data;
    },
  });
}

export function useAddAuthorizedPerson() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (req: AddAuthorizedPersonRequest) => {
      return (await myCompanyApi.addAuthorizedPerson(req)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [AUTHORIZED_PERSONS_KEY] }),
  });
}

export function useDeleteAuthorizedPerson() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      return (await myCompanyApi.deleteAuthorizedPerson(id)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [AUTHORIZED_PERSONS_KEY] }),
  });
}
