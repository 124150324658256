import * as React from 'react';
import { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, LinearProgress, StepIconProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { ArrowForward } from '@mui/icons-material';
import { ButtonProps } from '@mui/material/Button/Button';
import ActiveStepIcon from '../../../../icons/ActiveStepIcon';
import { SecondaryButton } from '../../../../components/Common/SecondaryButton';
import {
  CREATE_B2B_ACCOUNT_STEPS,
  useAccountStepper,
} from './useAccountStepper';

const StepIcon = (props: StepIconProps) => {
  const { active, completed } = props;

  return completed ? (
    <CheckCircleIcon sx={{ height: '16px', width: '16px' }} />
  ) : (
    <ActiveStepIcon fill={active ? '#00A399' : '#BBB8B4'} />
  );
};

const CreateAccountStepper = () => {
  const { activeStepIdx } = useAccountStepper();
  return activeStepIdx === CREATE_B2B_ACCOUNT_STEPS.length
    ? null
    : CREATE_B2B_ACCOUNT_STEPS[activeStepIdx].page;
};

export function AccountStepLayoutWrapper({ children, ...props }: BoxProps) {
  return (
    <Box
      sx={{
        marginTop: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
      }}
      {...props}
    >
      {children}
    </Box>
  );
}

export function AccountStepLayout({
  content,
  nextStepButton,
  backDisabled,
}: {
  content: ReactNode;
  nextStepButton?: ReactNode;
  backDisabled?: boolean;
}) {
  return (
    <>
      <AccountStepHeader />
      <AccountStepContent>{content}</AccountStepContent>
      <AccountStepFooter
        nextStepButton={nextStepButton}
        backDisabled={backDisabled}
      />
    </>
  );
}

function AccountStepHeader() {
  const { t } = useTranslation();
  const { activeStepIdx, activeStep, steps, isStepSkipped } =
    useAccountStepper();

  return (
    <>
      <Stepper activeStep={activeStepIdx} sx={{ marginBottom: '32px' }}>
        {steps.map((step, index) => {
          const stepProps = {
            completed: isStepSkipped(index) ? false : undefined,
          };

          return (
            <Step key={step.stepper} {...stepProps}>
              <StepLabel StepIconComponent={StepIcon}>
                {t(step.stepper)}
              </StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Typography variant="h4">{t(activeStep.title)}</Typography>
    </>
  );
}

function AccountStepContent({ children }: { children: ReactNode }) {
  const { activeStepIdx, steps } = useAccountStepper();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: '#FAF9F8',
        borderRadius: '8px',
        width: '100%',
        marginTop: '30px',
        minHeight: '360px',
      }}
    >
      <LinearProgress
        sx={{
          height: '6px',
          borderTopLeftRadius: '6px',
          borderTopRightRadius: '6px',
        }}
        variant="determinate"
        value={((activeStepIdx + 1) * 100) / steps.length}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginLeft: '40px',
          marginRight: '40px',
          marginTop: '24px',
          marginBottom: '20px',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export function NextStepButton(props: ButtonProps) {
  const { t } = useTranslation();
  const { nextStep, isLastStep } = useAccountStepper();

  return (
    <Button
      sx={{ width: '100%' }}
      variant="contained"
      size="medium"
      color="secondary"
      endIcon={<ArrowForward />}
      onClick={nextStep}
      {...props}
    >
      {isLastStep ? t('b2b.buttons.finish') : t('b2b.buttons.continue')}
    </Button>
  );
}

function AccountStepFooter({
  nextStepButton,
  backDisabled,
}: {
  nextStepButton?: ReactNode;
  backDisabled?: boolean;
}) {
  const { t } = useTranslation();
  const { activeStepIdx, activeStep, skipStep, prevStep } = useAccountStepper();

  return (
    <Grid container sx={{ marginTop: '32px', pb: 4 }}>
      <Grid item xs={4}>
        {activeStepIdx !== 0 && (
          <SecondaryButton
            disabled={activeStepIdx === 0 || backDisabled}
            onClick={prevStep}
          >
            {t('b2b.buttons.back')}
          </SecondaryButton>
        )}
      </Grid>
      <Grid item xs={4}>
        {nextStepButton ? nextStepButton : <NextStepButton />}
      </Grid>
      <Grid item container xs={4} direction="row" justifyContent="flex-end">
        {activeStep.optional && (
          <SecondaryButton disabled={activeStepIdx === 0} onClick={skipStep}>
            {t('b2b.buttons.skip')}
          </SecondaryButton>
        )}
      </Grid>
    </Grid>
  );
}

export default CreateAccountStepper;
