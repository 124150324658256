import React from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { DetailRow } from '../../Common/DetailRow';
import {
  SecondaryButton,
  secondaryButtonStyle,
} from '../../Common/SecondaryButton';
import EditIcon from '../../../icons/EditIcon';
import DeleteIcon from '../../../icons/DeleteIcon';
import { DATE_ONLY, formatDateTime } from '../../../utils/datetime-utils';
import {
  formatMoney,
  formatPhoneNumber,
  isInBackofficePath,
} from '../../../utils';
import LinkButton from '../../Common/LinkButton';
import { B2B_RESERVATIONS } from '../../../utils/b2b-routes-constants';
import { RelatedCustomerListItem } from '../../../store/api/related-customers';
import { RESERVATIONS } from '../../../utils/routes-constants';

interface Props {
  relatedCustomer: RelatedCustomerListItem;
  onDelete: () => void;
}

const isBackoffice = isInBackofficePath();

const RelatedCustomerCard = ({ relatedCustomer, onDelete }: Props) => {
  const { t } = useTranslation();

  const {
    created,
    totalReservations,
    limitToDateTime,
    invoicingComment,
    compensationType,
    costGroup,
    acceptance,
  } = relatedCustomer;

  const {
    id: privateCustomerId,
    name,
    email,
    areaCode,
    phoneNumber,
  } = relatedCustomer.privateCustomer;

  return (
    <>
      <Grid item container md={4} rowGap={2} alignContent="start">
        {!acceptance || acceptance?.status === 'confirmed' ? (
          <>
            <DetailRow
              title={t('relatedCustomers.name')}
              content={name}
              showDivider={false}
              titleWidth={6}
              contentWidth={6}
            />
            <DetailRow
              title={t('relatedCustomers.email')}
              content={email}
              showDivider={false}
              titleWidth={6}
              contentWidth={6}
            />
          </>
        ) : (
          <DetailRow
            title={t('relatedCustomers.acceptance')}
            content={t('no')}
            showDivider={false}
            titleWidth={6}
            contentWidth={6}
          />
        )}
        <DetailRow
          title={t('relatedCustomers.phoneNumber')}
          content={formatPhoneNumber(areaCode, phoneNumber)}
          showDivider={false}
          titleWidth={6}
          contentWidth={6}
        />
        <DetailRow
          title={t('relatedCustomers.addedDate')}
          content={formatDateTime(created)}
          showDivider={false}
          titleWidth={6}
          contentWidth={6}
        />
        <DetailRow
          title={t('relatedCustomers.reservations')}
          content={
            <LinkButton
              to={`${
                isBackoffice ? RESERVATIONS : B2B_RESERVATIONS
              }?customerId=${privateCustomerId}`}
              disabled={totalReservations === 0}
            >
              {totalReservations}
            </LinkButton>
          }
          showDivider={false}
          titleWidth={6}
          contentWidth={6}
        />
      </Grid>
      <Grid item container md={4} rowGap={2}>
        <DetailRow
          title={t('relatedCustomers.costGroup')}
          content={costGroup.name}
          showDivider={false}
          titleWidth={6}
          contentWidth={6}
        />
        <DetailRow
          title={t('relatedCustomers.dailyLimitPerReservation')}
          content={formatMoney(costGroup.dailyAmount)}
          showDivider={false}
          titleWidth={6}
          contentWidth={6}
        />
        <DetailRow
          title={t('relatedCustomers.limitValidUntil')}
          content={formatDateTime(limitToDateTime, DATE_ONLY)}
          showDivider={false}
          titleWidth={6}
          contentWidth={6}
        />
        <DetailRow
          title={t('relatedCustomers.invoicingComment')}
          content={invoicingComment}
          showDivider={false}
          titleWidth={6}
          contentWidth={6}
        />
        <DetailRow
          title={t('relatedCustomers.compensationType')}
          content={compensationType}
          showDivider={false}
          titleWidth={6}
          contentWidth={6}
        />
      </Grid>
      <Grid item container md={4} display="flex" justifyContent="end" gap={2}>
        <SecondaryButton startIcon={<DeleteIcon />} onClick={onDelete}>
          {t('delete')}
        </SecondaryButton>
        <Button
          variant="outlined"
          style={secondaryButtonStyle}
          startIcon={<EditIcon />}
          component={NavLink}
          to={`${relatedCustomer.id}/edit`}
        >
          {t('edit')}
        </Button>
      </Grid>
    </>
  );
};

export default RelatedCustomerCard;
