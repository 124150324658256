import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  Button,
  FormLabel,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import {
  GetReservationParams,
  ReservationStatusType,
  ReservationType,
} from '../../entities/Reservation';
import { CustomerListView } from '../../utils/customer-utils';
import { ParkingZone } from '../../entities/Vehicle';
import TextField from '../Common/TextField';
import Select from '../Common/Select';
import DateTimePicker from '../Common/DateTimePicker';
import { PaginatedTableToolbar } from '../Common/PaginatedTable';
import { useGetParkingZones } from '../../store/vehicle/queries';

const reservationTypeMapping: Record<ReservationType, boolean> = {
  [ReservationType.CUSTOMER]: false,
  [ReservationType.MAINTENANCE]: true,
};

const ReservationsToolbar = ({
  params,
  setParams,
  searchFocus,
  setSearchFocus,
}: PaginatedTableToolbar<GetReservationParams>) => {
  const { t } = useTranslation();

  const { data: parkingZones } = useGetParkingZones();

  const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFocus(true);
    setParams((prevState: GetReservationParams) => ({
      ...prevState,
      search: event.target.value,
    }));
  };

  const onFilterValueChange = (value: string, field: string) => {
    setParams((prevState: GetReservationParams) => ({
      ...prevState,
      [field]: value || undefined,
    }));
  };

  const handleClearButton = () => {
    setParams(({ page, limit }: GetReservationParams) => ({
      page,
      limit,
    }));
  };

  return (
    <GridToolbarContainer>
      <Grid container columnGap={2}>
        <Grid item xs={2}>
          <FormLabel>{t(`reservations.filters.search`)}</FormLabel>
          <TextField
            value={params.search || ''}
            onChange={onSearchValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            autoFocus={searchFocus}
          />
        </Grid>
        <Grid item xs={1}>
          <FormLabel>{t(`reservations.filters.customerType`)}</FormLabel>
          <Select
            items={Object.values(CustomerListView).map(
              (status: CustomerListView) => ({
                value: status,
                label: t(`reservations.customerTypes.${status}`),
              }),
            )}
            value={params.customerType || ''}
            onChange={(value: string) =>
              onFilterValueChange(value, 'customerType')
            }
            displayEmpty
          />
        </Grid>
        <Grid item xs={4}>
          <FormLabel>{t(`reservations.filters.reservationDates`)}</FormLabel>
          <Grid item container gap={2} wrap="nowrap">
            <DateTimePicker
              value={params.fromDateTime}
              onChange={(value: string) =>
                onFilterValueChange(value, 'fromDateTime')
              }
            />
            <DateTimePicker
              value={params.toDateTime}
              onChange={(value: string) =>
                onFilterValueChange(value, 'toDateTime')
              }
            />
          </Grid>
        </Grid>
        <Grid item xs={1}>
          <FormLabel>{t(`reservations.filters.carZone`)}</FormLabel>
          <Select
            items={(parkingZones || []).map(({ id, name }: ParkingZone) => ({
              value: id,
              label: name,
            }))}
            value={params.pickupParkingZoneId || ''}
            onChange={(value: string) =>
              onFilterValueChange(value, 'pickupParkingZoneId')
            }
            displayEmpty
          />
        </Grid>
        <Grid item xs={1}>
          <FormLabel>{t(`reservations.filters.reservationStatus`)}</FormLabel>
          <Select
            items={Object.values(ReservationStatusType).map(
              (status: ReservationStatusType) => ({
                value: status,
                label: t(`reservations.reservationStatus.${status}`),
              }),
            )}
            value={params.statusType || ''}
            onChange={(value: string) =>
              onFilterValueChange(value, 'statusType')
            }
            displayEmpty
          />
        </Grid>
        <Grid item xs={1}>
          <FormLabel>{t(`reservations.filters.reservationType`)}</FormLabel>
          <Select
            items={Object.values(ReservationType).map(
              (status: ReservationType) => ({
                value: `${reservationTypeMapping[status]}`,
                label: t(`reservations.reservationType.${status}`),
              }),
            )}
            value={params.maintenance || ''}
            onChange={(value: string) =>
              onFilterValueChange(value, 'maintenance')
            }
            displayEmpty
          />
        </Grid>
        <Grid item sx={{ paddingTop: 3 }} xs={1}>
          <Button
            style={{ color: '#141313' }}
            startIcon={<Close />}
            onClick={handleClearButton}
          >
            <Typography variant="subtitle2">{t('clearAll')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default ReservationsToolbar;
