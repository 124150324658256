import React from 'react';
import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { SecondaryButton } from '../../../components/Common/SecondaryButton';
import { TabHeader } from '../../../components/Common/TabHeader';
import { B2B_USERS } from '../../../utils/b2b-routes-constants';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import {
  CreateMyRelatedCustomerRequest,
  Fields,
  useRelatedCustomerSchemas,
} from '../../../store/api/related-customers';
import { useAddMyRelatedCustomer } from '../../../store/related-customers';
import RelatedCustomerForm, {
  useFieldLabels,
} from '../../../components/Customers/RelatedCustomer/RelatedCustomerForm';
import { useGetMyCostGroups } from '../wallet/api';
import PhoneField from '../../../components/Common/FormikFields/PhoneField';

const initialValues: CreateMyRelatedCustomerRequest = {
  costGroupId: '',
  compensationType: '',
  invoicingComment: '',
  fullPhoneNumber: '',
};

function AddMyUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snacks = useSnacks();

  const { createMyUserSchema } = useRelatedCustomerSchemas();
  const addRelatedCustomer = useAddMyRelatedCustomer();
  const { data: costGroups } = useGetMyCostGroups({});

  const fieldLabels = useFieldLabels();

  function handleSubmit(values: CreateMyRelatedCustomerRequest) {
    addRelatedCustomer.mutate(createMyUserSchema.cast(values), {
      onSuccess: () => {
        snacks.successSnack(t('relatedCustomers.snacks.added'));
        navigate(-1);
      },
      onError: e => snacks.apiErrorSnack(e, fieldLabels),
    });
  }

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <TabHeader text={t('b2b.myUsers.addRelatedCustomer')}>
        <SecondaryButton
          startIcon={<Close />}
          onClick={() => navigate(B2B_USERS)}
        >
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={createMyUserSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <RelatedCustomerForm
          costGroups={costGroups?.items}
          customerIdentifierField={
            <PhoneField
              name={Fields.fullPhoneNumber}
              label={t('relatedCustomers.phoneNumber')}
              placeholder={t('relatedCustomers.form.placeholders.phoneNumber')}
            />
          }
        />
      </Formik>
    </Box>
  );
}

export default AddMyUser;
