export const ROOT = '/admin';
export const HOME = `${ROOT}/home`;
export const LOGIN = `${ROOT}/login`;

export const RESERVATIONS = `${ROOT}/reservations`;
export const RESERVATION_ADD = `${RESERVATIONS}/add`;
export const RESERVATION_EDIT = `${RESERVATIONS}/:reservationId/edit`;
export const RESERVATION_DETAILS = `${RESERVATIONS}/:reservationId/*`;
export const RESERVATIONS_PARENT = `${RESERVATIONS}/*`;
export const RESERVATIONS_CALENDAR = `${RESERVATIONS}/calendar`;

export const INVOICES = `${ROOT}/invoices`;
export const INVOICES_PARENT = `${INVOICES}/*`;
export const INVOICE_DETAILS = `${INVOICES}/:invoiceId`;

export const SETTINGS = `${ROOT}/settings`;
export const SETTINGS_PARENT = `${SETTINGS}/*`;
export const ZONE_NEW = `${SETTINGS}/zones/new`;
export const ZONE_EDIT = `${SETTINGS}/zones/:zoneId/edit`;
export const PRICE_CLASSES = `${SETTINGS}/rules`;
export const PRICE_CLASS_NEW = `${SETTINGS}/rules/new`;
export const PRICE_CLASS_EDIT = `${SETTINGS}/rules/:priceClassId/edit`;
export const BONUS_CODES = `${SETTINGS}/codes`;
export const BONUS_CODE_NEW = `${SETTINGS}/codes/new`;
export const BONUS_CODE_EDIT = `${SETTINGS}/codes/:bonusCodeId/edit`;

export const VEHICLES = `${ROOT}/vehicles`;
export const VEHICLE_NEW = `${VEHICLES}/new`;
export const VEHICLE_DETAILS = `${VEHICLES}/:vehicleId/*`;
export const VEHICLE_EDIT = `${VEHICLES}/:vehicleId/edit`;

export const CUSTOMERS = `${ROOT}/customers`;
export const CUSTOMER_DETAILS_PARENT = `${CUSTOMERS}/:customerId/*`;
export const CUSTOMER_DETAILS = `${CUSTOMERS}/:customerId`;
export const CUSTOMER_EDIT = `${CUSTOMERS}/:customerId/edit`;

export const CUSTOMERS_BUSINESS = `${CUSTOMERS}/business`;
export const BUSINESS_CUSTOMER_DETAILS_PARENT = `${CUSTOMERS_BUSINESS}/:customerId/*`;
export const BUSINESS_CUSTOMER_DETAILS = `${CUSTOMERS_BUSINESS}/:customerId`;
export const BUSINESS_CUSTOMER_EDIT = `${CUSTOMERS_BUSINESS}/:customerId/edit`;
