import { Button, ButtonProps } from '@mui/material';
import React from 'react';

export const secondaryButtonStyle = {
  background: '#FFFFFF',
  border: '1px solid #E1DED9',
  boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
  color: '#141313',
};

export const SecondaryButton = (props: ButtonProps) => (
  <Button
    style={{
      ...secondaryButtonStyle,
      color: props.disabled ? 'default' : secondaryButtonStyle.color,
    }}
    variant="outlined"
    {...props}
  />
);
