import React from 'react';
import { useTranslation } from 'react-i18next';
import SelectField from '../../Common/FormikFields/SelectField';
import { SelectItem } from '../../Common/Select';
import { useGetAvailableVehicles } from '../api';

function AvailableVehicleSelect({
  fieldName,
  startDateTime,
  endDateTime,
  parkingZoneId,
  initialItem,
}: {
  fieldName: string;
  startDateTime: string;
  endDateTime: string;
  parkingZoneId: string;
  initialItem?: SelectItem;
}) {
  const { t } = useTranslation();

  const { data: vehicles, isFetching } = useGetAvailableVehicles({
    params: {
      startDateTime,
      endDateTime,
      parkingZoneId,
    },
    select: items =>
      items.map(item => ({
        value: item.id,
        label: item.registrationNumber,
      })),
  });

  const noVehiclesFound = vehicles == null || vehicles.length === 0;

  return (
    <SelectField
      name={fieldName}
      items={vehicles ?? []}
      label={t('reservations.car')}
      placeholder={t('reservations.form.placeholders.car')}
      disabled={!vehicles || vehicles.length === 0}
      loading={isFetching}
      initialItem={initialItem}
      helperText={
        noVehiclesFound && !isFetching
          ? t('reservations.form.noVehiclesFoundForParkingZone')
          : undefined
      }
    />
  );
}

export default AvailableVehicleSelect;
