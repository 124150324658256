import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { TabHeader } from '../../../../components/Common/TabHeader';
import { SecondaryButton } from '../../../../components/Common/SecondaryButton';
import PaymentMethods from './PaymentMethods';

function ManagePaymentMethodsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box sx={{ flex: 1 }}>
      <TabHeader
        headerVariant="h3"
        text={t('b2b.wallet.paymentMethods.manage.title')}
      >
        <SecondaryButton startIcon={<Close />} onClick={() => navigate(-1)}>
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>

      <PaymentMethods sx={{ mt: 4, maxWidth: '800px' }} />
    </Box>
  );
}

export default ManagePaymentMethodsPage;
