import React, { PropsWithChildren, ReactNode } from 'react';
import {
  Box,
  FormHelperTextProps,
  TextField as MuiTextField,
  TextFieldProps as MuiTextFieldProps,
  Typography,
} from '@mui/material';
import { WarningAmberRounded } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

export interface TextFieldProps extends Omit<MuiTextFieldProps, 'helperText'> {
  helperText?: ReactNode;
  hideHelperText?: boolean;
}

function TextField({ hideHelperText, helperText, ...props }: TextFieldProps) {
  return (
    <MuiTextField
      {...props}
      variant="outlined"
      size="small"
      helperText={
        hideHelperText || !helperText ? null : props.error ? (
          <ErrorHelperText>{helperText}</ErrorHelperText>
        ) : (
          helperText
        )
      }
      FormHelperTextProps={props.error ? helperTextProps : {}}
      fullWidth
    />
  );
}

export function ErrorHelperText({ children }: PropsWithChildren) {
  const { t } = useTranslation();
  return (
    <Box display="flex" alignItems="center" gap={0.5} component="span">
      <WarningAmberRounded />
      <Typography variant="body2" component="span">
        {typeof children === 'string' ? t(children) : children}
      </Typography>
    </Box>
  );
}

export const helperTextProps: Partial<FormHelperTextProps> = {
  sx: { color: '#D4002A', marginLeft: 0 },
};

export default TextField;
