import React from 'react';
import { Box, Grid, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Check } from '@mui/icons-material';
import { Vehicle } from '../../../entities/Vehicle';
import { DetailRow } from '../../Common/DetailRow';
import { DetailContent } from '../../Common/DetailContent';
import { DetailsCard } from '../../Common/DetailsCard';
import { VehicleStatusTextDetails } from '../VehicleStatus';
import { VehicleStateChip } from '../VehicleStateChip';
import { SecondaryButton } from '../../Common/SecondaryButton';
import EditIcon from '../../../icons/EditIcon';
import DeleteIcon from '../../../icons/DeleteIcon';
import { TabHeader } from '../../Common/TabHeader';
import { useRemoveVehicle } from '../../../store/vehicle/queries';
import { ModalType } from '../../../utils/modal-utils';
import { DATE_ONLY, formatDateTime } from '../../../utils/datetime-utils';
import LinkButton from '../../Common/LinkButton';
import { RESERVATIONS } from '../../../utils/routes-constants';
import { useModal } from '../../Modal/hooks';

interface Props {
  vehicle: Vehicle;
}

export const GeneralVehicleDetails = ({ vehicle }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const removeVehicle = useRemoveVehicle();
  const { open } = useModal();

  if (!vehicle) return null;

  function handleLocationClick(vehicle: Vehicle) {
    if (
      !vehicle.iotData.lastLocation ||
      vehicle.iotData.lastLocation.type !== 'Point'
    )
      return;
    open({
      type: ModalType.VEHICLE_LOCATION,
      props: {
        title: `${t('vehicles.lastLocation')} - ${vehicle.registrationNumber}`,
        id: `map-${vehicle.id}`,
        lastLocation: vehicle.iotData.lastLocation,
      },
    });
  }

  const {
    lockStatus,
    ignition,
    starterBlocked,
    carDoorStatus,
    lastSignal,
    lastLocation,
    nextMaintenanceDate,
    currentOdometerKm,
    fuelReserve,
    vehicleBatteryVoltage,
    remainingRangeKm,
    nextMaintenanceOdometerKm,
    activity,
  } = vehicle.iotData;

  const {
    activeReservation,
    nextReservation,
    lastReservation,
    totalReservations,
  } = vehicle.reservationData;

  return (
    <Box height="100%">
      <TabHeader text={t('vehicles.tabs.general')}>
        <SecondaryButton
          startIcon={<EditIcon />}
          onClick={() => navigate('edit')}
        >
          {t('vehicles.buttons.edit')}
        </SecondaryButton>
        <SecondaryButton
          sx={{ ml: 2 }}
          startIcon={<DeleteIcon />}
          onClick={() =>
            open({
              type: ModalType.DELETE_CONFIRMATION_MODAL,
              props: {
                title: 'vehicles.deleteVehicle',
                action: () =>
                  removeVehicle.mutate(vehicle.id, {
                    onSuccess: () => navigate('/vehicles'),
                  }),
              },
            })
          }
        >
          {t('vehicles.buttons.delete')}
        </SecondaryButton>
      </TabHeader>

      <DetailsCard>
        <DetailRow
          title={t('vehicles.registrationNumber')}
          content={vehicle.registrationNumber}
        />
        <DetailRow
          title={t('vehicles.model')}
          content={
            <Grid item xs={8}>
              <DetailContent text={vehicle.model.model} />
            </Grid>
          }
        />
        <DetailRow
          title={t('vehicles.pricingClass')}
          content={vehicle.priceClass.name}
        />
        <DetailRow
          title={t('vehicles.carZone')}
          content={vehicle.parkingZone?.name}
        />
        <DetailRow
          title={t('vehicles.status')}
          content={
            <Tooltip title={vehicle.status.comment} placement="left">
              <Box>
                <VehicleStatusTextDetails value={vehicle.status.type.slug} />
              </Box>
            </Tooltip>
          }
        />
        <DetailRow
          title={t('vehicles.state')}
          content={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: '8px',
              }}
            >
              {vehicle.states.map(state => (
                <VehicleStateChip
                  key={state.type.slug}
                  value={state.type.slug}
                  extended={true}
                />
              ))}
            </Box>
          }
          showDivider={false}
        />
      </DetailsCard>

      <DetailsCard
        content={
          <>
            <DetailRow
              key="activeReservation"
              title={t('vehicles.activeReservation')}
              content={
                activeReservation ? (
                  <LinkButton to={`${RESERVATIONS}/${activeReservation.id}`}>
                    {formatDateTime(activeReservation.startDateTime)}
                  </LinkButton>
                ) : null
              }
            />
            <DetailRow
              key="nextReservation"
              title={t('vehicles.nextReservation')}
              content={
                nextReservation ? (
                  <LinkButton to={`${RESERVATIONS}/${nextReservation.id}`}>
                    {formatDateTime(nextReservation.startDateTime)}
                  </LinkButton>
                ) : null
              }
            />
            <DetailRow
              key="lastReservation"
              title={t('vehicles.lastReservation')}
              content={
                lastReservation ? (
                  <LinkButton to={`${RESERVATIONS}/${lastReservation.id}`}>
                    {formatDateTime(lastReservation.startDateTime)}
                  </LinkButton>
                ) : null
              }
            />
            <DetailRow
              key="totalReservations"
              title={t('vehicles.totalReservations')}
              content={
                <LinkButton
                  to={`${RESERVATIONS}?search=${vehicle.registrationNumber}`}
                >
                  {totalReservations}
                </LinkButton>
              }
              showDivider={false}
            />
          </>
        }
      />

      <DetailsCard>
        <DetailRow title={t('vehicles.fuelType')} content={vehicle.fuelType} />
        <DetailRow
          title={t('vehicles.fuelCardNumber')}
          content={vehicle.fuelCard}
        />
        <DetailRow
          title={t('vehicles.fuelCardPin')}
          content={vehicle.fuelCardPin?.toString()}
          showDivider={false}
        />
      </DetailsCard>

      <DetailsCard>
        <DetailRow
          title={t('vehicles.lockStatus')}
          content={lockStatus ? <Check /> : null}
        />
        <DetailRow
          title={t('vehicles.starterBlocked')}
          content={starterBlocked ? <Check /> : null}
        />
        <DetailRow
          title={t('vehicles.ignition')}
          content={ignition ? <Check /> : null}
        />
        <DetailRow title={t('vehicles.doorStatus')} content={carDoorStatus} />
        <DetailRow title={t('vehicles.activity')} content={activity} />
        {vehicleBatteryVoltage && (
          <DetailRow
            title={t('vehicles.vehicleBatteryVoltage')}
            content={`${vehicleBatteryVoltage}V`}
          />
        )}
        {remainingRangeKm && (
          <DetailRow
            title={t('vehicles.remainingRange')}
            content={`${remainingRangeKm.toFixed(2)} km`}
          />
        )}
        <DetailRow
          title={t('vehicles.fuelTank')}
          content={
            fuelReserve != null && fuelReserve.capacityLitres > 0
              ? `${fuelReserve.remainingLitres.toFixed(2)} / ${
                  fuelReserve.capacityLitres
                } L`
              : null
          }
        />
        <DetailRow
          title={t('vehicles.lastSignal')}
          content={lastSignal ? formatDateTime(lastSignal) : null}
        />
        <DetailRow
          title={t('vehicles.lastLocation')}
          content={
            lastLocation ? (
              <LinkButton onClick={() => handleLocationClick(vehicle)}>
                {t('vehicles.showLastLocation')}
              </LinkButton>
            ) : null
          }
        />
        <DetailRow
          title={t('vehicles.nextMaintenance')}
          content={
            nextMaintenanceDate
              ? formatDateTime(nextMaintenanceDate, DATE_ONLY)
              : null
          }
        />
        <DetailRow
          title={t('vehicles.nextMaintenanceOdometerKm')}
          content={
            nextMaintenanceOdometerKm != null
              ? `${nextMaintenanceOdometerKm} km`
              : null
          }
        />
        <DetailRow
          title={t('vehicles.currentOdometer')}
          content={currentOdometerKm != null ? `${currentOdometerKm} km` : null}
          showDivider={false}
        />
      </DetailsCard>
    </Box>
  );
};
