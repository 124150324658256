import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainHeader } from '../../Common/MainHeader';
import { TabMenu } from '../../Common/TabMenu';

const menuItems = [
  { titleSlug: 'customers.tabs.private', path: '' },
  { titleSlug: 'customers.tabs.business', path: 'business' },
];

const CustomersContainer = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <MainHeader text={t('drawer.customers')}></MainHeader>
      <TabMenu menuItems={menuItems}></TabMenu>
      <Outlet />
    </Box>
  );
};

export default CustomersContainer;
