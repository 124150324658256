import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainHeader } from '../../Common/MainHeader';
import { TabMenu } from '../../Common/TabMenu';
import { RESERVATIONS } from '../../../utils/routes-constants';
import { useGetReservation } from '../api';
import ReservationGeneralDetails from './ReservationGeneralDetails';
import ReservationDetailsActions from './ReservationDetailsActions';
import ReservationTrajectory from './ReservationTrajectory';

const ReservationDetailsContainer = () => {
  const { t } = useTranslation();

  const { reservationId } = useParams();
  const { data: reservation } = useGetReservation(reservationId);

  const isStarted = ['active', 'ended'].includes(
    reservation?.details.status.type ?? '',
  );

  const menuItems = useMemo(() => {
    const items = [
      { titleSlug: 'reservations.tabs.general', path: '' },
      { titleSlug: 'reservations.tabs.history', path: 'history' },
    ];
    if (isStarted) {
      items.push({
        titleSlug: 'reservations.tabs.trajectory',
        path: 'trajectory',
      });
    }
    return items;
  }, [reservation]);

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <MainHeader
        text={t('reservations.reservation')}
        link={RESERVATIONS}
        linkText={t('reservations.buttons.allReservations')}
      />
      <TabMenu menuItems={menuItems} />
      <Routes>
        <Route index element={<ReservationGeneralDetails />} />
        <Route path="history" element={<ReservationDetailsActions />} />
        <Route path="trajectory" element={<ReservationTrajectory />} />
        <Route path="*" element={<Navigate to=".." />} />
      </Routes>
    </Box>
  );
};

export default ReservationDetailsContainer;
