import { useElements, useStripe } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
import {
  ConfirmStripePaymentMethodResponse,
  useConfirmStripePaymentMethod,
  useReconfirmStripePayment,
} from '../auth/CreateAccount/api';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';

type AddPaymentMethodErrorType =
  | 'stripe_init'
  | 'stripe_invalid_form'
  | 'avis_create'
  | 'avis_confirm'
  | 'stripe_next_action'
  | 'avis_reconfirm'
  | 'avis_confirmation_not_paid'
  | 'unknown';

class AddPaymentMethodError extends Error {
  type: AddPaymentMethodErrorType;

  constructor(type: AddPaymentMethodErrorType, cause?: unknown) {
    super();
    this.type = type;
    this.cause = cause;
  }
}

export function useAddStripePaymentMethod({
  allowSubmit = () => true,
  onSubmit,
}: {
  allowSubmit?: () => boolean;
  onSubmit: (methodId: string) => void;
}) {
  const stripe = useStripe();
  const elements = useElements();

  const confirmStripePayment = useConfirmStripePaymentMethod();
  const reconfirmStripePayment = useReconfirmStripePayment();
  const { errorSnack, apiErrorSnack } = useSnacks();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    if (!allowSubmit?.()) return;
    setIsLoading(true);
    try {
      const methodId = await createPaymentMethod();
      const confirmationResponse = await confirmPaymentMethod(methodId);
      await handleConfirmationResponse(confirmationResponse);
      onSubmit?.(methodId);
      return methodId;
    } catch (e) {
      if (!(e instanceof AddPaymentMethodError)) {
        throw new AddPaymentMethodError('unknown', e);
      }
      throw e;
    } finally {
      setIsLoading(false);
    }
  };

  async function createPaymentMethod(): Promise<string> {
    if (!stripe || !elements) {
      throw new AddPaymentMethodError('stripe_init');
    }
    const { error: submitError } = await elements.submit();
    if (submitError) {
      errorSnack(submitError.message);
      throw new AddPaymentMethodError('stripe_invalid_form', submitError);
    }

    const { error: apiError, paymentMethod } = await stripe.createPaymentMethod(
      {
        elements,
      },
    );

    if (apiError) {
      errorSnack(apiError.message);
      throw new AddPaymentMethodError('avis_create', apiError);
    } else {
      return paymentMethod.id;
    }
  }

  async function confirmPaymentMethod(paymentMethodId: string) {
    try {
      return await confirmStripePayment.mutateAsync({
        paymentMethodId,
      });
    } catch (err) {
      apiErrorSnack(err);
      throw new AddPaymentMethodError('avis_confirm', err);
    }
  }

  async function handleConfirmationResponse(
    response: ConfirmStripePaymentMethodResponse,
  ) {
    if (!stripe) return;
    if (
      response.details &&
      response.details.error === 'REQUIRES_ACTION' &&
      response.details.clientSecret
    ) {
      const { error: nextActionErr } = await stripe.handleNextAction({
        clientSecret: response.details.clientSecret,
      });
      if (nextActionErr) {
        errorSnack(nextActionErr.message);
        throw new AddPaymentMethodError('stripe_next_action', nextActionErr);
      }
      try {
        const reconfirmResponse = await reconfirmStripePayment.mutateAsync(
          response.id,
        );
        await handleConfirmationResponse(reconfirmResponse);
        return;
      } catch (reconfirmErr) {
        apiErrorSnack(reconfirmErr);
        throw new AddPaymentMethodError('avis_reconfirm', reconfirmErr);
      }
    }
    if (response.status !== 'paid') {
      throw new AddPaymentMethodError('avis_confirmation_not_paid');
    }
  }

  return {
    handleSubmit,
    isLoading,
  };
}
