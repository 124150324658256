import * as React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store';
import {
  accountFormDataChanged,
  activeStepIdxChanged,
  invoiceEnabledChanged,
  invoiceFormDataChanged,
  invoiceFormValidChanged,
  paymentMethodIdChanged,
  setOnboarded,
  skippedChanged,
} from '../../../../store/user/slice';
import CompanyInfoStep from './CompanyInfoStep';
import PaymentMethodStep from './PaymentMethodStep';
import { BoardMemberRequest, CreateBusinessAccountFormSchema } from './api';

export const CREATE_B2B_ACCOUNT_STEPS = [
  {
    stepper: 'b2b.createAccount.companyInfo.stepper',
    title: 'b2b.createAccount.companyInfo.title',
    page: <CompanyInfoStep />,
    optional: false,
  },
  {
    stepper: 'b2b.createAccount.paymentMethod.stepper',
    title: 'b2b.createAccount.paymentMethod.title',
    page: <PaymentMethodStep />,
    optional: true,
  },
  // {
  //   stepper: 'b2b.createAccount.relatedAccounts.stepper',
  //   title: 'b2b.createAccount.relatedAccounts.title',
  //   page: <RelatedAccountsStep />,
  //   optional: true,
  // },
];

export function useAccountStepper() {
  const dispatch = useAppDispatch();
  const activeStepIdx = useAppSelector(
    state => state.userReducer.accountCreationStepperData.activeStepIdx,
  );
  const skipped = useAppSelector(
    state => state.userReducer.accountCreationStepperData.skipped,
  );
  const accountFormData = useAppSelector(
    state => state.userReducer.accountCreationStepperData.accountFormData,
  );
  const paymentMethodId = useAppSelector(
    state => state.userReducer.accountCreationStepperData.paymentMethodId,
  );
  const customerId = useAppSelector(
    state => state.userReducer.accountCreationStepperData.customerId,
  );
  const isInvoiceEnabled = useAppSelector(
    state => state.userReducer.accountCreationStepperData.isInvoiceEnabled,
  );
  const isInvoiceFormValid = useAppSelector(
    state => state.userReducer.accountCreationStepperData.isInvoiceFormValid,
  );
  const invoiceFormData = useAppSelector(
    state => state.userReducer.accountCreationStepperData.invoiceFormData,
  );

  const isStepOptional = (idx: number) => {
    const step = CREATE_B2B_ACCOUNT_STEPS[idx];
    if (!step) throw Error('Invalid step index');
    return step.optional;
  };

  const isStepSkipped = (idx: number) => {
    return skipped && skipped.includes(idx);
  };

  const nextStep = () => {
    if (checkAndHandleEndState()) {
      return;
    }
    dispatch(activeStepIdxChanged(activeStepIdx + 1));
    dispatch(
      skippedChanged(skipped.filter((it: number) => it === activeStepIdx)),
    );
  };

  const prevStep = () => {
    dispatch(activeStepIdxChanged(activeStepIdx - 1));
  };

  const skipStep = () => {
    if (!isStepOptional(activeStepIdx)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }
    if (checkAndHandleEndState()) {
      return;
    }

    dispatch(activeStepIdxChanged(activeStepIdx + 1));
    dispatch(skippedChanged(skipped.concat(activeStepIdx)));
  };

  /** @return whether end state has been reached and handled */
  const checkAndHandleEndState = () => {
    if (activeStepIdx === CREATE_B2B_ACCOUNT_STEPS.length - 1) {
      // Just assume that if we are at the last step,
      // then we must have completed all required steps.
      dispatch(setOnboarded(true));
      // dispatch(stepperChanged(null)); TODO
      return true;
    }
    return false;
  };

  const setAccountFormData = (newFormData: CreateBusinessAccountFormSchema) => {
    dispatch(accountFormDataChanged(newFormData));
  };

  const setInvoiceFormData = (newFormData: BoardMemberRequest) => {
    dispatch(invoiceFormDataChanged(newFormData));
  };

  const setPaymentMethodId = (methodId: string) => {
    dispatch(paymentMethodIdChanged(methodId));
  };

  const setInvoiceEnabled = (isEnabled: boolean) => {
    dispatch(invoiceEnabledChanged(isEnabled));
  };
  const setInvoiceFormValid = (isValid: boolean) => {
    dispatch(invoiceFormValidChanged(isValid));
  };

  return {
    activeStepIdx,
    skipped,
    accountFormData,
    paymentMethodId,
    customerId,
    isInvoiceEnabled,
    isInvoiceFormValid,
    invoiceFormData,
    isLastStep: activeStepIdx === CREATE_B2B_ACCOUNT_STEPS.length - 1,
    steps: CREATE_B2B_ACCOUNT_STEPS,
    activeStep: CREATE_B2B_ACCOUNT_STEPS[activeStepIdx],
    nextStep,
    prevStep,
    skipStep,
    isStepSkipped,
    setAccountFormData,
    setInvoiceFormData,
    setPaymentMethodId,
    setInvoiceEnabled,
    setInvoiceFormValid,
  };
}
