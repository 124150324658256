import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormLabel,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import { Search } from '@mui/icons-material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  GetReservationParams,
  ReservationStatusType,
} from '../../../entities/Reservation';
import { PaginatedTableToolbar } from '../../../components/Common/PaginatedTable';
import TextField from '../../../components/Common/TextField';
import Select from '../../../components/Common/Select';
import CloseIcon from '../../../icons/CloseIcon';

const ReservationsToolbar = ({
  params,
  setParams,
  searchFocus,
  setSearchFocus,
}: PaginatedTableToolbar<GetReservationParams>) => {
  const { t } = useTranslation();

  const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFocus(true);
    setParams((prevState: GetReservationParams) => ({
      ...prevState,
      search: event.target.value,
    }));
  };

  const onFilterValueChange = (value: string, field: string) => {
    setParams((prevState: GetReservationParams) => ({
      ...prevState,
      [field]: value || undefined,
    }));
  };

  const handleClearFilter = () => {
    setParams((prevState: GetReservationParams) => ({
      ...prevState,
      page: 1,
      search: undefined,
      statusType: undefined,
    }));
  };

  return (
    <GridToolbarContainer>
      <Grid container columnGap={2}>
        <Grid item md={2} xs={4}>
          <FormLabel>{t(`b2b.reservations.filters.search`)}</FormLabel>
          <TextField
            value={params.search || ''}
            onChange={onSearchValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            autoFocus={searchFocus}
          />
        </Grid>
        <Grid item md={2} xs={4}>
          <FormLabel>
            {t(`b2b.reservations.filters.reservationStatus`)}
          </FormLabel>
          <Select
            items={Object.values(ReservationStatusType).map(
              (status: ReservationStatusType) => ({
                value: status,
                label: t(`b2b.reservations.reservationStatus.${status}`),
              }),
            )}
            value={params.statusType || ''}
            onChange={(value: string) =>
              onFilterValueChange(value, 'statusType')
            }
            displayEmpty
          />
        </Grid>
        <Grid item style={{ paddingTop: '20px', paddingLeft: '8px' }}>
          <Button
            style={{ color: '#141313' }}
            startIcon={<CloseIcon />}
            onClick={handleClearFilter}
          >
            <Typography variant="subtitle2">{t('clearAll')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default ReservationsToolbar;
