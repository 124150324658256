import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { Box } from '@mui/material';
import { Formik } from 'formik';
import { TabHeader } from '../../Common/TabHeader';
import { SecondaryButton } from '../../Common/SecondaryButton';
import { RESERVATIONS } from '../../../utils/routes-constants';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import { useGetReservation, useUpdateMaintenanceReservation } from '../api';
import {
  EditMaintenanceReservationState,
  getEditMaintenanceReservationInitialState,
  getEditMaintenanceReservationValidationSchema,
} from './editMaintenanceReservationData';
import EditMaintenanceReservationForm from './EditMaintenanceReservationForm';

const EditMaintenanceReservation = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();
  const navigate = useNavigate();
  const { successSnack, apiErrorSnack } = useSnacks();

  const { data: reservation } = useGetReservation(reservationId);
  const updateMaintenanceReservation = useUpdateMaintenanceReservation();

  if (!reservationId || !reservation) {
    return null;
  }

  const onSubmit = (values: EditMaintenanceReservationState) => {
    const { startDateTime, endDateTime } = values;
    const isUpcoming = reservation.details.status.type === 'upcoming';

    updateMaintenanceReservation.mutate(
      {
        id: reservationId,
        body: isUpcoming
          ? {
              startDateTime: moment.utc(startDateTime).format(),
              endDateTime: moment.utc(endDateTime).format(),
            }
          : {
              endDateTime: moment.utc(endDateTime).format(),
            },
      },
      {
        onSuccess: () => {
          successSnack(t('reservations.snacks.updated'));
          navigate(`${RESERVATIONS}/${reservationId}`);
        },
        onError: e => apiErrorSnack(e),
      },
    );
  };

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <TabHeader
        text={t('reservations.form.titles.editMaintenanceReservation')}
      >
        <SecondaryButton
          startIcon={<Close />}
          onClick={() => navigate(`${RESERVATIONS}/${reservationId}`)}
        >
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <Formik
        initialValues={getEditMaintenanceReservationInitialState(
          reservation.details,
        )}
        validationSchema={getEditMaintenanceReservationValidationSchema(
          t,
          reservation.details.status.type,
        )}
        onSubmit={onSubmit}
        enableReinitialize
        initialTouched={{
          startDateTime: true,
          endDateTime: true,
        }}
      >
        <EditMaintenanceReservationForm />
      </Formik>
    </Box>
  );
};

export default EditMaintenanceReservation;
