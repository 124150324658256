import React from 'react';

const LowBatteryIcon: React.FC<{ fill: string }> = ({ fill }) => (
  <svg
    width="12"
    height="6"
    viewBox="0 0 12 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.91699 0.083374H1.33366C1.02424 0.083374 0.727493 0.20629 0.508701 0.425083C0.289908 0.643875 0.166992 0.940621 0.166992 1.25004V4.75004C0.166992 5.05946 0.289908 5.35621 0.508701 5.575C0.727493 5.79379 1.02424 5.91671 1.33366 5.91671H8.91699C9.22641 5.91671 9.52316 5.79379 9.74195 5.575C9.96074 5.35621 10.0837 5.05946 10.0837 4.75004V1.25004C10.0837 0.940621 9.96074 0.643875 9.74195 0.425083C9.52316 0.20629 9.22641 0.083374 8.91699 0.083374ZM8.91699 4.75004H1.33366V1.25004H8.91699V4.75004ZM11.2503 1.83337C11.0956 1.83337 10.9472 1.89483 10.8378 2.00423C10.7284 2.11362 10.667 2.262 10.667 2.41671V3.58337C10.667 3.73808 10.7284 3.88646 10.8378 3.99585C10.9472 4.10525 11.0956 4.16671 11.2503 4.16671C11.405 4.16671 11.5534 4.10525 11.6628 3.99585C11.7722 3.88646 11.8337 3.73808 11.8337 3.58337V2.41671C11.8337 2.262 11.7722 2.11362 11.6628 2.00423C11.5534 1.89483 11.405 1.83337 11.2503 1.83337Z"
      fill={fill}
    />
  </svg>
);

export default LowBatteryIcon;
