import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { PaginatedRequestParams } from './api';
import {
  CreateMyRelatedCustomerRequest,
  CreateRelatedCustomerRequest,
  myUsersApi,
  UpdateRelatedCustomerRequest,
} from './api/related-customers';

const RELATED_CUSTOMERS_KEY = 'related-customers';

export function useGetRelatedCustomers(params: PaginatedRequestParams) {
  return useQuery({
    queryKey: [RELATED_CUSTOMERS_KEY, params],
    queryFn: async () => {
      return (await myUsersApi.getRelatedCustomers(params)).data;
    },
  });
}

export function useGetRelatedCustomer(id: string) {
  return useQuery({
    queryKey: [RELATED_CUSTOMERS_KEY, id],
    queryFn: async () => {
      return (await myUsersApi.getRelatedCustomer(id)).data;
    },
    enabled: !!id,
  });
}

export function useAddRelatedCustomer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: CreateRelatedCustomerRequest) => {
      return (await myUsersApi.addRelatedCustomer(body)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [RELATED_CUSTOMERS_KEY] }),
  });
}

export function useAddMyRelatedCustomer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: CreateMyRelatedCustomerRequest) => {
      return (await myUsersApi.addMyRelatedCustomer(body)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [RELATED_CUSTOMERS_KEY] }),
  });
}

export function useUpdateRelatedCustomer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      body,
    }: {
      id: string;
      body: UpdateRelatedCustomerRequest;
    }) => {
      return (await myUsersApi.updateRelatedCustomer(id, body)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [RELATED_CUSTOMERS_KEY] }),
  });
}

export function useDeleteRelatedCustomer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      return (await myUsersApi.removeRelatedCustomer(id)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [RELATED_CUSTOMERS_KEY] }),
  });
}
