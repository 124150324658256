import React from 'react';

const MoreIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9 7.5C8.70333 7.5 8.41332 7.58797 8.16665 7.7528C7.91997 7.91762 7.72771 8.15189 7.61418 8.42598C7.50065 8.70006 7.47094 9.00166 7.52882 9.29264C7.5867 9.58361 7.72956 9.85088 7.93934 10.0607C8.14912 10.2704 8.41639 10.4133 8.70737 10.4712C8.99834 10.5291 9.29994 10.4994 9.57403 10.3858C9.84811 10.2723 10.0824 10.08 10.2472 9.83336C10.412 9.58668 10.5 9.29667 10.5 9C10.5 8.60218 10.342 8.22064 10.0607 7.93934C9.77936 7.65804 9.39783 7.5 9 7.5ZM3.75 7.5C3.45333 7.5 3.16332 7.58797 2.91665 7.7528C2.66997 7.91762 2.47771 8.15189 2.36418 8.42598C2.25065 8.70006 2.22094 9.00166 2.27882 9.29264C2.3367 9.58361 2.47956 9.85088 2.68934 10.0607C2.89912 10.2704 3.16639 10.4133 3.45737 10.4712C3.74834 10.5291 4.04994 10.4994 4.32403 10.3858C4.59812 10.2723 4.83238 10.08 4.99721 9.83336C5.16203 9.58668 5.25 9.29667 5.25 9C5.25 8.60218 5.09197 8.22064 4.81066 7.93934C4.52936 7.65804 4.14783 7.5 3.75 7.5ZM14.25 7.5C13.9533 7.5 13.6633 7.58797 13.4166 7.7528C13.17 7.91762 12.9777 8.15189 12.8642 8.42598C12.7506 8.70006 12.7209 9.00166 12.7788 9.29264C12.8367 9.58361 12.9796 9.85088 13.1893 10.0607C13.3991 10.2704 13.6664 10.4133 13.9574 10.4712C14.2483 10.5291 14.5499 10.4994 14.824 10.3858C15.0981 10.2723 15.3324 10.08 15.4972 9.83336C15.662 9.58668 15.75 9.29667 15.75 9C15.75 8.60218 15.592 8.22064 15.3107 7.93934C15.0294 7.65804 14.6478 7.5 14.25 7.5Z"
      fill="#33302F"
    />
  </svg>
);

export default MoreIcon;
