import React, { useState } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  GridToolbarContainer,
} from '@mui/x-data-grid';
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { VehicleStatus, VehicleStatusTextTable } from '../VehicleStatus';
import { VehicleState, VehicleStateChip } from '../VehicleStateChip';
import VehiclesIcon from '../../../icons/VehiclesIcon';
import LocationIcon from '../../../icons/LocationIcon';
import CloseIcon from '../../../icons/CloseIcon';
import SearchIcon from '../../../icons/SearchIcon';
import { VehicleListItem } from '../../../entities/Vehicle';
import {
  GetVehiclesParams,
  useGetVehicleModels,
  useGetVehicles,
} from '../../../store/vehicle/queries';
import { ALL } from '../../../utils/table-utils';
import Select from '../../Common/Select';
import { openModal } from '../../../store/modal/slice';
import { ModalType } from '../../../utils/modal-utils';
import { useAppDispatch } from '../../../store';
import { PaginatedTable, usePaginatedTable } from '../../Common/PaginatedTable';
import { VEHICLES } from '../../../utils/routes-constants';
import MuiRouterLink from '../../Common/MuiRouterLink';

const EMPTY_FILTER: GetVehiclesParams = {
  search: '',
  modelId: '',
  statusTypeSlug: '',
  stateTypeSlug: '',
};

const VehiclesTable = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [params, setParams] = useState<GetVehiclesParams>(EMPTY_FILTER);
  const { data: vehiclesData, isLoading: isLoading } = useGetVehicles(params);
  const { rows, totalItems, debounceParams } = usePaginatedTable({
    paginatedItems: vehiclesData,
    params,
    setParams,
    mapGridRow: item => ({
      ...item,
      lastLocation: item,
    }),
  });

  const columns: GridColDef[] = [
    {
      field: 'registrationNumber',
      headerName: t('vehicles.registerNumber'),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <MuiRouterLink to={`${VEHICLES}/${params.id}`}>
          {params.value}
        </MuiRouterLink>
      ),
    },
    {
      field: 'modelName',
      headerName: t('vehicles.model'),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <>
          <span style={{ paddingRight: 8, marginTop: 3 }}>
            <VehiclesIcon size={13} />
          </span>
          <Typography variant="body2">{params.value}</Typography>
        </>
      ),
    },
    {
      field: 'statusSlug',
      headerName: t('vehicles.status'),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <VehicleStatusTextTable value={params.value} />
      ),
    },
    {
      field: 'stateSlugs',
      headerName: t('vehicles.state'),
      sortable: false,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <Box display="flex" flexWrap="wrap" flexDirection="row" gap="6px">
          {params.value.map((param: string) => (
            <VehicleStateChip key={param} value={param} />
          ))}
        </Box>
      ),
    },
    { field: 'zone', headerName: 'Zone', flex: 1, sortable: false },
    {
      field: 'lastUpdate',
      headerName: t('vehicles.lastUpdate'),
      sortable: false,
      flex: 1,
      align: 'center',
      maxWidth: 105,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2">
          {params.value
            ? `${moment().diff(moment(params.value), 'minutes')} ${t(
                'minutesShort',
              )}`
            : null}
        </Typography>
      ),
    },
    {
      field: 'lastLocation',
      headerName: t('vehicles.lastLocation'),
      sortable: false,
      flex: 1,
      align: 'center',
      maxWidth: 105,
      renderCell: (params: GridRenderCellParams<VehicleListItem>) =>
        params.value.lastLocation ? (
          <LocationIconButton
            onClick={() => handleLocationClick(params.value)}
          />
        ) : null,
    },
  ];

  function handleLocationClick(vehicle: VehicleListItem) {
    if (!vehicle.lastLocation || vehicle.lastLocation.type !== 'Point') return;
    dispatch(
      openModal({
        type: ModalType.VEHICLE_LOCATION,
        props: {
          title: `${t('vehicles.lastLocation')} - ${
            vehicle.registrationNumber
          }`,
          id: `map-${vehicle.id}`,
          lastLocation: vehicle.lastLocation,
        },
      }),
    );
  }

  return (
    <PaginatedTable
      rows={rows}
      columns={columns}
      totalItems={totalItems}
      isLoading={isLoading}
      fetchRows={debounceParams}
      Toolbar={VehiclesToolbar}
    />
  );
};

function LocationIconButton({ onClick }: { onClick: () => void }) {
  function handleClick(e: React.SyntheticEvent) {
    e.stopPropagation();
    if (typeof onClick === 'function') onClick();
  }

  return (
    <IconButton onClick={handleClick}>
      <LocationIcon />
    </IconButton>
  );
}

type ToolbarProps = {
  params: GetVehiclesParams;
  setParams: (
    value:
      | ((prevState: GetVehiclesParams) => GetVehiclesParams)
      | GetVehiclesParams,
  ) => void;
};

function VehiclesToolbar({ setParams, params }: ToolbarProps) {
  const { t } = useTranslation();
  const { data: models } = useGetVehicleModels();

  return (
    <GridToolbarContainer>
      <Grid container columnSpacing={2}>
        <Grid item style={{ width: 280 }}>
          <Typography variant="subtitle2">{t('search')}</Typography>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            value={params.search}
            onChange={event =>
              setParams((state: GetVehiclesParams) => ({
                ...state,
                search: event.target.value,
              }))
            }
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item style={{ width: 160 }}>
          <Typography variant="subtitle2">{t('vehicles.model')}</Typography>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            value={params.modelId}
            items={models.map(model => ({
              value: model.id,
              label: model.brand + ' ' + model.model,
            }))}
            onChange={value =>
              setParams((state: GetVehiclesParams) => ({
                ...state,
                modelId: value === ALL ? undefined : value,
              }))
            }
            displayEmpty
          />
        </Grid>
        <Grid item style={{ width: 160 }}>
          <Typography variant="subtitle2">{t('vehicles.status')}</Typography>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            value={params.statusTypeSlug}
            items={Object.entries(VehicleStatus).map(([key, status]) => ({
              value: key,
              label: t(`vehicles.statuses.${status.title}`),
            }))}
            onChange={value =>
              setParams((state: GetVehiclesParams) => ({
                ...state,
                statusTypeSlug: value === ALL ? undefined : value,
              }))
            }
            displayEmpty
          />
        </Grid>
        <Grid item style={{ width: 160 }}>
          <Typography variant="subtitle2">{t('vehicles.state')}</Typography>
          <Select
            fullWidth
            variant="outlined"
            size="small"
            value={params.stateTypeSlug}
            items={Object.entries(VehicleState).map(([key, state]) => ({
              value: key,
              label: t(`vehicles.states.${state.title}`),
            }))}
            onChange={value =>
              setParams((state: GetVehiclesParams) => ({
                ...state,
                stateTypeSlug: value === ALL ? undefined : value,
              }))
            }
            displayEmpty
          />
        </Grid>
        <Grid item style={{ paddingTop: '20px', paddingLeft: '8px' }}>
          <Button
            style={{ color: '#141313' }}
            startIcon={<CloseIcon />}
            onClick={() => setParams(EMPTY_FILTER)}
          >
            <Typography variant="subtitle2">{t('clearAll')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default VehiclesTable;
