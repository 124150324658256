import { Box, Grid } from '@mui/material';
import React, { PropsWithChildren } from 'react';

interface Props extends PropsWithChildren {
  content?: JSX.Element | JSX.Element[];
  fullWidth?: boolean;
}

export const DetailsCard = ({ content, fullWidth, children }: Props) => (
  <Box
    sx={{
      height: 'auto',
      maxWidth: !fullWidth ? '800px' : undefined,
      background: '#FAF9F8',
      boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
      borderRadius: '8px',
      marginBottom: '16px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: '0px',
        gap: '8px',
        paddingX: '20px',
        paddingY: '16px',
      }}
    >
      <Grid container spacing={1}>
        {content ? content : children}
      </Grid>
    </Box>
  </Box>
);
