import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Autocomplete,
  debounce,
  FormLabel,
  InputAdornment,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { InputFieldProps } from '../../Common/FormikFields/InputField';
import api from '../../../store/api';
import SearchIcon from '../../../icons/SearchIcon';

interface Props extends InputFieldProps {
  phoneNumber?: string;
  onCustomerSelect?: (customerId: string | null | undefined) => void;
}

interface SearchItem {
  label: string;
  id: string;
  phoneNumber: string;
}

const SEARCH_MIN_CHARS = 3;

function PrivateCustomerSelect(props: Props) {
  const { phoneNumber, onCustomerSelect, label, name, error } = props;

  const [search, setSearch] = useState(phoneNumber || '');
  const [isDirty, setIsDirty] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<SearchItem | null>(
    null,
  );

  const { t } = useTranslation();

  const { data: searchResult, status } = useQuery({
    queryKey: ['private-customers', search, selectedCustomer],

    queryFn: async () => {
      if (!search || search.length < SEARCH_MIN_CHARS) {
        return [];
      }
      const customers =
        (
          await api.getPrivateCustomers({
            search,
          })
        )?.data?.items?.map(
          c =>
            ({
              label: `${c.name} (${c.phoneNumber})`,
              id: c.id,
              phoneNumber: c.phoneNumber,
            } as SearchItem),
        ) ?? [];
      if (customers.length === 1 && !isDirty) {
        // Initialize the selected value since all we get from props is a phone number
        setSelectedCustomer(customers[0]);
        onCustomerSelect?.(customers[0].id);
      }
      if (
        selectedCustomer &&
        !customers.some(it => it.id === selectedCustomer.id)
      ) {
        // Stop the autocomplete from complaining
        // that the currently selected value is not present in options
        customers.push(selectedCustomer);
      }
      return customers;
    },
  });

  function handleSelectChange(
    _e: React.SyntheticEvent,
    selected: SearchItem | null,
  ) {
    setIsDirty(true);
    setSelectedCustomer(selected);
    if (!selected) {
      setSearch('');
    }
    onCustomerSelect?.(selected?.id ?? null);
  }

  const handleSearchChange = useMemo(
    () =>
      // It's a bit bad that upon handleSelectChange the search value changes as well
      // to the label of the customer list item which triggers another search request
      // with the full label text - would be neat to only perform requests for actual
      // user input, not selection changes.
      debounce((_e: React.SyntheticEvent, newValue: string) => {
        setSearch(newValue != null ? newValue : '');
      }, 300),
    [],
  );

  return (
    <Autocomplete
      id={name}
      fullWidth
      size="small"
      value={selectedCustomer}
      onChange={handleSelectChange}
      options={searchResult || []}
      onInputChange={handleSearchChange}
      isOptionEqualToValue={(opt, val) => opt.id === val.id}
      renderInput={({ ...params }) => (
        <>
          <FormLabel htmlFor={name}>{label}</FormLabel>
          <TextField
            name={name}
            variant="outlined"
            helperText={t('customers.representative.helper')}
            error={error}
            {...params}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
      noOptionsText={
        status === 'pending'
          ? '...'
          : search.length < SEARCH_MIN_CHARS
          ? t('customers.representative.noOptionsStartTyping')
          : t('customers.representative.noOptionsNotFound')
      }
    />
  );
}

export default PrivateCustomerSelect;
