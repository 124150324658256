import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box } from '@mui/material';
import {
  Add,
  Close,
  Download,
  LockOpen,
  LockOutlined,
  Payment,
} from '@mui/icons-material';
import moment from 'moment/moment';
import { useNavigate, useParams } from 'react-router-dom';
import { TabHeader } from '../../Common/TabHeader';
import EditIcon from '../../../icons/EditIcon';
import { SecondaryButton } from '../../Common/SecondaryButton';
import { DetailsCard } from '../../Common/DetailsCard';
import { DetailRow } from '../../Common/DetailRow';
import {
  DATE_TIME,
  formatDateTime,
  TIME_WITH_SECONDS,
} from '../../../utils/datetime-utils';
import LoadingIcon from '../../Common/LoadingIcon';
import ReservationFeedbackRating from '../ReservationFeedbackRating';
import LinkButton from '../../Common/LinkButton';
import {
  CUSTOMERS,
  CUSTOMERS_BUSINESS,
  VEHICLES,
} from '../../../utils/routes-constants';
import {
  useCancelMaintenanceReservation,
  useEndReservation,
  useGetReservation,
  useStartReservation,
} from '../api';
import {
  EUR,
  formatDistance,
  formatMoney,
  isNonZeroMoney,
} from '../../../utils';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import { useModal } from '../../Modal/hooks';
import { ModalType } from '../../../utils/modal-utils';

const ReservationGeneralDetails = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();
  const navigate = useNavigate();
  const { confirm, open } = useModal();
  const snacks = useSnacks();

  const {
    data: reservation,
    isLoading,
    isError,
  } = useGetReservation(reservationId);
  const cancelMaintenanceReservation = useCancelMaintenanceReservation();
  const startReservation = useStartReservation();
  const endReservation = useEndReservation();

  if (isLoading) {
    return <LoadingIcon />;
  }

  if (!reservation || isError) {
    return <Alert severity="error">{t('error.failedToLoadPage')}</Alert>;
  }

  const {
    id,
    customer,
    company,
    vehicle: { id: vehicleId, registrationNumber },
    startDateTime,
    endDateTime,
    actualEndDateTime,
    rideDetails: { durationSeconds, distanceMeters, totalPrice },
    feedback,
    status: { type: statusType },
    maintenance,
    invoices,
  } = reservation.details;

  const {
    paidWithCard,
    paidWithBonus,
    paidWithCompany,
    debt,
    status: paymentStatus,
    prepaid,
    postpaid,
  } = reservation.paymentSummary;

  const isEnded = statusType === 'ended';
  const isActive = statusType === 'active';
  const isUpcoming = statusType === 'upcoming';

  const duration =
    statusType === 'active'
      ? moment
          .utc(moment(endDateTime).diff(moment(startDateTime))) // Time between start and end for active
          .format(TIME_WITH_SECONDS)
      : isEnded
      ? moment.utc(durationSeconds * 1000).format(TIME_WITH_SECONDS) // Actual time spent for upcoming
      : null;

  function handleDownloadInvoices() {
    reservationId &&
      open({
        type: ModalType.RESERVATION_INVOICES,
        props: {
          reservationId,
        },
      });
  }

  function handleCancel() {
    if (!maintenance) return;
    confirm({
      title: t('reservations.modal.cancel.title'),
      action: async () => {
        try {
          await cancelMaintenanceReservation.mutateAsync(id);
          snacks.successSnack(t('reservations.snacks.cancelled'));
        } catch (e) {
          snacks.apiErrorSnack(e);
        }
      },
    });
  }

  function handleStart() {
    confirm({
      title: t('reservations.modal.start.title'),
      action: async () => {
        startReservation.mutate(id, {
          onSuccess: () =>
            snacks.successSnack(t('reservations.snacks.started')),
          onError: e => snacks.apiErrorSnack(e),
        });
      },
    });
  }

  function handleEnd() {
    confirm({
      title: t('reservations.modal.end.title'),
      action: async () => {
        endReservation.mutate(id, {
          onSuccess: () => snacks.successSnack(t('reservations.snacks.ended')),
          onError: e => snacks.apiErrorSnack(e),
        });
      },
    });
  }

  function handleCharge() {
    if (!reservationId || !debt) return;
    if (EUR(debt).value <= 0) return;
    open({
      type: ModalType.COLLECT_DEBT,
      props: { reservationId, debt },
    });
  }

  function handleCreateInvoice() {
    if (!reservationId) return;
    open({
      type: ModalType.RESERVATION_INVOICE_CREATE,
      props: { reservationId },
    });
  }

  return (
    <>
      <TabHeader text={t('reservations.tabs.general')}>
        <Box display="flex" sx={{ ml: 2 }} gap={2}>
          {isUpcoming && (
            <SecondaryButton startIcon={<LockOpen />} onClick={handleStart}>
              {t('reservations.buttons.startReservation')}
            </SecondaryButton>
          )}
          {isActive && (
            <SecondaryButton startIcon={<LockOutlined />} onClick={handleEnd}>
              {t('reservations.buttons.endReservation')}
            </SecondaryButton>
          )}
          {!maintenance && isEnded && (
            <SecondaryButton startIcon={<Add />} onClick={handleCreateInvoice}>
              {t('reservations.buttons.createInvoice')}
            </SecondaryButton>
          )}
          {isNonZeroMoney(debt) && (
            <SecondaryButton startIcon={<Payment />} onClick={handleCharge}>
              {t('reservations.buttons.charge')}
            </SecondaryButton>
          )}
          {(isActive || isUpcoming) && (
            <SecondaryButton
              startIcon={<EditIcon />}
              onClick={() => navigate('edit')}
            >
              {t('reservations.buttons.editReservation')}
            </SecondaryButton>
          )}
          {maintenance && (
            <SecondaryButton onClick={handleCancel} startIcon={<Close />}>
              {t('reservations.buttons.cancelReservation')}
            </SecondaryButton>
          )}
        </Box>
      </TabHeader>

      <DetailsCard>
        <DetailRow
          title={t(`reservations.customerName`)}
          content={
            customer?.name && (
              <LinkButton to={`${CUSTOMERS}/${customer?.id}`}>
                {customer?.name}
              </LinkButton>
            )
          }
        />
        <DetailRow
          title={t(`reservations.phoneNumber`)}
          content={customer?.phoneNumber}
        />
        <DetailRow title={t(`reservations.email`)} content={customer?.email} />
        <DetailRow
          title={t(`reservations.companyName`)}
          content={
            company && (
              <LinkButton to={`${CUSTOMERS_BUSINESS}/${company.id}`}>
                {company.companyName}
              </LinkButton>
            )
          }
        />
        <DetailRow
          title={t(`reservations.invoicingGroup`)}
          content="" // TODO
          showDivider={false}
        />
      </DetailsCard>

      <DetailsCard>
        <DetailRow
          title={t(`reservations.vehicleNumber`)}
          content={
            <LinkButton to={`${VEHICLES}/${vehicleId}`}>
              {registrationNumber}
            </LinkButton>
          }
        />
        <DetailRow
          title={t(`reservations.startTime`)}
          content={formatDateTime(startDateTime, DATE_TIME)}
        />
        <DetailRow
          title={t(`reservations.endTime`)}
          content={formatDateTime(endDateTime, DATE_TIME)}
        />
        <DetailRow
          title={t(`reservations.actualReturnDate`)}
          content={formatDateTime(actualEndDateTime, DATE_TIME)}
        />
        <DetailRow title={t(`reservations.duration`)} content={duration} />
        <DetailRow
          title={t(`reservations.distance`)}
          content={formatDistance(distanceMeters)}
        />
        <DetailRow
          title={t(`reservations.rideTrajectory`)}
          content={
            ['active', 'ended'].includes(statusType) ? (
              <LinkButton to="trajectory">
                {t('reservations.buttons.show')}
              </LinkButton>
            ) : null
          }
        />
        <DetailRow
          title={t(`reservations.price`)}
          content={formatMoney(totalPrice)}
        />
        <DetailRow
          title={t(`reservations.paymentStatus`)}
          content={
            paymentStatus === 'paid'
              ? t('reservations.payment.paid')
              : paymentStatus === 'not_paid'
              ? t('reservations.payment.unpaid')
              : t('reservations.payment.overpaid')
          }
        />
        <DetailRow
          title={t(`reservations.prepaidAmount`)}
          content={formatMoney(prepaid)}
        />
        <DetailRow
          title={t(`reservations.postpaidAmount`)}
          content={formatMoney(postpaid)}
          showDivider={false}
        />
      </DetailsCard>

      <DetailsCard>
        {paidWithCard && (
          <DetailRow
            title={t(`reservations.paidWithCard`)}
            content={formatMoney(paidWithCard)}
          />
        )}
        {paidWithBonus && (
          <DetailRow
            title={t(`reservations.paidWithBonus`)}
            content={formatMoney(paidWithBonus)}
          />
        )}
        {paidWithCompany && (
          <DetailRow
            title={t(`reservations.paidWithCompanyCompensation`)}
            content={formatMoney(paidWithCompany)}
          />
        )}
        {paidWithCompany && company && (
          <DetailRow
            title={t(`reservations.companyName`)}
            content={company.companyName}
          />
        )}
        {debt && isNonZeroMoney(debt) && (
          <DetailRow
            title={t(`reservations.debt`)}
            content={
              <LinkButton
                onClick={handleCharge}
                title={t('reservations.modal.collectDebt.title')}
              >
                {formatMoney(debt)}
              </LinkButton>
            }
          />
        )}
        <DetailRow
          title={t(`reservations.invoices`)}
          content={
            invoices && invoices.length > 0 ? (
              <LinkButton
                startIcon={<Download />}
                onClick={handleDownloadInvoices}
              >
                {t('reservations.buttons.downloadInvoices')}
              </LinkButton>
            ) : null
          }
          showDivider={false}
        />
      </DetailsCard>

      <DetailsCard>
        <DetailRow
          title={t(`reservations.feedback`)}
          content={<ReservationFeedbackRating rating={feedback?.rating} />}
        />
        <DetailRow
          title={t(`reservations.comment`)}
          content={feedback?.comment ?? ''}
          showDivider={false}
        />
      </DetailsCard>
    </>
  );
};

export default ReservationGeneralDetails;
