import React from 'react';
import { Box, Paper } from '@mui/material';
import GoogleMapReact from 'google-map-react';
import ModalHeader from '../../Modal/ModalHeader';
import LocationIcon from '../../../icons/LocationIcon';
import { PointGeometry } from '../../../entities';

interface Props {
  id: string;
  title: string;
  lastLocation: PointGeometry;
}

function LocationMarker({ lng, lat }: { lng: number; lat: number }) {
  return (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <LocationIcon key={`${lat}-${lng}`} />
    </div>
  );
}

const VehicleLocationModal = ({ id, title, lastLocation }: Props) => {
  const [lng, lat] = lastLocation.coordinates;
  return (
    <Paper sx={{ width: '100%', maxWidth: 568 }}>
      <ModalHeader title={title} />
      <Box sx={{ padding: 4, height: 384 }}>
        <GoogleMapReact
          key={id}
          options={{
            clickableIcons: false,
            fullscreenControl: false,
          }}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API as string,
          }}
          defaultCenter={{
            lng: lastLocation.coordinates[0],
            lat: lastLocation.coordinates[1],
          }}
          defaultZoom={12}
          yesIWantToUseGoogleMapApiInternals
        >
          <LocationMarker lat={lat} lng={lng} />
          {/* Note: in dev mode, the marker does not seem to be fixed to its coords, but moves when panning/zooming */}
        </GoogleMapReact>
      </Box>
    </Paper>
  );
};

export default VehicleLocationModal;
