import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import { MainHeader } from '../Common/MainHeader';
import { TabMenu } from '../Common/TabMenu';
import { TabHeader } from '../Common/TabHeader';
import ParkingZones from './ParkingZones/ParkingZones';
import SystemSettings from './SystemSettings/SystemSettings';
import PriceClasses from './PriceClasses/PriceClasses';
import BonusCodes from './BonusCodes/BonusCodes';

const menuItems = [
  { titleSlug: 'settings.tabs.settings', path: '' },
  { titleSlug: 'settings.tabs.zones', path: 'zones' },
  { titleSlug: 'settings.tabs.users', path: 'users' },
  { titleSlug: 'settings.tabs.content', path: 'content' },
  { titleSlug: 'settings.tabs.rules', path: 'rules' },
  { titleSlug: 'settings.tabs.codes', path: 'codes' },
  { titleSlug: 'settings.tabs.log', path: 'log' },
];

const SettingsPage = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <MainHeader text={t('settings.title')}></MainHeader>
      <TabMenu menuItems={menuItems}></TabMenu>
      <Routes>
        <Route index element={<SystemSettings />} />
        <Route path="zones" element={<ParkingZones />} />
        <Route
          path="users"
          element={<TabHeader text={t('settings.tabs.users')} />}
        />
        <Route
          path="content"
          element={<TabHeader text={t('settings.tabs.content')} />}
        />
        <Route path="rules" element={<PriceClasses />} />
        <Route path="codes" element={<BonusCodes />} />
        <Route
          path="log"
          element={<TabHeader text={t('settings.tabs.log')} />}
        />
        <Route path="*" element={<Navigate to=".." />} />
      </Routes>
    </Box>
  );
};

export default SettingsPage;
