export const ROOT = '/';
export const B2B_HOME = '/home';

export const B2B_LOGIN = '/login';
export const B2B_ENTER_CODE = '/enter-code';
export const B2B_CREATE_ACCOUNT = '/create-account';
export const B2B_COMPANY = '/my-company';

export const B2B_COMPANY_EDIT = `/my-company/edit`;
export const B2B_COMPANY_MANAGE_AUTHORIZED_PERSONS = `/my-company/authorized-persons`;
export const B2B_COMPANY_MANAGE_PAYMENT_METHODS = `/wallet/payment-methods`;
export const B2B_COMPANY_MANAGE_COST_GROUPS = `/wallet/cost-groups`;
export const B2B_COMPANY_EDIT_COST_GROUP = `/wallet/cost-groups/:id/edit`;
export const B2B_USERS = '/users';
export const B2B_USER_NEW = '/users/add';
export const B2B_USER_EDIT = '/users/:relatedCustomerId/edit';
export const B2B_INVOICES = '/invoices';
export const B2B_WALLET = '/wallet';
export const B2B_RESERVATIONS = '/reservations';
export const B2B_RESERVATION_DETAILS = '/reservations/:reservationId/*';
export const B2B_ACCEPT_INVITE = '/acc/:code';
