import React from 'react';
import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LoadingIcon from '../../Common/LoadingIcon';
import { useGetVehicle } from '../../../store/vehicle/queries';
import { TabMenu } from '../../Common/TabMenu';
import { MainHeader } from '../../Common/MainHeader';
import { VEHICLES } from '../../../utils/routes-constants';
import { GeneralVehicleDetails } from './GeneralVehicleDetails';
import VehicleFeedback from './VehicleFeedback';
import VehicleActions from './VehicleActions';
import VehicleActionLogs from './VehicleActionLogs';

const menuItems = [
  { titleSlug: 'vehicles.tabs.general', path: '' },
  { titleSlug: 'vehicles.tabs.feedback', path: 'feedback' },
  { titleSlug: 'vehicles.tabs.actions', path: 'actions' },
  { titleSlug: 'vehicles.tabs.history', path: 'history' },
];

const VehicleDetailsContainer = () => {
  const { t } = useTranslation();
  const { vehicleId } = useParams();

  const { data: vehicleData, isLoading } = useGetVehicle(vehicleId);

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {vehicleData && (
        <>
          <MainHeader
            text={`${t('vehicles.vehicle')}: ${vehicleData.registrationNumber}`}
            link={VEHICLES}
            linkText={t('vehicles.buttons.allVehicles')}
          ></MainHeader>
          <TabMenu menuItems={menuItems}></TabMenu>
          <Routes>
            <Route
              index
              element={<GeneralVehicleDetails vehicle={vehicleData} />}
            />
            <Route path="feedback" element={<VehicleFeedback />} />
            <Route path="actions" element={<VehicleActions />} />
            <Route path="history" element={<VehicleActionLogs />} />
            <Route path="*" element={<Navigate to=".." />} />
          </Routes>
        </>
      )}
      {isLoading && <LoadingIcon />}
    </Box>
  );
};

export default VehicleDetailsContainer;
