import React from 'react';

const CheckboxCheckedIcon = ({ fill = '#00A399' }: { fill?: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="18" rx="4" fill={fill} />
    <path
      d="M13.4667 5.79998C13.2 5.53331 12.8 5.53331 12.5334 5.79998L7.53337 10.8L5.46671 8.73331C5.20004 8.46664 4.80004 8.46664 4.53337 8.73331C4.26671 8.99998 4.26671 9.39998 4.53337 9.66664L7.06671 12.2C7.20004 12.3333 7.33337 12.4 7.53337 12.4C7.73337 12.4 7.86671 12.3333 8.00004 12.2L13.4667 6.73331C13.7334 6.46664 13.7334 6.06664 13.4667 5.79998Z"
      fill="white"
    />
  </svg>
);

export default CheckboxCheckedIcon;
