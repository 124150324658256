import React, { useMemo } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add, Close } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik, useFormikContext } from 'formik';
import { SecondaryButton } from '../Common/SecondaryButton';
import { TabHeader } from '../Common/TabHeader';
import EditIcon from '../../icons/EditIcon';
import {
  CreateVehicleParams,
  getCreateVehicleSchema,
  getUpdateVehicleSchema,
  UpdateVehicleParams,
  useCreateVehicle,
  useGetParkingZones,
  useGetPriceClasses,
  useGetVehicle,
  useGetVehicleModels,
  useUpdateVehicle,
} from '../../store/vehicle/queries';
import InputField from '../Common/FormikFields/InputField';
import SelectField from '../Common/FormikFields/SelectField';
import { SelectItem } from '../Common/Select';
import { fieldOf } from '../../store/api';
import { useSnacks } from '../Common/AvisSnackbarProvider';
import { ParkingZone } from '../../entities/Vehicle';
import { VehicleStatus } from './VehicleStatus';

type ModelSelectItem = SelectItem & {
  variants: string[];
};

const AddEditVehiclePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { vehicleId } = useParams();
  const { apiErrorSnack, successSnack } = useSnacks();

  const isEdit = !!vehicleId;

  const getVehicle = useGetVehicle(vehicleId);
  const { data: vehicle } = getVehicle;

  const { mutate: createVehicle } = useCreateVehicle({
    onSuccess: async vehicle => {
      successSnack(t(`vehicles.snacks.saved`));
      navigate(`../${vehicle.id}`, { relative: 'path' });
    },
    onError: apiErrorSnack,
  });

  const { mutate: updateVehicle } = useUpdateVehicle({
    onSuccess: async () => {
      successSnack(t(`vehicles.snacks.saved`));
      navigate(-1);
    },
    onError: apiErrorSnack,
  });

  function handleSubmit(values: CreateVehicleParams | UpdateVehicleParams) {
    if (isEdit) {
      updateVehicle({
        id: vehicleId,
        body: values as UpdateVehicleParams,
      });
    } else {
      createVehicle(values as CreateVehicleParams);
    }
  }

  const statusItems: SelectItem[] = Object.entries(VehicleStatus).map(
    ([value, state]) => ({
      label: t(`vehicles.statuses.${state.title}`),
      value: value,
    }),
  );

  const initialValues: CreateVehicleParams | UpdateVehicleParams =
    useMemo(() => {
      const statusTypeSlug = vehicle?.status?.type?.slug ?? '';
      return {
        modelId: vehicle?.model?.id ?? '',
        variant: vehicle?.variant ?? '',
        registrationNumber: vehicle?.registrationNumber ?? '',
        priceClassId: vehicle?.priceClass?.id ?? '',
        parkingZoneId: vehicle?.parkingZone?.id ?? '',
        parkingSpaceInfo: vehicle?.parkingSpaceInfo ?? '',
        fuelCard: vehicle?.fuelCard ?? '',
        fuelCardPin: vehicle?.fuelCardPin ?? undefined,
        status: {
          typeSlug: statusTypeSlug,
          comment: vehicle?.status?.comment ?? '',
        },
      };
    }, [vehicle]);

  return (
    <Box sx={{ flex: 1 }}>
      <TabHeader
        headerVariant="h3"
        text={isEdit ? t('vehicles.edit.title') : t('vehicles.add.title')}
      >
        <SecondaryButton startIcon={<Close />} onClick={() => navigate(-1)}>
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <Formik
        onSubmit={handleSubmit}
        validationSchema={
          isEdit ? getUpdateVehicleSchema() : getCreateVehicleSchema()
        }
        initialValues={initialValues}
      >
        {({ values }) => (
          <Form>
            <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
              {t('vehicles.addEdit.section.general')}
            </Typography>
            <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
              <Grid container columnSpacing={3} item>
                <Grid item xs={6}>
                  <ModelSelect isEdit={isEdit} />
                </Grid>
                <Grid item xs={6}>
                  <ModelVariantSelect isEdit={isEdit} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} item>
                <Grid item xs={6}>
                  <InputField
                    name={fieldOf<CreateVehicleParams>('registrationNumber')}
                    label={t('vehicles.registrationNumber')}
                    placeholder="123ABC"
                  />
                </Grid>
                <Grid item xs={6}>
                  <PriceClassSelect isEdit={isEdit} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} item>
                <Grid item xs={6}>
                  <ParkingZoneSelect
                    isEdit={isEdit}
                    vehicleZone={vehicle?.parkingZone}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SelectField
                    name="status.typeSlug"
                    label={t('vehicles.status')}
                    items={statusItems}
                  />
                  {values.status.typeSlug === 'other' && (
                    <Box sx={{ mt: 2 }}>
                      <InputField
                        name="status.comment"
                        label={t('vehicles.statusComment')}
                      />
                    </Box>
                  )}
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} item>
                <Grid item xs={12}>
                  <InputField
                    name={fieldOf<UpdateVehicleParams>('parkingSpaceInfo')}
                    label={t('vehicles.parkingSpaceInformation')}
                    placeholder={t(
                      'vehicles.addEdit.placeholder.parkingSpaceInformation',
                    )}
                    multiline
                    maxRows={3}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {t('vehicles.addEdit.section.fuel')}
            </Typography>
            <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
              <Grid container columnSpacing={3} item>
                <Grid item xs={6}>
                  <InputField
                    name={fieldOf<UpdateVehicleParams>('fuelCard')}
                    label={t('vehicles.fuelCardNumber')}
                    placeholder={t(
                      'vehicles.addEdit.placeholder.fuelCardNumber',
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField
                    name={fieldOf<UpdateVehicleParams>('fuelCardPin')}
                    label={t('vehicles.fuelCardPin')}
                    type="text"
                    inputMode="numeric"
                    inputProps={{ pattern: '[0-9]*' }}
                    placeholder={t('vehicles.addEdit.placeholder.fuelCardPin')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              type="submit"
              startIcon={isEdit ? <EditIcon fill="white" /> : <Add />}
            >
              {isEdit ? t('vehicles.edit.button') : t('vehicles.add.button')}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

type SelectProps = {
  isEdit: boolean;
};

function ModelSelect({ isEdit }: SelectProps) {
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const { data: models } = useGetVehicleModels(models =>
    models?.map<ModelSelectItem>(it => ({
      label: `${it.brand} ${it.model}`,
      value: it.id,
      variants: it.variants,
    })),
  );
  return (
    <SelectField
      name={fieldOf<CreateVehicleParams>('modelId')}
      label={t('vehicles.brandAndModel')}
      disabled={isEdit}
      items={models}
      onChange={async () => {
        await setFieldValue('variant', '');
      }}
    />
  );
}

function ModelVariantSelect({ isEdit }: SelectProps) {
  const { t } = useTranslation();
  const { values } = useFormikContext<CreateVehicleParams>();
  const { data: models } = useGetVehicleModels();

  const variants = useMemo(() => {
    return (
      models
        ?.find(it => it.id == values.modelId)
        ?.variants?.map<SelectItem>(it => ({ label: it, value: it })) ?? []
    );
  }, [values.modelId, models]);

  return (
    <SelectField
      name={fieldOf<CreateVehicleParams>('variant')}
      label={t('vehicles.variant')}
      disabled={isEdit || ('modelId' in values && !values.modelId)}
      items={variants}
    />
  );
}

function PriceClassSelect({ isEdit }: SelectProps) {
  const { t } = useTranslation();
  const { values } = useFormikContext<CreateVehicleParams>();

  const { data: priceClasses } = useGetPriceClasses({
    select: priceClasses =>
      priceClasses?.items.map<SelectItem>(it => ({
        label: it.name,
        value: it.id,
      })) ?? [],
  });

  return (
    <SelectField
      name={fieldOf<UpdateVehicleParams>('priceClassId')}
      label={t('vehicles.pricingClass')}
      disabled={isEdit || ('modelId' in values && !values.modelId)}
      items={priceClasses ?? []}
    />
  );
}

type ParkingZoneSelectProps = {
  vehicleZone?: ParkingZone | null;
} & SelectProps;

function ParkingZoneSelect({ isEdit, vehicleZone }: ParkingZoneSelectProps) {
  const { t } = useTranslation();

  const { data: parkingZones } = useGetParkingZones({
    params: { archived: false },
    select: data => {
      const isAssignedParkingZone = !data?.some(
        parkingZone => parkingZone.id === vehicleZone?.id,
      );
      const zones: ParkingZone[] =
        isEdit && vehicleZone && isAssignedParkingZone
          ? [vehicleZone, ...(data ?? [])]
          : data;
      return (
        zones?.map<SelectItem>(it => ({
          label: it.name,
          value: it.id,
        })) ?? []
      );
    },
  });

  return (
    <SelectField
      name={fieldOf<UpdateVehicleParams>('parkingZoneId')}
      label={t('vehicles.carZone')}
      items={parkingZones ?? []}
    />
  );
}

export default AddEditVehiclePage;
