import React from 'react';
import { Box, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MainHeader } from '../../Common/MainHeader';
import { VEHICLE_NEW } from '../../../utils/routes-constants';
import VehiclesTable from './VehiclesTable';

const VehiclesContainer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <MainHeader text={t('drawer.vehicles')}></MainHeader>
        <Button
          color="secondary"
          variant="contained"
          startIcon={<Add />}
          onClick={() => navigate(VEHICLE_NEW)}
        >
          {t('vehicles.buttons.add')}
        </Button>
      </div>
      <Box height="100%" sx={{ mt: 3 }}>
        <VehiclesTable />
      </Box>
    </Box>
  );
};
export default VehiclesContainer;
