import { useMutation, useQuery } from '@tanstack/react-query';
import * as Yup from 'yup';
import baseApi from '../../../services/apiService';
import { PaginatedItems } from '../../../store/api';
import {
  GetReservationParams,
  ReservationDetails,
  ReservationListItem,
} from '../../../entities/Reservation';
import { downloadBlob } from '../../../utils';

export const apiPaths = {
  reservations: '/api/business-customers/reservations',
  export: '/api/business-customers/reservations/export',
};

export const exportSchema = Yup.object({
  type: Yup.string().oneOf(['csv', 'pdf']).required(),
  ids: Yup.array().of(Yup.string()).required(),
});

export type ExportRequest = Yup.InferType<typeof exportSchema>;

export const b2bReservationsApi = {
  getCompanyReservations: (params: GetReservationParams) =>
    baseApi.get<PaginatedItems<ReservationListItem>>(apiPaths.reservations, {
      params,
    }),
  getCompanyReservation: (id: string) =>
    baseApi.get<ReservationDetails>(`${apiPaths.reservations}/${id}`),
  export: (req: ExportRequest) =>
    baseApi.post<Blob>(apiPaths.export, req, {
      responseType: 'blob',
    }),
};

const RESERVATION_KEY = 'b2b-reservation';
const RESERVATIONS_KEY = 'b2b-reservations';

export function useGetCompanyReservations(params: GetReservationParams) {
  return useQuery({
    queryKey: [RESERVATIONS_KEY, params],
    queryFn: async () => {
      return (await b2bReservationsApi.getCompanyReservations(params)).data;
    },
  });
}

export function useGetCompanyReservation(id: string) {
  return useQuery({
    queryKey: [RESERVATION_KEY, id],
    queryFn: async () => {
      return (await b2bReservationsApi.getCompanyReservation(id)).data;
    },
  });
}

export function useExportMutatation() {
  return useMutation({
    mutationFn: async ({
      req,
      filename,
    }: {
      req: ExportRequest;
      filename: string;
    }) => {
      const res = await b2bReservationsApi.export(req);
      const blob = res.data;
      downloadBlob(blob, filename);
    },
  });
}
