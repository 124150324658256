import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export const phoneNumberValidator = (
  t: TFunction,
  requiredMessage: string = t('validationMessages.required'),
) => {
  return Yup.string()
    .min(7, t('validationMessages.minNumber', { value: 7 }))
    .max(8, t('validationMessages.maxNumber', { value: 8 }))
    .required(requiredMessage)
    .test({
      test: it => /^\d+$/.test(it),
      message: t('validationMessages.phoneNumber'),
    });
};

export const fullPhoneNumberValidator = (t: TFunction) => {
  return Yup.string().test({
    test: it => !it || /^\+?[0-9 -]+$/.test(it),
    message: t('validationMessages.phoneNumber'),
  });
};

export function useFullPhoneNumberValidator() {
  const { t } = useTranslation();
  return useMemo(() => fullPhoneNumberValidator(t), [t]);
}

Yup.setLocale({
  mixed: {
    default: 'validationMessages.default',
    required: 'validationMessages.required',
    notType: function notType(_ref) {
      switch (_ref.type) {
        case 'number':
          return 'validationMessages.number';
        default:
          return 'validationMessages.default';
      }
    },
  },
});
