import React from 'react';

interface Props {
  fill?: string;
}
const AddIcon = ({ fill = 'white' }: Props) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 1.83398C9.18699 1.83398 7.41471 2.3716 5.90726 3.37885C4.39981 4.38609 3.22489 5.81773 2.53109 7.49272C1.83728 9.16771 1.65575 11.0108 2.00945 12.789C2.36315 14.5671 3.23619 16.2005 4.51817 17.4825C5.80015 18.7644 7.4335 19.6375 9.21166 19.9912C10.9898 20.3449 12.8329 20.1634 14.5079 19.4695C16.1829 18.7757 17.6145 17.6008 18.6218 16.0934C19.629 14.5859 20.1666 12.8136 20.1666 11.0007C20.1666 9.79687 19.9295 8.60487 19.4689 7.49272C19.0082 6.38057 18.333 5.37004 17.4818 4.51884C16.6306 3.66764 15.6201 2.99242 14.5079 2.53176C13.3958 2.07109 12.2038 1.83398 11 1.83398ZM11 18.334C9.54959 18.334 8.13176 17.9039 6.9258 17.0981C5.71984 16.2923 4.77991 15.147 4.22487 13.807C3.66982 12.467 3.5246 10.9925 3.80756 9.56999C4.09052 8.14746 4.78895 6.84079 5.81453 5.8152C6.84012 4.78962 8.14679 4.09118 9.56932 3.80823C10.9918 3.52527 12.4663 3.67049 13.8063 4.22553C15.1463 4.78058 16.2916 5.72051 17.0974 6.92647C17.9032 8.13243 18.3333 9.55025 18.3333 11.0007C18.3333 12.9456 17.5607 14.8108 16.1854 16.1861C14.8102 17.5614 12.9449 18.334 11 18.334ZM14.6666 10.084H11.9166V7.33398C11.9166 7.09087 11.8201 6.85771 11.6482 6.6858C11.4763 6.51389 11.2431 6.41732 11 6.41732C10.7569 6.41732 10.5237 6.51389 10.3518 6.6858C10.1799 6.85771 10.0833 7.09087 10.0833 7.33398V10.084H7.33332C7.0902 10.084 6.85704 10.1806 6.68514 10.3525C6.51323 10.5244 6.41665 10.7575 6.41665 11.0007C6.41665 11.2438 6.51323 11.4769 6.68514 11.6488C6.85704 11.8207 7.0902 11.9173 7.33332 11.9173H10.0833V14.6673C10.0833 14.9104 10.1799 15.1436 10.3518 15.3155C10.5237 15.4874 10.7569 15.584 11 15.584C11.2431 15.584 11.4763 15.4874 11.6482 15.3155C11.8201 15.1436 11.9166 14.9104 11.9166 14.6673V11.9173H14.6666C14.9098 11.9173 15.1429 11.8207 15.3148 11.6488C15.4867 11.4769 15.5833 11.2438 15.5833 11.0007C15.5833 10.7575 15.4867 10.5244 15.3148 10.3525C15.1429 10.1806 14.9098 10.084 14.6666 10.084Z"
      fill={fill}
    />
  </svg>
);

export default AddIcon;
