import React from 'react';

const MenuIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 5.83333C10.3297 5.83333 10.6519 5.73559 10.926 5.55245C11.2001 5.36931 11.4137 5.10902 11.5398 4.80447C11.666 4.49993 11.699 4.16482 11.6347 3.84152C11.5704 3.51822 11.4116 3.22124 11.1786 2.98816C10.9455 2.75507 10.6485 2.59633 10.3252 2.53203C10.0019 2.46772 9.66678 2.50072 9.36224 2.62687C9.05769 2.75301 8.79739 2.96664 8.61426 3.24072C8.43112 3.5148 8.33337 3.83703 8.33337 4.16667C8.33337 4.60869 8.50897 5.03262 8.82153 5.34518C9.13409 5.65774 9.55801 5.83333 10 5.83333ZM10 14.1667C9.67041 14.1667 9.34817 14.2644 9.07409 14.4476C8.80001 14.6307 8.58639 14.891 8.46024 15.1955C8.3341 15.5001 8.30109 15.8352 8.3654 16.1585C8.42971 16.4818 8.58844 16.7788 8.82153 17.0118C9.05462 17.2449 9.35159 17.4037 9.67489 17.468C9.99819 17.5323 10.3333 17.4993 10.6378 17.3731C10.9424 17.247 11.2027 17.0334 11.3858 16.7593C11.569 16.4852 11.6667 16.163 11.6667 15.8333C11.6667 15.3913 11.4911 14.9674 11.1786 14.6548C10.866 14.3423 10.4421 14.1667 10 14.1667ZM10 8.33333C9.67041 8.33333 9.34817 8.43108 9.07409 8.61422C8.80001 8.79735 8.58639 9.05765 8.46024 9.3622C8.3341 9.66674 8.30109 10.0018 8.3654 10.3252C8.42971 10.6485 8.58844 10.9454 8.82153 11.1785C9.05462 11.4116 9.35159 11.5703 9.67489 11.6346C9.99819 11.699 10.3333 11.6659 10.6378 11.5398C10.9424 11.4137 11.2027 11.2 11.3858 10.926C11.569 10.6519 11.6667 10.3296 11.6667 10C11.6667 9.55797 11.4911 9.13405 11.1786 8.82149C10.866 8.50893 10.4421 8.33333 10 8.33333Z"
      fill="#10171B"
    />
  </svg>
);

export default MenuIcon;
