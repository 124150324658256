import React from 'react';

const CloseIcon = ({ size = 10 }: { size?: number }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.71405 8.53565L6.17851 5.00011L9.71405 1.46458C9.87033 1.3083 9.95812 1.09634 9.95812 0.875322C9.95812 0.654309 9.87033 0.442347 9.71405 0.286067C9.55777 0.129787 9.3458 0.0419893 9.12479 0.0419895C8.90378 0.0419894 8.69181 0.129786 8.53553 0.286067L5 3.8216L1.46447 0.286067C1.30819 0.129786 1.09622 0.0419894 0.87521 0.0419895C0.654197 0.0419893 0.442235 0.129787 0.285955 0.286067C0.129674 0.442347 0.0418771 0.654309 0.0418773 0.875322C0.0418772 1.09634 0.129674 1.3083 0.285955 1.46458L3.82149 5.00011L0.285954 8.53565C0.129674 8.69193 0.0418765 8.90389 0.0418765 9.1249C0.0418769 9.34592 0.129674 9.55788 0.285955 9.71416C0.442235 9.87044 0.654196 9.95824 0.875209 9.95824C1.09622 9.95824 1.30819 9.87044 1.46447 9.71416L5 6.17862L8.53553 9.71416C8.69181 9.87044 8.90378 9.95824 9.12479 9.95824C9.3458 9.95824 9.55777 9.87044 9.71405 9.71416C9.87033 9.55788 9.95812 9.34592 9.95812 9.1249C9.95812 8.90389 9.87033 8.69193 9.71405 8.53565Z"
      fill="#10171B"
    />
  </svg>
);

export default CloseIcon;
