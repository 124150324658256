import { useMutation } from '@tanstack/react-query';
import { acceptInviteApi } from './api';

export function useAcceptInvite() {
  return useMutation({
    mutationFn: async (code: string) => {
      await acceptInviteApi.accept(code);
    },
  });
}
