import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowSelectionModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Box, IconButton, Typography } from '@mui/material';
import { PhotoCameraOutlined } from '@mui/icons-material';
import { PaginatedTable, usePaginatedTable } from '../Common/PaginatedTable';
import {
  Feedback,
  GetReservationParams,
  ReservationListItem,
} from '../../entities/Reservation';
import {
  DATE_ONLY,
  formatDateTime,
  formatDuration,
  TIME_ONLY,
} from '../../utils/datetime-utils';
import { dateTimeString } from '../../entities';
import { fieldOf } from '../../store/api';
import MuiRouterLink from '../Common/MuiRouterLink';
import { formatDistance, formatMoney } from '../../utils';
import { useModal } from '../Modal/hooks';
import { ModalType } from '../../utils/modal-utils';
import ReservationFeedbackRating from './ReservationFeedbackRating';
import ReservationsToolbar from './ReservationsToolbar';
import { useGetReservations } from './api';

const ReservationsTable = ({
  onSelectionChange,
}: {
  onSelectionChange?: (selectedIds: string[]) => void;
}) => {
  const { t } = useTranslation();
  const { open } = useModal();

  const [reservationParams, setReservationParams] =
    useState<GetReservationParams>({});

  const { data: reservations, isLoading } =
    useGetReservations(reservationParams);

  const { rows, totalItems, debounceParams } = usePaginatedTable({
    paginatedItems: reservations,
    params: reservationParams,
    setParams: setReservationParams,
    syncParamsWithUrl: true,
    mapGridRow: (r: ReservationListItem) =>
      ({
        id: r.id,
        reservationNumber: r,
        registerNumber: r.vehicle.registrationNumber,
        zone: r.pickupParkingZone.name,
        reservationStart: r.startDateTime,
        reservationEnd: r.endDateTime,
        customer: r.customer?.name,
        phoneNumber: r.customer?.phoneNumber,
        email: r.customer?.email,
        price: r.price,
        duration: r.durationSeconds,
        distance: r.distanceMeters,
        feedback: r.feedback,
        comment: r.feedback?.comment,
        photosConfirmed: r.photosConfirmed,
      } as ReservationRow),
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: fieldOf<ReservationRow>('reservationNumber'),
        headerName: t('reservations.reservationNumber'),
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<ReservationRow>) => {
          const { id, reservationNumber } = params.row;
          // TODO remove number derivation from ID when API provider the number
          return (
            <MuiRouterLink to={id}>
              {reservationNumber.reservationNumber ??
                id.substring(id.length - 5)}
            </MuiRouterLink>
          );
        },
      },
      {
        field: fieldOf<ReservationRow>('registerNumber'),
        headerName: t('reservations.registerNumber'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('zone'),
        headerName: t('reservations.zone'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('reservationStart'),
        headerName: t('reservations.reservationStart'),
        flex: 1.5,
        sortable: false,
        renderCell: params => (
          <Box>
            <Typography variant="body2">
              {formatDateTime(params.value, DATE_ONLY)}
            </Typography>
            <Typography variant="caption">
              {formatDateTime(params.value, TIME_ONLY)}
            </Typography>
          </Box>
        ),
      },
      {
        field: fieldOf<ReservationRow>('reservationEnd'),
        headerName: t('reservations.reservationEnd'),
        flex: 1.5,
        sortable: false,
        renderCell: params => (
          <Box>
            <Typography variant="body2">
              {formatDateTime(params.value, DATE_ONLY)}
            </Typography>
            <Typography variant="caption">
              {formatDateTime(params.value, TIME_ONLY)}
            </Typography>
          </Box>
        ),
      },
      {
        field: fieldOf<ReservationRow>('customer'),
        headerName: t('reservations.customer'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('price'),
        headerName: t('reservations.price'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value ? formatMoney(params.value) : '',
      },
      {
        field: fieldOf<ReservationRow>('duration'),
        headerName: t('reservations.duration'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          formatDuration(params.value * 1000),
      },
      {
        field: fieldOf<ReservationRow>('distance'),
        headerName: t('reservations.distance'),
        flex: 1,
        sortable: false,
        valueFormatter: ({ value }: GridValueFormatterParams<number>) =>
          formatDistance(value) ?? '',
      },
      {
        field: fieldOf<ReservationRow>('feedback'),
        headerName: t('reservations.feedback'),
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams<Feedback, Feedback>) => {
          return <ReservationFeedbackRating rating={params?.value?.rating} />;
        },
      },
      {
        field: fieldOf<ReservationRow>('comment'),
        headerName: t('reservations.comment'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('photosConfirmed'),
        headerName: t('reservations.photosConfirmed'),
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) =>
          params.value && (
            <IconButton onClick={() => handlePhotosClick(params.row.id)}>
              <PhotoCameraOutlined />
            </IconButton>
          ),
      },
    ],
    [],
  );

  function handlePhotosClick(reservationId: string) {
    open({
      type: ModalType.RESERVATION_PHOTOS,
      props: {
        reservationId,
      },
    });
  }

  function handleSelectionChange(selectionModel: GridRowSelectionModel) {
    onSelectionChange?.(selectionModel.map(it => it.toString()));
  }

  return (
    <PaginatedTable
      rows={rows}
      columns={columns}
      totalItems={totalItems}
      isLoading={isLoading}
      fetchRows={debounceParams}
      Toolbar={ReservationsToolbar}
      checkboxSelection={true}
      onSelectionChange={handleSelectionChange}
    />
  );
};

type ReservationRow = {
  id: string;
  reservationNumber: ReservationListItem;
  registerNumber: string;
  zone: string;
  reservationStart: dateTimeString;
  reservationEnd: dateTimeString;
  customer: string;
  price: string;
  duration: number;
  distance: number;
  feedback: Feedback;
  comment: string;
  photosConfirmed: boolean;
};

export default ReservationsTable;
