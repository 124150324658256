import { GridPaginationModel } from '@mui/x-data-grid';
import { PaginatedRequestParams } from '../store/api';

export const ALL = 'all';
export const DEFAULT_PAGE_SIZE_LIMIT = 50;

export const DEFAULT_GRID_PAGINATION: GridPaginationModel = {
  page: 0,
  pageSize: 0,
};

export const INITIAL_REQUEST_PARAMS: PaginatedRequestParams = {
  page: 1,
};
