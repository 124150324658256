import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';

interface Props {
  text: string;
  children?: ReactNode;
}
export const PageHeader = ({ text, children }: Props) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: '30px',
      alignItems: 'center',
    }}
  >
    <Typography variant="h3" display="inline">
      {text}
    </Typography>
    <div>{children}</div>
  </div>
);
