import React from 'react';
import { MenuItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MoreOptionsButton } from '../../Common/MoreOptionsButton';
import DeleteIcon from '../../../icons/DeleteIcon';
import EditIcon from '../../../icons/EditIcon';

interface Props {
  onEditPriceClass: () => void;
  onDeletePriceClass: () => void;
}

const PriceClassesTableDropdown = ({
  onEditPriceClass,
  onDeletePriceClass,
}: Props) => {
  const { t } = useTranslation();

  return (
    <MoreOptionsButton>
      <MenuItem key={0} onClick={onEditPriceClass}>
        <EditIcon />
        {t('settings.priceClasses.buttons.edit')}
      </MenuItem>
      <MenuItem key={1} onClick={onDeletePriceClass}>
        <DeleteIcon />
        {t('settings.priceClasses.buttons.remove')}
      </MenuItem>
    </MoreOptionsButton>
  );
};

export default PriceClassesTableDropdown;
