import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useNavigate, useParams } from 'react-router-dom';
import { SecondaryButton } from '../../../components/Common/SecondaryButton';
import { TabHeader } from '../../../components/Common/TabHeader';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import { EUR } from '../../../utils';
import { B2B_COMPANY_MANAGE_COST_GROUPS } from '../../../utils/b2b-routes-constants';
import {
  AddEditCostGroupRequest,
  addEditCostGroupSchema,
  useEditCostGroup,
  useGetCostGroup,
} from './api';
import CostGroupForm from './CostGroupForm';

const EditCostGroup = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const { successSnack, apiErrorSnack } = useSnacks();

  const editCostGroup = useEditCostGroup();
  const { data: costGroup } = useGetCostGroup(id || '');

  const handleSubmit = (values: AddEditCostGroupRequest) => {
    if (!id) return;
    editCostGroup.mutate(
      { id, body: addEditCostGroupSchema.cast(values) },
      {
        onSuccess: () => {
          successSnack(t('b2b.wallet.costGroup.snacks.edited'));
          navigate(B2B_COMPANY_MANAGE_COST_GROUPS);
        },
        onError: e => apiErrorSnack(e),
      },
    );
  };

  return (
    <Box sx={{ flex: 1 }}>
      <TabHeader headerVariant="h3" text={t('b2b.wallet.costGroup.edit')}>
        <SecondaryButton startIcon={<Close />} onClick={() => navigate(-1)}>
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      {costGroup && (
        <CostGroupForm
          onSubmit={handleSubmit}
          values={{
            name: costGroup.name,
            paymentMethodId: costGroup.paymentMethod.id,
            dailyAmount: EUR(costGroup.dailyAmount).value || 0,
          }}
        />
      )}
    </Box>
  );
};

export default EditCostGroup;
