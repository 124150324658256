import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { DetailContent } from '../Common/DetailContent';

export type VehicleStatusType =
  | 'available'
  | 'not_available'
  | 'archived'
  | 'other';

export const VehicleStatus: Record<
  VehicleStatusType,
  Record<string, string>
> = {
  available: {
    title: 'available',
    color: '#00A399',
  },
  not_available: {
    title: 'notAvailable',
    color: '#D4002A',
  },
  other: {
    title: 'other',
    color: '#65615D',
  },
  archived: {
    title: 'archived',
    color: '#65615D',
  },
};

export const VehicleStatusTextTable: React.FC<{ value: VehicleStatusType }> = ({
  value,
}) => {
  const { t } = useTranslation();
  const status = VehicleStatus[value] || {
    title: value,
  };

  return (
    <ul style={{ paddingInlineStart: 20, color: status.color }}>
      <li>
        <Typography variant="caption">
          {t(`vehicles.statuses.${status.title}`)}
        </Typography>
      </li>
    </ul>
  );
};

export const VehicleStatusTextDetails: React.FC<{
  value: VehicleStatusType;
}> = ({ value }) => {
  const { t } = useTranslation();
  const status = VehicleStatus[value] || {
    title: value,
  };

  return (
    <DetailContent
      text={t(`vehicles.statuses.${status.title}`)}
      style={{ color: status.color }}
    />
  );
};
