import React from 'react';
import { useParams } from 'react-router-dom';
import LoadingIcon from '../../Common/LoadingIcon';
import { useGetReservation } from '../api';
import EditMaintenanceReservation from './EditMaintenanceReservation';
import EditReservation from './EditReservation';

const EditReservationContainer = () => {
  const { reservationId } = useParams();

  const { data: reservation } = useGetReservation(reservationId);

  if (!reservation) {
    return <LoadingIcon />;
  }

  return reservation.details.maintenance ? (
    <EditMaintenanceReservation />
  ) : (
    <EditReservation />
  );
};

export default EditReservationContainer;
