import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { Button } from '@mui/material';
import { secondaryButtonStyle } from './SecondaryButton';

function CancelToParentPathButton() {
  const { t } = useTranslation();
  return (
    <Button
      component={Link}
      to=".."
      relative="path"
      startIcon={<Close />}
      style={secondaryButtonStyle}
      variant="outlined"
    >
      {t('cancel')}
    </Button>
  );
}

export default CancelToParentPathButton;
