import React, { useState } from 'react';
import {
  Alert,
  Box,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Check, WarningAmberOutlined } from '@mui/icons-material';
import ErrorIcon from '@mui/icons-material/Error';
import { TabHeader } from '../../Common/TabHeader';
import {
  useExecuteVehicleAction,
  useGetVehicleActions,
} from '../../../store/vehicle/queries';
import LoadingIcon from '../../Common/LoadingIcon';
import { SnackType, useSnacks } from '../../Common/AvisSnackbarProvider';
import { VehicleActionState } from '../../../entities/Vehicle';

function VehicleActions() {
  const { t } = useTranslation();
  const snacks = useSnacks();
  const { vehicleId } = useParams();
  const { data: actions, isLoading } = useGetVehicleActions(vehicleId || '');
  const execute = useExecuteVehicleAction();
  const [lastActionStates, setLastActionStates] = useState<
    Record<string, VehicleActionState>
  >({});

  function handleAction(actionId: string) {
    execute.mutate(actionId, {
      onSuccess: response => {
        setLastActionStates({
          ...lastActionStates,
          [actionId]: response.state,
        });
        switch (response.state) {
          case 'succeeded':
            snacks.snack(SnackType.SUCCESS, t('vehicles.actionsTab.succeeded'));
            break;
          case 'failed':
            snacks.snack(SnackType.ERROR, t('vehicles.actionsTab.failed'));
            break;
          case 'unconfirmed':
            snacks.snack(SnackType.INFO, t('vehicles.actionsTab.unconfirmed'));
        }
      },
      onError: e => snacks.apiErrorSnack(e),
    });
  }

  return (
    <Box>
      <TabHeader text={t('vehicles.tabs.actions')} />
      {isLoading ? (
        <LoadingIcon />
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ width: '25%' }}>
                {t('vehicles.actions.action')}
              </TableCell>
              <TableCell sx={{ width: '75%' }}>
                {t('vehicles.actions.state')}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {actions && actions.items.length > 0 ? (
              actions.items.map(action => {
                const lastState = lastActionStates[action.id];

                return (
                  <TableRow key={action.type}>
                    <TableCell>
                      <Chip
                        label={t([
                          `vehicles.actions.${action.type}`,
                          action.type,
                        ])}
                        onClick={() => handleAction(action.id)}
                      />
                    </TableCell>
                    <TableCell>
                      <Box sx={{ display: 'flex' }}>
                        {lastState === 'succeeded' ? (
                          <Check />
                        ) : lastState === 'failed' ? (
                          <ErrorIcon />
                        ) : lastState === 'unconfirmed' ? (
                          <WarningAmberOutlined />
                        ) : null}
                        {lastState && (
                          <Typography sx={{ ml: 2 }}>{lastState}</Typography>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })
            ) : (
              <Alert severity="info">
                {t('vehicles.actionsTab.noActionsFound')}
              </Alert>
            )}
          </TableBody>
        </Table>
      )}
    </Box>
  );
}

export default VehicleActions;
