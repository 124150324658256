import React from 'react';
import { useTranslation } from 'react-i18next';
import { Add, Block } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { TabHeader } from '../../Common/TabHeader';
import { SecondaryButton } from '../../Common/SecondaryButton';
import EditIcon from '../../../icons/EditIcon';
import DeleteIcon from '../../../icons/DeleteIcon';
import {
  BusinessCustomerDetail,
  PrivateCustomerDetail,
} from '../../../entities/Customer';
import { useDeleteCustomer } from '../../../store/customer/queries';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import { useModal } from '../../Modal/hooks';
import BusinessCustomerDetails from './BusinessCustomerDetails';
import PrivateCustomerDetails from './PrivateCustomerDetails';

interface Props {
  customer: BusinessCustomerDetail | PrivateCustomerDetail;
  isBusinessCustomer: boolean;
}

const CustomerDetailsGeneral = ({ isBusinessCustomer, customer }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snacks = useSnacks();
  const { confirm } = useModal();

  const deleteCustomer = useDeleteCustomer(
    isBusinessCustomer ? 'business' : 'private',
  );

  async function handleDelete() {
    confirm({
      title: t('customers.modal.delete.title'),
      action: () => {
        deleteCustomer.mutate(
          { customerId: customer.id, deleted: true },
          {
            onSuccess: () => {
              snacks.successSnack(t('customers.snacks.customerDeleted'));
              navigate(-1);
            },
            onError: e => snacks.apiErrorSnack(e),
          },
        );
      },
    });
  }

  return (
    <>
      <TabHeader text={t('customers.tabs.general')}>
        <SecondaryButton sx={{ ml: 2 }} startIcon={<Add />}>
          {t('customers.buttons.addBonus')}
        </SecondaryButton>
        <SecondaryButton sx={{ ml: 2 }} startIcon={<Block />}>
          {t('customers.buttons.block')}
        </SecondaryButton>
        <SecondaryButton
          onClick={() => navigate('edit')}
          sx={{ ml: 2 }}
          startIcon={<EditIcon />}
        >
          {t('customers.buttons.edit')}
        </SecondaryButton>
        <SecondaryButton
          sx={{ ml: 2 }}
          onClick={handleDelete}
          startIcon={<DeleteIcon />}
        >
          {t('customers.buttons.delete')}
        </SecondaryButton>
      </TabHeader>
      {isBusinessCustomer ? (
        <BusinessCustomerDetails
          customer={customer as BusinessCustomerDetail}
        />
      ) : (
        <PrivateCustomerDetails customer={customer as PrivateCustomerDetail} />
      )}
    </>
  );
};

export default CustomerDetailsGeneral;
