import React, { ReactNode, useEffect, useState } from 'react';
import { Alert, Box, Button, Fade } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UseQueryResult } from '@tanstack/react-query';
import LoadingIcon from './LoadingIcon';

type Props = {
  query: UseQueryResult;
  children: ReactNode;
};

const LOADER_VISIBILITY_DELAY_MS = 500;
const LOADER_FADE_TIMEOUT = 300;

function PageQueryLoader({ query, children }: Props) {
  const { t } = useTranslation();
  const { isError, isLoading } = query;
  const [loaderVisible, setLoaderVisible] = useState(false);

  useEffect(() => {
    // Prevent flicker of loading state for a short time on mount
    setTimeout(() => setLoaderVisible(true), LOADER_VISIBILITY_DELAY_MS);
  }, []);

  // Always render children if we got some data
  if (query.data)
    return (
      <Fade in>
        {/* Fade wants something better than just the children to hook into*/}
        <Box>{children}</Box>
      </Fade>
    );
  // Don't show anything before the short loader visibility mounting time
  if (!loaderVisible) return null;

  return (
    <Fade in timeout={LOADER_FADE_TIMEOUT} unmountOnExit>
      <Box>
        <Alert
          icon={isLoading ? <LoadingIcon size={20} color="info" /> : null}
          severity={isError ? 'error' : 'info'}
          sx={{ maxWidth: 670 }}
          action={
            isError ? (
              <Button
                color="inherit"
                size="small"
                onClick={() => query.refetch()}
                sx={{ textTransform: 'uppercase', height: '100%' }}
              >
                {t('reloadPage')}
              </Button>
            ) : null
          }
        >
          {isError ? t('error.failedToLoadPage') : t('loading')}
        </Alert>
      </Box>
    </Fade>
  );
}

export default PageQueryLoader;
