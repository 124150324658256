import React, { useEffect, useState } from 'react';
import { Pagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import PageQueryLoader from '../../Common/PageQueryLoader';
import {
  GetRelatedCustomersParams,
  RelatedCustomerListItem,
} from '../../../store/api/related-customers';
import { DetailsCard } from '../../Common/DetailsCard';
import { NoDataPlaceholder } from '../../Common/NoDataPlaceholder';
import NoUserIcon from '../../../icons/NoUserIcon';
import { useModal } from '../../Modal/hooks';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import {
  useDeleteRelatedCustomer,
  useGetRelatedCustomers,
} from '../../../store/related-customers';
import { usePaginatedTable } from '../../Common/PaginatedTable';
import RelatedCustomerCard from './RelatedCustomerCard';

type Props = {
  costGroupId?: string;
};

function RelatedCustomersList({ costGroupId }: Props) {
  const { t } = useTranslation();
  const { confirm } = useModal();
  const snacks = useSnacks();
  const navigate = useNavigate();
  const { customerId } = useParams();

  const [params, setParams] = useState<GetRelatedCustomersParams>({
    limit: 4,
    costGroupId,
    businessCustomerId: customerId,
  });
  const customersQuery = useGetRelatedCustomers(params);
  const { data: customers } = customersQuery;
  const { rows } = usePaginatedTable<RelatedCustomerListItem>({
    paginatedItems: customers,
    params,
    setParams,
    syncParamsWithUrl: true,
  });

  const deleteMyUser = useDeleteRelatedCustomer();

  useEffect(() => {
    setParams({ ...params, costGroupId });
  }, [costGroupId]);

  const handleDelete = (id: string) => {
    confirm({
      title: t('relatedCustomers.modal.delete.title'),
      action: () => {
        deleteMyUser.mutate(id, {
          onSuccess: () => {
            snacks.successSnack(t('relatedCustomers.snacks.deleted'));
          },
          onError: e => snacks.apiErrorSnack(e),
        });
      },
    });
  };

  return (
    <PageQueryLoader query={customersQuery}>
      {rows.length > 0 ? (
        rows.map((rc: RelatedCustomerListItem) => (
          <DetailsCard
            key={rc.id}
            fullWidth
            content={
              <RelatedCustomerCard
                relatedCustomer={rc}
                onDelete={() => handleDelete(rc.id)}
              />
            }
          />
        ))
      ) : (
        <NoDataPlaceholder
          icon={<NoUserIcon />}
          title={t('relatedCustomers.noData')}
          titleVariant="h4"
          buttonTitle={t('relatedCustomers.buttons.add')}
          onClick={() => navigate('add')}
        />
      )}
      {customers?.pagination.totalPages &&
        customers?.pagination.totalPages > 1 && (
          <Pagination
            count={customers?.pagination.totalPages}
            page={customers?.pagination.currentPage}
            onChange={(event, page) => setParams({ ...params, page: page })}
          />
        )}
    </PageQueryLoader>
  );
}

export default RelatedCustomersList;
