import { Button, CircularProgress } from '@mui/material';
import React from 'react';
import { ButtonProps } from '@mui/material/Button/Button';

interface Props extends ButtonProps {
  loading: boolean;
}

const LoadingButton: React.FC<Props> = ({
  loading,
  disabled,
  startIcon,
  endIcon,
  children,
  ...otherProps
}) => (
  <Button
    variant="contained"
    size="medium"
    color="secondary"
    startIcon={!loading && startIcon}
    endIcon={!loading && endIcon}
    disabled={loading || disabled}
    {...otherProps}
  >
    {loading ? <CircularProgress color="inherit" size={16} /> : children}
  </Button>
);

export default LoadingButton;
