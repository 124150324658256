import * as Yup from 'yup';
import i18next, { TFunction } from 'i18next';
import currency from 'currency.js';
import {
  BusinessCustomerDetail,
  BusinessCustomerListItem,
  CustomerListItem,
  CustomerParams,
  CustomerType,
  PrivateCustomerDetail,
} from '../../entities/Customer';
import baseApi from '../../services/apiService';
import { PaymentMethod } from '../../entities';
import { PaginatedItems } from './index';

const privateCustomerApi = {
  getPrivateCustomers: (params?: CustomerParams) =>
    baseApi.get<PaginatedItems<CustomerListItem>>('/api/private-customers', {
      params: params,
    }),
  getPrivateCustomer: (customerId: string) =>
    baseApi.get<PrivateCustomerDetail>(`/api/private-customers/${customerId}`),
  updatePrivateCustomer: (
    customerId: string,
    params: PrivateCustomerEditRequest,
  ) => baseApi.put(`/api/private-customers/${customerId}`, params),
  deletePrivateCustomer: (customerId: string, params: { deleted: boolean }) =>
    baseApi.post(`/api/private-customers/${customerId}/remove`, params),
  deletePrivateCustomersBulk: (customerIds: string[]) =>
    baseApi.post(`/api/private-customers/bulk/remove`, customerIds),
};

const businessCustomerApi = {
  getBusinessCustomers: (params?: CustomerParams) =>
    baseApi.get<PaginatedItems<BusinessCustomerListItem>>(
      '/api/business-customers',
      {
        params: params,
      },
    ),
  getBusinessCustomer: (customerId: string) =>
    baseApi.get<BusinessCustomerDetail>(
      `/api/business-customers/${customerId}`,
    ),
  deleteBusinessCustomer: (customerId: string, params: { deleted: boolean }) =>
    baseApi.post(`/api/business-customers/${customerId}/remove`, params),
  deleteBusinessCustomersBulk: (customerIds: string[]) =>
    baseApi.post(`/api/business-customers/bulk/remove`, customerIds),
  updateBusinessCustomer: (
    customerId: string,
    params: BusinessCustomerEditRequest,
  ) => baseApi.put(`/api/business-customers/${customerId}`, params),
};

export const customersApi = {
  ...privateCustomerApi,
  ...businessCustomerApi,
  getCustomerPaymentMethods: (params: CustomerPaymentMethodsParams) =>
    baseApi.get<PaymentMethod[]>('/api/payment-methods/customer', {
      params,
    }),
};

export interface CustomerPaymentMethodsParams {
  customerType?: CustomerType;
  customerId?: string;
}

export function getBusinessCustomerEditSchema(t: TFunction) {
  return Yup.object({
    address: Yup.string().required(),
    email: Yup.string().email(t('validationMessages.email')).required(),
    monthlyLimit: Yup.number()
      .default(0)
      .min(0)
      .transform(value => currency(value).intValue)
      .required(),
    vatNumber: Yup.string(),
    verified: Yup.boolean(),
    emailMarketing: Yup.boolean(),
    contractNumber: Yup.string(),
    directoId: Yup.string(),
  });
}

/** A schema instance just for inferring the request type,
 * actual schema users should use the schema getter function
 * and pass the translation function from an useTranslation hook */
const businessCustomerEditSchema = getBusinessCustomerEditSchema(i18next.t);

// prettier-ignore
export type BusinessCustomerEditRequest = Yup.InferType<typeof businessCustomerEditSchema>;

export function getPrivateCustomerEditSchema(t: TFunction) {
  return Yup.object({
    name: Yup.string().required(t('validationMessages.required')),
    email: Yup.string().email(t('validationMessages.email')).required(),
    emailMarketing: Yup.boolean(),
  });
}

const privateCustomerEditSchema = getPrivateCustomerEditSchema(i18next.t);

// prettier-ignore
export type PrivateCustomerEditRequest = Yup.InferType<typeof privateCustomerEditSchema>;
