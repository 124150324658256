import React, { ReactNode } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';

type Props = {
  icon?: ReactNode;
  label?: string;
  extended?: boolean;
  backgroundColor?: string;
  textColor?: string;
};

function StateChip({
  extended,
  label,
  icon,
  backgroundColor,
  textColor,
}: Props) {
  return (
    <Tooltip title={!extended && label}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '3px 7px',
          gap: '4px',
          backgroundColor,
          borderRadius: '16px',
          minWidth: '26px',
          width: 'fit-content',
          height: '22px',
        }}
      >
        {icon}
        {extended && (
          <Typography
            variant="caption"
            style={{
              color: textColor,
            }}
          >
            {label}
          </Typography>
        )}
      </Box>
    </Tooltip>
  );
}

export default StateChip;
