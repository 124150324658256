import React, { ChangeEvent } from 'react';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { FormLabel, Grid } from '@mui/material';
import {
  BonusCodeStatusType,
  GetBonusCodeParams,
} from '../../../entities/BonusCode';
import Checkbox from '../../Common/Checkbox';
import Select from '../../Common/Select';
import { PaginatedTableToolbar } from '../../Common/PaginatedTable';

const BonusCodesToolbar = ({
  params,
  setParams,
}: PaginatedTableToolbar<GetBonusCodeParams>) => {
  const { t } = useTranslation();

  const onStatusValueChange = (value: string) => {
    setParams((prevState: GetBonusCodeParams) => ({
      ...prevState,
      status: value || undefined,
    }));
  };

  const onCheckboxValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setParams((prevState: GetBonusCodeParams) => ({
      ...prevState,
      archived: event.target.checked || undefined,
    }));
  };

  return (
    <GridToolbarContainer>
      <Grid container columnSpacing={3} alignItems="center">
        <Grid item sx={{ width: 160 }}>
          <FormLabel>{t(`settings.bonusCodes.filters.status`)}</FormLabel>
          <Select
            items={Object.values(BonusCodeStatusType).map(
              (status: BonusCodeStatusType) => ({
                value: status,
                label: t(`settings.bonusCodes.statuses.${status}`),
              }),
            )}
            value={params.status || ''}
            onChange={onStatusValueChange}
            displayEmpty
          />
        </Grid>
        <Grid item sx={{ paddingTop: 3 }}>
          <Checkbox
            label={t('settings.bonusCodes.filters.displayArchived')}
            checked={params.archived}
            onChange={onCheckboxValueChange}
            disableRipple
          />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default BonusCodesToolbar;
