import React from 'react';
import { Alert, Box, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DetailRow } from '../../../components/Common/DetailRow';
import { DetailsCard } from '../../../components/Common/DetailsCard';
import { DATE_TIME_WITH_SECONDS } from '../../../utils/datetime-utils';
import {
  SecondaryButton,
  secondaryButtonStyle,
} from '../../../components/Common/SecondaryButton';
import EditIcon from '../../../icons/EditIcon';
import MenuIcon from '../../../icons/MenuIcon';
import { PageHeader } from '../../../components/Common/PageHeader';
import { B2B_COMPANY_MANAGE_AUTHORIZED_PERSONS } from '../../../utils/b2b-routes-constants';
import TableWrapper from '../../../components/Common/TableWrapper';
import { selectIsRoleRepresentative } from '../../../store/user/slice';
import { useGetMyCompany } from './api';
import AuthorizedPersons from './AuthorizedPersons';

const MyCompanyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isRepresentative = useSelector(selectIsRoleRepresentative);

  const { data: company, isLoading, isError } = useGetMyCompany();

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {isLoading ? (
        <Alert severity="info">{t('loading')}</Alert>
      ) : isError || !company ? (
        <Alert severity="error">{t('error.appCrashed')}</Alert>
      ) : (
        <>
          <PageHeader
            text={isLoading ? t('loading') : company?.companyName ?? '-'}
          >
            <SecondaryButton
              sx={{ ml: 2 }}
              startIcon={<EditIcon />}
              onClick={() => navigate('edit')}
            >
              {t('b2b.buttons.edit')}
            </SecondaryButton>
            <SecondaryButton sx={{ ml: 2 }} startIcon={<MenuIcon />} />
          </PageHeader>
          <Box height="100%">
            <DetailsCard
              content={
                <>
                  <DetailRow
                    key="companyName"
                    title={t('b2b.myCompany.companyName')}
                    content={company.companyName}
                  />
                  <DetailRow
                    key="registrationNumber"
                    title={t('b2b.myCompany.registrationNumber')}
                    content={company.registrationNumber}
                  />
                  <DetailRow
                    key="kmkrNumber"
                    title={t('b2b.myCompany.kmkrNumber')}
                    content={company.vatNumber}
                  />
                  <DetailRow
                    key="address"
                    title={t('b2b.myCompany.address')}
                    content={company.address}
                    showDivider={false}
                  />
                </>
              }
            />
            <DetailsCard
              content={
                <>
                  <DetailRow
                    key="representativeName"
                    title={t('b2b.myCompany.representativeName')}
                    content={company.representative?.name}
                  />
                  <DetailRow
                    key="phoneNumber"
                    title={t('b2b.myCompany.phoneNumber')}
                    content={`${company.representative?.areaCode} ${company.representative?.phoneNumber}`}
                  />
                  <DetailRow
                    key="email"
                    title={t('b2b.myCompany.email')}
                    content={company.email}
                  />
                  <DetailRow
                    key="registrationDate"
                    title={t('b2b.myCompany.registrationDate')}
                    content={moment(company.created).format(
                      DATE_TIME_WITH_SECONDS,
                    )}
                  />
                  <DetailRow
                    key="verified"
                    title={t('b2b.myCompany.verified')}
                    content={company.verified ? t('yes') : t('no')}
                  />
                  <DetailRow
                    key="contractNumber"
                    title={t('b2b.myCompany.contractNumber')}
                    content={company.contractNumber}
                  />
                  <DetailRow
                    key="emailMarketing"
                    title={t('b2b.myCompany.emailSubscription')}
                    content={company.emailMarketing ? t('yes') : t('no')}
                    showDivider={false}
                  />
                </>
              }
            />
            {isRepresentative && (
              <TableWrapper
                title={t('b2b.myCompany.authorizedPersons.title')}
                infoTooltipText={t(
                  'b2b.myCompany.authorizedPersons.titleInfoTooltip',
                )}
                titleButton={
                  <Button
                    startIcon={<EditIcon />}
                    component={Link}
                    style={secondaryButtonStyle}
                    to={B2B_COMPANY_MANAGE_AUTHORIZED_PERSONS}
                  >
                    {t('b2b.myCompany.buttons.manage')}
                  </Button>
                }
              >
                <AuthorizedPersons showPlaceholder />
              </TableWrapper>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default MyCompanyPage;
