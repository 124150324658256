import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../icons/CloseIcon';
import { useAppDispatch } from '../../store';
import { closeModal } from '../../store/modal/slice';

interface Props {
  title: string;
}

const ModalHeader = ({ title }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Box
      sx={{
        background: '#F3F2F0',
        py: 3,
        pr: 3,
        pl: 4,
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: 2,
      }}
    >
      <Typography variant="h5">{t(title)}</Typography>
      <IconButton size="small" sx={{ height: 'auto' }} onClick={handleClose}>
        <CloseIcon size={14} />
      </IconButton>
    </Box>
  );
};

export default ModalHeader;
