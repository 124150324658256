import React from 'react';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import { ReservationRating } from '../../entities/Reservation';

interface ReservationFeedbackRatingProps {
  rating?: ReservationRating;
}
const ReservationFeedbackRating = ({
  rating,
}: ReservationFeedbackRatingProps) => {
  if (!rating) return null;

  return rating === 'thumbs_up' ? (
    <ThumbUpOffAltIcon color="success" />
  ) : (
    <ThumbDownOffAltIcon color="error" />
  );
};

export default ReservationFeedbackRating;
