import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CreditCardOutlined } from '@mui/icons-material';
import { useAddStripePaymentMethod } from '../../payment-method/useAddStripePaymentMethod';
import StripePaymentMethodElement from '../../payment-method/StripePaymentMethodElement';
import InvoicesIcon from '../../../../icons/InvoicesIcon';
import {
  AccountStepLayout,
  AccountStepLayoutWrapper,
  NextStepButton,
} from './CreateAccountStepper';
import { useAccountStepper } from './useAccountStepper';
import InvoicePaymentSetup from './InvoicePaymentSetup';

const PaymentMethodStep = () => {
  const { t } = useTranslation();
  const {
    nextStep,
    setPaymentMethodId,
    paymentMethodId,
    isInvoiceEnabled,
    isInvoiceFormValid,
  } = useAccountStepper();

  const { handleSubmit, isLoading } = useAddStripePaymentMethod({
    allowSubmit: () => {
      return !(isInvoiceEnabled && !isInvoiceFormValid);
    },
    onSubmit: methodId => {
      setPaymentMethodId(methodId);
      // TODO save invoice payment data
      nextStep();
    },
  });

  return (
    <AccountStepLayoutWrapper component="form" onSubmit={handleSubmit}>
      <AccountStepLayout
        nextStepButton={
          <NextStepButton
            onClick={paymentMethodId ? nextStep : undefined}
            type={paymentMethodId ? 'button' : 'submit'}
            disabled={isLoading}
          />
        }
        content={
          <>
            <Accordion>
              <AccordionSummary>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <CreditCardOutlined />
                  <Typography>
                    {t('b2b.createAccount.paymentMethod.setUpCard')}
                  </Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {paymentMethodId ? (
                  <Alert severity="success">
                    {t('b2b.createAccount.paymentMethod.success')}
                  </Alert>
                ) : (
                  <StripePaymentMethodElement />
                )}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  <InvoicesIcon />
                  {t('b2b.createAccount.paymentMethod.setUpInvoice')}
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <InvoicePaymentSetup />
              </AccordionDetails>
            </Accordion>
          </>
        }
      />
    </AccountStepLayoutWrapper>
  );
};

export default PaymentMethodStep;
