import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import { PrivateCustomerDetail } from '../../../entities/Customer';
import { DetailsCard } from '../../Common/DetailsCard';
import { DetailRow } from '../../Common/DetailRow';
import {
  DATE_ONLY,
  DATE_TIME_WITH_SECONDS,
  formatDateTime,
} from '../../../utils/datetime-utils';
import { CustomerStatus } from '../../../utils/customer-utils';
import { DetailContent } from '../../Common/DetailContent';
import VerificationStatus from '../VerificationStatus';
import { formatBoolean, formatMoney } from '../../../utils';
import { RESERVATIONS } from '../../../utils/routes-constants';
import LinkButton from '../../Common/LinkButton';

type CustomerProps = {
  customer: PrivateCustomerDetail;
};

function PrivateCustomerDetails({ customer }: CustomerProps) {
  return (
    <Box height="100%">
      <GeneralInfoCard customer={customer} />
      <VerificationCard customer={customer} />
      <ReservationsCard customer={customer} />
      <WalletCard customer={customer} />
      <CompaniesCard customer={customer} />
      <AppInfoCard customer={customer} />
    </Box>
  );
}

function GeneralInfoCard({ customer }: CustomerProps) {
  const { t } = useTranslation();
  return (
    <DetailsCard>
      <DetailRow title={t('customers.name')} content={customer.name} />
      <DetailRow
        title={t('customers.phoneNumber')}
        content={`+${customer.areaCode} ${customer.phoneNumber}`}
      />
      <DetailRow title={t('customers.email')} content={customer.email} />
      <DetailRow
        title={t('customers.registrationDate')}
        content={formatDateTime(customer.created, DATE_TIME_WITH_SECONDS)}
      />
      <DetailRow
        title={t('customers.accountStatus')}
        content={
          customer.status?.type === CustomerStatus.BLOCKED ? (
            <DetailContent
              text={t(`customers.customerStatuses.${customer.status?.type}`)}
              style={{ color: '#D4002A' }}
            />
          ) : (
            t(`customers.customerStatuses.${customer.status?.type}`)
          )
        }
      />
      <DetailRow
        title={t('customers.emailSubscription')}
        content={customer.emailMarketing ? t('yes') : t('no')}
        showDivider={false}
      />
    </DetailsCard>
  );
}

function VerificationCard({ customer }: CustomerProps) {
  const { t } = useTranslation();
  return (
    <DetailsCard>
      {customer.verification && (
        <>
          {customer.verification.documents.map((doc, idx) => (
            <DetailRow
              key={doc.number ?? `${doc.type}-${idx}`}
              title={t([
                `customers.verification.document.${doc.type}`,
                'customers.verification.document.unknown',
              ])}
              content={doc.number}
            />
          ))}
          <DetailRow
            title={t('customers.verification.documentId')}
            content={customer.verification.documentId}
          />
          {customer.verification.completed && (
            <DetailRow
              title={t('customers.verification.verificationDate')}
              content={formatDateTime(
                customer.verification.completed,
                DATE_ONLY,
              )}
            />
          )}
        </>
      )}
      <DetailRow
        title={t('customers.verification.status')}
        content={
          customer.verification ? (
            <VerificationStatus status={customer.verification?.status} />
          ) : null
        }
        showDivider={false}
      />
    </DetailsCard>
  );
}

function ReservationsCard({ customer }: CustomerProps) {
  const { t } = useTranslation();
  if (!customer.reservationData) return null;
  const {
    activeReservation,
    nextReservation,
    lastReservation,
    totalReservations,
  } = customer.reservationData;
  return (
    <DetailsCard>
      {activeReservation && (
        <DetailRow
          title={t('customers.reservationsCard.activeReservation')}
          content={
            <LinkButton to={`${RESERVATIONS}/${activeReservation.id}`}>
              {formatDateTime(activeReservation.startDateTime)}
            </LinkButton>
          }
        />
      )}
      {nextReservation && (
        <DetailRow
          title={t('customers.reservationsCard.nextReservation')}
          content={
            <LinkButton to={`${RESERVATIONS}/${nextReservation.id}`}>
              {formatDateTime(nextReservation.startDateTime)}
            </LinkButton>
          }
        />
      )}
      {lastReservation && (
        <DetailRow
          title={t('customers.reservationsCard.lastReservation')}
          content={
            <LinkButton to={`${RESERVATIONS}/${lastReservation.id}`}>
              {formatDateTime(lastReservation.startDateTime)}
            </LinkButton>
          }
        />
      )}
      <DetailRow
        title={t('customers.reservationsCard.totalReservations')}
        content={
          <LinkButton to={`${RESERVATIONS}?customerId=${customer.id}`}>
            {totalReservations}
          </LinkButton>
        }
        showDivider={false}
      />
    </DetailsCard>
  );
}

function WalletCard({ customer }: CustomerProps) {
  const { t } = useTranslation();
  if (!customer.wallet) return null;
  const { savedCards, balance, bonuses, money } = customer.wallet;
  return (
    <DetailsCard>
      <DetailRow
        title={t('customers.walletCard.savedCards')}
        content={formatBoolean(savedCards, t)}
      />
      <DetailRow
        title={t('customers.walletCard.balance')}
        content={formatMoney(balance)}
      />
      <DetailRow
        title={t('customers.walletCard.bonuses')}
        content={formatMoney(bonuses)}
      />
      <DetailRow
        title={t('customers.walletCard.money')}
        content={formatMoney(money)}
        showDivider={false}
      />
    </DetailsCard>
  );
}

function CompaniesCard({ customer }: CustomerProps) {
  const { t } = useTranslation();
  if (!customer.companyData) return null;
  const { companyInvoicingGroups } = customer.companyData;
  return (
    <DetailsCard>
      <DetailRow
        title={t('customers.companiesCard.companyName')}
        content={t('customers.companiesCard.invoicingGroup')}
      />
      {companyInvoicingGroups.map((cig, cigIdx) => (
        <DetailRow
          key={cig.companyId}
          title={cig.companyName}
          showDivider={cigIdx !== companyInvoicingGroups.length - 1}
          content={
            <>
              {cig.invoicingGroups.map((ig, idx) => (
                <Typography key={`cig-${idx}-${ig}`}>{ig}</Typography>
              ))}
            </>
          }
        />
      ))}
    </DetailsCard>
  );
}

function AppInfoCard({ customer }: CustomerProps) {
  const { t } = useTranslation();
  const { locale, phoneOPSystem, appVersion } = customer;
  return (
    <DetailsCard>
      <DetailRow title={t('customers.language')} content={locale} />
      <DetailRow title={t('customers.os')} content={phoneOPSystem} />
      <DetailRow
        title={t('customers.appVersion')}
        content={appVersion}
        showDivider={false}
      />
    </DetailsCard>
  );
}

export default PrivateCustomerDetails;
