import React from 'react';
import { Tooltip } from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

type Props = {
  text: string;
};

function InfoTooltipIcon({ text }: Props) {
  return (
    <Tooltip title={text}>
      <InfoOutlined sx={{ cursor: 'help' }} />
    </Tooltip>
  );
}

export default InfoTooltipIcon;
