import React from 'react';
import { Box, Grid } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../../../../store';
import LoginImage from '../../../../icons/login-image.svg';
import AvisLogo from '../../../../icons/AvisLogo';
import DevMenu from '../../../../components/Common/DevMenu';

interface Props {
  children: JSX.Element;
}
const LoginWrapper = ({ children }: Props) => {
  const isAuthenticated = useAppSelector(
    state => !!state.userReducer.authToken,
  );
  const isOnboarded = useAppSelector(state => state.userReducer.isOnboarded);

  if (isAuthenticated && isOnboarded) {
    return <Navigate to="/b2b/home" />;
  }

  return (
    <Grid container alignItems="center">
      <Grid item xs={12} lg={4} sx={{ height: '100%' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            marginLeft: { md: '48px' },
            marginRight: { md: '48px' },
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <DevMenu>
            <AvisLogo />
          </DevMenu>
          {children}
          <Box sx={{ height: '16px' }}></Box>
        </Box>
      </Grid>
      <Grid item xs={0} lg={8} display="flex" style={{ height: '100%' }}>
        <Box
          sx={{
            display: { xs: 'none', lg: 'block' },
            width: '100%',
            backgroundImage: `url(${LoginImage})`,
            backgroundPosition: '0% 100%',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        />
      </Grid>
    </Grid>
  );
};

export default LoginWrapper;
