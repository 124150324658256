import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';
import moment from 'moment';
import { PageHeader } from '../../../components/Common/PageHeader';
import ExportIcon from '../../../icons/ExportIcon';
import { SecondaryButton } from '../../../components/Common/SecondaryButton';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import { downloadBlob } from '../../../utils';
import { b2bReservationsApi } from './api';
import ReservationsTable from './ReservationsTable';

const ReservationsPage = () => {
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { apiErrorSnack } = useSnacks();

  function handleSelectionChange(selected: GridRowSelectionModel) {
    setSelectedIds(selected as string[]);
  }

  async function handleExport(type: 'csv' | 'pdf') {
    try {
      const res = await b2bReservationsApi.export({
        type,
        ids: selectedIds,
      });
      // Filename might have to come from server eventually
      downloadBlob(
        res.data,
        `avisnow-b2b-reservations_${moment().format(
          'yyyy-MM-DD_HH-mm',
        )}.${type}`,
      );
    } catch (e) {
      apiErrorSnack(e);
    }
  }

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <PageHeader text={t('b2b.drawer.reservations')}>
        <Box display="flex" gap={2}>
          <SecondaryButton
            startIcon={<ExportIcon />}
            onClick={() => handleExport('csv')}
          >
            {t('b2b.reservations.buttons.exportToCsv')}
          </SecondaryButton>
          <SecondaryButton
            startIcon={<ExportIcon />}
            onClick={() => handleExport('pdf')}
          >
            {t('b2b.reservations.buttons.exportToPdf')}
          </SecondaryButton>
        </Box>
      </PageHeader>
      <ReservationsTable onSelectionChange={handleSelectionChange} />
    </Box>
  );
};

export default ReservationsPage;
