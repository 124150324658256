import React from 'react';

const CompanyIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 16.5003C0 16.0861 0.335786 15.7503 0.75 15.7503H17.25C17.6642 15.7503 18 16.0861 18 16.5003C18 16.9145 17.6642 17.2503 17.25 17.2503H0.75C0.335786 17.2503 0 16.9145 0 16.5003Z"
      fill="#33302F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.835 12.4128C15.2492 12.4128 15.585 12.7486 15.585 13.1628V16.5078C15.585 16.922 15.2492 17.2578 14.835 17.2578C14.4208 17.2578 14.085 16.922 14.085 16.5078V13.1628C14.085 12.7486 14.4208 12.4128 14.835 12.4128Z"
      fill="#33302F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.45 9.81783C12.45 8.48861 13.5208 7.41782 14.85 7.41782C16.1792 7.41782 17.25 8.48861 17.25 9.81783V11.5203C17.25 12.8495 16.1792 13.9203 14.85 13.9203C13.5208 13.9203 12.45 12.8495 12.45 11.5203V9.81783ZM14.85 8.91782C14.3492 8.91782 13.95 9.31704 13.95 9.81783V11.5203C13.95 12.0211 14.3492 12.4203 14.85 12.4203C15.3508 12.4203 15.75 12.0211 15.75 11.5203V9.81783C15.75 9.31704 15.3508 8.91782 14.85 8.91782Z"
      fill="#33302F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.66953 3.35269C2.48361 3.54047 2.32498 3.88376 2.32498 4.52283V16.5003C2.32498 16.9145 1.9892 17.2503 1.57498 17.2503C1.16077 17.2503 0.824982 16.9145 0.824982 16.5003V4.52283C0.824982 3.65439 1.04138 2.86518 1.6036 2.29732C2.16717 1.72811 2.9528 1.50781 3.81752 1.50781H8.49001C9.35473 1.50781 10.1395 1.72815 10.7015 2.29867C11.2615 2.86712 11.475 3.65628 11.475 4.52283V16.5003C11.475 16.9145 11.1392 17.2503 10.725 17.2503C10.3108 17.2503 9.97499 16.9145 9.97499 16.5003V4.52283C9.97499 3.88187 9.81728 3.53853 9.63289 3.35134C9.45054 3.16623 9.11778 3.00781 8.49001 3.00781H3.81752C3.18974 3.00781 2.8541 3.16627 2.66953 3.35269Z"
      fill="#33302F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.59999 6.18781C3.59999 5.77359 3.93578 5.43781 4.34999 5.43781H8.0625C8.47671 5.43781 8.8125 5.77359 8.8125 6.18781C8.8125 6.60202 8.47671 6.93781 8.0625 6.93781H4.34999C3.93578 6.93781 3.59999 6.60202 3.59999 6.18781Z"
      fill="#33302F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.59999 9.00031C3.59999 8.58609 3.93578 8.25031 4.34999 8.25031H8.0625C8.47671 8.25031 8.8125 8.58609 8.8125 9.00031C8.8125 9.41452 8.47671 9.75031 8.0625 9.75031H4.34999C3.93578 9.75031 3.59999 9.41452 3.59999 9.00031Z"
      fill="#33302F"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.1875 12.9378C6.60171 12.9378 6.9375 13.2736 6.9375 13.6878V16.5003C6.9375 16.9145 6.60171 17.2503 6.1875 17.2503C5.77329 17.2503 5.4375 16.9145 5.4375 16.5003V13.6878C5.4375 13.2736 5.77329 12.9378 6.1875 12.9378Z"
      fill="#33302F"
    />
  </svg>
);

export default CompanyIcon;
