import { AppBar as MuiAppBar, Box, IconButton, Toolbar } from '@mui/material';
import React from 'react';
import NotificationsIcon from '../../icons/NotificationsIcon';
import AvatarIcon from '../../icons/AvatarIcon';
import { useAppDispatch, useAppSelector } from '../../store';

const AppBar = () => {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(
    state => !!state.userReducer.authToken && state.userReducer.isOnboarded,
  );

  const logout = () => {
    dispatch({ type: 'LOG_OUT' });
  };

  if (!isAuthenticated) return null;
  return (
    <MuiAppBar
      position="fixed"
      sx={{
        zIndex: theme => theme.zIndex.drawer - 1,
        backgroundColor: '#FFFFFF',
      }}
      elevation={0}
    >
      <Toolbar variant="dense" sx={{ maxWidth: '100vw' }}>
        <Box sx={{ flexGrow: 1 }} />
        <IconButton>
          <NotificationsIcon />
        </IconButton>
        <IconButton onClick={logout}>
          <AvatarIcon />
        </IconButton>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
