import React from 'react';
import { Box, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

export interface TabMenuItem {
  titleSlug: string;
  path: string;
}

interface Props {
  menuItems: TabMenuItem[];
}
export const TabMenu = ({ menuItems }: Props) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  const itemFromPath = menuItems.find(item => {
    return (
      (item.path && pathname.includes(item.path)) ||
      pathname.split('/')?.pop() === item.path
    );
  });
  const selectedItem = itemFromPath || menuItems[0];

  return (
    <Box sx={{ mt: 1 }}>
      <Tabs value={selectedItem.path}>
        {menuItems.map((item: TabMenuItem) => (
          <Tab
            key={item.path}
            value={item.path}
            component={Link}
            to={item.path}
            label={t(item.titleSlug)}
          ></Tab>
        ))}
      </Tabs>
      <Box
        sx={{
          borderBottom: 2,
          borderColor: 'divider',
          zIndex: -1,
          marginTop: '-2px',
          marginBottom: '18px',
        }}
      ></Box>
    </Box>
  );
};
