const MOCK_API_FLAG = 'useMsw';

function shouldStartMockApi() {
  if (process.env.NODE_ENV !== 'development') return false;
  if (process.env.REACT_APP_DEVTOOLS !== 'true') return false;
  const storageFlag = getMockApiStorageFlag();
  return storageFlag == null
    ? process.env.REACT_APP_MOCK_API === 'true'
    : storageFlag;
}

function getMockApiStorageFlag() {
  const flag = sessionStorage.getItem(MOCK_API_FLAG);
  if (!flag) return null;
  return flag === 'true';
}

function toggleMockApiFlag() {
  sessionStorage.setItem(
    MOCK_API_FLAG,
    getMockApiStorageFlag() ? 'false' : 'true',
  );
}

export { shouldStartMockApi, toggleMockApiFlag };
