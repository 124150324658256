import React from 'react';
import { useAppDispatch } from '../../store';
import { closeModal, openModal } from '../../store/modal/slice';
import { ModalType } from '../../utils/modal-utils';
import ConfirmationModal from './ConfirmationModal';
import { ModalComponent } from './Modal';

export function useModal() {
  const dispatch = useAppDispatch();

  function confirm(props: React.ComponentProps<typeof ConfirmationModal>) {
    dispatch(
      openModal({
        type: ModalType.CONFIRMATION_MODAL,
        props,
      }),
    );
  }

  function open(modal: ModalComponent) {
    dispatch(openModal(modal));
  }

  function close() {
    dispatch(closeModal());
  }

  return { confirm, open, close };
}
