import { useMutation, useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import * as Yup from 'yup';
import currency from 'currency.js';
import {
  AvailableVehicleListItem,
  GetAvailableVehiclesParams,
  GetReservationParams,
  GetVehiclesAvailabilityParams,
} from '../../entities/Reservation';
import { queryAllPages } from '../../utils/request-utils';
import { GetReservationFeedbackParams } from '../../entities/ReservationFeedback';
import api, { PaginatedRequestParams } from '../../store/api';
import { summarizePayments } from '../../utils';
import { centsNumber, dateTimeString } from '../../entities';
import { queryClient } from '../../App';

export interface CreateMaintenanceReservationBody {
  vehicleId: string;
  startDateTime: dateTimeString;
  endDateTime: dateTimeString;
}

export interface UpdateMaintenanceReservationBody {
  startDateTime?: dateTimeString;
  endDateTime: dateTimeString;
}

export interface UpdateReservationBody {
  vehicleId: string;
  pickupParkingZoneId: string;
  returnParkingZoneId: string;
}

export interface CollectDebtBody {
  amount: number;
}

export interface CreateReservationInvoiceRequest {
  rows: {
    title: string;
    amount: number;
  }[];
}

export function useCreateReservationInvoiceSchema() {
  return useMemo(
    () =>
      Yup.object({
        rows: Yup.array()
          .of(
            Yup.object({
              title: Yup.string().required(),
              amount: Yup.number()
                .required()
                .transform(val => currency(val).intValue),
            }),
          )
          .min(1)
          .required(),
      }),
    [],
  );
}

const RESERVATIONS_KEY = 'reservations';
const FEEDBACK_KEY = 'reservation-feedback';
const VEHICLE_AVAILABILITY_KEY = 'vehicle-availability';
const AVAILABLE_VEHICLES_KEY = 'available-vehicles';
const ACTIONS_KEY = 'reservation-actions';

export function useGetReservations(params: GetReservationParams) {
  return useQuery({
    queryKey: [RESERVATIONS_KEY, params],
    queryFn: async () => (await api.getReservations(params)).data,
    enabled: !!params.page || !!params.limit,
    refetchInterval: 30_000,
  });
}

export function useGetReservationFeedback(
  params: GetReservationFeedbackParams,
) {
  return useQuery({
    queryKey: [FEEDBACK_KEY, params],
    queryFn: async () => (await api.getReservationFeedback(params)).data,
    enabled: !!params.page || !!params.limit,
  });
}

export function useGetReservation(id?: string) {
  return useQuery({
    queryKey: [RESERVATIONS_KEY, id],
    queryFn: async () => (await api.getReservation(id || '')).data,
    select: details => ({
      details,
      paymentSummary: details.payment ? summarizePayments(details.payment) : {},
    }),
    enabled: !!id,
    refetchInterval: query => {
      return query.state.data?.status.type === 'active' ? 5000 : undefined;
    },
  });
}

export function useUpdateReservation() {
  return useMutation({
    mutationFn: ({ id, body }: { id: string; body: UpdateReservationBody }) =>
      api.updateReservation(id, body),
    onSuccess: (_data, id) => {
      queryClient.invalidateQueries({ queryKey: [RESERVATIONS_KEY, id] });
    },
  });
}

export function useCreateMaintenanceReservation() {
  return useMutation({
    mutationFn: (body: CreateMaintenanceReservationBody) =>
      api.createMaintenanceReservation(body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [RESERVATIONS_KEY] });
    },
  });
}

export function useUpdateMaintenanceReservation() {
  return useMutation({
    mutationFn: ({
      id,
      body,
    }: {
      id: string;
      body: UpdateMaintenanceReservationBody;
    }) => api.updateMaintenanceReservation(id, body),
    onSuccess: (_data, id) => {
      queryClient.invalidateQueries({ queryKey: [RESERVATIONS_KEY, id] });
    },
  });
}

export function useCancelMaintenanceReservation() {
  return useMutation({
    mutationFn: (id: string) => api.cancelMaintenanceReservation(id),
    onSuccess: (_data, id) => {
      queryClient.invalidateQueries({ queryKey: [RESERVATIONS_KEY, id] });
    },
  });
}

export function useStartReservation() {
  return useMutation({
    mutationFn: (id: string) => api.startReservation(id),
    onSuccess: (_data, id) => {
      queryClient.invalidateQueries({ queryKey: [RESERVATIONS_KEY, id] });
    },
  });
}

export function useEndReservation() {
  return useMutation({
    mutationFn: (id: string) => api.endReservation(id),
    onSuccess: (_data, id) => {
      queryClient.invalidateQueries({ queryKey: [RESERVATIONS_KEY, id] });
    },
  });
}

export function useCollectReservationDebt() {
  return useMutation({
    mutationFn: ({
      reservationId,
      amount,
    }: {
      reservationId: string;
      amount: centsNumber;
    }) => api.collectReservationDebt(reservationId, { amount }),
    onSuccess: (_data, id) => {
      queryClient.invalidateQueries({ queryKey: [RESERVATIONS_KEY, id] });
    },
  });
}

export function useGetVehiclesAvailability(
  params: GetVehiclesAvailabilityParams,
) {
  return useQuery({
    queryKey: [VEHICLE_AVAILABILITY_KEY, params],
    queryFn: async () => (await api.getVehiclesAvailability(params)).data,
    enabled: !!params.registrationNumber,
  });
}

export function useGetAvailableVehicles<TData = AvailableVehicleListItem[]>({
  params,
  select,
}: {
  params?: GetAvailableVehiclesParams;
  select?: (data: AvailableVehicleListItem[]) => TData;
}) {
  return useQuery({
    queryKey: [AVAILABLE_VEHICLES_KEY, params],
    queryFn: async () => await queryAllPages(api.getAvailableVehicles, params),
    select,
  });
}

export function useGetReservationActions(
  id: string | undefined,
  params: PaginatedRequestParams,
) {
  return useQuery({
    queryKey: [ACTIONS_KEY, id],
    queryFn: async () =>
      (await api.getReservationActions(id || '', params)).data,
    enabled: !!id,
  });
}

export function useGetReservationTrajectory(
  id: string | undefined,
  refetchInterval?: number,
) {
  return useQuery({
    queryKey: ['reservation-trajectory', id],
    queryFn: async () => (await api.getReservationTrajectory(id || '')).data,
    enabled: !!id,
    refetchInterval,
  });
}

export function useGetReservationPhotos(id: string | undefined) {
  return useQuery({
    queryKey: ['reservation-photos', id],
    queryFn: async () => (await api.getReservationPhotos(id || '')).data,
    enabled: !!id,
  });
}

export function useGetReservationInvoices(id: string | undefined) {
  return useQuery({
    queryKey: ['reservation-invoices', id],
    queryFn: async () => (await api.getReservationInvoiceLinks(id || '')).data,
    retry: false,
    enabled: !!id,
  });
}

export function useCreateReservationInvoice() {
  return useMutation({
    mutationFn: ({
      reservationId,
      body,
    }: {
      reservationId: string;
      body: CreateReservationInvoiceRequest;
    }) => api.createReservationInvoice(reservationId, body),
    onSuccess: (_data, id) => {
      queryClient.invalidateQueries({ queryKey: [RESERVATIONS_KEY, id] });
    },
  });
}
