import * as Yup from 'yup';

export const EE_AREA_CODE = '+372';
export const LV_AREA_CODE = '+371';
export const LT_AREA_CODE = '+370';

const phoneNumberFormatValidator = (value: number) =>
  /^\+?[1-9][0-9]{7,14}$/.test(`${value}`);

export const loginValidationSchema = Yup.object({
  areaCode: Yup.string().required(),
  phoneNumber: Yup.number().required().test(phoneNumberFormatValidator),
  country: Yup.string().required(),
});
