import * as Yup from 'yup';
import baseApi from '../../services/apiService';
import { ParkingZone, PriceClass, Vehicle } from '../../entities/Vehicle';
import {
  CreateUpdateParkingZoneParams,
  CreateUpdatePriceClassBody,
} from '../vehicle/queries';
import { AuthResponse, SignInWithPasswordParams } from '../user/thunk';
import { vehiclesApi } from './vehicles';
import { customersApi } from './customer';
import { reservationsApi } from './reservations';
import { b2bApi } from './b2b';
import { reservationFeedbackApi } from './reservationFeedback';
import { bonusCodesApi } from './bonus-codes';

export interface PaginatedItems<R> {
  items: R[];
  pagination: {
    currentPage: number;
    pageLimit: number;
    totalItems: number;
    totalPages: number;
  };
}

export interface PaginatedRequestParams {
  page?: number;
  limit?: number;
}

export const paginatedRequestParamsSchema = Yup.object({
  page: Yup.number(),
  limit: Yup.number(),
});

export function paramsToSearch<T>(obj: T): Record<string, string | string[]> {
  const result: Record<string, string | string[]> = {};

  for (const key in obj) {
    const value = obj[key];

    if (Array.isArray(value)) {
      result[key] = value;
    } else if (value) {
      result[key] = String(value);
    }
  }

  return result;
}

const api = {
  ...customersApi,
  ...vehiclesApi,
  ...reservationsApi,
  ...reservationFeedbackApi,
  ...bonusCodesApi,
  ...b2bApi, // Could be conditionally imported so B2B and BO wouldn't share code
  signIn: (payload: SignInWithPasswordParams) =>
    baseApi.post<AuthResponse>('/auth/token', payload),
  getPriceClasses: (params?: PaginatedRequestParams) =>
    baseApi.get<PaginatedItems<PriceClass>>('/api/price-classes', { params }),
  getPriceClass: (priceClassId: string) =>
    baseApi.get<PriceClass>(`/api/price-classes/${priceClassId}`),
  createPriceClass: (body: CreateUpdatePriceClassBody) =>
    baseApi.post<PriceClass>('/api/price-classes', body),
  updatePriceClass: (priceClassId: string, body: CreateUpdatePriceClassBody) =>
    baseApi.put<PriceClass>(`/api/price-classes/${priceClassId}`, body),
  deletePriceClass: (priceClassId: string) =>
    baseApi.delete<PriceClass>(`/api/price-classes/${priceClassId}`),
  getParkingZones: (params?: PaginatedRequestParams) =>
    baseApi.get<PaginatedItems<ParkingZone>>('/api/parking-zones', { params }),
  getParkingZone: (zoneId: string) =>
    baseApi.get<ParkingZone>(`/api/parking-zones/${zoneId}`),
  updateParkingZone: (zoneId: string, params: CreateUpdateParkingZoneParams) =>
    baseApi.put(`/api/parking-zones/${zoneId}`, params),
  createParkingZone: (params: CreateUpdateParkingZoneParams) =>
    baseApi.post<Vehicle>(`/api/parking-zones`, params),
  deleteParkingZone: (zoneId: string) =>
    baseApi.post<Vehicle>(`/api/parking-zones/${zoneId}/remove`),
};
export function fieldOf<T extends object>(fieldName: keyof T): string {
  return fieldName as string;
}

export function enumValues<T extends Record<string, U>, U extends string>(
  e: T,
): U[] {
  return Object.keys(e).map(key => e[key as keyof T]);
}

function nullAsEmptyString(curr: string, orig: string): string {
  return curr == null ? '' : orig;
}

export function nullableDefaultEmptyString() {
  return Yup.string().nullable().transform(nullAsEmptyString);
}

export default api;
