import React, { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid } from '@mui/material';
import { Form, useFormikContext } from 'formik';
import moment from 'moment';
import InputField from '../../Common/FormikFields/InputField';
import SelectField from '../../Common/FormikFields/SelectField';
import DatePickerField from '../../Common/FormikFields/DatePickerField';
import { enumValues } from '../../../store/api';
import {
  CompensationType,
  Fields,
  UpdateRelatedCustomerRequest,
} from '../../../store/api/related-customers';
import { CostGroup } from '../../../entities/Customer';
import FormikCurrencyField from '../../Common/FormikFields/FormikCurrencyField';
import FormErrorsMessage from '../../Common/FormikFields/FormErrorsMessage';
import { EUR } from '../../../utils';
import FormikNumberField from '../../Common/FormikFields/FormikNumberField';

type Props = {
  costGroups?: CostGroup[];
  customerIdentifierField?: ReactNode;
};

function RelatedCustomerForm({ costGroups, customerIdentifierField }: Props) {
  const { t } = useTranslation();
  const { values } = useFormikContext<UpdateRelatedCustomerRequest>();

  const selectedCostGroup = useMemo(() => {
    return costGroups?.find(cg => cg.id === values.costGroupId);
  }, [values]);

  const maxSum = useMemo(() => {
    if (!selectedCostGroup || !values.limitDays) return '';
    return EUR(selectedCostGroup.dailyAmount)
      .multiply(values.limitDays)
      .format();
  }, [values, selectedCostGroup]);

  const fieldLabels = useFieldLabels();

  return (
    <Form>
      <Grid
        item
        container
        direction="column"
        rowGap={2}
        marginBottom={4}
        md={6}
      >
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            {customerIdentifierField}
          </Grid>
          <Grid item xs={6}>
            <SelectField
              name={Fields.compensationType}
              items={enumValues(CompensationType).map(it => ({
                value: it,
                label: t(`relatedCustomers.form.compensationType.${it}`),
              }))}
              label={fieldLabels['compensationType']}
              placeholder={t(
                'relatedCustomers.form.placeholders.compensationType',
              )}
            />
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            {costGroups && (
              <SelectField
                name={Fields.costGroupId}
                items={costGroups.map(it => ({
                  value: it.id,
                  label: it.name,
                }))}
                label={fieldLabels['costGroupId']}
                placeholder={t(
                  'relatedCustomers.form.placeholders.invoicingGroup',
                )}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            <FormikCurrencyField
              disabled
              name="limitPerDay"
              label={t('relatedCustomers.limitPerDay')}
              value={selectedCostGroup?.dailyAmount ?? ''}
            />
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <FormikNumberField
              decimalScale={0}
              name={Fields.limitDays}
              label={fieldLabels['limitDays']}
            />
          </Grid>
          <Grid item xs={6}>
            <FormikCurrencyField
              disabled
              name="maxSum"
              value={maxSum ?? ''}
              label={t('relatedCustomers.maxSum')}
            />
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <DatePickerField
              name={Fields.limitFromDateTime}
              label={fieldLabels['limitFromDateTime']}
              labelTooltip={t('relatedCustomers.form.tooltip.limitValidPeriod')}
              disablePast
              maxDate={
                values.limitToDateTime
                  ? moment(values.limitToDateTime)
                  : undefined
              }
              placeholder={t(
                'relatedCustomers.form.placeholders.limitValidFrom',
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <DatePickerField
              name={Fields.limitToDateTime}
              label={fieldLabels['limitToDateTime']}
              labelTooltip={t('relatedCustomers.form.tooltip.limitValidPeriod')}
              disablePast
              minDate={
                values.limitFromDateTime
                  ? moment(values.limitFromDateTime)
                  : undefined
              }
              placeholder={t(
                'relatedCustomers.form.placeholders.limitValidUntil',
              )}
            />
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <InputField
              name={Fields.invoicingComment}
              label={fieldLabels['invoicingComment']}
              placeholder={t(
                'relatedCustomers.form.placeholders.compensationComment',
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <Button
          type="submit"
          variant="contained"
          size="medium"
          color="secondary"
        >
          {t('save')}
        </Button>
        <FormErrorsMessage />
      </Box>
    </Form>
  );
}

export function useFieldLabels(): Record<keyof typeof Fields, string> {
  const { t } = useTranslation();
  return useMemo(
    () => ({
      compensationType: t('relatedCustomers.compensationType'),
      costGroupId: t('relatedCustomers.invoicingGroup'),
      limitDays: t('relatedCustomers.limitDays'),
      limitFromDateTime: t('relatedCustomers.limitValidFrom'),
      limitToDateTime: t('relatedCustomers.limitValidUntil'),
      invoicingComment: t('relatedCustomers.invoicingComment'),
      fullPhoneNumber: t('relatedCustomers.phoneNumber'),
      privateCustomerId: t('relatedCustomers.privateCustomer'),
    }),
    [t],
  );
}

export default RelatedCustomerForm;
