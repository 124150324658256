import React, { ReactNode } from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SecondaryButton } from '../Common/SecondaryButton';
import { useAppDispatch } from '../../store';
import { closeModal } from '../../store/modal/slice';

interface Props {
  children?: ReactNode | ReactNode[];
}

const ModalFooter = ({ children }: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Box
      sx={{ px: 3, pb: 6, display: 'flex', justifyContent: 'center', gap: 3 }}
    >
      <SecondaryButton onClick={handleClose} sx={{ px: 3 }}>
        {t('cancel')}
      </SecondaryButton>
      {children}
    </Box>
  );
};

export default ModalFooter;
