import React from 'react';

const DrawerItemActiveIcon = () => (
  <svg
    width="4"
    height="46"
    viewBox="0 0 4 46"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.0765 17.459C4.30783 20.5192 4.30783 25.4808 3.0765 28.541L0.231326 35.612C-0.55708 37.5714 -1 40.229 -1 43L-0.999999 3C-1 5.77102 -0.557077 8.42857 0.231328 10.388L3.0765 17.459Z"
      fill="#D4002A"
    />
  </svg>
);

export default DrawerItemActiveIcon;
