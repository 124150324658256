import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormLabel,
  Grid,
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Add, Close } from '@mui/icons-material';
import { Field, FieldProps, Form, Formik } from 'formik';
import { TabHeader } from '../../Common/TabHeader';
import { SecondaryButton } from '../../Common/SecondaryButton';
import EditIcon from '../../../icons/EditIcon';
import LoadingIcon from '../../Common/LoadingIcon';
import {
  convertEuroToCents,
  PriceType,
} from '../../../utils/price-class-utils';
import {
  CreateUpdatePriceClassBody,
  useCreatePriceClass,
  useGetPriceClass,
  useUpdatePriceClass,
} from '../../../store/vehicle/queries';
import { PRICE_CLASSES } from '../../../utils/routes-constants';
import {
  priceClassInitialState,
  PriceClassState,
  priceClassValidationSchema,
} from './addEditPriceClassData';

interface FormFieldProps {
  name: string;
  showStartAdornment?: boolean;
}

const FormField = ({ name, showStartAdornment }: FormFieldProps) => {
  const { t } = useTranslation();

  // eslint-disable-next-line @docusaurus/no-untranslated-text
  const startAdornment = <InputAdornment position="start">€</InputAdornment>;

  return (
    <>
      <FormLabel>{t(`settings.priceClasses.fields.${name}`)}</FormLabel>
      <Field name={name}>
        {({ field, form: { errors, touched } }: FieldProps) => (
          <TextField
            {...field}
            variant="outlined"
            size="small"
            placeholder={t(`settings.priceClasses.form.placeholders.${name}`)}
            InputProps={{
              startAdornment: showStartAdornment ? startAdornment : undefined,
            }}
            error={!!errors[field.name] && !!touched[field.name]}
            fullWidth
          />
        )}
      </Field>
    </>
  );
};

const AddEditPriceClass = () => {
  const { t } = useTranslation();
  const { priceClassId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const [priceClassFormState, setPriceClassFormState] =
    useState<PriceClassState>(priceClassInitialState);

  const { data: priceClass, isLoading: isLoading } =
    useGetPriceClass(priceClassId);

  const createPriceClass = useCreatePriceClass();
  const updatePriceClass = useUpdatePriceClass();

  useEffect(() => {
    if (priceClass) {
      const { name, prices } = priceClass;

      const newState: PriceClassState = { ...priceClassInitialState, name };

      prices.forEach(({ type, amount }) => {
        newState[type] = amount;
      });

      setPriceClassFormState(newState);
    }
  }, [priceClass]);

  const onSubmit = (values: PriceClassState) => {
    const {
      name,
      minuteRegular,
      hourRegular,
      dayRegular,
      weekRegular,
      kmRegular,
    } = values;

    const body: CreateUpdatePriceClassBody = {
      name,
      prices: [
        {
          type: PriceType.MINUTE_REGULAR,
          amount: convertEuroToCents(minuteRegular),
        },
        {
          type: PriceType.HOUR_REGULAR,
          amount: convertEuroToCents(hourRegular),
        },
        {
          type: PriceType.DAY_REGULAR,
          amount: convertEuroToCents(dayRegular),
        },
        {
          type: PriceType.WEEK_REGULAR,
          amount: convertEuroToCents(weekRegular),
        },
        { type: PriceType.KM_REGULAR, amount: convertEuroToCents(kmRegular) },
      ],
    };

    priceClassId
      ? updatePriceClass.mutate(
          { priceClassId, body },
          {
            onSuccess: () => navigate(PRICE_CLASSES),
          },
        )
      : createPriceClass.mutate(body, {
          onSuccess: () => navigate(PRICE_CLASSES),
        });
  };

  if (priceClassId && isLoading) {
    return <LoadingIcon />;
  }

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <TabHeader
        text={t(
          priceClassId
            ? 'settings.priceClasses.form.titles.editPriceClass'
            : 'settings.priceClasses.form.titles.addNewPriceClass',
        )}
      >
        <SecondaryButton startIcon={<Close />} onClick={() => navigate(-1)}>
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <Formik
        initialValues={priceClassFormState}
        validationSchema={priceClassValidationSchema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Grid
            container
            direction="column"
            sx={{ maxWidth: isDownMd ? '100%' : '50%' }}
            rowGap={2}
          >
            <Grid item>
              <FormField name="name" />
            </Grid>
            <Grid item container columnSpacing={2}>
              <Grid item xs={6}>
                <FormField name="minuteRegular" showStartAdornment />
              </Grid>
              <Grid item xs={6}>
                <FormField name="hourRegular" showStartAdornment />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={2}>
              <Grid item xs={6}>
                <FormField name="dayRegular" showStartAdornment />
              </Grid>
              <Grid item xs={6}>
                <FormField name="weekRegular" showStartAdornment />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={2}>
              <Grid item xs={6}>
                <FormField name="kmRegular" showStartAdornment />
              </Grid>
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            size="medium"
            color="secondary"
            sx={{ mt: 4 }}
            startIcon={priceClassId ? <EditIcon fill="white" /> : <Add />}
          >
            {t(
              priceClassId
                ? 'settings.priceClasses.buttons.editPriceClass'
                : 'settings.priceClasses.buttons.addPriceClass',
            )}
          </Button>
        </Form>
      </Formik>
    </Box>
  );
};

export default AddEditPriceClass;
