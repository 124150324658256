import React from 'react';
import { Box, Button, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ModalHeader from '../../../components/Modal/ModalHeader';
import ModalFooter from '../../../components/Modal/ModalFooter';
import { useModal } from '../../../components/Modal/hooks';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import StripePaymentMethodElement from './StripePaymentMethodElement';
import { useAddStripePaymentMethod } from './useAddStripePaymentMethod';

function AddPaymentMethodModal() {
  const { t } = useTranslation();
  const { close } = useModal();
  const snacks = useSnacks();
  const { handleSubmit, isLoading } = useAddStripePaymentMethod({
    onSubmit,
  });

  function onSubmit() {
    snacks.successSnack(t('b2b.wallet.paymentMethods.snacks.added'));
    close();
  }

  return (
    <Paper sx={{ width: '100%', maxWidth: 568 }}>
      <form>
        <ModalHeader title={t('b2b.wallet.paymentMethods.addModalTitle')} />
        <Box sx={{ padding: 4, textAlign: 'center' }}>
          <StripePaymentMethodElement />
        </Box>
        <ModalFooter>
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleSubmit}
            disabled={isLoading}
            sx={{ pr: 3, pl: 3 }}
          >
            {t('modal.confirm')}
          </Button>
        </ModalFooter>
      </form>
    </Paper>
  );
}

export default AddPaymentMethodModal;
