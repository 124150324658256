import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import moment from 'moment';
import baseApi from '../../services/apiService';
import { dateTimeString, Entity, moneyString } from '../../entities';
import { useFullPhoneNumberValidator } from '../../utils/yup';
import { DATE_TIME_UTC_FORMAT } from '../../utils/datetime-utils';
import {
  enumValues,
  fieldOf,
  PaginatedItems,
  PaginatedRequestParams,
} from './index';

export const apiPaths = {
  relatedCustomers: '/api/related-customers',
  relatedCustomer: (id: string) => `/api/related-customers/${id}`,
  removeRelatedCustomer: (id: string) => `/api/related-customers/${id}/remove`,
  myRelatedCustomers: '/api/related-customers/mine',
};

export const myUsersApi = {
  getRelatedCustomer: (id: string) =>
    baseApi.get<RelatedCustomerDetails>(apiPaths.relatedCustomer(id)),
  getRelatedCustomers: (params: GetRelatedCustomersParams) =>
    baseApi.get<PaginatedItems<RelatedCustomerListItem>>(
      apiPaths.relatedCustomers,
      {
        params,
      },
    ),
  addRelatedCustomer: (body: CreateRelatedCustomerRequest) =>
    baseApi.post(apiPaths.relatedCustomers, body),
  addMyRelatedCustomer: (body: CreateMyRelatedCustomerRequest) =>
    baseApi.post(apiPaths.myRelatedCustomers, body),
  updateRelatedCustomer: (id: string, body: UpdateRelatedCustomerRequest) =>
    baseApi.put(apiPaths.relatedCustomer(id), body),
  removeRelatedCustomer: (id: string) =>
    baseApi.post(apiPaths.removeRelatedCustomer(id)),
};

export interface GetRelatedCustomersParams extends PaginatedRequestParams {
  costGroupId?: string;
  businessCustomerId?: string;
}

export interface RelatedCustomerDetails extends Entity {
  privateCustomer: RelatedPrivateCustomer;
  businessCustomer: {
    id: string;
    companyName: string;
    registrationNumber: string;
  };
  totalReservations: number;
  costGroup: RelatedCustomerCostGroup;
  compensationType: CompensationType;
  limitDays: number;
  limitMaxAmount: moneyString;
  limitFromDateTime?: dateTimeString;
  limitToDateTime?: dateTimeString;
  invoicingComment?: string;
  acceptance: RelatedAcceptance;
}

export interface RelatedCustomerListItem extends Entity {
  privateCustomer: RelatedPrivateCustomer;
  totalReservations: number;
  costGroup: RelatedCustomerCostGroup;
  compensationType: CompensationType;
  limitDays: number;
  limitFromDateTime?: dateTimeString;
  limitToDateTime?: dateTimeString;
  invoicingComment?: string;
  acceptance?: RelatedAcceptance;
}

export interface RelatedAcceptance {
  status: 'confirmed' | 'unconfirmed';
}

interface RelatedCustomerCostGroup {
  id: string;
  name: string;
  dailyAmount: moneyString;
}

interface RelatedPrivateCustomer {
  id?: string;
  name?: string;
  email?: string;
  areaCode: string;
  phoneNumber: string;
}

export enum CompensationType {
  duration = 'duration',
  distance = 'distance',
  durationDistance = 'duration_distance',
}

export const Fields = {
  costGroupId: fieldOf<UpdateRelatedCustomerRequest>('costGroupId'),
  compensationType: fieldOf<UpdateRelatedCustomerRequest>('compensationType'),
  limitDays: fieldOf<UpdateRelatedCustomerRequest>('limitDays'),
  limitFromDateTime: fieldOf<UpdateRelatedCustomerRequest>('limitFromDateTime'),
  limitToDateTime: fieldOf<UpdateRelatedCustomerRequest>('limitToDateTime'),
  invoicingComment: fieldOf<UpdateRelatedCustomerRequest>('invoicingComment'),

  fullPhoneNumber: fieldOf<CreateMyRelatedCustomerRequest>('fullPhoneNumber'),
  privateCustomerId: fieldOf<CreateRelatedCustomerRequest>('privateCustomerId'),
} as const;

export function useRelatedCustomerSchemas() {
  const { t } = useTranslation();
  const updateRelatedCustomerSchema = useMemo(
    () =>
      Yup.object({
        costGroupId: Yup.string().required(),
        compensationType: Yup.string()
          .oneOf(enumValues(CompensationType))
          .required(),
        limitDays: Yup.number().optional(),
        limitFromDateTime: Yup.string()
          .optional()
          .transform((value, originalValue) => {
            const momentVal = moment(originalValue);
            if (momentVal.isSame(moment(), 'day')) {
              return moment().add(1, 'minutes').format(DATE_TIME_UTC_FORMAT);
            }
            return value;
          }),
        limitToDateTime: Yup.string().optional(),
        invoicingComment: Yup.string().optional(),
      }),
    [t],
  );

  const fullPhoneValidator = useFullPhoneNumberValidator();
  const createMyUserSchema = useMemo(
    () =>
      updateRelatedCustomerSchema.shape({
        fullPhoneNumber: fullPhoneValidator.required(),
      }),
    [t, updateRelatedCustomerSchema],
  );

  const createRelatedCustomerSchema = useMemo(
    () =>
      updateRelatedCustomerSchema.shape({
        privateCustomerId: Yup.string().required(),
        businessCustomerId: Yup.string().required(),
      }),
    [t, updateRelatedCustomerSchema],
  );

  return {
    updateRelatedCustomerSchema,
    createRelatedCustomerSchema,
    createMyUserSchema,
  };
}

export interface UpdateRelatedCustomerRequest {
  costGroupId: string;
  compensationType: string;
  limitDays?: number;
  limitFromDateTime?: string | null;
  limitToDateTime?: string | null;
  invoicingComment?: string;
}

export interface CreateRelatedCustomerRequest
  extends UpdateRelatedCustomerRequest {
  privateCustomerId: string;
  businessCustomerId: string;
}

export interface CreateMyRelatedCustomerRequest
  extends UpdateRelatedCustomerRequest {
  fullPhoneNumber: string;
}
