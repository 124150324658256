import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  Typography,
} from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { useTranslation } from 'react-i18next';

import B2BRoutes from './components/Routes/B2BRoutes';
import AvisRoutes from './components/Routes/Routes';
import { useAppSelector } from './store';

interface Props {
  isBackoffice?: boolean;
}

const Layout = ({ isBackoffice = false }: Props) => {
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(
    state => !!state.userReducer.authToken && state.userReducer.isOnboarded,
  );
  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'auto',
      }}
    >
      {isAuthenticated && <div style={{ height: 56 }} />}

      <Box
        sx={{
          ml: !isAuthenticated ? 0 : 4,
          mr: !isAuthenticated ? 0 : 4,
          mt: !isAuthenticated ? 0 : 2,
          mb: !isAuthenticated ? 0 : 4,
          flexGrow: 1,
          display: 'flex',
        }}
      >
        <ErrorBoundary
          fallbackRender={({ error }) => (
            <Backdrop open sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
              <Alert
                severity="error"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    onClick={() => window.location.reload()}
                    sx={{ textTransform: 'uppercase' }}
                  >
                    {t('reloadPage')}
                  </Button>
                }
              >
                <AlertTitle>{t('error.appCrashed')}</AlertTitle>
                {process.env.REACT_APP_DEVTOOLS ? (
                  <Accordion defaultExpanded={false} sx={{ maxWidth: '70vw' }}>
                    <AccordionSummary>{error.message}</AccordionSummary>
                    <AccordionDetails>
                      <Typography sx={{ fontFamily: 'monospace' }}>
                        {error.stack}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  t('error.pleaseReload')
                )}
              </Alert>
            </Backdrop>
          )}
        >
          {isBackoffice ? <AvisRoutes /> : <B2BRoutes />}
        </ErrorBoundary>
      </Box>
    </Box>
  );
};

export default Layout;
