import React from 'react';
import { useTranslation } from 'react-i18next';
import FuelIcon from '../../icons/FuelIcon';
import NoGpsIcon from '../../icons/NoGpsIcon';
import MaintenanceIcon from '../../icons/MaintenanceIcon';
import LowBatteryIcon from '../../icons/LowBatteryIcon';
import StateChip from '../Common/StateChip';

export const VehicleState = {
  low_fuel: {
    title: 'lowFuel',
    icon: <FuelIcon fill="#CA8700" />,
    chipColor: '#F3EAD6',
    textColor: '#CA8700',
  },
  no_gps: {
    title: 'noGps',
    icon: <NoGpsIcon fill="#33302F" />,
    chipColor: 'rgba(51, 48, 47, 0.07)',
    textColor: '#33302F',
  },
  low_battery: {
    title: 'lowBattery',
    icon: <LowBatteryIcon fill="#33302F" />,
    chipColor: 'rgba(51, 48, 47, 0.07)',
    textColor: '#33302F',
  },
  iot_disconnected: {
    title: 'iotDisconnected',
    icon: <MaintenanceIcon fill="white" />,
    chipColor: '#D4002A',
    textColor: 'white',
  },
  iot_never_synced: {
    title: 'iotNeverSynced',
    icon: <MaintenanceIcon fill="white" />,
    chipColor: '#D4002A',
    textColor: 'white',
  },
};

const defaultState = (value: string) => {
  return {
    title: value,
    icon: <MaintenanceIcon fill="#33302F" />, // TODO Icon not available,
    chipColor: 'rgba(51, 48, 47, 0.07)',
    textColor: '#33302F',
  };
};

export const VehicleStateChip: React.FC<{
  value: string;
  extended?: boolean;
}> = ({ value, extended }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const state = VehicleState[value] || defaultState(value);

  return (
    <StateChip
      extended={extended}
      icon={state.icon}
      label={t(`vehicles.states.${state.title}`)}
      backgroundColor={state.chipColor}
      textColor={state.textColor}
    />
  );
};
