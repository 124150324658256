import React, { useMemo } from 'react';
import { Alert, Box, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomerType } from '../../../entities/Customer';
import { useGetCustomerPaymentMethods } from '../../../store/customer/queries';
import PaymentMethodDetails from '../../Common/PaymentMethodDetails';
import LoadingIcon from '../../Common/LoadingIcon';

interface Props {
  customerId: string;
  customerType: CustomerType;
}

const PaymentCardList = ({ customerId, customerType }: Props) => {
  const { t } = useTranslation();

  const params = useMemo(
    () => ({
      customerId,
      customerType,
    }),
    [customerId, customerType],
  );

  const { data: paymentMethods, isLoading } =
    useGetCustomerPaymentMethods(params);

  return (
    <Box sx={{ maxWidth: 670, mb: 4 }}>
      <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
        {t('customers.payments')}
      </Typography>
      {paymentMethods && paymentMethods.length > 0 ? (
        <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
          {paymentMethods.map(method => (
            <Grid key={method.id} container columnSpacing={3} item>
              <Grid item xs={6}>
                <PaymentMethodDetails paymentMethod={method} />
              </Grid>
            </Grid>
          ))}
        </Grid>
      ) : isLoading ? (
        <LoadingIcon />
      ) : (
        <Alert severity="info" sx={{ width: '100%' }}>
          {t('noData.title')}
        </Alert>
      )}
    </Box>
  );
};

export default PaymentCardList;
