import React from 'react';
import { Box } from '@mui/material';
import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { MainHeader } from '../../../components/Common/MainHeader';
import { B2B_RESERVATIONS } from '../../../utils/b2b-routes-constants';
import { DetailsCard } from '../../../components/Common/DetailsCard';
import LoadingIcon from '../../../components/Common/LoadingIcon';
import { DetailRow } from '../../../components/Common/DetailRow';
import { DATE_TIME, TIME_WITH_SECONDS } from '../../../utils/datetime-utils';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import LinkButton from '../../../components/Common/LinkButton';
import { useExportMutatation, useGetCompanyReservation } from './api';

const ReservationDetails = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();
  const { apiErrorSnack } = useSnacks();

  const { data: reservation } = useGetCompanyReservation(reservationId || '');
  const downloadInvoices = useExportMutatation();

  function handleDownloadInvoices() {
    downloadInvoices.mutate(
      {
        req: { type: 'pdf', ids: [reservationId] },
        filename: `avisnow-b2b-reservation_${moment().format(
          'yyyy-MM-DD_HH-mm',
        )}.pdf`,
      },
      {
        onError: e => apiErrorSnack(e),
      },
    );
  }

  if (!reservation) {
    return <LoadingIcon />;
  }

  const {
    customer,
    company,
    vehicle: { registrationNumber },
    startDateTime,
    endDateTime,
    rideDetails: { durationSeconds, distanceMeters, totalPrice },
  } = reservation;

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <MainHeader
        text={t('b2b.reservations.reservation')}
        link={B2B_RESERVATIONS}
        linkText={t('b2b.reservations.buttons.allReservations')}
      />
      <Box marginTop={3}>
        <DetailsCard>
          <DetailRow
            title={t(`b2b.reservations.customerId`)}
            content={customer?.id}
          />
          <DetailRow
            title={t(`b2b.reservations.customerName`)}
            content={customer?.name}
          />
          <DetailRow
            title={t(`b2b.reservations.phoneNumber`)}
            content={customer?.phoneNumber}
          />
          <DetailRow
            title={t(`b2b.reservations.email`)}
            content={customer?.email}
          />
          <DetailRow
            title={t(`b2b.reservations.companyName`)}
            content={company?.companyName}
          />
          <DetailRow
            title={t(`b2b.reservations.invoicingGroup`)}
            content="" // TODO
            showDivider={false}
          />
        </DetailsCard>

        <DetailsCard>
          <DetailRow
            title={t(`b2b.reservations.vehicleNumber`)}
            content={registrationNumber}
          />
          <DetailRow
            title={t(`b2b.reservations.startTime`)}
            content={moment.utc(startDateTime).format(DATE_TIME)}
          />
          <DetailRow
            title={t(`b2b.reservations.endTime`)}
            content={moment.utc(endDateTime).format(DATE_TIME)}
          />
          <DetailRow
            title={t(`b2b.reservations.actualReturnDate`)}
            // content={moment.utc(actualReturnDate).format(DATE_TIME)}
            content="" // TODO
          />
          <DetailRow
            title={t(`b2b.reservations.duration`)}
            content={moment
              .utc(durationSeconds * 1000)
              .format(TIME_WITH_SECONDS)}
          />
          <DetailRow
            title={t(`b2b.reservations.distance`)}
            content={`${distanceMeters || 0}km`}
          />
          <DetailRow
            title={t(`b2b.reservations.price`)}
            content={totalPrice ? `${totalPrice} €` : ''}
          />

          <DetailRow
            title={t(`b2b.reservations.paymentStatus`)}
            content="" // TODO
          />
          <DetailRow
            title={t(`b2b.reservations.prepaidAmount`)}
            content="" // TODO
          />
          <DetailRow
            title={t(`b2b.reservations.postpaidAmount`)}
            content="" // TODO
          />
          <DetailRow
            title={t(`b2b.reservations.paidWithMoney`)}
            content="" // TODO
          />
          <DetailRow
            title={t(`b2b.reservations.paidWithBonus`)}
            content="" // TODO
          />
          <DetailRow
            title={t(`b2b.reservations.paidWithCompany`)}
            content="" // TODO
          />
          <DetailRow
            title={t(`b2b.reservations.debt`)}
            content="" // TODO
          />
          <DetailRow
            title={t(`b2b.reservations.invoices`)}
            content={
              <LinkButton
                startIcon={<Download />}
                onClick={handleDownloadInvoices}
              >
                {t('b2b.reservations.buttons.downloadInvoices')}
              </LinkButton>
            }
            showDivider={false}
          />
        </DetailsCard>
      </Box>
    </Box>
  );
};

export default ReservationDetails;
