import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Box, Pagination } from '@mui/material';
import { TabHeader } from '../../Common/TabHeader';
import { GetReservationParams } from '../../../entities/Reservation';
import { useGetReservations } from '../../Reservations/api';
import { DetailRow } from '../../Common/DetailRow';
import { DetailsCard } from '../../Common/DetailsCard';
import {
  BusinessCustomerDetail,
  PrivateCustomerDetail,
} from '../../../entities/Customer';
import { formatDateTime } from '../../../utils/datetime-utils';
import LinkButton from '../../Common/LinkButton';
import { formatMoney } from '../../../utils';

interface Props {
  customer: PrivateCustomerDetail | BusinessCustomerDetail;
  isBusinessCustomer: boolean;
}

const CustomerDetailsReservations = ({
  customer,
  isBusinessCustomer,
}: Props) => {
  const { t } = useTranslation();

  const [reservationParams, setReservationParams] =
    useState<GetReservationParams>({
      customerId: customer.id,
      customerType: isBusinessCustomer ? 'business' : 'private',
      page: 1,
      limit: 5,
    });

  const { data: reservations, isLoading } =
    useGetReservations(reservationParams);

  return (
    <>
      <TabHeader text={t('customers.tabs.reservations')} />
      {!isLoading && (!reservations || reservations.items.length === 0) ? (
        <Alert severity="info">{t('customers.reservation.noData')}</Alert>
      ) : (
        <Box height="100%">
          {reservations?.items.map(reservation => {
            const {
              id,
              startDateTime,
              endDateTime,
              pickupParkingZone,
              vehicle,
              price,
            } = reservation;

            return (
              <DetailsCard key={id}>
                <DetailRow
                  title={t('customers.reservation.startDateTime')}
                  content={
                    <LinkButton to={`/reservations/${reservation.id}`}>
                      {formatDateTime(startDateTime)}
                    </LinkButton>
                  }
                  showDivider={false}
                />
                <DetailRow
                  title={t('customers.reservation.endDateTime')}
                  content={formatDateTime(endDateTime)}
                  showDivider={false}
                />
                <DetailRow
                  title={t('customers.reservation.parkingZone')}
                  content={pickupParkingZone.name}
                  showDivider={false}
                />
                <DetailRow
                  title={t('customers.reservation.registrationNumber')}
                  content={vehicle.registrationNumber}
                  showDivider={false}
                />
                <DetailRow
                  title={t('customers.reservation.price')}
                  content={formatMoney(price)}
                  showDivider={false}
                />
              </DetailsCard>
            );
          })}
          {reservations?.pagination.totalPages &&
            reservations?.pagination.totalPages > 1 && (
              <Pagination
                count={reservations?.pagination.totalPages}
                page={reservations?.pagination.currentPage}
                onChange={(event, page) =>
                  setReservationParams({ ...reservationParams, page: page })
                }
              />
            )}
        </Box>
      )}
    </>
  );
};
export default CustomerDetailsReservations;
