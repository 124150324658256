import React from 'react';
import { Helmet } from 'react-helmet-async';

type Props = {
  title?: string;
};

function AvisHelmet({ title }: Props) {
  return (
    <Helmet>
      {/*eslint-disable-next-line @docusaurus/no-untranslated-text*/}
      <title>
        {title ? `${title} | ` : ''}
        {process.env.REACT_APP_TITLE}
      </title>
    </Helmet>
  );
}

export default AvisHelmet;
