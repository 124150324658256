import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { TabHeader } from '../../Common/TabHeader';
import { PaginatedTable } from '../../Common/PaginatedTable';
import {
  useDeletePriceClass,
  useGetPriceClasses,
} from '../../../store/vehicle/queries';
import { PriceClass } from '../../../entities/Vehicle';
import { PaginatedRequestParams } from '../../../store/api';
import { PriceType } from '../../../utils/price-class-utils';
import { useAppDispatch } from '../../../store';
import { openModal } from '../../../store/modal/slice';
import { ModalType } from '../../../utils/modal-utils';
import PriceClassesTableDropdown from './PriceClassesTableDropdown';

const PriceClasses = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [paginatedRequestParams, setPaginatedRequestParams] =
    useState<PaginatedRequestParams>({});
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [totalItems, setTotalItems] = useState(0);

  const deletePriceClass = useDeletePriceClass();

  const { data: priceClasses, isLoading: isLoading } = useGetPriceClasses({
    params: paginatedRequestParams,
  });

  useEffect(() => {
    if (priceClasses) {
      const {
        items,
        pagination: { totalItems },
      } = priceClasses;

      setRows(
        items.map(({ id, name, prices }: PriceClass) => ({
          id,
          name,
          ...prices.reduce(
            (result: Record<string, string>, { type, amount }) => {
              result[type] = amount;
              return result;
            },
            {},
          ),
        })),
      );
      setTotalItems(totalItems);
    }
  }, [priceClasses]);

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('settings.priceClasses.fields.name'),
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        params.value,
    },
    ...Object.values(PriceType).map((priceType: PriceType) => ({
      field: priceType,
      headerName: t(`settings.priceClasses.fields.${priceType}`),
      flex: 1,
      sortable: false,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        `${params.value} €`,
    })),
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      width: 50,
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <PriceClassesTableDropdown
          onEditPriceClass={() => navigate(`${params.row.id}/edit`)}
          onDeletePriceClass={() =>
            dispatch(
              openModal({
                type: ModalType.DELETE_CONFIRMATION_MODAL,
                props: {
                  title: 'settings.priceClasses.deletePriceClass',
                  action: () => deletePriceClass.mutate(params.row.id),
                },
              }),
            )
          }
        />
      ),
    },
  ];

  return (
    <>
      <TabHeader text={t('settings.tabs.rules')}>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          startIcon={<Add />}
          onClick={() => navigate('new')}
        >
          {t('settings.priceClasses.buttons.addPriceClass')}
        </Button>
      </TabHeader>
      <PaginatedTable
        rows={rows}
        columns={columns}
        totalItems={totalItems}
        isLoading={isLoading}
        fetchRows={setPaginatedRequestParams}
        autoPageSize={false}
        onRowClick={(params: GridRowParams) =>
          navigate(`${params.row.id}/edit`)
        }
      />
    </>
  );
};

export default PriceClasses;
