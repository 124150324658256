import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import {
  Fields,
  UpdateRelatedCustomerRequest,
  useRelatedCustomerSchemas,
} from '../../../store/api/related-customers';
import {
  useGetRelatedCustomer,
  useUpdateRelatedCustomer,
} from '../../../store/related-customers';
import { useGetCostGroups } from '../../../b2b/features/wallet/api';
import InputField from '../../Common/FormikFields/InputField';
import RelatedCustomerForm, { useFieldLabels } from './RelatedCustomerForm';

type Props = {
  onSuccess?: () => void;
};

function RelatedCustomerEditForm({ onSuccess }: Props) {
  const { relatedCustomerId } = useParams();
  const { t } = useTranslation();
  const snacks = useSnacks();

  const { updateRelatedCustomerSchema } = useRelatedCustomerSchemas();

  const { data: customer } = useGetRelatedCustomer(relatedCustomerId || '');
  const { data: costGroups } = useGetCostGroups({
    businessCustomerId: customer?.businessCustomer?.id,
  });

  const updateRelatedCustomer = useUpdateRelatedCustomer();

  const initialValues: UpdateRelatedCustomerRequest = useMemo(() => {
    return {
      costGroupId: customer?.costGroup.id ?? '',
      compensationType: customer?.compensationType ?? '',
      limitDays: customer?.limitDays ?? 0,
      limitFromDateTime: customer?.limitFromDateTime ?? undefined,
      limitToDateTime: customer?.limitToDateTime ?? undefined,
      invoicingComment: customer?.invoicingComment ?? '',
    };
  }, [relatedCustomerId, customer]);

  const fieldLabels = useFieldLabels();

  function handleSubmit(values: UpdateRelatedCustomerRequest) {
    relatedCustomerId &&
      updateRelatedCustomer.mutate(
        {
          id: relatedCustomerId,
          body: updateRelatedCustomerSchema.cast(values),
        },
        {
          onSuccess: () => {
            snacks.successSnack(t('relatedCustomers.snacks.updated'));
            onSuccess?.();
          },
          onError: e => snacks.apiErrorSnack(e, fieldLabels),
        },
      );
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={updateRelatedCustomerSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <RelatedCustomerForm
        costGroups={costGroups?.items}
        customerIdentifierField={
          <InputField
            disabled
            label={t('relatedCustomers.privateCustomer')}
            name={Fields.privateCustomerId}
            value={customer?.privateCustomer.name ?? '-'}
          />
        }
      />
    </Formik>
  );
}

export default RelatedCustomerEditForm;
