import { createTheme } from '@mui/material';

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      letterSpacing: '0px',
    },
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '60px',
      lineHeight: '72px',
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '48px',
      lineHeight: '52px',
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '36px',
      lineHeight: '40px',
    },
    h4: {
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '28px',
      lineHeight: '32px',
      letterSpacing: '-1px',
    },
    h5: {
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '22px',
      lineHeight: '24px',
      letterSpacing: '-0.5px',
    },
    h6: {
      fontFamily: 'Montserrat',
      fontWeight: '600',
      fontSize: '18px',
      lineHeight: '22px',
    },
    subtitle1: {
      fontWeight: '500',
      fontSize: '15px',
      lineHeight: '16px',
    },
    subtitle2: {
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '16px',
    },
    caption: {
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '16px',
    },
    body1: {
      fontWeight: '600',
      fontSize: '14px',
      lineHeight: '20px',
    },
    body2: {
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '20px',
    },
    button: {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '16px',
    },
  },
  palette: {
    primary: {
      main: '#cd052a',
    },
    secondary: {
      main: '#00A399',
    },
    text: {
      primary: '#141313',
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: '#00A399',
          borderRadius: '2px 2px 0px 0px',
          height: '4px',
          flex: 'none',
          order: '1',
          alignSelf: 'stretch',
          flexGrow: '0',
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: 'inherit',
          },
          fontSize: '15px',
          lineHeight: '16px',
          fontWeight: '500',
          minHeight: '45px',
          textTransform: 'none',
          color: '#33302F',
          borderRadius: '8px 8px 0px 0px',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: '8px',
          borderRadius: '6px',
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        toolbarContainer: {
          display: 'flex',
          flex: '1',
          maxHeight: '104px',
          paddingLeft: '18px',
          paddingBottom: '20px',
          paddingTop: '20px',
          marginRight: '-4px',
          marginTop: '0',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderBottomColor: 'rgba(224, 224, 224, 1)',
        },
        columnHeaderTitle: {
          variant: 'caption',
          fontWeight: '600',
          fontSize: '12px',
          lineHeight: '16px',
          color: '#65615D',
        },
        row: {
          '&.even': {
            backgroundColor: '#FAF9F8',
            '&:hover, &.Mui-hovered': {
              backgroundColor: 'rgba(0, 0, 0, 0.04)',
            },
          },
        },
        cell: {
          border: '0',
          '&:focus-within': {
            outline: 'none !important',
          },
        },
        columnHeaders: {
          border: '0',
        },
        columnHeader: {
          '&:focus-within': {
            outline: 'none',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '16px',
          lineHeight: '16px',
          fontWeight: '500',
          textTransform: 'none',
          height: 48,
        },
        startIcon: {
          marginLeft: '0',
          marginRight: '4px',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          gap: 8,
          borderRadius: 8,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderRadius: '6px !important',
          fontWeight: '400',
          fontSize: '16px',
          height: 46,
          background: '#FFFFFF',
        },
        sizeSmall: {
          height: 40,
        },
        input: {
          '&::placeholder': {
            fontFamily: 'Open Sans',
            fontWight: 400,
            color: '#8E8C89',
            opacity: 1,
          },
        },
        multiline: {
          height: 'auto',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderColor: '#E1DED9',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            borderColor: '#E1DED9',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00A399',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#00A399',
          },
        },
        notchedOutline: {
          borderColor: '#E1DED9',
        },
        input: {
          padding: '13px 12px',
        },
        inputMultiline: {
          padding: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: '500',
          fontSize: '14px',
          lineHeight: '16px',
          color: '#141313',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          border: '1px solid #E1DED9',
          boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
          borderRadius: '8px',
          background: '#FFFFFF',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#65615D',
          fontFamily: 'Open Sans',
          fontWeight: '600',
          fontSize: '12px',
          lineHeight: '16px',
        },
        body: {
          color: '#141313',
          fontFamily: 'Open Sans',
          fontWeight: '400',
          fontSize: '14px',
          lineHeight: '20px',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-checked': {
            color: '#00A399',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          width: '32px',
          '&.Mui-active': {
            border: '1px solid #00A399',
          },
          '&.Mui-completed': {
            border: '1px solid #00A399',
          },
          '&.Mui-disabled': {
            border: '1px solid #BBB8B4',
          },
        },
        line: {
          border: 'none',
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          color: '#00A399',
        },
        label: {
          '&.Mui-disabled': {
            color: '#8E8C89',
          },
          '&.Mui-completed': {
            color: '#008D85',
            fontWeight: 600,
          },
          '&.Mui-active': {
            color: '#141313',
            fontWeight: 600,
          },
          color: '#141313',
          fontSize: '10px',
          fontWeight: 600,
          fontFamily: 'Inter',
          lineHeight: '12px',
          textTransform: 'uppercase',
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {},
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: '#E1DED9',
        },
        bar: {
          backgroundColor: '#00A399',
        },
      },
    },
  },
});
