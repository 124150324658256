import React from 'react';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainHeader } from '../Common/MainHeader';
import { TabMenu } from '../Common/TabMenu';

const menuItems = [
  { titleSlug: 'reservations.tabs.table', path: '' },
  { titleSlug: 'reservations.tabs.calendar', path: 'calendar' },
];

const ReservationsContainer = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <MainHeader text={t('drawer.reservations')}></MainHeader>
      <TabMenu menuItems={menuItems}></TabMenu>
      <Outlet />
    </Box>
  );
};

export default ReservationsContainer;
