import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import RelatedCustomerEditForm from '../RelatedCustomer/RelatedCustomerEditForm';

function CustomerDetailsRelatedAccountEdit() {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('customers.editRelatedCustomer')}
      </Typography>
      <RelatedCustomerEditForm />
    </>
  );
}

export default CustomerDetailsRelatedAccountEdit;
