import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GuardedRoute from '../../GuardedRoute';

import { useAppSelector } from '../../store';
import {
  B2B_ACCEPT_INVITE,
  B2B_COMPANY,
  B2B_COMPANY_EDIT,
  B2B_COMPANY_EDIT_COST_GROUP,
  B2B_COMPANY_MANAGE_AUTHORIZED_PERSONS,
  B2B_COMPANY_MANAGE_COST_GROUPS,
  B2B_COMPANY_MANAGE_PAYMENT_METHODS,
  B2B_CREATE_ACCOUNT,
  B2B_ENTER_CODE,
  B2B_HOME,
  B2B_INVOICES,
  B2B_LOGIN,
  B2B_RESERVATION_DETAILS,
  B2B_RESERVATIONS,
  B2B_USER_EDIT,
  B2B_USER_NEW,
  B2B_USERS,
  B2B_WALLET,
  ROOT,
} from '../../utils/b2b-routes-constants';
import MyCompanyPage from '../../b2b/features/company/MyCompany';
import LoginPage from '../../b2b/features/auth/Login/Login';
import WalletPage from '../../b2b/features/wallet/Wallet';
import EditMyCompanyPage from '../../b2b/features/company/EditMyCompany';
import EditCostGroup from '../../b2b/features/wallet/EditCostGroup';
import EnterCodePage from '../../b2b/features/auth/Login/EnterCode';
import CreateAccountPage from '../../b2b/features/auth/CreateAccount/CreateAccount';
import ReservationsPage from '../../b2b/features/reservations/Reservations';
import InvoicesPage from '../B2B/Invoices/Invoices';
import MyUsersPage from '../../b2b/features/my-users/MyUsers';
import ReservationDetails from '../../b2b/features/reservations/ReservationDetails';
import AddMyUser from '../../b2b/features/my-users/AddMyUser';
import ManageAuthorizedPersons from '../../b2b/features/company/ManageAuthorizedPersons';
import ManageCostGroupsPage from '../../b2b/features/wallet/ManageCostGroupsPage';
import ManagePaymentMethodsPage from '../../b2b/features/wallet/payment-methods/ManagePaymentMethodsPage';
import AcceptInvitePage from '../../b2b/features/accept-invite/AcceptInvitePage';
import AvisHelmet from '../Common/AvisHelmet';
import EditMyUser from '../../b2b/features/my-users/EditMyUser';

const B2BRoutes = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(
    state => !!state.userReducer.authToken,
  );
  const isOnboarded = useAppSelector(state => state.userReducer.isOnboarded);
  const isBusinessCustomerCreated = isAuthenticated && isOnboarded;

  return (
    <>
      <AvisHelmet title={t('head.title.b2b')} />
      <Routes>
        {/* Unguarded Routes */}
        <Route path={B2B_ACCEPT_INVITE} element={<AcceptInvitePage />} />
        <Route
          path={ROOT}
          element={
            <Navigate to={isBusinessCustomerCreated ? B2B_HOME : B2B_LOGIN} />
          }
        />
        <Route path={B2B_LOGIN} element={<LoginPage />} />
        <Route path={B2B_ENTER_CODE} element={<EnterCodePage />} />
        <Route path={B2B_CREATE_ACCOUNT} element={<CreateAccountPage />} />
        {/* Authenticated Routes */}
        <Route
          element={
            <GuardedRoute isRouteAccessible={isBusinessCustomerCreated} />
          }
        >
          <Route path={B2B_HOME} element={<Navigate to={B2B_COMPANY} />} />
          <Route path={B2B_COMPANY} element={<MyCompanyPage />} />
          <Route path={B2B_COMPANY_EDIT} element={<EditMyCompanyPage />} />
          <Route
            path={B2B_COMPANY_MANAGE_AUTHORIZED_PERSONS}
            element={<ManageAuthorizedPersons />}
          />
          <Route
            path={B2B_COMPANY_EDIT_COST_GROUP}
            element={<EditCostGroup />}
          />
          <Route
            path={B2B_COMPANY_MANAGE_PAYMENT_METHODS}
            element={<ManagePaymentMethodsPage />}
          />
          <Route
            path={B2B_COMPANY_MANAGE_COST_GROUPS}
            element={<ManageCostGroupsPage />}
          />
          <Route path={B2B_USERS} element={<MyUsersPage />} />
          <Route path={B2B_USER_NEW} element={<AddMyUser />} />
          <Route path={B2B_USER_EDIT} element={<EditMyUser />} />
          <Route path={B2B_INVOICES} element={<InvoicesPage />} />
          <Route path={B2B_WALLET} element={<WalletPage />} />
          <Route path={B2B_RESERVATIONS} element={<ReservationsPage />} />
          <Route
            path={B2B_RESERVATION_DETAILS}
            element={<ReservationDetails />}
          />
        </Route>
        {/* Not found Route */}
        <Route path="*" element={<Navigate to={ROOT} />} />
      </Routes>
    </>
  );
};

export default B2BRoutes;
