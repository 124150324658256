import React, { useState } from 'react';
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { CustomerParams } from '../../../entities/Customer';
import { PaginatedTable, usePaginatedTable } from '../../Common/PaginatedTable';
import { DATE_TIME_WITH_SECONDS } from '../../../utils/datetime-utils';
import {
  useDeleteCustomer,
  useDeleteCustomersBulk,
  useGetPrivateCustomers,
} from '../../../store/customer/queries';
import { dateTimeString } from '../../../entities';
import { formatMoney } from '../../../utils';
import { CUSTOMERS } from '../../../utils/routes-constants';
import MuiRouterLink from '../../Common/MuiRouterLink';
import { useModal } from '../../Modal/hooks';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import { CustomersTableDropdown } from './CustomersTableDropdown';
import { CustomersTabHeader } from './CustomersTabHeader';
import CustomersToolbar from './CustomersToolbar';

export const PrivateCustomersTable = () => {
  const { t } = useTranslation();
  const { confirm } = useModal();
  const snacks = useSnacks();

  const [params, setParams] = useState<CustomerParams>({});
  const { data: customers, isLoading } = useGetPrivateCustomers(params);
  const { rows, totalItems, debounceParams } = usePaginatedTable({
    paginatedItems: customers,
    params,
    setParams,
    syncParamsWithUrl: true,
    mapGridRow: c =>
      ({
        id: c.id,
        name: c.name,
        phoneNumber: c.phoneNumber,
        areaCode: c.areaCode,
        registrationDate: c.created,
        rides: c.rides,
        balance: '', // TODO
      } as PrivateCustomerRow),
  });
  const gridApiRef = useGridApiRef();

  const deleteCustomer = useDeleteCustomer('private');
  const deleteCustomersBulk = useDeleteCustomersBulk('private');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function sendNotification(customerId?: string) {
    //TODO customerId ? send to customer : send bulk
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function addBonus(customerId?: string) {
    //TODO
  }

  function handleDelete(customerId: string) {
    confirm({
      title: t('customers.modal.delete.title'),
      action() {
        deleteCustomer.mutate(
          { customerId, deleted: true },
          {
            onSuccess: () =>
              snacks.successSnack(t('customers.snacks.customerDeleted')),
            onError: e => snacks.apiErrorSnack(e),
          },
        );
      },
    });
  }

  function handleBulkDelete() {
    const selectedRows = gridApiRef.current.getSelectedRows();
    if (!selectedRows || selectedRows.size === 0) return;
    const customerIds = Array.from(selectedRows.keys()).map(it =>
      it.toString(),
    );
    confirm({
      title: t('customers.modal.bulkDelete.title'),
      action() {
        deleteCustomersBulk.mutate(customerIds, {
          onSuccess: () =>
            snacks.successSnack(t('customers.snacks.customerDeleted')),
          onError: e => snacks.apiErrorSnack(e),
        });
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function blockCustomer(customerId?: string) {
    //TODO
  }

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('customers.name'),
      flex: 1,
      sortable: false,
      renderCell: (params: GridRenderCellParams) => (
        <MuiRouterLink to={`${CUSTOMERS}/${params.id}`}>
          {params.value}
        </MuiRouterLink>
      ),
    },
    {
      field: 'phoneNumber',
      headerName: t('customers.phoneNumber'),
      flex: 1,
      sortable: false,
      renderCell: ({ row, value }: GridRenderCellParams) => (
        <Typography variant="body2">
          {row.areaCode && `+${row.areaCode}`} {value}
        </Typography>
      ),
    },
    {
      field: 'registrationDate',
      headerName: t('customers.registrationDate'),
      sortable: true,
      flex: 1,
      renderCell: (params: GridRenderCellParams<string[]>) => (
        <Typography variant="body2">
          {moment(params.value).format(DATE_TIME_WITH_SECONDS)}
        </Typography>
      ),
    },
    {
      field: 'rides',
      headerName: t('customers.rides'),
      sortable: false,
      flex: 0.65,
      renderCell: ({ value }: GridRenderCellParams) => (
        <Typography variant="body2">
          {typeof value === 'number' && value > 0 && value}
        </Typography>
      ),
    },
    {
      field: 'balance',
      headerName: t('customers.walletBalance'),
      sortable: false,
      flex: 0.65,
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2">{formatMoney(params.value)}</Typography>
      ),
    },
    {
      field: 'actions',
      headerName: '',
      width: 50,
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <CustomersTableDropdown
          onSendNotification={() => sendNotification(params.row.id)}
          onBlockCustomer={() => blockCustomer(params.row.id)}
          onDeleteCustomer={() => handleDelete(params.row.id)}
          onAddBonus={() => addBonus(params.row.id)}
        />
      ),
    },
  ];

  return (
    <>
      <CustomersTabHeader
        onSendNotification={sendNotification}
        onBlockCustomer={blockCustomer}
        onDeleteCustomer={handleBulkDelete}
        onAddBonus={addBonus}
        header={t('customers.tabs.private')}
      />
      <PaginatedTable
        columns={columns}
        rows={rows}
        isLoading={isLoading}
        totalItems={totalItems}
        fetchRows={debounceParams}
        Toolbar={CustomersToolbar}
        checkboxSelection
        apiRef={gridApiRef}
      />
    </>
  );
};

interface PrivateCustomerRow {
  id: string;
  name: string;
  phoneNumber: string;
  areaCode: string;
  registrationDate: dateTimeString;
  rides: number;
  balance: string;
}
