import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from '../../../components/Common/SecondaryButton';
import { TabHeader } from '../../../components/Common/TabHeader';
import AddCostGroup from './AddCostGroup';
import CostGroups from './CostGroups';

function ManageCostGroupsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ flex: 1 }}>
      <TabHeader headerVariant="h3" text={t('b2b.wallet.costGroup.manage')}>
        <SecondaryButton startIcon={<Close />} onClick={() => navigate(-1)}>
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <AddCostGroup />
      <CostGroups sx={{ mt: 4, maxWidth: '800px' }} />
    </Box>
  );
}

export default ManageCostGroupsPage;
