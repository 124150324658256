import axios from 'axios';
import { RootState, store } from '../store';
import { apiPaths } from '../b2b/features/auth/api';

const baseApi = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
});

baseApi.interceptors.request.use(config => {
  const { userReducer } = store.getState() as RootState;
  const { authToken } = userReducer;
  if (config.url !== apiPaths.code && authToken) {
    config.headers.Authorization = `Bearer ${authToken}`;
  }
  return config;
});

baseApi.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401 && error.config?.url !== apiPaths.code) {
      store.dispatch({ type: 'LOG_OUT' });
      return Promise.reject(error.message);
    }
    return Promise.reject(error);
  },
);

export default baseApi;
