import * as Yup from 'yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import baseApi from '../../../../services/apiService';
import { PAYMENT_METHODS_KEY } from '../../wallet/api';
import { useFullPhoneNumberValidator } from '../../../../utils/yup';
import { fieldOf } from '../../../../store/api';

export const apiPaths = {
  businessCustomers: '/api/business-customers',
  confirmStripePaymentMethod: '/api/stripe/confirm-method',
  reconfirmStripePayment: '/api/stripe/reconfirm',
};

const BUSINESS_CUSTOMER_KEY = 'business-customer';

type ConfirmStripePaymentMethodRequest = {
  paymentMethodId: string;
};

type ConfirmationPaymentDetails = {
  clientSecret: string;
  error: string;
  paymentMethodId: string;
  type: string;
};

export type ConfirmStripePaymentMethodResponse = {
  details: ConfirmationPaymentDetails;
  id: string;
  status: string;
};

export const businessCustomerApi = {
  createBusinessCustomer: (body: CreateBusinessAccountRequest) =>
    baseApi.post<CreateBusinessAccountResponse>(
      apiPaths.businessCustomers,
      body,
    ),
  confirmStripePaymentMethod: (body: ConfirmStripePaymentMethodRequest) =>
    baseApi.post<ConfirmStripePaymentMethodResponse>(
      apiPaths.confirmStripePaymentMethod,
      body,
    ),
  reconfirmStripePayment: (id: string) =>
    baseApi.post<ConfirmStripePaymentMethodResponse>(
      `${apiPaths.reconfirmStripePayment}/${id}`,
    ),
};

export function useCreateBusinessCustomer() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: CreateBusinessAccountRequest) => {
      return (await businessCustomerApi.createBusinessCustomer(body)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [BUSINESS_CUSTOMER_KEY] }),
  });
}

export function useConfirmStripePaymentMethod() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: ConfirmStripePaymentMethodRequest) => {
      return (await businessCustomerApi.confirmStripePaymentMethod(body)).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BUSINESS_CUSTOMER_KEY] });
      queryClient.invalidateQueries({ queryKey: [PAYMENT_METHODS_KEY] });
    },
  });
}

export function useReconfirmStripePayment() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      return (await businessCustomerApi.reconfirmStripePayment(id)).data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [BUSINESS_CUSTOMER_KEY] });
      queryClient.invalidateQueries({ queryKey: [PAYMENT_METHODS_KEY] });
    },
  });
}

export const businessAccountInitialState: CreateBusinessAccountFormSchema = {
  companyName: '',
  registrationNumber: '',
  vatNumber: '',
  address: '',
  email: '',
  agreeTerms: false,
  emailMarketing: false,
  monthlyInvoice: false,
  paymentMethodId: undefined,
};

export const businessAccountValidationSchema = Yup.object({
  companyName: Yup.string().required(),
  registrationNumber: Yup.string().required(),
  vatNumber: Yup.string(),
  address: Yup.string().required(),
  email: Yup.string().required(),
  agreeTerms: Yup.boolean()
    .required()
    .oneOf([true], 'b2b.createAccount.mustAgreeTerms'),
  emailMarketing: Yup.boolean(),
  monthlyInvoice: Yup.boolean(),
  paymentMethodId: Yup.string().notRequired().optional().default(undefined),
});

export type CreateBusinessAccountFormSchema = Yup.InferType<
  typeof businessAccountValidationSchema
>;

export type CreateBusinessAccountRequest = Omit<
  CreateBusinessAccountFormSchema,
  'agreeTerms'
>;

export type CreateBusinessAccountResponse = {
  id: string;
  companyName: string;
  registrationNumber: string;
  vatNumber: string;
  address: string;
  email: string;
  emailMarketing: boolean;
  monthlyInvoice: boolean;
};

export interface BoardMemberRequest {
  name: string;
  email: string;
  phoneNumber: string;
}

export function useBoardMemberSchema() {
  const phoneValidator = useFullPhoneNumberValidator();
  return useMemo(
    () =>
      Yup.object({
        [fieldOf<BoardMemberRequest>('name')]: Yup.string().required(),
        [fieldOf<BoardMemberRequest>('email')]: Yup.string().required(),
        [fieldOf<BoardMemberRequest>('phoneNumber')]: phoneValidator.required(),
      }),
    [phoneValidator],
  );
}
