import * as React from 'react';
import { forwardRef, Ref, useEffect, useMemo } from 'react';
import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Form, Formik, useFormikContext } from 'formik';
import InputField from '../../../../components/Common/FormikFields/InputField';
import { fieldOf } from '../../../../store/api';
import { BoardMemberRequest, useBoardMemberSchema } from './api';
import { useAccountStepper } from './useAccountStepper';

const INITIAL_VALUES: BoardMemberRequest = {
  name: '',
  email: '',
  phoneNumber: '',
};

function InvoicePaymentSetup() {
  const { invoiceFormData } = useAccountStepper();
  const validationSchema = useBoardMemberSchema();

  const initialValues = useMemo(() => {
    return invoiceFormData || INITIAL_VALUES;
  }, []);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const dummy = () => {};

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={dummy}
      enableReinitialize
    >
      <BoardMemberForm />
    </Formik>
  );
}

const BoardMemberForm = forwardRef(function BoardMemberForm(_props, ref) {
  const { t } = useTranslation();
  const { values, isValid } = useFormikContext();
  const { setInvoiceFormData, setInvoiceFormValid, isInvoiceEnabled } =
    useAccountStepper();

  useEffect(() => {
    setInvoiceFormData(values as BoardMemberRequest);
    setInvoiceFormValid(isValid);
  }, [values, isValid]);

  return (
    <Form>
      <Grid
        container
        direction="column"
        rowGap={2}
        ref={ref as Ref<HTMLDivElement> | undefined}
      >
        <Grid item>
          <InputField
            name={fieldOf<BoardMemberRequest>('name')}
            label={t(`b2b.createAccount.invoicePayment.boardMemberName`)}
            disabled={!isInvoiceEnabled}
          />
        </Grid>
        <Grid item>
          <InputField
            name={fieldOf<BoardMemberRequest>('email')}
            label={t(`b2b.createAccount.invoicePayment.boardMemberEmail`)}
            disabled={!isInvoiceEnabled}
          />
        </Grid>
        <Grid item>
          <InputField
            name={fieldOf<BoardMemberRequest>('phoneNumber')}
            label={t(`b2b.createAccount.invoicePayment.boardMemberPhoneNumber`)}
            disabled={!isInvoiceEnabled}
          />
        </Grid>
      </Grid>
    </Form>
  );
});

export default InvoicePaymentSetup;
