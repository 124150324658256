import { useMutation, useQuery } from '@tanstack/react-query';
import api from '../api';
import {
  BusinessCustomerDetail,
  CustomerParams,
  CustomerType,
  PrivateCustomerDetail,
} from '../../entities/Customer';
import { queryClient } from '../../App';
import {
  BusinessCustomerEditRequest,
  CustomerPaymentMethodsParams,
  PrivateCustomerEditRequest,
} from '../api/customer';

const PRIVATE_CUSTOMERS = 'private-customers';
const BUSINESS_CUSTOMERS = 'business-customers';

const PRIVATE_CUSTOMER = 'private-customer';
const BUSINESS_CUSTOMER = 'business-customer';

const CUSTOMER_PAYMENT_METHODS = 'customer-payment-methods';

export function useGetPrivateCustomers(params: CustomerParams) {
  return useQuery({
    queryKey: [PRIVATE_CUSTOMERS, params],
    queryFn: async () => (await api.getPrivateCustomers(params)).data,
  });
}

export function useGetBusinessCustomers(params: CustomerParams) {
  return useQuery({
    queryKey: [BUSINESS_CUSTOMERS, params],
    queryFn: async () => (await api.getBusinessCustomers(params)).data,
  });
}

export function useGetCustomer<T extends CustomerType>(
  customerType: T,
  id: string,
) {
  const apiCall =
    customerType === 'business'
      ? api.getBusinessCustomer
      : api.getPrivateCustomer;
  const queryKey =
    customerType === 'business' ? BUSINESS_CUSTOMER : PRIVATE_CUSTOMER;
  return useQuery({
    queryKey: [queryKey, id],
    queryFn: async () =>
      (await apiCall(id)).data as T extends 'business'
        ? BusinessCustomerDetail
        : PrivateCustomerDetail,
    enabled: !!id,
  });
}

export function useDeleteCustomer(customerType: CustomerType) {
  const apiCall =
    customerType === 'business'
      ? api.deleteBusinessCustomer
      : api.deletePrivateCustomer;
  return useMutation({
    mutationFn: ({
      customerId,
      deleted,
    }: {
      customerId: string;
      deleted: boolean;
    }) => apiCall(customerId, { deleted }),
    onSuccess: async (_data, { customerId }) => {
      await queryClient.invalidateQueries({
        queryKey: [
          customerType === 'business' ? BUSINESS_CUSTOMERS : PRIVATE_CUSTOMERS,
        ],
      });
      await queryClient.invalidateQueries({
        queryKey: [
          customerType === 'business' ? BUSINESS_CUSTOMER : PRIVATE_CUSTOMER,
          customerId,
        ],
      });
    },
  });
}

export function useDeleteCustomersBulk(customerType: CustomerType) {
  const apiCall =
    customerType === 'business'
      ? api.deleteBusinessCustomersBulk
      : api.deletePrivateCustomersBulk;
  return useMutation({
    mutationFn: (customerIds: string[]) => apiCall(customerIds),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [
          customerType === 'business' ? BUSINESS_CUSTOMERS : PRIVATE_CUSTOMERS,
        ],
      });
      await queryClient.invalidateQueries({
        queryKey: [
          customerType === 'business' ? BUSINESS_CUSTOMER : PRIVATE_CUSTOMER,
        ],
      });
    },
  });
}

export function useEditPrivateCustomer() {
  return useMutation({
    mutationFn: async ({
      customerId,
      body,
    }: {
      customerId: string;
      body: PrivateCustomerEditRequest;
    }) => (await api.updatePrivateCustomer(customerId, body)).data,
    onSuccess: async (_data, { customerId }) => {
      await queryClient.invalidateQueries({ queryKey: [PRIVATE_CUSTOMERS] });
      await queryClient.invalidateQueries({
        queryKey: [PRIVATE_CUSTOMER, customerId],
      });
    },
  });
}

export function useEditBusinessCustomer() {
  return useMutation({
    mutationFn: async ({
      customerId,
      body,
    }: {
      customerId: string;
      body: BusinessCustomerEditRequest;
    }) => (await api.updateBusinessCustomer(customerId, body)).data,
    onSuccess: async (_data, { customerId }) => {
      await queryClient.invalidateQueries({ queryKey: [BUSINESS_CUSTOMERS] });
      await queryClient.invalidateQueries({
        queryKey: [BUSINESS_CUSTOMER, customerId],
      });
    },
  });
}

export function useGetCustomerPaymentMethods(
  params: CustomerPaymentMethodsParams,
) {
  return useQuery({
    queryKey: [CUSTOMER_PAYMENT_METHODS, params],
    queryFn: async () => (await api.getCustomerPaymentMethods(params)).data,
  });
}
