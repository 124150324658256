import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { RESERVATION_ADD } from '../../utils/routes-constants';
import { SecondaryButton } from '../Common/SecondaryButton';
import ExportIcon from '../../icons/ExportIcon';
import { useSnacks } from '../Common/AvisSnackbarProvider';
import ReservationsTable from './ReservationsTable';

const ReservationsPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snacks = useSnacks();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedReservationIds, setSelectedReservationIds] = useState<
    string[]
  >([]);

  function exportCsv() {
    snacks.errorSnack('TODO' + selectedReservationIds.length);
  }

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <Box display="flex" />
        <Box display="flex" gap={2}>
          <SecondaryButton startIcon={<ExportIcon />} onClick={exportCsv}>
            {t('reservations.buttons.exportToCsv')}
          </SecondaryButton>
          <Button
            color="secondary"
            variant="contained"
            startIcon={<Add />}
            onClick={() => navigate(RESERVATION_ADD)}
          >
            {t('reservations.buttons.addMaintenanceReservation')}
          </Button>
        </Box>
      </Box>
      <ReservationsTable onSelectionChange={setSelectedReservationIds} />
    </Box>
  );
};

export default ReservationsPage;
