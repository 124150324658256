import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, Formik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { FormLabel, Typography } from '@mui/material';
import RelatedCustomerForm, {
  useFieldLabels,
} from '../RelatedCustomer/RelatedCustomerForm';
import {
  CreateRelatedCustomerRequest,
  Fields,
  useRelatedCustomerSchemas,
} from '../../../store/api/related-customers';
import { useAddRelatedCustomer } from '../../../store/related-customers';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import {
  CostGroupParams,
  useGetCostGroups,
} from '../../../b2b/features/wallet/api';
import PrivateCustomerSelect from '../EditCustomer/PrivateCustomerSelect';

function CustomerDetailsRelatedAccountAdd() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snacks = useSnacks();
  const { customerId } = useParams();

  const { createRelatedCustomerSchema } = useRelatedCustomerSchemas();
  const addRelatedCustomer = useAddRelatedCustomer();

  const params: CostGroupParams = useMemo(
    () => ({
      businessCustomerId: customerId,
    }),
    [customerId],
  );

  const { data: costGroups } = useGetCostGroups(params);

  const initialValues = useMemo(
    () => ({
      costGroupId: '',
      compensationType: '',
      invoicingComment: '',
      privateCustomerId: '',
      businessCustomerId: customerId ?? '',
    }),
    [customerId],
  );

  const fieldLabels = useFieldLabels();

  function handleSubmit(values: CreateRelatedCustomerRequest) {
    addRelatedCustomer.mutate(createRelatedCustomerSchema.cast(values), {
      onSuccess: () => {
        snacks.successSnack(t('relatedCustomers.snacks.added'));
        navigate(-1);
      },
      onError: e => snacks.apiErrorSnack(e, fieldLabels),
    });
  }

  return (
    <>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {t('customers.addRelatedCustomer')}
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={createRelatedCustomerSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue }) => (
          <RelatedCustomerForm
            costGroups={costGroups?.items}
            customerIdentifierField={
              <Field name={Fields.privateCustomerId}>
                {({ meta: { error, touched } }: FieldProps) => (
                  <>
                    <FormLabel htmlFor={Fields.privateCustomerId}>
                      {t('relatedCustomers.privateCustomer')}
                    </FormLabel>
                    <PrivateCustomerSelect
                      error={touched && !!error}
                      name={Fields.privateCustomerId}
                      onCustomerSelect={id =>
                        setFieldValue(Fields.privateCustomerId, id)
                      }
                    />
                  </>
                )}
              </Field>
            }
          />
        )}
      </Formik>
    </>
  );
}

export default CustomerDetailsRelatedAccountAdd;
