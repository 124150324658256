import React, { useState } from 'react';
import { Box, Paper } from '@mui/material';
import {
  GridColDef,
  GridRowParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PaginatedRequestParams } from '../../../store/api';
import { useGetBonusCodeCustomers } from '../../../store/bonusCode/queries';
import ModalHeader from '../../Modal/ModalHeader';
import { PaginatedTable, usePaginatedTable } from '../../Common/PaginatedTable';
import { CUSTOMERS } from '../../../utils/routes-constants';
import { closeModal } from '../../../store/modal/slice';
import { useAppDispatch } from '../../../store';

interface Props {
  id: string;
  title: string;
}

const BonusCodeCustomersModal = ({ id, title }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [params, setParams] = useState<PaginatedRequestParams>({});
  const { data: bonusCodeCustomers, isLoading } = useGetBonusCodeCustomers({
    id,
    params,
  });

  const { rows, totalItems, debounceParams } = usePaginatedTable({
    params,
    paginatedItems: bonusCodeCustomers,
    setParams,
  });

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: t('settings.bonusCodeCustomers.name'),
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        params.value,
    },
  ];

  return (
    <Paper sx={{ width: '100%', maxWidth: 568 }}>
      <ModalHeader title={title} />
      <Box sx={{ padding: 4, height: 384 }}>
        <PaginatedTable
          rows={rows}
          columns={columns}
          totalItems={totalItems}
          isLoading={isLoading}
          fetchRows={debounceParams}
          onRowClick={(params: GridRowParams) => {
            navigate(`${CUSTOMERS}/${params.row.id}`);
            dispatch(closeModal());
          }}
        />
      </Box>
    </Paper>
  );
};

export default BonusCodeCustomersModal;
