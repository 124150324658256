import React from 'react';
import { Field, FieldProps } from 'formik';
import { CheckboxProps } from '@mui/material';
import Checkbox from '../Checkbox';

interface Props extends CheckboxProps {
  name: string;
  label: string;
}

const CheckboxField = ({ name, label, ...checkboxProps }: Props) => (
  <Field name={name}>
    {({ field, meta: { error, touched } }: FieldProps) => (
      <Checkbox
        {...field}
        {...checkboxProps}
        checked={field.value}
        label={label}
        error={error && touched ? error : undefined}
        color={error && touched ? 'error' : undefined}
      />
    )}
  </Field>
);

export default CheckboxField;
