import React from 'react';
import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TabHeader } from '../../Common/TabHeader';
import { useGetParkingZones } from '../../../store/vehicle/queries';
import { ParkingZoneCard } from './ParkingZoneCard';

const ParkingZones = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: parkingZones } = useGetParkingZones();

  return (
    <>
      <TabHeader text={t('settings.zones.title')}>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          startIcon={<Add />}
          onClick={() => navigate('new')}
        >
          {t('settings.zones.addNewButton')}
        </Button>
      </TabHeader>
      <Grid container spacing={3} sx={{ mt: 0 }}>
        {parkingZones?.map(parkingZone => (
          <Grid item key={parkingZone.id} xs={6} md={4} lg={3}>
            <ParkingZoneCard parkingZone={parkingZone} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};
export default ParkingZones;
