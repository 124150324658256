import { AxiosResponse } from 'axios';
import { PaginatedItems, PaginatedRequestParams } from '../store/api';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const removeEmptyParams = (params: any) => {
  Object.keys(params).forEach(key => {
    if (!params[key]) {
      delete params[key];
    }
  });
  return params;
};

export const queryAllPages = async <T, U extends PaginatedRequestParams>(
  queryFn: (params?: U) => Promise<AxiosResponse<PaginatedItems<T>>>,
  initialParams?: U,
) => {
  const params: Required<PaginatedRequestParams> & U = Object.assign(
    {},
    {
      page: 1,
      limit: 50,
    },
    initialParams,
  );
  let allItems: T[] = [];
  let exhausted = false;
  do {
    const response = await queryFn(params);
    allItems = [...allItems, ...response.data.items];
    if (allItems.length >= response.data.pagination.totalItems) {
      exhausted = true;
    }
    params.page += 1;
  } while (!exhausted);
  return allItems;
};
