import React from 'react';
import { FormLabel } from '@mui/material';
import { Field, FieldProps, getIn } from 'formik';
import { SelectProps } from '@mui/material/Select/Select';
import { useTranslation } from 'react-i18next';
import Select, { SelectItem } from '../Select';

interface Props extends Omit<SelectProps<string>, 'onChange'> {
  name: string;
  items: SelectItem[];
  label: string;
  placeholder?: string;
  disabled?: boolean;
  displayEmpty?: boolean;
  value?: string;
  defaultValue?: string;
  initialItem?: SelectItem;
  onChange?: (val: string) => void;
  helperText?: string;
  loading?: boolean;
}

const SelectField = ({
  name,
  items,
  value,
  defaultValue,
  initialItem,
  label,
  placeholder,
  disabled,
  displayEmpty,
  onChange,
  helperText,
  loading,
}: Props) => {
  const { t } = useTranslation();
  const optionalProps = {
    ...(value ? { value } : {}),
    ...(defaultValue ? { defaultValue } : {}),
    ...(initialItem ? { initialItem } : {}),
  };
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Field name={name}>
        {({ field, form: { setFieldValue, errors, touched } }: FieldProps) => (
          <Select
            {...field}
            {...optionalProps}
            items={items}
            onChange={async (value: string) => {
              onChange?.(value);
              await setFieldValue(name, value, true);
            }}
            placeholder={placeholder || t('choose')}
            error={!!getIn(errors, field.name) && !!getIn(touched, field.name)}
            helperText={t(getIn(errors, field.name) || helperText)}
            disabled={disabled}
            displayEmpty={displayEmpty}
            loading={loading}
          />
        )}
      </Field>
    </>
  );
};

export default SelectField;
