import React from 'react';

const NoUserIcon = () => (
  <svg
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="44"
      cy="44"
      r="44"
      transform="matrix(-1 0 0 1 88 0)"
      fill="#E1DED9"
    />
    <circle
      cx="44"
      cy="44"
      r="44"
      transform="matrix(-1 0 0 1 88 0)"
      fill="url(#paint0_linear_237_6774)"
      fillOpacity="0.7"
    />
    <path
      d="M65.0001 44.8327C65.0001 57.0827 55.0834 66.9993 42.8334 66.9993C30.5834 66.9993 20.6667 57.0827 20.6667 44.8327C20.6667 32.5827 30.5834 22.666 42.8334 22.666C45.8901 22.666 48.8067 23.2727 51.4667 24.3927C51.1634 25.326 51.0001 26.306 51.0001 27.3327C51.0001 29.0827 51.4901 30.7393 52.3534 32.1393C52.8201 32.9327 53.4267 33.6559 54.1267 34.2626C55.7601 35.7559 57.9301 36.666 60.3334 36.666C61.3601 36.666 62.3401 36.5026 63.2501 36.176C64.3701 38.836 65.0001 41.776 65.0001 44.8327Z"
      fill="#E1DED9"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 27.4571V54C32 56.2091 33.7909 58 36 58H51C53.2091 58 55 56.2091 55 54V27.4571C55 25.9905 53.9396 24.7389 52.4929 24.4979L43.9929 23.0821C43.6665 23.0277 43.3335 23.0277 43.0071 23.0821L34.5071 24.4979C33.0604 24.7389 32 25.9905 32 27.4571Z"
      fill="#F3F2F0"
    />
    <path
      d="M65.0001 44.8327C65.0001 57.0827 55.0834 66.9993 42.8334 66.9993C30.5834 66.9993 20.6667 57.0827 20.6667 44.8327C20.6667 32.5827 30.5834 22.666 42.8334 22.666C45.8901 22.666 48.8067 23.2727 51.4667 24.3927C51.1634 25.326 51.0001 26.306 51.0001 27.3327C51.0001 29.0827 51.4901 30.7393 52.3534 32.1393C52.8201 32.9327 53.4267 33.6559 54.1267 34.2626C55.7601 35.7559 57.9301 36.666 60.3334 36.666C61.3601 36.666 62.3401 36.5026 63.2501 36.176C64.3701 38.836 65.0001 41.776 65.0001 44.8327Z"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39 52.5H47"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeLinecap="round"
    />
    <path
      d="M41 41.1309L45 41.1309C47.2091 41.1309 49 42.9217 49 45.1309L49 46.1309C49 46.6831 48.5534 47.1309 48.0011 47.1309L43 47.1309L37.9989 47.1309C37.4466 47.1309 37 46.6831 37 46.1309L37 45.1309C37 42.9217 38.7909 41.1309 41 41.1309Z"
      fill="#E1DED9"
      stroke="#65615D"
      strokeWidth="2.5"
    />
    <path
      d="M55 35V55C55 57.2091 53.2091 59 51 59H35C32.7909 59 31 57.2091 31 55V26"
      stroke="#65615D"
      strokeWidth="2.5"
    />
    <path
      d="M43 37.5508C41.3431 37.5508 40 36.2076 40 34.5508C40 32.8939 41.3431 31.5508 43 31.5508C44.6569 31.5508 46 32.8939 46 34.5508C46 36.2076 44.6569 37.5508 43 37.5508Z"
      fill="#E1DED9"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M69.6667 27.3346C69.6667 26.588 69.5733 25.8647 69.3867 25.1647C69.1767 24.2313 68.8033 23.3213 68.3133 22.528C67.1933 20.638 65.3967 19.168 63.25 18.4914C62.34 18.1647 61.36 18.0013 60.3333 18.0013C57.93 18.0013 55.76 18.9114 54.1267 20.4047C53.4267 21.0114 52.82 21.7346 52.3533 22.528C51.49 23.928 51 25.5846 51 27.3346C51 28.3613 51.1633 29.3413 51.4667 30.2747C51.91 31.628 52.6567 32.8413 53.6367 33.8446C55.34 35.5946 57.72 36.668 60.3333 36.668C63.0867 36.668 65.5833 35.4779 67.2633 33.5646C68.7567 31.9079 69.6667 29.7146 69.6667 27.3346Z"
      fill="#F3F2F0"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M63.81 27.3809H56.8567"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M60.3334 30.7871V23.8105"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_237_6774"
        x1="44"
        y1="0"
        x2="44"
        y2="88"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default NoUserIcon;
