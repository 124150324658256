import React from 'react';

const NoPaymentMethodIcon = () => (
  <svg
    width="88"
    height="88"
    viewBox="0 0 88 88"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="44" cy="44" r="44" fill="#E1DED9" />
    <circle
      cx="44"
      cy="44"
      r="44"
      fill="url(#paint0_linear_240_6808)"
      fillOpacity="0.7"
    />
    <path
      d="M31.0541 60.2949C27.8533 61.1525 24.5633 59.253 23.7056 56.0522L18.6587 37.2167C17.801 34.0159 19.7005 30.7259 22.9013 29.8682L57.0979 20.7053C60.2987 19.8476 63.5888 21.7471 64.4464 24.9479L69.4934 43.7835C70.351 46.9842 68.4515 50.2743 65.2507 51.1319L31.0541 60.2949Z"
      fill="#E1DED9"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1027 38.4746L64.0523 26.6983"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      x="18.691"
      y="38.3379"
      width="47.25"
      height="5.25"
      transform="rotate(-15 18.691 38.3379)"
      fill="#65615D"
    />
    <path
      d="M21.6262 42.7539L65.5758 30.9776"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.1292 47.3535L34.108 45.4835L41.0868 43.6136"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M28.3742 52L35.3446 50.1323"
      stroke="#65615D"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx="54.9379"
      cy="53.1875"
      r="9.1875"
      fill="#F3F2F0"
      stroke="#65615D"
      strokeWidth="2.5"
    />
    <path
      d="M53.5095 55.1541C53.3899 55.1541 53.293 55.0571 53.293 54.9375C53.293 54.3388 53.3423 53.8617 53.4409 53.506C53.543 53.1503 53.6909 52.8651 53.8846 52.6503C54.0783 52.4319 54.3142 52.233 54.5924 52.0534C54.8143 51.9125 55.0115 51.7699 55.184 51.6255C55.3601 51.4776 55.4992 51.3191 55.6013 51.1501C55.7034 50.981 55.7545 50.7891 55.7545 50.5743C55.7545 50.3665 55.7052 50.1834 55.6066 50.0249C55.508 49.8665 55.3724 49.745 55.1999 49.6605C55.0308 49.5724 54.8424 49.5284 54.6347 49.5284C54.4269 49.5284 54.2314 49.5759 54.0483 49.671C53.8687 49.7661 53.7208 49.9017 53.6046 50.0778C53.5741 50.1244 53.5476 50.1738 53.5251 50.2259C53.4168 50.4769 53.2107 50.7064 52.9374 50.7064H51.5076C51.2287 50.7064 50.9997 50.4772 51.0353 50.2006C51.1015 49.6858 51.2607 49.2487 51.5128 48.8892C51.8474 48.4174 52.2893 48.0687 52.8387 47.8434C53.388 47.6145 53.9937 47.5 54.6558 47.5C55.3812 47.5 56.0257 47.6127 56.5891 47.8381C57.1561 48.0634 57.6016 48.3962 57.9255 48.8364C58.2495 49.2766 58.4115 49.8154 58.4115 50.4528C58.4115 50.8719 58.3411 51.2416 58.2002 51.5621C58.0594 51.8826 57.8622 52.166 57.6086 52.4126C57.3586 52.6555 57.0645 52.8774 56.7265 53.0781C56.4623 53.2331 56.2422 53.3951 56.0662 53.5641C55.8936 53.7331 55.7633 53.9268 55.6753 54.1452C55.5872 54.36 55.5432 54.6241 55.5432 54.9375C55.5432 55.0571 55.4463 55.1541 55.3266 55.1541H53.5095ZM54.4603 58.6193C54.08 58.6193 53.7543 58.4855 53.4831 58.2178C53.2155 57.9502 53.0834 57.6262 53.0869 57.2459C53.0834 56.8726 53.2155 56.5539 53.4831 56.2898C53.7543 56.0221 54.08 55.8883 54.4603 55.8883C54.8231 55.8883 55.1418 56.0221 55.4164 56.2898C55.6911 56.5539 55.8302 56.8726 55.8337 57.2459C55.8302 57.4994 55.7633 57.7301 55.633 57.9379C55.5062 58.1456 55.339 58.3111 55.1312 58.4344C54.9269 58.5576 54.7033 58.6193 54.4603 58.6193Z"
      fill="#65615D"
    />
    <path
      d="M62.3754 60.625L67.6254 65.875"
      stroke="#65615D"
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_240_6808"
        x1="44"
        y1="0"
        x2="44"
        y2="88"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default NoPaymentMethodIcon;
