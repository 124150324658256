import { useTranslation } from 'react-i18next';
import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  Button,
  Grid,
  InputAdornment,
  Switch,
  Typography,
} from '@mui/material';
import { Close, Search } from '@mui/icons-material';
import React, { ChangeEvent } from 'react';
import {
  CustomerStatus,
  PaymentStatus,
  SubscriptionStatus,
} from '../../../utils/customer-utils';
import { CustomerParams } from '../../../entities/Customer';
import { PaginatedTableToolbar } from '../../Common/PaginatedTable';
import TextField from '../../Common/TextField';
import Select from '../../Common/Select';

const CustomersToolbar = ({
  params,
  setParams,
  searchFocus,
  setSearchFocus,
}: PaginatedTableToolbar<CustomerParams>) => {
  const { t } = useTranslation();

  const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFocus(true);
    setParams((prevState: CustomerParams) => ({
      ...prevState,
      search: event.target.value,
    }));
  };

  const onFilterValueChange = (value: string, field: string) => {
    setParams((prevState: CustomerParams) => {
      const newState: CustomerParams = {
        ...prevState,
        [field]: value || undefined,
      };

      if (field === 'emailMarketing') {
        newState.emailMarketing =
          value === '' ? undefined : value === SubscriptionStatus.SUBSCRIBED;
      }

      return newState;
    });
  };

  const onArchivedChange = (archived: boolean) => {
    setParams({
      ...params,
      archived,
    });
  };

  const handleClearButton = () => {
    setParams(({ page, limit }: CustomerParams) => ({ page, limit }));
  };

  return (
    <GridToolbarContainer>
      <Grid container columnSpacing={2}>
        <Grid item style={{ width: 240 }}>
          <Typography variant="subtitle2">{t('search')}</Typography>
          <TextField
            value={params.search || ''}
            onChange={onSearchValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            autoFocus={searchFocus}
          />
        </Grid>
        <Grid item style={{ width: 160 }}>
          <Typography variant="subtitle2">
            {t('customers.paymentStatus')}
          </Typography>
          <Select
            items={Object.values(PaymentStatus).map(
              (status: PaymentStatus) => ({
                value: status,
                label: t(`customers.paymentStatuses.${status}`),
              }),
            )}
            value={params.paymentStatus || ''}
            onChange={(value: string) =>
              onFilterValueChange(value, 'paymentStatus')
            }
            displayEmpty
          />
        </Grid>
        <Grid item style={{ width: 160 }}>
          <Typography variant="subtitle2">
            {t('customers.accountStatus')}
          </Typography>
          <Select
            items={Object.values(CustomerStatus).map(
              (status: CustomerStatus) => ({
                value: status,
                label: t(`customers.customerStatuses.${status}`),
              }),
            )}
            value={params.status || ''}
            onChange={(value: string) => onFilterValueChange(value, 'status')}
            displayEmpty
          />
        </Grid>
        <Grid item style={{ width: 160 }}>
          <Typography variant="subtitle2">
            {t('customers.marketingEmails')}
          </Typography>
          <Select
            items={Object.values(SubscriptionStatus).map(
              (status: SubscriptionStatus) => ({
                value: status,
                label: t(`customers.subscriptionStatuses.${status}`),
              }),
            )}
            value={
              params.emailMarketing === undefined
                ? ''
                : (params.emailMarketing && SubscriptionStatus.SUBSCRIBED) ||
                  SubscriptionStatus.NOT_SUBSCRIBED
            }
            onChange={(value: string) =>
              onFilterValueChange(value, 'emailMarketing')
            }
            displayEmpty
          />
        </Grid>
        <Grid item style={{ width: 80 }}>
          <Typography variant="subtitle2">{t('customers.archived')}</Typography>
          <Switch
            checked={params.archived || false}
            onChange={(_e, archived) => onArchivedChange(archived)}
          />
        </Grid>
        <Grid item style={{ paddingTop: '20px', paddingLeft: '8px' }}>
          <Button
            style={{ color: '#141313' }}
            startIcon={<Close />}
            onClick={handleClearButton}
          >
            <Typography variant="subtitle2">{t('clearAll')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default CustomersToolbar;
