import { PaginatedRequestParams } from '../store/api';
import {
  dateTimeString,
  Entity,
  Geometry,
  moneyString,
  PointGeometry,
} from './index';

export interface ReservationListItem {
  id: string;
  reservationNumber: string;
  startDateTime: dateTimeString;
  endDateTime: dateTimeString;
  price: string;
  durationSeconds: number;
  distanceMeters: number | null;
  maintenance: boolean;
  created: dateTimeString;
  updated: dateTimeString;
  vehicle: {
    id: string;
    registrationNumber: string;
  };
  pickupParkingZone: {
    id: string;
    name: string;
  };
  customer: ReservationCustomer | null;
  feedback: Feedback | null;
  photosConfirmed: boolean;
}

export interface ReservationDetails extends Entity {
  reservationNumber: string;
  status: ReservationStatus;
  startDateTime: dateTimeString;
  actualStartDateTime?: dateTimeString;
  endDateTime: dateTimeString;
  actualEndDateTime?: dateTimeString;
  maxEndDateTime?: dateTimeString;
  rideDetails: ReservationRideDetails;
  refundAmount?: moneyString;
  payment?: ReservationPayment;
  invoices?: ReservationInvoiceItem[];
  fullRefundDateTime?: dateTimeString;
  pickupParkingZone: ReservationParkingZone;
  returnParkingZone: ReservationParkingZone;
  customer?: ReservationCustomer | null;
  company?: ReservationCompany | null;
  vehicle: ReservationVehicle;
  maintenance: boolean;
  feedback?: Feedback | null;
  photosConfirmed: boolean;
}

interface ReservationInvoiceItem {
  id: string;
  paymentStatus: InvoicePaymentStatus;
  file: {
    id: string;
    filePath: string;
    fileName: string;
  };
  number: string;
  date: dateTimeString;
  serviceDate: dateTimeString;
  dueDate: dateTimeString;
}

type InvoicePaymentStatus = 'paid' | 'unpaid';

type ReservationRideDetails = {
  durationSeconds: number;
  durationPrice: string;
  distanceMeters?: number;
  distancePrice?: string;
  distancePricePerKm?: string;
  totalPrice: string;
};

export type ReservationPayment = {
  pending?: ReservationPaymentPending;
  details: FundedDetail[];
  debt?: moneyString;
};

export type PaymentSummary = {
  status?: 'paid' | 'not_paid' | 'overpaid';
  prepaid?: moneyString;
  postpaid?: moneyString;
  paidWithCard?: moneyString;
  paidWithBonus?: moneyString;
  paidWithCompany?: moneyString;
  debt?: moneyString;
};

type ReservationPaymentPending = {
  total: string;
  unpaid: string;
  details: FundingDetail[];
};

export type FundingDetail = {
  type: FundingType;
  amount: string;
  name: string;
};

export type FundingType = 'card' | 'wallet' | 'company';

type FundedDetail = FundingDetail;

type ReservationParkingZone = {
  id: string;
  name: string;
  displayArea: Geometry;
};

type ReservationCustomer = {
  id: string;
  name: string;
  areaCode: string;
  phoneNumber: string;
  email: string;
};

type ReservationCompany = {
  id: string;
  companyName: string;
};

type ReservationVehicle = {
  id: string;
  registrationNumber: string;
  parkingSpaceInfo: string;
  model: ReservationVehicleModel;
  iotData: ReservationVehicleIotData;
};

type ReservationVehicleModel = {
  id: string;
  brand: string;
  model: string;
  image?: string;
  gearbox: string;
  battery: string;
  seats: number;
};

type ReservationVehicleIotData = {
  lockStatus: boolean;
  starterBlocked: boolean;
};

export enum ReservationStatusType {
  UPCOMING = 'upcoming',
  ACTIVE = 'active',
  ENDED = 'ended',
  CANCELED = 'canceled',
}

export type ReservationStatus = {
  type: ReservationStatusType;
};

export enum ReservationType {
  CUSTOMER = 'customer',
  MAINTENANCE = 'maintenance',
}

export interface GetReservationParams extends PaginatedRequestParams {
  search?: string;
  customerId?: string;
  customerType?: string;
  fromDateTime?: string;
  toDateTime?: string;
  pickupParkingZoneId?: string;
  statusType?: string;
  maintenance?: string;
}

export interface GetVehiclesAvailabilityParams extends PaginatedRequestParams {
  registrationNumber?: string;
}

export interface GetAvailableVehiclesParams extends PaginatedRequestParams {
  startDateTime: string;
  endDateTime: string;
  parkingZoneId?: string;
}

export interface AvailableVehicleListItem {
  id: string;
  modelName: string;
  registrationNumber: string;
}

export interface Feedback {
  id: string;
  rating: ReservationRating;
  comment: string;
}

export type ReservationRating = 'thumbs_up' | 'thumbs_down';

export interface Author {
  id: string;
  name: string;
  type: string;
}

export interface Action {
  type: string;
  author: Author;
  created: string;
}

export interface ReservationTrajectoryItem {
  time: dateTimeString;
  location: PointGeometry;
}

export interface ReservationPhoto {
  type: string;
  image: string;
}

export interface ReservationInvoiceLink {
  uri: string;
  fileName: string;
}
