import React from 'react';

const MaintenanceIcon: React.FC<{ fill: string }> = ({ fill }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.6587 3.43329L8.56699 0.341626C8.45033 0.224959 8.33366 0.166626 8.15866 0.166626H3.84199C3.66699 0.166626 3.55033 0.224959 3.43366 0.341626L0.341992 3.43329C0.225325 3.54996 0.166992 3.66663 0.166992 3.84163V8.21663C0.166992 8.39163 0.225325 8.50829 0.341992 8.62496L3.43366 11.7166C3.55033 11.775 3.66699 11.8333 3.84199 11.8333H8.21699C8.39199 11.8333 8.50866 11.775 8.62533 11.6583L11.717 8.56663C11.8337 8.44996 11.892 8.33329 11.892 8.15829V3.84163C11.8337 3.66663 11.7753 3.54996 11.6587 3.43329ZM6.00033 8.91663C5.65033 8.91663 5.41699 8.68329 5.41699 8.33329C5.41699 7.98329 5.65033 7.74996 6.00033 7.74996C6.35033 7.74996 6.58366 7.98329 6.58366 8.33329C6.58366 8.68329 6.35033 8.91663 6.00033 8.91663ZM6.58366 5.99996C6.58366 6.34996 6.35033 6.58329 6.00033 6.58329C5.65033 6.58329 5.41699 6.34996 5.41699 5.99996V3.66663C5.41699 3.31663 5.65033 3.08329 6.00033 3.08329C6.35033 3.08329 6.58366 3.31663 6.58366 3.66663V5.99996Z"
      fill={fill}
    />
  </svg>
);

export default MaintenanceIcon;
