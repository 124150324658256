import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { MainHeader } from '../Common/MainHeader';
import ExportIcon from '../../icons/ExportIcon';
import AddDocumentIcon from '../../icons/AddDocumentIcon';
import { SecondaryButton } from '../Common/SecondaryButton';
import InvoicesTable from './InvoicesTable';

const InvoicesPage = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <MainHeader text={t('drawer.invoices')} />
        <Box display="flex" gap={2}>
          <SecondaryButton startIcon={<AddDocumentIcon />}>
            {t('invoices.buttons.generateInvoice')}
          </SecondaryButton>
          <SecondaryButton startIcon={<ExportIcon />}>
            {t('invoices.buttons.downloadPdf')}
          </SecondaryButton>
        </Box>
      </Box>
      <InvoicesTable />
    </Box>
  );
};

export default InvoicesPage;
