import React from 'react';

const AvisLogoMini = () => (
  <svg
    width="64"
    height="56"
    viewBox="0 0 64 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 56H64V0H0V56Z"
      fill="#D4002A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M57.6185 21.5363H57.7613C57.8446 21.5363 57.9696 21.5329 57.9696 21.3901C57.9696 21.2511 57.8446 21.2443 57.7613 21.2443H57.6185V21.5363ZM57.2675 20.9728H57.7473C57.8935 20.9728 58.3249 20.9728 58.3249 21.4109C58.3249 21.6859 58.1646 21.7692 58.0083 21.7904L58.3559 22.3293H57.9908L57.6814 21.8074H57.6219V22.3293H57.2675V20.9728ZM57.7647 22.6455C58.2828 22.6455 58.7035 22.204 58.7035 21.6651C58.7035 21.1258 58.2828 20.6842 57.7647 20.6842C57.2467 20.6842 56.8255 21.1258 56.8255 21.6651C56.8255 22.204 57.2467 22.6455 57.7647 22.6455ZM57.7647 20.371C58.4779 20.371 59.0584 20.952 59.0584 21.6651C59.0584 22.3778 58.4779 22.9587 57.7647 22.9587C57.0516 22.9587 56.4711 22.3778 56.4711 21.6651C56.4711 20.952 57.0516 20.371 57.7647 20.371ZM52.7398 24.164C52.2077 23.4831 50.991 23.0127 50.1827 23.0127C49.0764 23.0127 47.9494 23.4882 47.9494 24.6157C47.9494 26.9854 54.8829 25.594 54.8829 30.8689C54.8829 34.2292 52.1822 35.8242 49.0764 35.8242C47.1411 35.8242 45.0582 34.9806 43.6656 33.5824L45.8419 31.4061C46.5227 32.2994 48.1619 32.7618 49.2681 32.7618C50.3526 32.7618 51.3603 32.2513 51.3603 31.1876C51.3603 28.4227 44.4424 29.9106 44.3494 24.892C44.2869 21.4989 47.2898 20 50.2464 20C51.948 20 53.7117 20.6834 55.0283 21.906L52.7398 24.164ZM37.9649 35.4413H41.4841V20.3829H37.9649V35.4413ZM21.5134 20.3829H25.4003L29.0216 31.0363L32.6985 20.3829H36.4048L30.7241 35.4413H27.1559L21.5134 20.3829ZM15.5952 24.6025L13.766 29.4435H17.4455L15.5952 24.6025ZM13.9879 20.3829H17.5305L23.6133 35.4413H19.8705L18.573 32.4286L12.6522 32.4332L11.469 35.4413H7.52902L13.9879 20.3829Z"
      fill="white"
    />
  </svg>
);

export default AvisLogoMini;
