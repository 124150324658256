import React from 'react';
import { Modal as MuiModal } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store';
import { closeModal } from '../../store/modal/slice';
import { ModalType } from '../../utils/modal-utils';
import BonusCodeCustomersModal from '../Settings/BonusCodes/BonusCodeCustomersModal';
import VehicleLocationModal from '../Vehicles/VehiclesList/VehicleLocationModal';
import AddPaymentMethodModal from '../../b2b/features/payment-method/AddPaymentMethodModal';
import CollectReservationDebtModal from '../Reservations/ReservationDetails/CollectReservationDebtModal';
import ReservationPhotosModal from '../Reservations/ReservationPhotosModal';
import DownloadReservationInvoicesModal from '../Reservations/ReservationDetails/DownloadReservationInvoicesModal';
import CreateReservationInvoiceModal from '../Reservations/ReservationDetails/CreateReservationInvoiceModal';
import DeleteConfirmationModal from './DeleteConfirmationModal';
import ConfirmationModal from './ConfirmationModal';

export type ModalProps = {
  [ModalType.CONFIRMATION_MODAL]: React.ComponentProps<
    typeof ConfirmationModal
  >;
  [ModalType.DELETE_CONFIRMATION_MODAL]: React.ComponentProps<
    typeof DeleteConfirmationModal
  >;
  [ModalType.BONUS_CODE_CUSTOMERS_MODAL]: React.ComponentProps<
    typeof BonusCodeCustomersModal
  >;
  [ModalType.VEHICLE_LOCATION]: React.ComponentProps<
    typeof VehicleLocationModal
  >;
  [ModalType.ADD_PAYMENT_METHOD]: React.ComponentProps<
    typeof AddPaymentMethodModal
  >;
  [ModalType.COLLECT_DEBT]: React.ComponentProps<
    typeof CollectReservationDebtModal
  >;
  [ModalType.RESERVATION_PHOTOS]: React.ComponentProps<
    typeof ReservationPhotosModal
  >;
  [ModalType.RESERVATION_INVOICES]: React.ComponentProps<
    typeof DownloadReservationInvoicesModal
  >;
  [ModalType.RESERVATION_INVOICE_CREATE]: React.ComponentProps<
    typeof CreateReservationInvoiceModal
  >;
};

const MODAL_COMPONENTS: {
  [key in ModalType]: React.ComponentType<ModalProps[key]>;
} = {
  [ModalType.CONFIRMATION_MODAL]: ConfirmationModal,
  [ModalType.DELETE_CONFIRMATION_MODAL]: DeleteConfirmationModal,
  [ModalType.BONUS_CODE_CUSTOMERS_MODAL]: BonusCodeCustomersModal,
  [ModalType.VEHICLE_LOCATION]: VehicleLocationModal,
  [ModalType.ADD_PAYMENT_METHOD]: AddPaymentMethodModal,
  [ModalType.COLLECT_DEBT]: CollectReservationDebtModal,
  [ModalType.RESERVATION_PHOTOS]: ReservationPhotosModal,
  [ModalType.RESERVATION_INVOICES]: DownloadReservationInvoicesModal,
  [ModalType.RESERVATION_INVOICE_CREATE]: CreateReservationInvoiceModal,
};

export type ModalComponent = {
  [K in keyof ModalProps]: { type: K; props?: ModalProps[K] };
}[keyof ModalProps];

const Modal = () => {
  const dispatch = useAppDispatch();

  const type: ModalComponent['type'] | undefined = useAppSelector(
    state => state.modalReducer.type,
  );
  const props: ModalComponent['props'] | undefined = useAppSelector(
    state => state.modalReducer.props,
  );

  const handleClose = () => {
    dispatch(closeModal());
  };

  if (!type || !props) {
    return null;
  }

  const ModalComponent = MODAL_COMPONENTS[type] as React.ComponentType<
    ModalProps[typeof type]
  >;

  return (
    <MuiModal
      open={!!type}
      onClose={handleClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {/*Weird workaround for: */}
      {/*Invalid prop `children` supplied to `ForwardRef(Modal)` */}
      {/*https://github.com/mui/material-ui/issues/31261#issuecomment-1057478910 */}
      <>
        <ModalComponent {...props} />
      </>
    </MuiModal>
  );
};

export default Modal;
