export enum ModalType {
  CONFIRMATION_MODAL = 'CONFIRMATION_MODAL',
  DELETE_CONFIRMATION_MODAL = 'DELETE_CONFIRMATION_MODAL',
  BONUS_CODE_CUSTOMERS_MODAL = 'BONUS_CODE_CUSTOMERS_MODAL',
  VEHICLE_LOCATION = 'VEHICLE_LOCATION',
  ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD',
  COLLECT_DEBT = 'COLLECT_DEBT',
  RESERVATION_PHOTOS = 'RESERVATION_PHOTOS',
  RESERVATION_INVOICES = 'RESERVATION_INVOICES',
  RESERVATION_INVOICE_CREATE = 'RESERVATION_INVOICE_CREATE',
}
