import '@fontsource/open-sans/400.css';
import '@fontsource/open-sans/500.css';
import '@fontsource/open-sans/600.css';
import '@fontsource/inter/600.css';
import '@fontsource/montserrat/600.css';
import React, { Suspense } from 'react';
import {
  Box,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import {
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
// noinspection ES6UnusedImports
import {} from '@mui/x-data-grid/themeAugmentation';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { HelmetProvider } from 'react-helmet-async';
import Drawer from './components/Drawer/Drawer';
import { persistor, store } from './store';
import AppBar from './components/AppBar/AppBar';
import { theme } from './theme';
import Layout from './Layout';
import Modal from './components/Modal/Modal';
import {
  AvisSnackbarProvider,
  SnackType,
} from './components/Common/AvisSnackbarProvider';
import StripeProvider from './b2b/features/payment-method/StripeProvider';
import { isInBackofficePath } from './utils';
import AppGuard from './components/Routes/AppGuard';

const isBackoffice = isInBackofficePath();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
    },
  },
  queryCache: new QueryCache({
    onError: (_error, query) => {
      // Only show error toasts if we already have data in the cache
      // which indicates a failed background update
      if (query.state.data !== undefined) {
        enqueueSnackbar('Something went wrong', {
          variant: SnackType.ERROR,
        });
      }
    },
  }),
});

window.queryClient = queryClient;

const App = () => (
  <HelmetProvider>
    <StripeProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <AppGuard>
            <BrowserRouter>
              <QueryClientProvider client={queryClient}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <AvisSnackbarProvider>
                    <Box display="flex" sx={{ minHeight: '100vh' }}>
                      <AppBar />
                      <Drawer isBackoffice={isBackoffice} />
                      <Layout isBackoffice={isBackoffice} />
                    </Box>
                    <Modal />
                  </AvisSnackbarProvider>
                </ThemeProvider>
                <ReactQueryDevtools initialIsOpen={false} />
              </QueryClientProvider>
            </BrowserRouter>
          </AppGuard>
        </PersistGate>
      </Provider>
    </StripeProvider>
  </HelmetProvider>
);

export default function WrappedApp() {
  return (
    <Suspense
      fallback={
        <Box
          display="flex"
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minHeight: '100vh',
            minWidth: '100vw',
          }}
        >
          <CircularProgress />
        </Box>
      }
    >
      <App />
    </Suspense>
  );
}
