import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModalComponent } from '../../components/Modal/Modal';

interface ModalState {
  type?: ModalComponent['type'];
  props?: ModalComponent['props'];
}

const initialState: ModalState = {};

const modalSlice = createSlice({
  name: 'modalSlice',
  initialState,
  reducers: {
    openModal: (state, action: PayloadAction<ModalComponent>) => {
      const { type, props } = action.payload;

      state.type = type;
      state.props = props ?? {};
    },
    closeModal: state => {
      state.type = undefined;
      state.props = undefined;
    },
  },
});

export const {
  reducer,
  actions: { openModal, closeModal },
} = modalSlice;
