import React from 'react';

const EditIcon: React.FC<{ fill?: string }> = ({ fill = '#10171B' }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.16675 14H6.70008C6.80975 14.0007 6.91847 13.9796 7.02 13.9382C7.12153 13.8967 7.21388 13.8356 7.29175 13.7584L13.0584 7.98336L15.4251 5.6667C15.5032 5.58923 15.5652 5.49706 15.6075 5.39551C15.6498 5.29396 15.6716 5.18504 15.6716 5.07503C15.6716 4.96502 15.6498 4.8561 15.6075 4.75455C15.5652 4.653 15.5032 4.56083 15.4251 4.48336L11.8917 0.908364C11.8143 0.830257 11.7221 0.768262 11.6206 0.725954C11.519 0.683647 11.4101 0.661865 11.3001 0.661865C11.1901 0.661865 11.0812 0.683647 10.9796 0.725954C10.8781 0.768262 10.7859 0.830257 10.7084 0.908364L8.35841 3.2667L2.57508 9.0417C2.49785 9.11956 2.43674 9.21191 2.39527 9.31344C2.3538 9.41497 2.33278 9.52369 2.33341 9.63336V13.1667C2.33341 13.3877 2.42121 13.5997 2.57749 13.756C2.73377 13.9122 2.94573 14 3.16675 14ZM11.3001 2.67503L13.6584 5.03336L12.4751 6.2167L10.1167 3.85836L11.3001 2.67503ZM4.00008 9.97503L8.94175 5.03336L11.3001 7.3917L6.35842 12.3334H4.00008V9.97503ZM16.5001 15.6667H1.50008C1.27907 15.6667 1.06711 15.7545 0.910826 15.9108C0.754545 16.0671 0.666748 16.279 0.666748 16.5C0.666748 16.721 0.754545 16.933 0.910826 17.0893C1.06711 17.2456 1.27907 17.3334 1.50008 17.3334H16.5001C16.7211 17.3334 16.9331 17.2456 17.0893 17.0893C17.2456 16.933 17.3334 16.721 17.3334 16.5C17.3334 16.279 17.2456 16.0671 17.0893 15.9108C16.9331 15.7545 16.7211 15.6667 16.5001 15.6667Z"
      fill={fill}
    />
  </svg>
);

export default EditIcon;
