import React from 'react';
import { Box } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { DetailsCard } from '../../Common/DetailsCard';
import { BusinessCustomerDetail } from '../../../entities/Customer';
import { DetailRow } from '../../Common/DetailRow';
import { DATE_TIME_WITH_SECONDS } from '../../../utils/datetime-utils';
import { CustomerStatus } from '../../../utils/customer-utils';
import { DetailContent } from '../../Common/DetailContent';
import { NavigationButton } from '../../Common/NavigationButton';
import { CUSTOMERS_BUSINESS } from '../../../utils/routes-constants';
import { formatMoney } from '../../../utils';

function BusinessCustomerDetails({
  customer,
}: {
  customer: BusinessCustomerDetail;
}) {
  const { t } = useTranslation();
  return (
    <Box height="100%">
      <DetailsCard>
        <DetailRow
          key="companyName"
          title={t('customers.companyName')}
          content={customer.companyName}
        />
        <DetailRow
          key="registrationNumber"
          title={t('customers.registrationNumber')}
          content={customer.registrationNumber}
        />
        <DetailRow
          key="kmkrNumber"
          title={t('customers.kmkrNumber')}
          content={customer.vatNumber}
        />
        <DetailRow
          key="address"
          title={t('customers.address')}
          content={customer.address}
        />
        <DetailRow
          key="representativeName"
          title={t('customers.representativeName')}
          content={customer.representative?.name}
        />
        <DetailRow
          key="phoneNumber"
          title={t('customers.phoneNumber')}
          content={`+${customer.representative?.areaCode} ${customer.representative?.phoneNumber}`}
        />
        <DetailRow
          key="email"
          title={t('customers.email')}
          content={customer.email}
        />
        <DetailRow
          key="registrationDate"
          title={t('customers.registrationDate')}
          content={moment(customer.created).format(DATE_TIME_WITH_SECONDS)}
        />
        <DetailRow
          key="contractNumber"
          title={t('customers.contractNumber')}
          content={customer.contractNumber}
        />
        <DetailRow
          key="accountStatus"
          title={t('customers.accountStatus')}
          content={
            customer.status?.type === CustomerStatus.BLOCKED ? (
              <DetailContent
                text={t(`customers.customerStatuses.${customer.status?.type}`)}
                style={{ color: '#D4002A' }}
              />
            ) : (
              t(`customers.customerStatuses.${customer.status?.type}`)
            )
          }
        />
        <DetailRow
          key="emailMarketing"
          title={t('customers.emailSubscription')}
          content={customer.emailMarketing ? t('yes') : t('no')}
        />
        <DetailRow
          key="directoId"
          title={t('customers.directoId')}
          content={customer.directoId}
          showDivider={false}
        />
      </DetailsCard>

      <DetailsCard>
        <DetailRow
          key="savedCards"
          title={t('customers.savedCards')}
          content={null} //TODO
        />
        <DetailRow
          key="walletBalance"
          title={t('customers.walletBalance')}
          content={customer.wallet.balance}
        />
        <DetailRow
          key="invoice"
          title={t('customers.invoice')}
          content={customer.monthlyInvoice ? t('yes') : t('no')}
        />
        <DetailRow
          key="verified"
          title={t('customers.verified')}
          content={customer.verified ? t('yes') : t('no')}
        />
        <DetailRow
          key="monthlyLimit"
          title={t('customers.monthlyLimit')}
          content={formatMoney(customer.monthlyLimit)}
        />
        <DetailRow
          key="freeLimit"
          title={t('customers.freeLimit')}
          content={null} //TODO
        />
        <DetailRow //TODO
          key="consolidatedInvoices"
          title={t('customers.consolidatedInvoices')}
          content={
            <NavigationButton
              to={CUSTOMERS_BUSINESS}
              text={t('view')}
              style={{ height: '20px', left: '-8px' }}
            />
          }
          showDivider={false}
        />
      </DetailsCard>
    </Box>
  );
}

export default BusinessCustomerDetails;
