import { Navigate, Route, Routes } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import LoginPage from '../Login/Login';
import GuardedRoute from '../../GuardedRoute';
import VehiclesContainer from '../Vehicles/VehiclesList/VehiclesContainer';
import VehicleDetailsContainer from '../Vehicles/VehicleDetails/VehicleDetailsContainer';
import CustomersContainer from '../Customers/CustomersList/CustomersContainer';
import ReservationsPage from '../Reservations/Reservations';
import InvoicesPage from '../Invoices/Invoices';
import SettingsPage from '../Settings/Settings';
import { useAppSelector } from '../../store';
import {
  BONUS_CODE_EDIT,
  BONUS_CODE_NEW,
  BUSINESS_CUSTOMER_DETAILS_PARENT,
  BUSINESS_CUSTOMER_EDIT,
  CUSTOMER_DETAILS_PARENT,
  CUSTOMER_EDIT,
  CUSTOMERS,
  HOME,
  INVOICE_DETAILS,
  INVOICES_PARENT,
  LOGIN,
  PRICE_CLASS_EDIT,
  PRICE_CLASS_NEW,
  RESERVATION_ADD,
  RESERVATION_DETAILS,
  RESERVATION_EDIT,
  RESERVATIONS,
  RESERVATIONS_CALENDAR,
  RESERVATIONS_PARENT,
  ROOT,
  SETTINGS_PARENT,
  VEHICLE_DETAILS,
  VEHICLE_EDIT,
  VEHICLE_NEW,
  VEHICLES,
  ZONE_EDIT,
  ZONE_NEW,
} from '../../utils/routes-constants';
import AddEditVehiclePage from '../Vehicles/AddEditVehicle';
import { CustomerListView } from '../../utils/customer-utils';
import CustomerDetailsContainer from '../Customers/CustomerDetails/CustomerDetailsContainer';
import { PrivateCustomersTable } from '../Customers/CustomersList/PrivateCustomersTable';
import { BusinessCustomersTable } from '../Customers/CustomersList/BusinessCustomersTable';
import { AddEditParkingZone } from '../Settings/ParkingZones/AddEditParkingZone';
import ReservationDetailsContainer from '../Reservations/ReservationDetails/ReservationDetailsContainer';
import { EditPrivateCustomer } from '../Customers/EditCustomer/EditPrivateCustomer';
import AddEditPriceClass from '../Settings/PriceClasses/AddEditPriceClass';
import AddEditBonusCode from '../Settings/BonusCodes/AddEditBonusCode';
import AddMaintenanceReservation from '../Reservations/AddReservation/AddMaintenanceReservation';
import EditReservationContainer from '../Reservations/EditReservation/EditReservationContainer';
import InvoiceDetails from '../Invoices/InvoiceDetails';
import { EditBusinessCustomer } from '../Customers/EditCustomer/EditBusinessCustomer';
import AvisHelmet from '../Common/AvisHelmet';
import ReservationsContainer from '../Reservations/ReservationsContainer';
import ReservationsCalendar from '../Reservations/ReservationsCalendar';

const AvisRoutes = () => {
  const { t } = useTranslation();
  const isAuthenticated = useAppSelector(
    state => !!state.userReducer.authToken,
  );

  return (
    <>
      <AvisHelmet title={t('head.title.backoffice')} />
      <Routes>
        {/* Unguarded Routes */}
        <Route
          path={ROOT}
          element={<Navigate to={isAuthenticated ? HOME : LOGIN} />}
        />
        <Route path={LOGIN} element={<LoginPage />} />
        {/* Authenticated Routes */}
        <Route element={<GuardedRoute isRouteAccessible={isAuthenticated} />}>
          <Route path={HOME} element={<Navigate to={VEHICLES} />} />
          <Route path={VEHICLES} element={<VehiclesContainer />} />
          <Route path={VEHICLE_NEW} element={<AddEditVehiclePage />} />
          <Route path={VEHICLE_EDIT} element={<AddEditVehiclePage />} />
          <Route path={VEHICLE_DETAILS} element={<VehicleDetailsContainer />} />
          <Route path={CUSTOMERS} element={<CustomersContainer />}>
            <Route index element={<PrivateCustomersTable />} />
            <Route
              path={CustomerListView.BUSINESS}
              element={<BusinessCustomersTable />}
            />
          </Route>
          <Route
            path={CUSTOMER_DETAILS_PARENT}
            element={<CustomerDetailsContainer />}
          />
          <Route path={CUSTOMER_EDIT} element={<EditPrivateCustomer />} />
          <Route
            path={BUSINESS_CUSTOMER_EDIT}
            element={<EditBusinessCustomer />}
          />
          <Route
            path={BUSINESS_CUSTOMER_DETAILS_PARENT}
            element={<CustomerDetailsContainer isBusinessCustomer={true} />}
          />

          <Route path={RESERVATIONS} element={<ReservationsContainer />}>
            <Route index element={<ReservationsPage />} />
            <Route
              path={RESERVATIONS_CALENDAR}
              element={<ReservationsCalendar />}
            />
          </Route>
          <Route path={RESERVATIONS_PARENT} element={<ReservationsPage />} />
          <Route
            path={RESERVATION_DETAILS}
            element={<ReservationDetailsContainer />}
          />
          <Route
            path={RESERVATION_ADD}
            element={<AddMaintenanceReservation />}
          />
          <Route
            path={RESERVATION_EDIT}
            element={<EditReservationContainer />}
          />
          <Route path={INVOICES_PARENT} element={<InvoicesPage />} />
          <Route path={INVOICE_DETAILS} element={<InvoiceDetails />} />
          <Route path={ZONE_NEW} element={<AddEditParkingZone />} />
          <Route path={ZONE_EDIT} element={<AddEditParkingZone />} />
          <Route path={SETTINGS_PARENT} element={<SettingsPage />} />
          <Route path={PRICE_CLASS_NEW} element={<AddEditPriceClass />} />
          <Route path={PRICE_CLASS_EDIT} element={<AddEditPriceClass />} />
          <Route path={BONUS_CODE_NEW} element={<AddEditBonusCode />} />
          <Route path={BONUS_CODE_EDIT} element={<AddEditBonusCode />} />
        </Route>
        {/* Not found Route */}
        <Route path="*" element={<Navigate to={ROOT} />} />
      </Routes>
    </>
  );
};

export default AvisRoutes;
