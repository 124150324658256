import React from 'react';

const InvoicesIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 12 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.75 6.5H4.5C4.69891 6.5 4.88968 6.42098 5.03033 6.28033C5.17098 6.13968 5.25 5.94891 5.25 5.75C5.25 5.55109 5.17098 5.36032 5.03033 5.21967C4.88968 5.07902 4.69891 5 4.5 5H3.75C3.55109 5 3.36032 5.07902 3.21967 5.21967C3.07902 5.36032 3 5.55109 3 5.75C3 5.94891 3.07902 6.13968 3.21967 6.28033C3.36032 6.42098 3.55109 6.5 3.75 6.5ZM3.75 8C3.55109 8 3.36032 8.07902 3.21967 8.21967C3.07902 8.36032 3 8.55109 3 8.75C3 8.94891 3.07902 9.13968 3.21967 9.28033C3.36032 9.42098 3.55109 9.5 3.75 9.5H8.25C8.44891 9.5 8.63968 9.42098 8.78033 9.28033C8.92098 9.13968 9 8.94891 9 8.75C9 8.55109 8.92098 8.36032 8.78033 8.21967C8.63968 8.07902 8.44891 8 8.25 8H3.75ZM12 5.705C11.9922 5.6361 11.9771 5.56822 11.955 5.5025V5.435C11.9189 5.35788 11.8708 5.287 11.8125 5.225L7.3125 0.725C7.2505 0.666662 7.17962 0.618561 7.1025 0.5825C7.08011 0.57932 7.05739 0.57932 7.035 0.5825C6.95881 0.538806 6.87467 0.510758 6.7875 0.5H2.25C1.65326 0.5 1.08097 0.737053 0.65901 1.15901C0.237053 1.58097 0 2.15326 0 2.75V13.25C0 13.8467 0.237053 14.419 0.65901 14.841C1.08097 15.2629 1.65326 15.5 2.25 15.5H9.75C10.3467 15.5 10.919 15.2629 11.341 14.841C11.7629 14.419 12 13.8467 12 13.25V5.75C12 5.75 12 5.75 12 5.705ZM7.5 3.0575L9.4425 5H8.25C8.05109 5 7.86032 4.92098 7.71967 4.78033C7.57902 4.63968 7.5 4.44891 7.5 4.25V3.0575ZM10.5 13.25C10.5 13.4489 10.421 13.6397 10.2803 13.7803C10.1397 13.921 9.94891 14 9.75 14H2.25C2.05109 14 1.86032 13.921 1.71967 13.7803C1.57902 13.6397 1.5 13.4489 1.5 13.25V2.75C1.5 2.55109 1.57902 2.36032 1.71967 2.21967C1.86032 2.07902 2.05109 2 2.25 2H6V4.25C6 4.84674 6.23705 5.41903 6.65901 5.84099C7.08097 6.26295 7.65326 6.5 8.25 6.5H10.5V13.25ZM8.25 11H3.75C3.55109 11 3.36032 11.079 3.21967 11.2197C3.07902 11.3603 3 11.5511 3 11.75C3 11.9489 3.07902 12.1397 3.21967 12.2803C3.36032 12.421 3.55109 12.5 3.75 12.5H8.25C8.44891 12.5 8.63968 12.421 8.78033 12.2803C8.92098 12.1397 9 11.9489 9 11.75C9 11.5511 8.92098 11.3603 8.78033 11.2197C8.63968 11.079 8.44891 11 8.25 11Z"
      fill="#33302F"
    />
  </svg>
);

export default InvoicesIcon;
