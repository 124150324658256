import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabHeader } from '../../Common/TabHeader';
import { DetailsCard } from '../../Common/DetailsCard';
import { DetailRow } from '../../Common/DetailRow';
import { SystemSetting, systemSettings } from './systemSettingsData';

const SystemSettings = () => {
  const { t } = useTranslation();

  return (
    <>
      <TabHeader text={t('settings.tabs.settings')} />
      <DetailsCard
        content={systemSettings.map(
          ({ id, value }: SystemSetting, index: number) => (
            <DetailRow
              key={id}
              title={t(`settings.system.parameters.${id}`)}
              content={value}
              titleWidth={10}
              contentWidth={2}
              showDivider={systemSettings.length - 1 !== index}
            />
          ),
        )}
      />
    </>
  );
};

export default SystemSettings;
