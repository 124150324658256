import * as Yup from 'yup';
import i18next, { TFunction } from 'i18next';
import baseApi from '../../../services/apiService';
import { phoneNumberValidator } from '../../../utils/yup';

export const apiPaths = {
  code: '/auth/business-user/code' as const,
  token: '/auth/business-user/token' as const,
  trade: '/auth/business-user/trade' as const,
};

export const getCodeSchema = (t: TFunction) =>
  Yup.object({
    areaCode: Yup.string().required(),
    phoneNumber: phoneNumberValidator(
      t,
      t('b2b.login.errors.phoneNumberRequired'),
    ),
  });
const _codeSchema = getCodeSchema(i18next.t);
export type CodeRequest = Yup.InferType<typeof _codeSchema>;
export type CodeResponse = {
  token: string;
  refreshToken: string;
};

export const tokenSchema = Yup.object({
  code: Yup.string().required(),
});
export type TokenRequest = Yup.InferType<typeof tokenSchema>;
export type TokenResponse = {
  token: string;
  isNewUser: boolean;
};

export type TradeResponse = {
  token: string;
  isNewUser: boolean;
};

export const b2bAuthApi = {
  b2bGetCode: (body: CodeRequest) =>
    baseApi.post<CodeResponse>(apiPaths.code, body),
  b2bSendCode: (body: TokenRequest) =>
    baseApi.post<TokenResponse>(apiPaths.token, body),
  b2bTradeToken: () => baseApi.get<TradeResponse>(apiPaths.trade),
};
