import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { OverridableStringUnion } from '@mui/types';
import { Variant } from '@mui/material/styles/createTypography';
import { TypographyPropsVariantOverrides } from '@mui/material/Typography/Typography';
import { useTranslation } from 'react-i18next';
import { SearchOff } from '@mui/icons-material';
import AddIcon from '../../icons/AddIcon';

interface Props {
  icon?: JSX.Element;
  title?: string;
  titleVariant?: OverridableStringUnion<
    Variant | 'inherit',
    TypographyPropsVariantOverrides
  >;
  buttonTitle?: string | undefined;
  onClick?: () => void;
}

export function NoDataPlaceholder({
  icon = <SearchOff />,
  title,
  titleVariant = 'h5',
  buttonTitle = undefined,
  onClick,
}: Props) {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        height: 'auto',
        border: '1px solid #E1DED9',
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '16px',
          paddingTop: '24px',
          paddingBottom: '30px',
        }}
      >
        {icon}
        <Typography variant={titleVariant} sx={{ mb: 1 }}>
          {title ?? t('noData.title')}
        </Typography>
        {buttonTitle && (
          <Button
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={<AddIcon />}
            onClick={onClick}
          >
            {buttonTitle}
          </Button>
        )}
      </Box>
    </Box>
  );
}
