import React, { useState } from 'react';
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Navigate } from 'react-router-dom';
import { ArrowForward, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../store';
import { signIn } from '../../store/user/thunk';
import LoadingButton from '../Common/LoadingButton';
import AvisLogo from '../../icons/AvisLogo';

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(
    state => !!state.userReducer.authToken,
  );
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState(false);
  const [isErroneous, setIsErroneous] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  if (isAuthenticated) {
    return <Navigate to="/home" />;
  }

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await dispatch(signIn({ username, password })).unwrap();
    } catch (e) {
      setIsErroneous(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        width: '100%',
      }}
    >
      <form onSubmit={onSubmit}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              alignSelf: 'center',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 4,
            }}
          >
            <AvisLogo />
            {/* eslint-disable-next-line @docusaurus/no-untranslated-text */}
            <Typography
              sx={{ mt: 1, textTransform: 'uppercase' }}
              variant="subtitle2"
            >
              Backoffice
            </Typography>
          </Box>
          <Typography variant="h3" alignSelf="center">
            {t('login.title')}
          </Typography>
          {isErroneous && (
            <Alert severity="error" variant="outlined" sx={{ mt: 1 }}>
              {t('login.error')}
            </Alert>
          )}
          <Box sx={{ mt: 1 }}>
            <TextField
              required
              size="medium"
              fullWidth
              placeholder={t('login.placeholders.username')}
              value={username}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUsername(event.target.value);
              }}
            ></TextField>
          </Box>
          <Box sx={{ mt: 1 }}>
            <TextField
              required
              fullWidth
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      edge="end"
                      onClick={() => setShowPassword(show => !show)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              placeholder={t('login.placeholders.password')}
              value={password}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPassword(event.target.value);
              }}
            ></TextField>
          </Box>
          <LoadingButton
            sx={{ mt: 4 }}
            endIcon={<ArrowForward />}
            loading={isLoading}
            disabled={!username || !password}
            onClick={e => {
              e.preventDefault();
              onSubmit();
            }}
          >
            {t('login.button')}
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
};

export default LoginPage;
