import * as Yup from 'yup';

const emailValidator = (value: string) =>
  /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/.test(value);
export const privateCustomerValidationSchema = Yup.object({
  name: Yup.string().required(),
  email: Yup.string().required().test(emailValidator),
  emailMarketing: Yup.boolean().required(),
});

export type EditPrivateCustomerRequest = Yup.InferType<
  typeof privateCustomerValidationSchema
>;
