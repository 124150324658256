import { Invoice } from '../../entities/Invoice';
import { PaginatedItems } from '../../store/api';

export interface InvoiceDetailsData {
  customerId: JSX.Element;
  companyName: string;
  invoiceDate: string;
  dueDate: string;
  reservations: JSX.Element;
  sumWithoutVAT: string;
  sumWithVAT: string;
  status: string;
}

export type InvoiceDetailsDataField = keyof InvoiceDetailsData;

export const invoiceDetailsFields: InvoiceDetailsDataField[] = [
  'customerId',
  'companyName',
  'invoiceDate',
  'dueDate',
  'reservations',
  'sumWithoutVAT',
  'sumWithVAT',
  'status',
];

export const mockedInvoicesData: PaginatedItems<Invoice> = {
  items: [
    {
      id: '1',
      date: '2023-01-01',
      receiver: 'SKO Motors Laagri',
      invoiceNumber: '12353332',
      amount: '430',
      status: 'Not paid',
      customerId: '123445',
      companyName: 'Test Ettevote OU',
      invoiceDate: '2023-01-01',
      dueDate: '2023-01-15',
      reservations: '3',
      sumWithoutVAT: '400',
      sumWithVAT: '420',
    },
    {
      id: '2',
      date: '2023-01-01',
      receiver: 'SKO Motors Laagri',
      invoiceNumber: '12353332',
      amount: '430',
      status: 'Paid',
      customerId: '123445',
      companyName: 'Test Ettevote OU',
      invoiceDate: '2023-01-01',
      dueDate: '2023-01-15',
      reservations: '3',
      sumWithoutVAT: '400',
      sumWithVAT: '420',
    },
    {
      id: '3',
      date: '2023-01-01',
      receiver: 'SKO Motors Laagri',
      invoiceNumber: '12353332',
      amount: '430',
      status: 'Not paid',
      customerId: '123445',
      companyName: 'Test Ettevote OU',
      invoiceDate: '2023-01-01',
      dueDate: '2023-01-15',
      reservations: '3',
      sumWithoutVAT: '400',
      sumWithVAT: '420',
    },
  ],
  pagination: {
    currentPage: 1,
    pageLimit: 5,
    totalItems: 3,
    totalPages: 1,
  },
};
