import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { SecondaryButton } from '../../../components/Common/SecondaryButton';
import { TabHeader } from '../../../components/Common/TabHeader';
import AddAuthorizedPerson from './AddAuthorizedPerson';
import AuthorizedPersons from './AuthorizedPersons';

function ManageAuthorizedPersons() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box sx={{ flex: 1 }}>
      <TabHeader
        headerVariant="h3"
        text={t('b2b.myCompany.authorizedPersons.manage')}
      >
        <SecondaryButton startIcon={<Close />} onClick={() => navigate(-1)}>
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <AddAuthorizedPerson />
      <AuthorizedPersons readOnly={false} sx={{ mt: 4, maxWidth: '800px' }} />
    </Box>
  );
}

export default ManageAuthorizedPersons;
