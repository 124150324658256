import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button,
  FormLabel,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import moment from 'moment';
import { Form, useFormikContext } from 'formik';
import { useParams } from 'react-router-dom';
import EditIcon from '../../../icons/EditIcon';
import DateTimePickerField from '../../Common/FormikFields/DateTimePickerField';
import TextField from '../../Common/TextField';
import { useGetReservation } from '../api';
import { useVehicleAvailability } from '../hooks';
import ReservedPeriodsAlert from '../ReservedPeriodsAlert';
import { EditMaintenanceReservationState } from './editMaintenanceReservationData';

const EditMaintenanceReservationForm = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { values, isValid } =
    useFormikContext<EditMaintenanceReservationState>();
  const { startDateTime, endDateTime } = values;

  const { data: reservationDetails } = useGetReservation(reservationId);
  const reservation = reservationDetails?.details;

  const { reservedPeriods, isAvailabilityConflict } = useVehicleAvailability(
    reservation?.vehicle?.registrationNumber,
    startDateTime,
    endDateTime,
    reservation && {
      startDateTime: reservation.startDateTime,
      endDateTime: reservation.endDateTime,
    },
  );

  const statusType = reservation?.status.type;
  const isEditable = statusType === 'active' || statusType === 'upcoming';

  return (
    <Form>
      <Grid
        container
        direction="column"
        sx={{ maxWidth: isDownMd ? '100%' : '50%', marginBottom: 4 }}
        rowGap={2}
      >
        <Grid item>
          <Grid item xs={12}>
            <FormLabel>{t('reservations.car')}</FormLabel>
            <TextField
              InputProps={{
                readOnly: true,
                disabled: true,
              }}
              value={reservation?.vehicle?.registrationNumber}
            />
          </Grid>
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <DateTimePickerField
              name="startDateTime"
              label={t('reservations.startDate')}
              placeholder={t('reservations.form.placeholders.startDate')}
              disabled={statusType !== 'upcoming'}
              disablePast
              maxDateTime={moment(endDateTime)}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePickerField
              name="endDateTime"
              label={t('reservations.endDate')}
              placeholder={t('reservations.form.placeholders.endDate')}
              disabled={!isEditable}
              minDateTime={moment(startDateTime)}
            />
          </Grid>
          {reservedPeriods.length ? (
            <Grid item xs={12}>
              <ReservedPeriodsAlert
                reservedPeriods={reservedPeriods}
                isConflict={isAvailabilityConflict}
              />
            </Grid>
          ) : null}
          <Grid item>
            <Button
              sx={{ mt: 2 }}
              type="submit"
              variant="contained"
              size="medium"
              color="secondary"
              startIcon={<EditIcon fill="white" />}
              disabled={!isValid || isAvailabilityConflict || !isEditable}
            >
              {t('reservations.buttons.editMaintenanceReservation')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditMaintenanceReservationForm;
