import React, { useMemo, useState } from 'react';
import { Form, useFormikContext } from 'formik';
import {
  Autocomplete,
  Button,
  debounce,
  FormLabel,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import moment from 'moment';
import { useGetVehicles } from '../../../store/vehicle/queries';
import { VehicleListItem } from '../../../entities/Vehicle';
import TextField from '../../Common/TextField';
import DateTimePickerField from '../../Common/FormikFields/DateTimePickerField';
import { useVehicleAvailability } from '../hooks';
import ReservedPeriodsAlert from '../ReservedPeriodsAlert';
import { AddMaintenanceReservationState } from './addMaintenanceReservationData';

const AddMaintenanceReservationForm = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const { values, isValid, dirty, errors, setFieldValue } =
    useFormikContext<AddMaintenanceReservationState>();
  const { startDateTime, endDateTime } = values;
  const [vehicleSearch, setVehicleSearch] = useState('');
  const { data: vehicles } = useGetVehicles({ search: vehicleSearch });
  const registrationNumber =
    vehicles?.items.find(it => it.id === values.vehicleId)
      ?.registrationNumber ?? '';

  const { reservedPeriods, isAvailabilityConflict } = useVehicleAvailability(
    registrationNumber,
    startDateTime,
    endDateTime,
  );

  const handleVehicleSearch = useMemo(
    () =>
      debounce((_e: React.SyntheticEvent, newValue: string) => {
        setVehicleSearch(newValue != null ? newValue : '');
      }, 300),
    [],
  );

  const vehicleItems = vehicles
    ? vehicles.items.map(({ id, registrationNumber }: VehicleListItem) => ({
        value: id,
        label: registrationNumber,
      }))
    : [];

  return (
    <Form>
      <Grid
        container
        direction="column"
        sx={{ maxWidth: isDownMd ? '100%' : '50%', marginBottom: 4 }}
        rowGap={2}
      >
        <Grid item>
          <Autocomplete
            options={vehicleItems}
            onInputChange={handleVehicleSearch}
            isOptionEqualToValue={(opt, val) => opt.value === val.value}
            onChange={(_e, value) => setFieldValue('vehicleId', value?.value)}
            renderInput={params => (
              <>
                <FormLabel htmlFor="vehicleId">
                  {t('reservations.car')}
                </FormLabel>
                <TextField
                  name="vehicleId"
                  error={!!errors['vehicleId']}
                  {...params}
                />
              </>
            )}
          />
        </Grid>
        <Grid item container columnSpacing={2}>
          <Grid item xs={6}>
            <DateTimePickerField
              name="startDateTime"
              label={t('reservations.startTime')}
              placeholder={t('reservations.form.placeholders.startTime')}
              disablePast
              maxDateTime={moment(endDateTime)}
            />
          </Grid>
          <Grid item xs={6}>
            <DateTimePickerField
              name="endDateTime"
              label={t('reservations.endTime')}
              placeholder={t('reservations.form.placeholders.endTime')}
              minDateTime={moment(startDateTime)}
            />
          </Grid>
          {reservedPeriods.length ? (
            <Grid item xs={12}>
              <ReservedPeriodsAlert
                reservedPeriods={reservedPeriods}
                isConflict={isAvailabilityConflict}
              />
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Button
              sx={{ mt: 2 }}
              type="submit"
              variant="contained"
              size="medium"
              color="secondary"
              startIcon={<Add />}
              disabled={!dirty || !isValid || isAvailabilityConflict}
            >
              {t('reservations.buttons.addMaintenanceReservation')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
};

export default AddMaintenanceReservationForm;
