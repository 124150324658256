import React from 'react';
import { useTranslation } from 'react-i18next';
import { Check } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { TFunction } from 'i18next';
import moment from 'moment';
import { SecondaryButton } from '../Common/SecondaryButton';
import { DetailsCard } from '../Common/DetailsCard';
import { MainHeader } from '../Common/MainHeader';
import { INVOICES } from '../../utils/routes-constants';
import ExportIcon from '../../icons/ExportIcon';
import { DetailRow } from '../Common/DetailRow';
import { Invoice } from '../../entities/Invoice';
import LoadingIcon from '../Common/LoadingIcon';
import { DATE_ONLY } from '../../utils/datetime-utils';
import {
  InvoiceDetailsData,
  InvoiceDetailsDataField,
  invoiceDetailsFields,
  mockedInvoicesData,
} from './invoicesTableData';

const getInvoiceDetailsData = (
  t: TFunction,
  invoice: Invoice,
): InvoiceDetailsData => {
  const {
    customerId,
    companyName,
    invoiceDate,
    dueDate,
    reservations,
    sumWithoutVAT,
    sumWithVAT,
    status,
  } = invoice;

  return {
    customerId: (
      <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
        {customerId}
      </Typography>
    ),
    companyName,
    invoiceDate: moment.utc(invoiceDate).format(DATE_ONLY),
    dueDate: moment.utc(dueDate).format(DATE_ONLY),
    reservations: (
      <Typography sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
        {reservations}
      </Typography>
    ),
    sumWithoutVAT: sumWithoutVAT ? `${sumWithVAT} €` : '',
    sumWithVAT: sumWithVAT ? `${sumWithVAT} €` : '',
    status,
  };
};

const InvoiceDetails = () => {
  const { t } = useTranslation();
  const { invoiceId } = useParams();

  // TODO: To be replaced with react-query when API is ready
  const invoice = mockedInvoicesData.items.find(
    (item: Invoice) => item.id === invoiceId,
  );

  if (!invoice) {
    return <LoadingIcon />;
  }

  const invoiceDetailsData = getInvoiceDetailsData(t, invoice);

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
        <MainHeader
          text={t('invoices.invoice')}
          link={INVOICES}
          linkText={t('invoices.buttons.allInvoices')}
        />
        <Box display="flex" gap={2}>
          <SecondaryButton startIcon={<Check />}>
            {t('invoices.buttons.markAsPaid')}
          </SecondaryButton>
          <SecondaryButton startIcon={<ExportIcon />}>
            {t('invoices.buttons.downloadPdf')}
          </SecondaryButton>
        </Box>
      </Box>
      <DetailsCard
        content={invoiceDetailsFields.map(
          (field: InvoiceDetailsDataField, index: number) => (
            <DetailRow
              key={field}
              title={t(`invoices.${field}`)}
              content={invoiceDetailsData[field]}
              showDivider={invoiceDetailsFields.length - 1 !== index}
            />
          ),
        )}
      />
    </Box>
  );
};

export default InvoiceDetails;
