import { PaginatedRequestParams } from '../store/api';

export interface BonusCode {
  id: string;
  code: string;
  amount: string;
  quantity: string;
  validFrom: string | null;
  validTo: string | null;
  isNewCustomer: boolean;
  used: number;
}

export enum BonusCodeStatusType {
  ACTIVE = 'active',
  EXPIRED = 'expired',
  USED = 'used',
}

export interface GetBonusCodeParams extends PaginatedRequestParams {
  status?: string;
  archived?: boolean;
}

export interface BonusCodeCustomer {
  id: string;
  name: string;
}
