import React from 'react';
import { Box, Button, Grid, IconButton, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FieldArray, Form, Formik } from 'formik';
import ModalHeader from '../../Modal/ModalHeader';
import ModalFooter from '../../Modal/ModalFooter';
import {
  CreateReservationInvoiceRequest,
  useCreateReservationInvoice,
  useCreateReservationInvoiceSchema,
} from '../api';
import FormikCurrencyField from '../../Common/FormikFields/FormikCurrencyField';
import InputField from '../../Common/FormikFields/InputField';
import DeleteIcon from '../../../icons/DeleteIcon';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import { useModal } from '../../Modal/hooks';

type Props = {
  reservationId: string;
};

const initialValues: CreateReservationInvoiceRequest = {
  rows: [{ amount: 0, title: '' }],
};

function CreateReservationInvoiceModal({ reservationId }: Props) {
  const { t } = useTranslation();
  const snacks = useSnacks();
  const { close } = useModal();

  const createInvoice = useCreateReservationInvoice();
  const schema = useCreateReservationInvoiceSchema();

  function handleSubmit(body: CreateReservationInvoiceRequest) {
    if (!reservationId) return;
    createInvoice.mutate(
      { reservationId, body: schema.cast(body) },
      {
        onSuccess: () => {
          snacks.successSnack(
            t('reservations.modal.createInvoice.snacks.created'),
          );
          close();
        },
        onError: e => snacks.apiErrorSnack(e),
      },
    );
  }

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validationSchema={schema}
    >
      {({ values }) => (
        <Form>
          <FieldArray name="rows">
            {helpers => (
              <Paper
                sx={{
                  width: '100%',
                  maxWidth: 568,
                }}
              >
                <ModalHeader
                  title={t('reservations.modal.createInvoice.title')}
                />

                <Box
                  sx={{
                    overflow: 'auto',
                    maxHeight: '70vh',
                  }}
                >
                  <Grid container spacing={1} sx={{ p: 2 }}>
                    <Grid container item spacing={3}>
                      <Grid item xs={3}>
                        {t('reservations.modal.createInvoice.row.amount')}
                      </Grid>
                      <Grid item xs={3}>
                        {t('reservations.modal.createInvoice.row.title')}
                      </Grid>
                    </Grid>
                    {values.rows.map((row, idx) => (
                      <Grid
                        key={`invoice-row-${idx}`}
                        container
                        item
                        spacing={3}
                        alignItems="center"
                      >
                        <Grid item xs={3}>
                          <FormikCurrencyField
                            name={`rows[${idx}].amount`}
                            hideHelperText
                          />
                        </Grid>
                        <Grid item xs={7}>
                          <InputField
                            name={`rows[${idx}].title`}
                            hideHelperText
                          />
                        </Grid>
                        <Grid item xs={2}>
                          {values?.rows.length > 1 && (
                            <IconButton
                              type="button"
                              disabled={values?.rows.length === 1}
                              onClick={() => helpers.remove(idx)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Box>

                <ModalFooter>
                  <Button
                    type="button"
                    onClick={() => helpers.push({ amount: 0, title: '' })}
                  >
                    {t('reservations.modal.createInvoice.addRow')}
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    sx={{ pr: 3, pl: 3 }}
                    disabled={createInvoice.isPending}
                  >
                    {t('reservations.modal.createInvoice.create')}
                  </Button>
                </ModalFooter>
              </Paper>
            )}
          </FieldArray>
        </Form>
      )}
    </Formik>
  );
}

export default CreateReservationInvoiceModal;
