import { Box, Button, Grid, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { TabHeader } from '../../Common/TabHeader';
import { SecondaryButton } from '../../Common/SecondaryButton';
import EditIcon from '../../../icons/EditIcon';
import LoadingIcon from '../../Common/LoadingIcon';
import InputField from '../../Common/FormikFields/InputField';
import CheckboxField from '../../Common/FormikFields/CheckboxField';
import { CUSTOMER_DETAILS } from '../../../utils/routes-constants';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import { PrivateCustomerEditRequest } from '../../../store/api/customer';
import {
  useEditPrivateCustomer,
  useGetCustomer,
} from '../../../store/customer/queries';
import {
  EditPrivateCustomerRequest,
  privateCustomerValidationSchema,
} from './editCustomerData';
import PaymentCardList from './PaymentCardList';

export const EditPrivateCustomer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { customerId } = useParams();
  const snacks = useSnacks();

  const { data: customer, status: queryStatus } = useGetCustomer(
    'private',
    customerId || '',
  );

  const editPrivateCustomer = useEditPrivateCustomer();

  function onSubmit(values: EditPrivateCustomerRequest) {
    const { name, email, emailMarketing } = values;
    const body: PrivateCustomerEditRequest = {
      name,
      email,
      emailMarketing,
    };
    customerId &&
      editPrivateCustomer.mutate(
        {
          customerId,
          body,
        },
        {
          onSuccess: () => {
            snacks.successSnack(t('customers.snacks.customerEdited'));
            navigate(CUSTOMER_DETAILS.replace(':customerId', customerId));
          },
          onError: () =>
            snacks.errorSnack(t('customers.snacks.customerEditError')),
        },
      );
  }

  return (
    <Box sx={{ flex: 1 }}>
      <TabHeader headerVariant="h3" text={t('customers.editCustomer')}>
        <SecondaryButton
          startIcon={<Close />}
          onClick={() =>
            customerId &&
            navigate(CUSTOMER_DETAILS.replace(':customerId', customerId))
          }
        >
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      {queryStatus === 'pending' && <LoadingIcon></LoadingIcon>}
      {queryStatus === 'success' && customer && (
        <Formik
          initialValues={customer}
          onSubmit={onSubmit}
          validationSchema={privateCustomerValidationSchema}
        >
          <Form>
            <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
              {t('customers.general')}
            </Typography>
            <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
              <Grid container columnSpacing={3} item>
                <Grid item xs={6}>
                  <InputField
                    name="phoneNumber"
                    label={t('customers.phoneNumber')}
                    disabled
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputField name="name" label={t('customers.name')} />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3} item>
                <Grid item xs={6}>
                  <InputField name="email" label={t('customers.email')} />
                </Grid>
              </Grid>
            </Grid>
            <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
              <Grid container columnSpacing={3} item>
                <Grid item xs={6}>
                  <CheckboxField
                    name="emailMarketing"
                    label={t('customers.emailSubscription')}
                  />
                </Grid>
              </Grid>
            </Grid>

            <PaymentCardList customerId={customer?.id} customerType="private" />

            <Button
              type="submit"
              variant="contained"
              size="medium"
              color="secondary"
              sx={{ mt: 4 }}
              startIcon={<EditIcon fill="white" />}
            >
              {t('customers.buttons.edit')}
            </Button>
          </Form>
        </Formik>
      )}
    </Box>
  );
};
