import React, { useState } from 'react';
import { Box, Button, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import currency from 'currency.js';
import { useCollectReservationDebt } from '../api';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import ModalHeader from '../../Modal/ModalHeader';
import ModalFooter from '../../Modal/ModalFooter';
import { useModal } from '../../Modal/hooks';
import CurrencyField from '../../Common/CurrencyField';

type Props = {
  reservationId: string;
  debt: string;
};

function CollectReservationDebtModal({ reservationId, debt }: Props) {
  const { t } = useTranslation();
  const snacks = useSnacks();
  const { close } = useModal();
  const collectDebt = useCollectReservationDebt();
  const [amount, setAmount] = useState(debt);

  function handleCollect() {
    collectDebt.mutate(
      { reservationId, amount: currency(amount).intValue },
      {
        onSuccess: () => {
          snacks.successSnack(t('reservations.snacks.collected'));
          close();
        },
        onError: e => snacks.apiErrorSnack(e),
      },
    );
  }

  return (
    <Paper sx={{ width: '100%', maxWidth: 568 }}>
      <ModalHeader title={t('reservations.modal.collectDebt.title')} />

      <Box sx={{ padding: 4, textAlign: 'center' }}>
        <CurrencyField
          name="collect-debt"
          value={amount}
          onChange={e => setAmount(e.target.value)}
          disabled={collectDebt.isPending}
          InputProps={{
            inputProps: {
              isAllowed: (amount: string) => {
                return currency(amount).intValue <= currency(debt).intValue;
              },
            },
          }}
        />
      </Box>

      <ModalFooter>
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={handleCollect}
          disabled={collectDebt.isPending}
          sx={{ pr: 3, pl: 3 }}
        >
          {t('reservations.modal.collectDebt.collect')}
        </Button>
      </ModalFooter>
    </Paper>
  );
}

export default CollectReservationDebtModal;
