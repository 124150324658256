import React from 'react';
import { FormLabel } from '@mui/material';
import { DateTimePickerProps } from '@mui/x-date-pickers';
import { Field, FieldProps } from 'formik';
import { Moment } from 'moment';
import DateTimePicker from '../DateTimePicker';

interface Props extends DateTimePickerProps<Moment> {
  name: string;
  label: string;
  placeholder?: string;
  disabled?: boolean;
  disablePast?: boolean;
  minDate?: Moment | undefined;
  maxDate?: Moment | undefined;
}

const DateTimePickerField = ({
  name,
  label,
  placeholder,
  disabled,
  disablePast,
  minDate,
  maxDate,
  ...pickerProps
}: Props) => {
  return (
    <>
      <FormLabel>{label}</FormLabel>
      <Field name={name}>
        {({
          field,
          form: { setFieldValue, setFieldTouched },
          meta: { touched, error },
        }: FieldProps) => (
          <DateTimePicker
            {...field}
            {...pickerProps}
            onChange={async (value: string | null) => {
              await setFieldTouched(name);
              await setFieldValue(name, value, true);
            }}
            placeholder={placeholder}
            error={!!error && touched}
            helperText={error}
            disabled={disabled}
            disablePast={disablePast}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </Field>
    </>
  );
};

export default DateTimePickerField;
