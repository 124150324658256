import React from 'react';
import { List, ListItem, styled } from '@mui/material';
import {
  MaterialDesignContent,
  SnackbarMessage,
  SnackbarProvider,
  useSnackbar,
} from 'notistack';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { apiErrorSchema, getFieldErrors, RequestError } from '../../store';

interface Props {
  children: React.ReactNode;
}

export enum SnackType {
  SUCCESS = 'success',
  ERROR = 'error',
  INFO = 'info',
}
export const AvisSnackbarProvider: React.FC<Props> = ({ children }) => (
  <SnackbarProvider
    anchorOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    style={{
      backgroundColor: '#FFFFFF',
      textDecoration: 'none',
      textTransform: 'none',
      color: 'unset',
    }}
    Components={{
      success: SuccessSnack,
      error: ErrorSnack,
      info: InfoSnack,
    }}
  >
    {children}
  </SnackbarProvider>
);

const SuccessSnack = styled(MaterialDesignContent)(() => ({
  'svg:not(:root)': {
    color: '#00A399',
  },
}));

const ErrorSnack = styled(MaterialDesignContent)(() => ({
  'svg:not(:root)': {
    color: '#D4002A',
  },
}));

const InfoSnack = styled(MaterialDesignContent)(() => ({
  'svg:not(:root)': {
    color: '#33ACEE',
  },
}));

export function useSnacks() {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  function snack(variant: SnackType, message: SnackbarMessage) {
    enqueueSnackbar(message, { variant });
  }

  function successSnack(message: SnackbarMessage) {
    enqueueSnackbar(message, { variant: SnackType.SUCCESS });
  }

  function apiErrorSnack(
    err: unknown,
    fieldTranslations: Record<string, string> = {},
    defaultMessage: string = t('api.genericError'),
  ) {
    let requestError: RequestError | undefined = undefined;
    if (err instanceof AxiosError && err.response) {
      try {
        requestError = apiErrorSchema.validateSync(err.response.data);
      } catch (ve) {
        const { status, statusText } = err.response;
        requestError = {
          code: status,
          message: statusText,
        };
      }
    } else {
      requestError = apiErrorSchema.cast(err);
    }
    if (!requestError) {
      errorSnack(defaultMessage);
      return;
    }
    const fieldErrors = getFieldErrors(requestError, fieldTranslations);
    const message =
      fieldErrors.length > 0 ? (
        <List dense>
          {fieldErrors.map(err => (
            <ListItem key={err}>{err}</ListItem>
          ))}
        </List>
      ) : (
        requestError.message
      );
    return errorSnack(message);
  }

  function errorSnack(message: SnackbarMessage = t('api.genericError')) {
    enqueueSnackbar(message, {
      variant: SnackType.ERROR,
    });
  }

  return { apiErrorSnack, errorSnack, successSnack, snack };
}
