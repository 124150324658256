import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { SecondaryButton } from '../../../components/Common/SecondaryButton';
import LoadingIcon from '../../../components/Common/LoadingIcon';
import { TabHeader } from '../../../components/Common/TabHeader';
import EditIcon from '../../../icons/EditIcon';
import InputField from '../../../components/Common/FormikFields/InputField';
import { fieldOf } from '../../../store/api';
import SelectField from '../../../components/Common/FormikFields/SelectField';
import TextField from '../../../components/Common/TextField';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import {
  EditMyCompanyRequest,
  editMyCompanyRequestSchema,
  useEditMyCompany,
  useGetAuthorizedPersons,
  useGetMyCompany,
} from './api';

const EditMyCompanyPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { successSnack, apiErrorSnack } = useSnacks();

  const { data: company } = useGetMyCompany();
  const editMyCompany = useEditMyCompany();

  const { data: authorizedPersons } = useGetAuthorizedPersons({});

  const representativeSelectItems = useMemo(() => {
    return (
      authorizedPersons?.items
        ?.filter(p => p.id)
        ?.map(p => ({
          value: p.id as string,
          label: p.name as string,
          phoneNumber: p.phoneNumber,
        })) ?? []
    );
  }, [authorizedPersons]);

  if (!company) return <LoadingIcon />;

  const onSubmit = (values: EditMyCompanyRequest) => {
    editMyCompany.mutate(values, {
      onSuccess: () => {
        successSnack(t('b2b.myCompany.edit.snacks.edited'));
        navigate('/my-company');
      },
      onError: e => apiErrorSnack(e),
    });
  };

  const { vatNumber, address, email, emailMarketing } = company;

  return (
    <Box sx={{ flex: 1 }}>
      <TabHeader headerVariant="h3" text={t('b2b.myCompany.edit.title')}>
        <SecondaryButton startIcon={<Close />} onClick={() => navigate(-1)}>
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <Formik
        initialValues={{
          vatNumber,
          address,
          email,
          emailMarketing,
          representativeId: company.representative.id,
        }}
        onSubmit={onSubmit}
        validateOnMount={false}
        validationSchema={editMyCompanyRequestSchema}
      >
        {({ values: { representativeId } }) => (
          <Form>
            <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
              <Grid container columnSpacing={3}>
                <Grid item sm={6} xs={12} mt={2}>
                  <FormLabel>{t('b2b.myCompany.companyName')}</FormLabel>
                  <TextField
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                    }}
                    value={company.companyName}
                  />
                </Grid>
                <Grid item sm={6} xs={12} mt={2}>
                  <FormLabel>{t('b2b.myCompany.registrationNumber')}</FormLabel>
                  <TextField
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                    }}
                    value={company.registrationNumber}
                  />
                </Grid>
                <Grid item sm={6} xs={12} mt={2}>
                  <InputField
                    name={fieldOf<EditMyCompanyRequest>('vatNumber')}
                    label={t('b2b.myCompany.kmkrNumber')}
                  />
                </Grid>
                <Grid item sm={6} xs={12} mt={2}>
                  <InputField
                    name={fieldOf<EditMyCompanyRequest>('address')}
                    label={t('b2b.myCompany.address')}
                    helperText={t('b2b.myCompany.addressHelperText')}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3}>
                <Grid item sm={6} xs={12} mt={2}>
                  <InputField
                    name={fieldOf<EditMyCompanyRequest>('email')}
                    label={t('b2b.myCompany.email')}
                  />
                </Grid>
              </Grid>
              <Grid container columnSpacing={3}>
                <Grid item sm={6} xs={12} mt={2}>
                  <SelectField
                    fullWidth
                    name={fieldOf<EditMyCompanyRequest>('representativeId')}
                    items={representativeSelectItems}
                    label={t('b2b.myCompany.representativeName')}
                  />
                </Grid>
                <Grid item sm={6} xs={12} mt={2}>
                  <FormLabel>{t('b2b.myCompany.phoneNumber')}</FormLabel>
                  <TextField
                    InputProps={{
                      readOnly: true,
                      disabled: true,
                    }}
                    value={
                      authorizedPersons?.items?.find(
                        it => it.id === representativeId,
                      )?.phoneNumber
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
              <Grid container columnSpacing={3}>
                <Grid item xs={12}>
                  <FormControlLabel
                    name={fieldOf<EditMyCompanyRequest>('emailMarketing')}
                    control={<Checkbox />}
                    label={t('b2b.myCompany.emailSubscription')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    name={fieldOf<EditMyCompanyRequest>('acceptTerms')}
                    control={<Checkbox />}
                    label={t('b2b.myCompany.acceptTerms')}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              variant="contained"
              size="medium"
              type="submit"
              color="secondary"
              startIcon={<EditIcon fill="white" />}
            >
              {t('b2b.myCompany.buttons.edit')}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditMyCompanyPage;
