import * as Yup from 'yup';
import { TFunction } from 'i18next';
import moment from 'moment';
import { DATE_ONLY } from '../../../utils/datetime-utils';

export interface BonusCodeState {
  code: string;
  amount: string;
  quantity: string;
  validFrom: string;
  validTo: string;
  isNewCustomer: boolean;
}

export const bonusCodeInitialState: BonusCodeState = {
  code: '',
  amount: '',
  quantity: '',
  validFrom: '',
  validTo: '',
  isNewCustomer: false,
};

const MIN_VALUE = 0;
const MAX_VALUE = Number.MAX_SAFE_INTEGER;

const decimalFormatValidator = (value: number) =>
  /^\d+(\.\d{0,2})?$/.test(`${value}`);

export const getBonusCodeValidationSchema = (t: TFunction, used?: number) =>
  Yup.object({
    code: Yup.string().required(t('validationMessages.required')),
    amount: Yup.number()
      .typeError(t('validationMessages.number'))
      .required(t('validationMessages.required'))
      .min(MIN_VALUE, t('validationMessages.minNumber', { value: MIN_VALUE }))
      .max(MAX_VALUE, t('validationMessages.maxNumber', { value: MAX_VALUE }))
      .test('decimal', t('validationMessages.decimal'), decimalFormatValidator),
    quantity: Yup.number()
      .typeError(t('validationMessages.number'))
      .min(
        used || MIN_VALUE,
        t('validationMessages.minNumber', { value: used || MIN_VALUE }),
      )
      .max(MAX_VALUE, t('validationMessages.maxNumber', { value: MAX_VALUE })),
    validFrom: Yup.date().typeError(t('validationMessages.date')),
    validTo: Yup.date()
      .typeError(t('validationMessages.date'))
      .test(
        'minDate',
        (value: Date | undefined, { parent: { validFrom }, createError }) =>
          validFrom && !isNaN(validFrom) && value
            ? moment(value).isSameOrAfter(validFrom) ||
              createError({
                message: t('validationMessages.minDate', {
                  value: moment(validFrom).format(DATE_ONLY),
                }),
              })
            : true,
      ),
    isNewCustomer: Yup.boolean(),
  });
