import baseApi from '../../services/apiService';
import {
  GetReservationFeedbackParams,
  ReservationFeedback,
} from '../../entities/ReservationFeedback';
import { PaginatedItems } from './index';

export const reservationFeedbackApi = {
  getReservationFeedback: (params: GetReservationFeedbackParams) =>
    baseApi.get<PaginatedItems<ReservationFeedback>>(
      '/api/reservation-feedback',
      {
        params,
      },
    ),
};
