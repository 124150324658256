import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Pagination, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { TabHeader } from '../../Common/TabHeader';
import {
  BusinessCustomerDetail,
  PrivateCustomerDetail,
} from '../../../entities/Customer';
import { DetailsCard } from '../../Common/DetailsCard';
import { DetailRow } from '../../Common/DetailRow';
import ReservationFeedbackRating from '../../Reservations/ReservationFeedbackRating';
import { GetReservationFeedbackParams } from '../../../entities/ReservationFeedback';
import { useGetReservationFeedback } from '../../Reservations/api';
import PageQueryLoader from '../../Common/PageQueryLoader';
import { NoDataPlaceholder } from '../../Common/NoDataPlaceholder';

interface Props {
  customer: PrivateCustomerDetail | BusinessCustomerDetail;
}
const CustomerDetailsFeedback = ({ customer }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [reservationParams, setReservationParams] =
    useState<GetReservationFeedbackParams>({
      customerId: customer.id,
      page: 1,
      limit: 5,
    });

  const getFeedback = useGetReservationFeedback(reservationParams);
  const { data: feedbacks } = getFeedback;

  return (
    <>
      <TabHeader text={t('customers.tabs.feedback')}></TabHeader>
      <PageQueryLoader query={getFeedback}>
        <Box height="100%">
          {feedbacks?.items?.length === 0 ? (
            <NoDataPlaceholder />
          ) : (
            feedbacks?.items.map(feedback => {
              const { id, rating, comment, reservation } = feedback;
              return (
                <DetailsCard key={id}>
                  <DetailRow
                    key="reservationId"
                    title={t('customers.feedback.reservationId')}
                    content={
                      <Typography
                        sx={{ cursor: 'pointer', textDecoration: 'underline' }}
                        onClick={() =>
                          navigate('/reservations/' + reservation.id)
                        }
                      >
                        {reservation.id}
                      </Typography>
                    }
                    showDivider={false}
                  />
                  <DetailRow
                    key="rating"
                    title={t('customers.feedback.rating')}
                    content={<ReservationFeedbackRating rating={rating} />}
                    showDivider={false}
                  />
                  <DetailRow
                    key="comment"
                    title={t('customers.feedback.comment')}
                    content={comment}
                    showDivider={false}
                  />
                </DetailsCard>
              );
            })
          )}
          {feedbacks?.pagination.totalPages &&
            feedbacks?.pagination.totalPages > 1 && (
              <Pagination
                count={feedbacks?.pagination.totalPages}
                page={feedbacks?.pagination.currentPage}
                onChange={(event, page) =>
                  setReservationParams({ ...reservationParams, page: page })
                }
              />
            )}
        </Box>
      </PageQueryLoader>
    </>
  );
};

export default CustomerDetailsFeedback;
