import {
  AnyAction,
  combineReducers,
  configureStore,
  Reducer,
} from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AxiosError, AxiosResponse } from 'axios';
import { array, InferType, number, object, string } from 'yup';
import { queryClient } from '../App';
import { reducer as userReducer } from './user/slice';
import { reducer as bonusCodeReducer } from './bonusCode/slice';
import { openModal, reducer as modalReducer } from './modal/slice';

export const appReducer = combineReducers({
  userReducer,
  bonusCodeReducer,
  modalReducer,
});
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'LOG_OUT') {
    storage.removeItem('persist:root');
    queryClient.removeQueries();

    // eslint-disable-next-line no-param-reassign
    state = {} as RootState;
  }
  return appReducer(state, action);
};
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: ['modalReducer'],
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          FLUSH,
          REHYDRATE,
          PAUSE,
          PERSIST,
          PURGE,
          REGISTER,
          openModal.type,
        ],
        ignoredPaths: ['modalReducer.props.action'],
      },
    }),
});
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;

export type RootState = {
  userReducer: ReturnType<typeof userReducer>;
  bonusCodeReducer: ReturnType<typeof bonusCodeReducer>;
  modalReducer: ReturnType<typeof modalReducer>;
};

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const apiErrorSchema = object({
  code: number().required(),
  message: string().required(),
  type: string(),
  fields: array().of(
    object({
      field: string().required(),
      errors: array().of(string().required()),
    }),
  ),
});

export type ApiError = InferType<typeof apiErrorSchema>;
export interface UnknownError {
  cause: unknown;
}
export type RequestError = ApiError | UnknownError;

export function getFieldErrors(
  requestError: RequestError,
  fieldTranslations: Record<string, string>,
): string[] {
  if (!apiErrorSchema.isValidSync(requestError) || !requestError.fields) {
    return [];
  }
  const allErrors: string[] = [];
  requestError.fields.forEach(({ field, errors }) => {
    if (errors) {
      const fieldTranslation = fieldTranslations[field] || field;
      allErrors.push(...errors.map(err => `${fieldTranslation}: ${err}`));
    }
  });
  return allErrors;
}

/**
 * Useful to get access to Axios error response data
 * and make decisions or display something based on that.
 */
export async function catchRejectionAxiosResponse(
  apiRequestPromise: Promise<AxiosResponse>,
  rejectWithValue: (rejection: RequestError) => unknown,
) {
  try {
    return (await apiRequestPromise).data;
  } catch (e) {
    if (e instanceof AxiosError && e.response) {
      try {
        return rejectWithValue(await apiErrorSchema.validate(e.response.data));
      } catch (ve) {
        // If anything went wrong with API response validation,
        // just give access to the status from Axios
        return rejectWithValue({
          code: e.response.status,
          message: e.response.statusText,
        });
      }
    }
    return rejectWithValue({ cause: e });
  }
}
