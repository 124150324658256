import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Box, useTheme } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import { TabHeader } from '../../Common/TabHeader';
import { useGetVehicleActionLogs } from '../../../store/vehicle/queries';
import { PaginatedTable, usePaginatedTable } from '../../Common/PaginatedTable';
import { PaginatedRequestParams } from '../../../store/api';
import { formatDateTime } from '../../../utils/datetime-utils';
import {
  ActionCreatedBy,
  VehicleActionLog,
  VehicleActionState,
} from '../../../entities/Vehicle';
import StateChip from '../../Common/StateChip';

function VehicleActionLogs() {
  const { t } = useTranslation();
  const theme = useTheme();

  const { vehicleId } = useParams();
  const [params, setParams] = useState<PaginatedRequestParams>({});
  const { data: actions, isLoading } = useGetVehicleActionLogs(
    vehicleId || '',
    params,
  );
  const { rows, debounceParams, totalItems } = usePaginatedTable({
    paginatedItems: actions,
    params,
    setParams,
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: 'type',
        headerName: t('vehicles.actionLogs.type'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          t(`vehicles.actions.${params.value}`),
      },
      {
        field: 'state',
        headerName: t('vehicles.actionLogs.state'),
        flex: 1,
        sortable: false,
        renderCell: ({
          value,
        }: GridRenderCellParams<VehicleActionLog, VehicleActionState>) => {
          return (
            <StateChip
              label={t(`vehicles.actionLogs.states.${value}`)}
              backgroundColor={
                value === 'failed'
                  ? theme.palette.error.main
                  : value === 'succeeded'
                  ? theme.palette.success.main
                  : theme.palette.warning.main
              }
              textColor="white"
              extended
            />
          );
        },
      },
      {
        field: 'created',
        headerName: t('vehicles.actionLogs.created'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatDateTime(params.value),
      },
      {
        field: 'createdBy',
        headerName: t('vehicles.actionLogs.executor'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<ActionCreatedBy>) =>
          params.value.name ?? params.value.type,
      },
    ],
    [],
  );

  return (
    <Box>
      <TabHeader text={t('vehicles.tabs.actions')} />
      <PaginatedTable
        rows={rows}
        columns={columns}
        totalItems={totalItems}
        isLoading={isLoading}
        fetchRows={debounceParams}
      />
    </Box>
  );
}

export default VehicleActionLogs;
