import React from 'react';
import { FormLabel } from '@mui/material';
import { useField } from 'formik';
import NumberField, { NumberFieldProps } from '../NumberField';

type Props = {
  name: string;
} & NumberFieldProps;

function FormikNumberField({ name, label, ...otherProps }: Props) {
  const [field, { error, touched }] = useField(name);

  return (
    <>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <NumberField
        {...field}
        {...otherProps}
        id={name}
        error={!!error && touched}
        helperText={error}
      />
    </>
  );
}

export default FormikNumberField;
