import React from 'react';

const CheckboxUncheckedIcon = ({
  fill = 'white',
  stroke = '#8E8C89',
}: {
  fill?: string;
  stroke?: string;
}) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="0.5"
      y="0.5"
      width="17"
      height="17"
      rx="3.5"
      fill={fill}
      stroke={stroke}
    />
  </svg>
);

export default CheckboxUncheckedIcon;
