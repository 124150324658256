import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';
import { catchRejectionAxiosResponse } from '../index';

export interface SignInWithPasswordParams {
  password: string;
  username: string;
}

export interface AuthResponse {
  token: string;
}

export interface GetCodeParams {
  areaCode: string;
  phoneNumber: string;
}

export const signIn = createAsyncThunk(
  'auth/signIn',
  async (payload: SignInWithPasswordParams, { rejectWithValue }) => {
    return await catchRejectionAxiosResponse(
      api.signIn(payload),
      rejectWithValue,
    );
  },
);

export const getCode = createAsyncThunk(
  'auth/getCode',
  async (payload: GetCodeParams, { rejectWithValue }) => {
    return await catchRejectionAxiosResponse(
      api.b2bGetCode(payload),
      rejectWithValue,
    );
  },
);

export const sendCode = createAsyncThunk(
  'auth/sendCode',
  async (payload: string, { rejectWithValue }) => {
    return await catchRejectionAxiosResponse(
      api.b2bSendCode({ code: payload }),
      rejectWithValue,
    );
  },
);

export const tradeToken = createAsyncThunk(
  'auth/tradeToken',
  async (_, { rejectWithValue }) => {
    return await catchRejectionAxiosResponse(
      api.b2bTradeToken(),
      rejectWithValue,
    );
  },
);
