import currency from 'currency.js';
import { TFunction } from 'i18next';
import { PaymentSummary, ReservationPayment } from '../entities/Reservation';
import { moneyString } from '../entities';

export function downloadBlob(blob: Blob, filename: string) {
  const href = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
}

export const EUR = (value: currency.Any) =>
  currency(value, { symbol: '€', precision: 2, decimal: '.' });

export function summarizePayments(payment: ReservationPayment): PaymentSummary {
  const summary: PaymentSummary = {};
  summary.debt = payment.debt;
  // summary.prepaid = // TODO
  // summary.postpaid = // TODO
  let totalPaid = EUR(0);
  payment.details.reduce((sum, detail) => {
    totalPaid = totalPaid.add(detail.amount);
    // Making the assumption that there's only one detail for each payment type,
    // so it should be safe to override here.
    switch (detail.type) {
      case 'card':
        summary.paidWithCard = detail.amount;
        break;
      case 'wallet':
        summary.paidWithBonus = detail.amount;
        break;
      case 'company':
        summary.paidWithCompany = detail.amount;
    }
    return sum;
  }, summary);
  summary.status = summary.debt ? 'not_paid' : 'paid';
  return summary;
}

export function formatMoney(str?: moneyString) {
  return str ? `${str} €` : '';
}

export function isNonZeroMoney(amount: moneyString | null | undefined) {
  if (!amount) return false;
  return currency(amount).intValue > 0;
}

export function formatBoolean(bool: boolean | undefined | null, t: TFunction) {
  return bool ? t('yes') : t('no');
}

export type CountryCode = 'EE' | 'LV' | 'LT';

export function isInBackofficePath() {
  return window.location.pathname.startsWith('/admin');
}

export function formatPhoneNumber(areaCode: string, phoneNumber: string) {
  return `+${areaCode} ${phoneNumber}`;
}

export function formatDistance(meters: number | undefined) {
  if (!meters || meters === 0) return null;
  if (meters >= 1000) {
    const kilometers = meters / 1000;
    return `${kilometers.toFixed(2)} km`;
  } else {
    return `${meters} m`;
  }
}
