export interface SystemSetting {
  id: string;
  value: string;
}

export const systemSettings: SystemSetting[] = [
  { id: 'photoProof', value: 'Yes' },
  { id: 'doorsActiveTime', value: '10min' },
  { id: 'slackTime', value: '59min' },
  { id: 'overlapCancellationDelay', value: '30min' },
  { id: 'overtimeLimit', value: '15min' },
  { id: 'freeCancellationBuffer', value: '2m' },
  { id: 'penaltyCancellationTime', value: '24h' },
  { id: 'maxPeriod', value: '30 days' },
  { id: 'gpsOfflineLimit', value: '3h' },
  { id: 'minBattery', value: '11.6 voltages' },
  { id: 'minFuel', value: '100km' },
  { id: 'iotDisconnectedCode', value: 'Code 252' },
  { id: 'maxSpeed', value: '135km/h' },
  { id: 'vat', value: '22%' },
];
