import React from 'react';
import { Link as MuiLink, LinkProps } from '@mui/material';
import { Link, NavLinkProps } from 'react-router-dom';

type Props = LinkProps & NavLinkProps;
function MuiRouterLink(props: Props) {
  const { to, children, ...rest } = props;
  return (
    <MuiLink component={Link} to={to} {...rest}>
      {children}
    </MuiLink>
  );
}

export default MuiRouterLink;
