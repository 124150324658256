import React from 'react';
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DownloadOutlined } from '@mui/icons-material';
import ModalHeader from '../../Modal/ModalHeader';
import ModalFooter from '../../Modal/ModalFooter';
import { useGetReservationInvoices } from '../api';
import PageQueryLoader from '../../Common/PageQueryLoader';

type Props = {
  reservationId: string;
};

function DownloadReservationInvoicesModal({ reservationId }: Props) {
  const { t } = useTranslation();

  const query = useGetReservationInvoices(reservationId);
  const { data: invoiceLinks } = query;

  return (
    <Paper sx={{ width: '100%', maxWidth: 568 }}>
      <ModalHeader title={t('reservations.modal.downloadInvoices.title')} />

      <PageQueryLoader query={query}>
        <Box sx={{ padding: 4, textAlign: 'center' }}>
          <List>
            {invoiceLinks?.map(link => (
              <ListItem key={link.uri}>
                <ListItemButton href={link.uri}>
                  <ListItemIcon>
                    <DownloadOutlined />
                  </ListItemIcon>
                  <ListItemText>{link.fileName}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </PageQueryLoader>

      <ModalFooter />
    </Paper>
  );
}

export default DownloadReservationInvoicesModal;
