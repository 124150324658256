import React from 'react';
import { CreditCardOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { ReactComponent as MastercardSvg } from '../../icons/mastercard.svg';
import { ReactComponent as VisaSvg } from '../../icons/visa.svg';
import { PaymentMethod } from '../../entities';

type Props = {
  paymentMethod: PaymentMethod;
};

function PaymentMethodDetails({ paymentMethod }: Props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {paymentMethod.brand === 'mastercard' ? (
        <MastercardSvg height={20} width={48} />
      ) : paymentMethod.brand === 'visa' ? (
        <VisaSvg height={30} width={48} />
      ) : (
        <CreditCardOutlined />
      )}
      <Typography sx={{ fontWeight: 600 }}>{paymentMethod.title}</Typography>
    </Box>
  );
}

export default PaymentMethodDetails;
