import React from 'react';

const ExportIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 10.667C16.279 10.667 16.067 10.7548 15.9108 10.9111C15.7545 11.0674 15.6667 11.2793 15.6667 11.5003V14.8337C15.6667 15.0547 15.5789 15.2666 15.4226 15.4229C15.2663 15.5792 15.0544 15.667 14.8334 15.667H3.16669C2.94567 15.667 2.73371 15.5792 2.57743 15.4229C2.42115 15.2666 2.33335 15.0547 2.33335 14.8337V11.5003C2.33335 11.2793 2.24556 11.0674 2.08928 10.9111C1.933 10.7548 1.72103 10.667 1.50002 10.667C1.27901 10.667 1.06704 10.7548 0.910765 10.9111C0.754484 11.0674 0.666687 11.2793 0.666687 11.5003V14.8337C0.666687 15.4967 0.930079 16.1326 1.39892 16.6014C1.86776 17.0703 2.50365 17.3337 3.16669 17.3337H14.8334C15.4964 17.3337 16.1323 17.0703 16.6011 16.6014C17.07 16.1326 17.3334 15.4967 17.3334 14.8337V11.5003C17.3334 11.2793 17.2456 11.0674 17.0893 10.9111C16.933 10.7548 16.721 10.667 16.5 10.667ZM8.40835 12.092C8.48761 12.1679 8.58106 12.2273 8.68335 12.267C8.7831 12.3111 8.89096 12.3339 9.00002 12.3339C9.10908 12.3339 9.21694 12.3111 9.31669 12.267C9.41898 12.2273 9.51243 12.1679 9.59169 12.092L12.925 8.75866C13.0819 8.60174 13.1701 8.38891 13.1701 8.16699C13.1701 7.94507 13.0819 7.73225 12.925 7.57533C12.7681 7.41841 12.5553 7.33025 12.3334 7.33025C12.1114 7.33025 11.8986 7.41841 11.7417 7.57533L9.83335 9.49199V1.50033C9.83335 1.27931 9.74556 1.06735 9.58928 0.91107C9.433 0.75479 9.22103 0.666992 9.00002 0.666992C8.77901 0.666992 8.56705 0.75479 8.41077 0.91107C8.25449 1.06735 8.16669 1.27931 8.16669 1.50033V9.49199L6.25835 7.57533C6.18066 7.49763 6.08841 7.43599 5.9869 7.39394C5.88538 7.35189 5.77657 7.33025 5.66669 7.33025C5.5568 7.33025 5.448 7.35189 5.34648 7.39394C5.24496 7.43599 5.15272 7.49763 5.07502 7.57533C4.99732 7.65302 4.93569 7.74527 4.89364 7.84678C4.85159 7.9483 4.82994 8.05711 4.82994 8.16699C4.82994 8.27688 4.85159 8.38568 4.89364 8.4872C4.93569 8.58872 4.99732 8.68096 5.07502 8.75866L8.40835 12.092Z"
      fill="#10171B"
    />
  </svg>
);

export default ExportIcon;
