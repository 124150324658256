import React from 'react';
import { Typography } from '@mui/material';

interface Props {
  text: string | null | undefined;
  style?: React.CSSProperties | undefined;
}
export const DetailContent = ({ text, style }: Props) => (
  <Typography
    variant="body1"
    style={{
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      ...style,
    }}
    title={text || undefined}
  >
    {text || '-'}
  </Typography>
);
