import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { isDate } from 'moment';
import moment from 'moment/moment';
import { EditMaintenanceReservationState } from '../EditReservation/editMaintenanceReservationData';
import { DATE_TIME, parseDate } from '../../../utils/datetime-utils';

export type AddMaintenanceReservationState = EditMaintenanceReservationState & {
  vehicleId: string;
};

export const getAddMaintenanceReservationValidationSchema = (t: TFunction) =>
  Yup.object({
    vehicleId: Yup.string().required(t('validationMessages.required')),
    startDateTime: Yup.date()
      .transform(parseDate)
      .test(
        'maxStartDateTime',
        (value: Date | undefined, { parent: { endDateTime }, createError }) => {
          if (isDate(endDateTime)) {
            if (moment(value).isSameOrAfter(endDateTime)) {
              return createError({
                message: t('validationMessages.maxDate', {
                  value: moment(endDateTime).format(DATE_TIME),
                }),
              });
            }
          }
          return true;
        },
      )
      .required(t('validationMessages.required')),
    endDateTime: Yup.date()
      .transform(parseDate)
      .test(
        'minEndDateTime',
        (
          value: Date | undefined,
          { parent: { startDateTime }, createError },
        ) => {
          if (isDate(startDateTime)) {
            if (moment(value).isSameOrBefore(startDateTime)) {
              return createError({
                message: t('validationMessages.minDate', {
                  value: moment(startDateTime).format(DATE_TIME),
                }),
              });
            }
          }
          return true;
        },
      )
      .required(t('validationMessages.required')),
  });
