import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Add, Close } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import { TabHeader } from '../../Common/TabHeader';
import { SecondaryButton } from '../../Common/SecondaryButton';
import { useAppDispatch, useAppSelector } from '../../../store';
import { BONUS_CODES } from '../../../utils/routes-constants';
import EditIcon from '../../../icons/EditIcon';
import {
  createBonusCode,
  CreateUpdateBonusCodeBody,
  getBonusCode,
  updateBonusCode,
} from '../../../store/bonusCode/thunk';
import { DataState } from '../../../entities/DataState';
import { BonusCode } from '../../../entities/BonusCode';
import { isLoadingFinalized, LoadingStatus } from '../../../utils/query-enums';
import {
  revertBonusCode,
  revertCreateBonusCode,
  revertUpdateBonusCode,
} from '../../../store/bonusCode/slice';
import LoadingIcon from '../../Common/LoadingIcon';
import CheckboxField from '../../Common/FormikFields/CheckboxField';
import DatePickerField from '../../Common/FormikFields/DatePickerField';
import InputField from '../../Common/FormikFields/InputField';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import {
  bonusCodeInitialState,
  BonusCodeState,
  getBonusCodeValidationSchema,
} from './addEditBonusCodeData';

const AddEditBonusCode = () => {
  const { t } = useTranslation();
  const { apiErrorSnack, successSnack } = useSnacks();

  const { bonusCodeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const bonusCode: DataState<BonusCode> = useAppSelector(
    state => state.bonusCodeReducer.bonusCode,
  );
  const createBonusCodeState: DataState<BonusCode> = useAppSelector(
    state => state.bonusCodeReducer.createBonusCode,
  );
  const updateBonusCodeState: DataState<BonusCode> = useAppSelector(
    state => state.bonusCodeReducer.updateBonusCode,
  );

  const [bonusCodeFormState, setBonusCodeFormState] = useState<BonusCodeState>(
    bonusCodeInitialState,
  );

  useEffect(() => {
    bonusCodeId && dispatch(getBonusCode(bonusCodeId));
  }, [bonusCodeId]);

  useEffect(() => {
    const state = bonusCodeId ? updateBonusCodeState : createBonusCodeState;
    if (state.loading === LoadingStatus.SUCCEEDED) {
      successSnack(t('settings.bonusCodes.snacks.saved'));
      navigate(BONUS_CODES);
    }
    if (state.loading === LoadingStatus.FAILED && state.error) {
      apiErrorSnack(
        state.error,
        {},
        t('settings.bonusCodes.snacks.saveFailed'),
      );
    }
  }, [createBonusCodeState.loading, updateBonusCodeState.loading]);

  useEffect(() => {
    if (bonusCode.data) {
      const { code, amount, quantity, validFrom, validTo, isNewCustomer } =
        bonusCode.data;

      setBonusCodeFormState({
        ...bonusCodeInitialState,
        code,
        amount,
        quantity,
        validFrom: validFrom || '',
        validTo: validTo || '',
        isNewCustomer,
      });
    }
  }, [bonusCode.data]);

  useEffect(() => {
    return () => {
      dispatch(revertBonusCode());
      dispatch(revertCreateBonusCode());
      dispatch(revertUpdateBonusCode());
    };
  }, []);

  const onSubmit = (values: BonusCodeState) => {
    const { code, amount, quantity, validFrom, validTo, isNewCustomer } =
      values;

    const body: CreateUpdateBonusCodeBody = {
      code,
      amount: +amount * 100,
      quantity: +quantity,
      validFrom,
      validTo,
      isNewCustomer,
    };

    bonusCodeId
      ? dispatch(updateBonusCode({ id: bonusCodeId, body }))
      : dispatch(createBonusCode(body));
  };

  if (bonusCodeId && !isLoadingFinalized(bonusCode?.loading)) {
    return <LoadingIcon />;
  }

  return (
    <Box flex={1}>
      <TabHeader
        text={t(
          bonusCodeId
            ? 'settings.bonusCodes.form.titles.editBonusCode'
            : 'settings.bonusCodes.form.titles.addBonusCode',
        )}
      >
        <SecondaryButton
          startIcon={<Close />}
          onClick={() => navigate(BONUS_CODES)}
        >
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <Formik
        initialValues={bonusCodeFormState}
        validationSchema={getBonusCodeValidationSchema(t, bonusCode.data?.used)}
        onSubmit={onSubmit}
        enableReinitialize
      >
        <Form>
          <Grid
            container
            direction="column"
            sx={{ maxWidth: isDownMd ? '100%' : '50%', marginBottom: 4 }}
            rowGap={2}
          >
            <Grid item container columnSpacing={2}>
              <Grid item xs={6}>
                <InputField
                  name="code"
                  label={t('settings.bonusCodes.bonusCodeName')}
                  placeholder={t(
                    'settings.bonusCodes.form.placeholders.bonusCodeName',
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <InputField
                  name="amount"
                  label={t('settings.bonusCodes.amount')}
                  placeholder={t(
                    'settings.bonusCodes.form.placeholders.amount',
                  )}
                  startAdornment={
                    // eslint-disable-next-line @docusaurus/no-untranslated-text
                    <InputAdornment position="start">€</InputAdornment>
                  }
                  disabled={!!bonusCodeId}
                />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={2}>
              <Grid item xs={6}>
                <InputField
                  name="quantity"
                  label={t('settings.bonusCodes.quantity')}
                  placeholder={t(
                    'settings.bonusCodes.form.placeholders.quantity',
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container columnSpacing={2}>
              <Grid item xs={6}>
                <DatePickerField
                  name="validFrom"
                  label={t('settings.bonusCodes.codeValidFrom')}
                  placeholder={t(
                    'settings.bonusCodes.form.placeholders.codeValidFrom',
                  )}
                  disabled={!!bonusCodeId}
                />
              </Grid>
              <Grid item xs={6}>
                <DatePickerField
                  name="validTo"
                  label={t('settings.bonusCodes.codeValidTo')}
                  placeholder={t(
                    'settings.bonusCodes.form.placeholders.codeValidTo',
                  )}
                />
              </Grid>
            </Grid>
            <Grid item>
              <CheckboxField
                name="isNewCustomer"
                label={t('settings.bonusCodes.onlyForNewCustomers')}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            variant="contained"
            size="medium"
            color="secondary"
            startIcon={bonusCodeId ? <EditIcon fill="white" /> : <Add />}
          >
            {t(
              bonusCodeId
                ? 'settings.bonusCodes.buttons.editBonusCode'
                : 'settings.bonusCodes.buttons.addBonusCode',
            )}
          </Button>
        </Form>
      </Formik>
    </Box>
  );
};

export default AddEditBonusCode;
