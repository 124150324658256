import React, { useMemo, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PaginatedTable, usePaginatedTable } from '../../Common/PaginatedTable';
import { useGetReservationFeedback } from '../../Reservations/api';
import { RESERVATIONS } from '../../../utils/routes-constants';
import {
  GetReservationFeedbackParams,
  ReservationFeedback,
} from '../../../entities/ReservationFeedback';
import { TabHeader } from '../../Common/TabHeader';
import ReservationFeedbackRating from '../../Reservations/ReservationFeedbackRating';

function VehicleFeedback() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { vehicleId } = useParams();

  const [params, setParams] = useState<GetReservationFeedbackParams>({
    vehicleId,
  });

  const { data: feedbacks, isLoading } = useGetReservationFeedback(params);
  const { rows, totalItems, debounceParams } = usePaginatedTable({
    paginatedItems: feedbacks,
    params,
    setParams,
    syncParamsWithUrl: false,
    mapGridRow: f =>
      ({
        id: f.id,
        rating: f.rating,
        comment: f.comment,
        reservationId: f.reservation.id,
      } as FeedbackRow),
  });

  const columns: GridColDef<ReservationFeedback>[] = useMemo(() => {
    return [
      {
        field: 'rating',
        headerName: t('vehicles.feedback.rating'),
        flex: 0.2,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Typography variant="body2">
            <ReservationFeedbackRating rating={params.row.rating} />
          </Typography>
        ),
      },
      {
        field: 'comment',
        headerName: t('vehicles.feedback.comment'),
        flex: 1,
        sortable: false,
        renderCell: (params: GridRenderCellParams) => (
          <Typography variant="body2">{params.row.comment}</Typography>
        ),
      },
    ];
  }, [t]);

  return (
    <Box>
      <TabHeader text={t('vehicles.tabs.feedback')} />
      <PaginatedTable
        rows={rows}
        columns={columns}
        totalItems={totalItems}
        isLoading={isLoading}
        fetchRows={debounceParams}
        onRowClick={(params: GridRowParams<FeedbackRow>) =>
          navigate(`${RESERVATIONS}/${params.row.reservationId}`)
        }
      />
    </Box>
  );
}

interface FeedbackRow {
  id: string;
  rating: string;
  comment: string;
  reservationId: string;
}

export default VehicleFeedback;
