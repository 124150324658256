import React from 'react';
import { FormLabel, Tooltip } from '@mui/material';
import { DatePickerProps } from '@mui/x-date-pickers';
import { Field, FieldProps } from 'formik';
import { Moment } from 'moment';
import DatePicker from '../DatePicker';

interface Props extends DatePickerProps<Moment> {
  name: string;
  label: string;
  labelTooltip?: string;
  placeholder?: string;
  disabled?: boolean;
  disablePast?: boolean;
  minDate?: Moment | undefined;
  maxDate?: Moment | undefined;
}

const DatePickerField = ({
  name,
  label,
  labelTooltip,
  placeholder,
  disabled,
  disablePast,
  minDate,
  maxDate,
  ...pickerProps
}: Props) => {
  return (
    <>
      <Tooltip title={labelTooltip} placement="top">
        <FormLabel>{label}</FormLabel>
      </Tooltip>
      <Field name={name}>
        {({
          field,
          form: { setFieldValue, setFieldTouched },
          meta: { touched, error },
        }: FieldProps) => (
          <DatePicker
            {...field}
            {...pickerProps}
            onChange={async (value: string | null) => {
              await setFieldTouched(name);
              await setFieldValue(name, value, true);
            }}
            placeholder={placeholder}
            error={!!error && touched}
            helperText={error}
            disabled={disabled}
            disablePast={disablePast}
            minDate={minDate}
            maxDate={maxDate}
          />
        )}
      </Field>
    </>
  );
};

export default DatePickerField;
