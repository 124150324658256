import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';
import { DetailContent } from './DetailContent';

interface Props {
  title: string;
  content: string | undefined | null | JSX.Element;
  titleWidth?: number;
  contentWidth?: number;
  showDivider?: boolean;
}
export const DetailRow = ({
  title,
  content,
  titleWidth = 4,
  contentWidth = 8,
  showDivider = true,
}: Props) => (
  <>
    <Grid item xs={titleWidth}>
      <Typography variant="body2">{title}</Typography>
    </Grid>
    {(typeof content === 'string' || !content) && (
      <Grid item xs={contentWidth}>
        <DetailContent text={content} />
      </Grid>
    )}
    {content && typeof content !== 'string' && (
      <Grid item xs={contentWidth}>
        {content}
      </Grid>
    )}
    {showDivider && (
      <Grid item xs={12}>
        <Divider />
      </Grid>
    )}
  </>
);
