import { GridToolbarContainer } from '@mui/x-data-grid';
import {
  Button,
  FormLabel,
  Grid,
  InputAdornment,
  Typography,
} from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Close, Search } from '@mui/icons-material';
import TextField from '../Common/TextField';
import { PaginatedTableToolbar } from '../Common/PaginatedTable';
import { GetInvoicesParams } from '../../entities/Invoice';
import { PaymentStatus } from '../../utils/customer-utils';
import Select from '../Common/Select';
import DatePicker from '../Common/DatePicker';

const InvoicesToolbar = ({
  params,
  setParams,
  searchFocus,
  setSearchFocus,
}: PaginatedTableToolbar<GetInvoicesParams>) => {
  const { t } = useTranslation();

  const onSearchValueChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchFocus(true);
    setParams((prevState: GetInvoicesParams) => ({
      ...prevState,
      search: event.target.value,
    }));
  };

  const onFilterValueChange = (value: string, field: string) => {
    setParams((prevState: GetInvoicesParams) => ({
      ...prevState,
      [field]: value || undefined,
    }));
  };

  const handleClearButton = () => {
    setParams(({ page, limit }: GetInvoicesParams) => ({
      page,
      limit,
    }));
  };

  return (
    <GridToolbarContainer>
      <Grid container columnGap={2}>
        <Grid item xs={2}>
          <FormLabel>{t('invoices.filters.search')}</FormLabel>
          <TextField
            value={params.search || ''}
            onChange={onSearchValueChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            autoFocus={searchFocus}
          />
        </Grid>
        <Grid item xs={1}>
          <FormLabel>{t('invoices.filters.status')}</FormLabel>
          <Select
            items={Object.values(PaymentStatus).map(
              (status: PaymentStatus) => ({
                value: status,
                label: t(`customers.paymentStatuses.${status}`),
              }),
            )}
            value={params.status || ''}
            onChange={(value: string) => onFilterValueChange(value, 'status')}
            displayEmpty
          />
        </Grid>
        <Grid item xs={4}>
          <FormLabel>{t('invoices.filters.invoiceDates')}</FormLabel>
          <Grid item container gap={2} wrap="nowrap">
            <DatePicker
              value={params.fromDate}
              onChange={(value: string) =>
                onFilterValueChange(value, 'fromDate')
              }
            />
            <DatePicker
              value={params.toDate}
              onChange={(value: string) => onFilterValueChange(value, 'toDate')}
            />
          </Grid>
        </Grid>
        <Grid item sx={{ paddingTop: 3 }} xs={1}>
          <Button
            style={{ color: '#141313' }}
            startIcon={<Close />}
            onClick={handleClearButton}
          >
            <Typography variant="subtitle2">{t('clearAll')}</Typography>
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
};

export default InvoicesToolbar;
