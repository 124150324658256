export enum CustomerListView {
  PRIVATE = 'private',
  BUSINESS = 'business',
}

export enum CustomerDetailsView {
  RELATED_ACCOUNTS = 'related-accounts',
  RESERVATIONS = 'reservations',
  FEEDBACK = 'feedback',
  NOTES = 'notes',
  ACTIVITY = 'activity',
}

export enum PaymentStatus {
  PAID = 'paid',
  NOT_PAID = 'notPaid',
}

export enum CustomerStatus {
  ACTIVE = 'active',
  BLOCKED = 'blocked',
  STOPPED = 'stopped',
}

export enum SubscriptionStatus {
  SUBSCRIBED = 'subscribed',
  NOT_SUBSCRIBED = 'notSubscribed',
}
