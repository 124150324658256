import { Box, Button, FormLabel, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import TextField from '../../Common/TextField';
import { TabHeader } from '../../Common/TabHeader';
import EditIcon from '../../../icons/EditIcon';
import LoadingIcon from '../../Common/LoadingIcon';
import InputField from '../../Common/FormikFields/InputField';
import CheckboxField from '../../Common/FormikFields/CheckboxField';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import { fieldOf } from '../../../store/api';
import {
  BusinessCustomerEditRequest,
  getBusinessCustomerEditSchema,
} from '../../../store/api/customer';
import CancelToParentPathButton from '../../Common/CancelToParentPathButton';
import {
  useEditBusinessCustomer,
  useGetCustomer,
} from '../../../store/customer/queries';
import { BUSINESS_CUSTOMER_DETAILS } from '../../../utils/routes-constants';
import FormikCurrencyField from '../../Common/FormikFields/FormikCurrencyField';
import { EUR } from '../../../utils';
import PaymentCardList from './PaymentCardList';

export const EditBusinessCustomer = () => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const snacks = useSnacks();
  const navigate = useNavigate();

  const { data: customer, status: queryStatus } = useGetCustomer(
    'business',
    customerId || '',
  );
  const { mutate } = useEditBusinessCustomer();

  const validationSchema = useMemo(() => getBusinessCustomerEditSchema(t), [t]);
  const initialValues: BusinessCustomerEditRequest | null = useMemo(() => {
    if (!customer) return null;
    return {
      address: customer.address,
      vatNumber: customer.vatNumber,
      email: customer.email,
      directoId: customer.directoId,
      verified: customer.verified,
      emailMarketing: customer.emailMarketing,
      monthlyLimit: customer.monthlyLimit
        ? EUR(customer.monthlyLimit).value
        : 0,
    };
  }, [customer, validationSchema]);

  async function onSubmit(values: BusinessCustomerEditRequest) {
    if (!customer || !customerId) return;

    const body = validationSchema.cast(values);

    mutate(
      {
        customerId,
        body,
      },
      {
        onSuccess: async () => {
          snacks.successSnack(t('customers.snacks.customerEdited'));
          navigate(
            BUSINESS_CUSTOMER_DETAILS.replace(':customerId', customerId),
          );
        },
        onError: () => {
          snacks.errorSnack(t('customers.snacks.customerEditError'));
        },
      },
    );
  }

  return (
    <Box sx={{ flex: 1 }}>
      <TabHeader headerVariant="h3" text={t('customers.editCustomer')}>
        <CancelToParentPathButton />
      </TabHeader>
      {queryStatus === 'pending' || !initialValues || !customer ? (
        <LoadingIcon />
      ) : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validateOnMount={false}
          validationSchema={validationSchema}
        >
          {({ values }) => (
            <Form>
              <Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                {t('customers.general')}
              </Typography>
              <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
                <Grid container columnSpacing={3}>
                  <Grid sm={6} xs={12}>
                    <FormLabel>{t('customers.companyName')}</FormLabel>
                    <TextField
                      InputProps={{
                        readOnly: true,
                        disabled: true,
                      }}
                      value={customer.companyName}
                    />
                  </Grid>
                  <Grid sm={6} xs={12}>
                    <FormLabel>{t('customers.registrationNumber')}</FormLabel>
                    <TextField
                      InputProps={{
                        readOnly: true,
                        disabled: true,
                      }}
                      value={customer.registrationNumber}
                    />
                  </Grid>
                  <Grid sm={6} xs={12}>
                    <InputField
                      name={fieldOf<BusinessCustomerEditRequest>('vatNumber')}
                      label={t('customers.kmkrNumber')}
                    />
                  </Grid>
                  <Grid sm={6} xs={12}>
                    <InputField
                      name={fieldOf<BusinessCustomerEditRequest>('address')}
                      label={t('customers.address')}
                    />
                  </Grid>
                  <Grid sm={6} xs={12}>
                    <InputField
                      name={fieldOf<BusinessCustomerEditRequest>('email')}
                      label={t('customers.email')}
                    />
                  </Grid>
                  <Grid sm={6} xs={12}>
                    <FormLabel>{t('customers.phoneNumber')}</FormLabel>
                    <TextField
                      InputProps={{
                        readOnly: true,
                        disabled: true,
                      }}
                      value={customer.representative.phoneNumber}
                    />
                  </Grid>
                  <Grid sm={6} xs={12}>
                    <FormLabel>{t('customers.representativeName')}</FormLabel>
                    <TextField
                      InputProps={{
                        readOnly: true,
                        disabled: true,
                      }}
                      value={customer.representative.name}
                    />
                  </Grid>
                  <Grid sm={6} xs={12}>
                    <InputField
                      name={fieldOf<BusinessCustomerEditRequest>(
                        'contractNumber',
                      )}
                      label={t('customers.contractNumber')}
                    />
                  </Grid>
                  <Grid sm={6} xs={12}>
                    <InputField
                      name={fieldOf<BusinessCustomerEditRequest>('directoId')}
                      label={t('customers.directoId')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid container sx={{ maxWidth: 670, mb: 4 }}>
                <Grid xs={12}>
                  <CheckboxField
                    name={fieldOf<BusinessCustomerEditRequest>(
                      'emailMarketing',
                    )}
                    label={t('customers.emailSubscription')}
                  />
                </Grid>
              </Grid>

              <PaymentCardList
                customerId={customer?.id}
                customerType="business"
              />

              <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
                <Grid xs={12}>
                  <CheckboxField
                    name={fieldOf<BusinessCustomerEditRequest>('verified')}
                    label={t('customers.verified')}
                  />
                </Grid>
                {values.verified && (
                  <Grid xs={12}>
                    <FormikCurrencyField
                      name={fieldOf<BusinessCustomerEditRequest>(
                        'monthlyLimit',
                      )}
                      label={t('customers.monthlyLimit')}
                      sx={{ width: 'auto' }}
                    />
                  </Grid>
                )}
              </Grid>

              <Button
                type="submit"
                variant="contained"
                size="medium"
                color="secondary"
                sx={{ mt: 4 }}
                startIcon={<EditIcon fill="white" />}
              >
                {t('customers.buttons.edit')}
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </Box>
  );
};
