import React from 'react';
import {
  Box,
  Checkbox as MuiCheckbox,
  CheckboxProps,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WarningAmberRounded } from '@mui/icons-material';
import CheckboxCheckedIcon from '../../icons/CheckboxCheckedIcon';
import CheckboxUncheckedIcon from '../../icons/CheckboxUncheckedIcon';

type Props = {
  label: string;
  error?: string;
  FormControlLabelProps?: Omit<FormControlLabelProps, 'label' | 'control'>;
} & CheckboxProps;

const Checkbox = ({ label, error, FormControlLabelProps, ...props }: Props) => {
  const { t } = useTranslation();
  return (
    <FormControl error={!!error}>
      <FormControlLabel
        label={label}
        componentsProps={{
          typography: {
            variant: 'subtitle2',
            color: error ? 'error' : 'default',
          },
        }}
        control={
          <MuiCheckbox
            {...props}
            icon={
              <CheckboxUncheckedIcon
                fill={props.disabled ? '#F3F2F0' : undefined}
                stroke={props.disabled ? '#BBB8B4' : undefined}
              />
            }
            checkedIcon={
              <CheckboxCheckedIcon
                fill={props.disabled ? '#8E8C89' : undefined}
              />
            }
          />
        }
        {...FormControlLabelProps}
      />
      {error && (
        <FormHelperText>
          <Box display="flex" alignItems="center" gap={0.5} component="span">
            <WarningAmberRounded />
            <Typography variant="body2" component="span">
              {t(error)}
            </Typography>
          </Box>
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Checkbox;
