import React, { useMemo, useState } from 'react';
import {
  Alert,
  Box,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowForward } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, Form, Formik } from 'formik';
import styled from '@emotion/styled';
import LoadingButton from '../../../../components/Common/LoadingButton';
import { ReactComponent as EeFlag } from '../../../../icons/flags/ee-flag.svg';
import { ReactComponent as LvFlag } from '../../../../icons/flags/lv-flag.svg';
import { ReactComponent as LtFlag } from '../../../../icons/flags/lt-flag.svg';
import { B2B_ENTER_CODE } from '../../../../utils/b2b-routes-constants';
import { fieldOf } from '../../../../store/api';
import { getCode } from '../../../../store/user/thunk';
import { useAppDispatch } from '../../../../store';
import { useSnacks } from '../../../../components/Common/AvisSnackbarProvider';
import { CodeRequest, getCodeSchema } from '../api';
import { CountryCode } from '../../../../utils';
import LoginWrapper from './LoginWrapper';
import { EE_AREA_CODE, LT_AREA_CODE, LV_AREA_CODE } from './loginData';

export interface Country {
  label: string;
  value: CountryCode;
  areaCode: string;
  flag: JSX.Element;
}
export const countries: Country[] = [
  {
    label: 'Estonia',
    value: 'EE',
    areaCode: EE_AREA_CODE,
    flag: <EeFlag />,
  },
  {
    label: 'Latvia',
    value: 'LV',
    areaCode: LV_AREA_CODE,
    flag: <LvFlag />,
  },
  {
    label: 'Lithuania',
    value: 'LT',
    areaCode: LT_AREA_CODE,
    flag: <LtFlag />,
  },
];

const CountryCodeSelectField = styled(TextField)`
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

const PhoneNumberTextField = styled(TextField)`
  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
`;

const LoginPage = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { apiErrorSnack } = useSnacks();
  const [isLoading, setIsLoading] = useState(false);
  const countryCode = process.env.REACT_APP_COUNTRY ?? 'EE';

  const initialValues: CodeRequest = useMemo(
    () => ({
      areaCode:
        countries.find(it => it.value === countryCode)?.areaCode ?? '+372',
      phoneNumber: '',
    }),
    [],
  );

  const onSubmit = async (values: CodeRequest) => {
    const { areaCode, phoneNumber } = values;
    setIsLoading(true);

    try {
      await dispatch(
        getCode({
          areaCode: areaCode.substring(1, areaCode.length),
          phoneNumber: phoneNumber,
        }),
      ).unwrap();
      navigate(B2B_ENTER_CODE);
    } catch (e) {
      apiErrorSnack(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <LoginWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '320px',
        }}
      >
        <Typography variant="h3">{t('b2b.login.title')}</Typography>
        <Typography
          sx={{
            mt: 2,
            fontSize: '18px',
            fontWeight: 600,
            fontFamily: 'Open Sans',
            lineHeight: '26px',
            mb: '40px',
          }}
        >
          {t('b2b.login.subtitle')}
        </Typography>
        <Formik
          initialValues={initialValues}
          validationSchema={getCodeSchema(t)}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {({ errors, isValid, submitCount }) => (
            <Form style={{ position: 'relative' }}>
              <Grid container direction="column" rowGap={2}>
                <Grid item>
                  <Box sx={{ display: 'flex' }}>
                    <Field name={fieldOf<CodeRequest>('areaCode')}>
                      {({ field, meta: { error, touched } }: FieldProps) => (
                        <CountryCodeSelectField
                          {...field}
                          variant="outlined"
                          error={!!error && touched}
                          fullWidth
                          sx={{
                            backgroundColor: '#F3F2F0',
                            width: '105px',
                          }}
                          select
                        >
                          {countries.map(country => (
                            <MenuItem
                              key={country.value}
                              value={country.areaCode}
                            >
                              <Box
                                sx={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 1,
                                  width: '65px',
                                }}
                              >
                                {country.flag}
                                {country.areaCode}
                              </Box>
                            </MenuItem>
                          ))}
                        </CountryCodeSelectField>
                      )}
                    </Field>
                    <Field name={fieldOf<CodeRequest>('phoneNumber')}>
                      {({ field, meta: { error, touched } }: FieldProps) => (
                        <PhoneNumberTextField
                          {...field}
                          variant="outlined"
                          placeholder={t('b2b.login.placeholders.phoneNumber')}
                          error={!!error && touched}
                          fullWidth
                          sx={{ width: '215px' }}
                        />
                      )}
                    </Field>
                  </Box>
                </Grid>
              </Grid>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <LoadingButton
                  type="submit"
                  sx={{ mt: 4, width: '180px' }}
                  endIcon={<ArrowForward />}
                  loading={isLoading}
                >
                  {t('b2b.login.button')}
                </LoadingButton>
              </Box>
              {submitCount > 0 && !isValid ? (
                <Alert
                  severity="error"
                  variant="outlined"
                  sx={{ mt: 2, position: 'absolute', width: '100%' }}
                >
                  {Object.values(errors).map(err => (
                    <Typography key={err}>{err}</Typography>
                  ))}
                </Alert>
              ) : null}
            </Form>
          )}
        </Formik>
      </Box>
    </LoginWrapper>
  );
};

export default LoginPage;
