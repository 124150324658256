import * as Yup from 'yup';

export interface PriceClassState {
  name: string;
  minuteRegular: string;
  hourRegular: string;
  dayRegular: string;
  weekRegular: string;
  kmRegular: string;
}

export const priceClassInitialState: PriceClassState = {
  name: '',
  minuteRegular: '',
  hourRegular: '',
  dayRegular: '',
  weekRegular: '',
  kmRegular: '',
};

const MIN_VALUE = 0;
const MAX_VALUE = Number.MAX_SAFE_INTEGER;

const decimalFormatValidator = (value: number) =>
  /^\d+(\.\d{0,2})?$/.test(`${value}`);

export const priceClassValidationSchema = Yup.object({
  name: Yup.string().required(),
  minuteRegular: Yup.number()
    .required()
    .min(MIN_VALUE)
    .max(MAX_VALUE)
    .test(decimalFormatValidator),
  hourRegular: Yup.number()
    .required()
    .min(MIN_VALUE)
    .max(MAX_VALUE)
    .test(decimalFormatValidator),
  dayRegular: Yup.number()
    .required()
    .min(MIN_VALUE)
    .max(MAX_VALUE)
    .test(decimalFormatValidator),
  weekRegular: Yup.number()
    .required()
    .min(MIN_VALUE)
    .max(MAX_VALUE)
    .test(decimalFormatValidator),
  kmRegular: Yup.number()
    .required()
    .min(MIN_VALUE)
    .max(MAX_VALUE)
    .test(decimalFormatValidator),
});
