import moment, { isDate, isMoment } from 'moment/moment';
import { dateTimeString } from '../entities';

export const DATE_TIME_UTC_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';
export const DATE_TIME = 'DD.MM.YYYY HH:mm';
export const DATE_TIME_WITH_SECONDS = 'DD.MM.YYYY HH:mm:ss';
export const TIME_WITH_SECONDS = 'HH:mm:ss';
export const DATE_ONLY = 'DD.MM.YYYY';
export const TIME_ONLY = 'HH:mm';

export function formatDuration(durationMs: number) {
  const durationMoment = moment(durationMs);
  const format =
    durationMoment.get('days') >= 1 ? 'HH:mm:ss' : 'D [days], HH:mm:ss';
  return durationMoment.format(format);
}

export function formatDateTime(
  dateTime: dateTimeString | null | undefined,
  format: string = DATE_TIME,
) {
  return dateTime ? moment(dateTime).format(format) : null;
}

export function parseDate(_value: Date, originalValue: unknown) {
  if (isDate(originalValue)) {
    return originalValue;
  }
  if (isMoment(originalValue)) {
    return originalValue.toDate();
  }
  if (typeof originalValue === 'string') {
    return moment(originalValue).toDate();
  }
  throw Error('validationMessages.date');
}
