import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { PageHeader } from '../../Common/PageHeader';
import { NoDataPlaceholder } from '../../Common/NoDataPlaceholder';
import NoUserIcon from '../../../icons/NoUserIcon';

const InvoicesPage = () => {
  const { t } = useTranslation();

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <PageHeader text={t('b2b.drawer.invoices')}></PageHeader>
      <NoDataPlaceholder
        icon={<NoUserIcon />}
        title={t('b2b.invoices.noData')}
        titleVariant="h4"
      />
    </Box>
  );
};

export default InvoicesPage;
