import {
  BonusCode,
  BonusCodeCustomer,
  GetBonusCodeParams,
} from '../../entities/BonusCode';
import baseApi from '../../services/apiService';
import { CreateUpdateBonusCodeBody } from '../bonusCode/thunk';
import { PaginatedItems, PaginatedRequestParams } from './index';

const apiPaths = {
  bonusCodes: '/api/bonus-codes',
  bonusCode: (id: string) => `/api/bonus-codes/${id}`,
  removeBonusCode: (id: string) => `/api/bonus-codes/${id}/remove`,
  bonusCodeCustomers: (id: string) => `/api/bonus-codes/${id}/customers`,
};

export const bonusCodesApi = {
  getBonusCodes: (params: GetBonusCodeParams) =>
    baseApi.get<PaginatedItems<BonusCode>>(apiPaths.bonusCodes, { params }),
  getBonusCode: (id: string) => baseApi.get<BonusCode>(apiPaths.bonusCode(id)),
  createBonusCode: (body: CreateUpdateBonusCodeBody) =>
    baseApi.post<BonusCode>(apiPaths.bonusCodes, body),
  updateBonusCode: (id: string, body: CreateUpdateBonusCodeBody) =>
    baseApi.put<BonusCode>(apiPaths.bonusCode(id), body),
  deleteBonusCode: (id: string) =>
    baseApi.post<BonusCode>(apiPaths.removeBonusCode(id), {
      deleted: true,
    }),
  getBonusCodeCustomers: (id: string, params: PaginatedRequestParams) =>
    baseApi.get<PaginatedItems<BonusCodeCustomer>>(
      apiPaths.bonusCodeCustomers(id),
      {
        params,
      },
    ),
};
