import React, { useEffect, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { Alert, Button, Container, Typography } from '@mui/material';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import LoginWrapper from '../auth/Login/LoginWrapper';
import LoadingButton from '../../../components/Common/LoadingButton';
import { B2B_LOGIN } from '../../../utils/b2b-routes-constants';
import { useAcceptInvite } from './queries';
import { acceptCodeSchema } from './api';

function AcceptInvitePage() {
  const { t } = useTranslation();
  const { code } = useParams();
  const accept = useAcceptInvite();
  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [validatedCode, setValidatedCode] = useState<string | null>(null);

  useEffect(() => {
    try {
      setValidatedCode(acceptCodeSchema.validateSync(code));
      setValidationErrors([]);
    } catch (e) {
      if (e instanceof Yup.ValidationError && e.errors.length > 0) {
        setValidationErrors(e.errors);
      }
    }
  }, [code]);

  function handleAccept() {
    if (!validatedCode) return;
    accept.mutate(validatedCode);
  }

  const { isPending, isSuccess, isError } = accept;

  return (
    <LoginWrapper>
      <Container
        sx={{
          height: '50vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <Typography
          variant="body1"
          sx={{ textAlign: 'center', textJustify: 'balance' }}
        >
          {t('b2b.acceptInvite.title')}
        </Typography>
        {validationErrors.length > 0 ? (
          <Alert severity="error">{validationErrors.map(err => t(err))}</Alert>
        ) : isSuccess ? (
          <Alert
            severity="success"
            action={
              <Button
                color="inherit"
                size="small"
                component={NavLink}
                to={B2B_LOGIN}
                sx={{
                  textTransform: 'uppercase',
                  lineHeight: '22.75px',
                  fontSize: '13px',
                  height: 'auto',
                }}
              >
                {t('b2b.acceptInvite.login')}
              </Button>
            }
          >
            {t('b2b.acceptInvite.success')}
          </Alert>
        ) : (
          <LoadingButton
            sx={{ alignSelf: 'center' }}
            loading={isPending}
            onClick={handleAccept}
          >
            {t('b2b.acceptInvite.acceptButton')}
          </LoadingButton>
        )}
        {isError ? (
          <Alert severity="error">{t('b2b.acceptInvite.failed')}</Alert>
        ) : null}
      </Container>
    </LoginWrapper>
  );
}

export default AcceptInvitePage;
