import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import ArrowLeftIcon from '../../icons/ArrowLeftIcon';
import ArrowOutwardIcon from '../../icons/ArrowOutwardIcon';

interface Props {
  text: string;
  to: string;
  back?: boolean;
  style?: React.CSSProperties | undefined;
}
export const NavigationButton = ({ text, to, back = false, style }: Props) => {
  const navigate = useNavigate();
  return (
    <Button
      variant="text"
      sx={{
        textDecoration: 'none',
        textTransform: 'none',
        color: 'unset',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '8px',
        ':hover': {
          backgroundColor: '#fff',
        },
        ...style,
      }}
      onClick={() => navigate(to)}
    >
      {back && <ArrowLeftIcon />}
      <Typography
        style={{ textTransform: 'none' }}
        variant="subtitle2"
        color="#141313"
      >
        {text}
      </Typography>
      {!back && <ArrowOutwardIcon />}
    </Button>
  );
};
