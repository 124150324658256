import React from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DeleteIcon from '../../icons/DeleteIcon';
import ConfirmationModal from './ConfirmationModal';

interface Props {
  title: string;
  action: () => void;
}

const DeleteConfirmationModal = ({ title, action }: Props) => {
  const { t } = useTranslation();

  return (
    <ConfirmationModal
      title={title}
      content={
        <Typography variant="h5">{t('deleteConfirmationMessage')}</Typography>
      }
      action={action}
      confirmButtonProps={{
        startIcon: <DeleteIcon fill="white" />,
      }}
    />
  );
};

export default DeleteConfirmationModal;
