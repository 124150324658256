import React from 'react';
import { Box, FormLabel } from '@mui/material';
import { useField } from 'formik';
import CurrencyField, { CurrencyFieldProps } from '../CurrencyField';

type Props = {
  name: string;
} & CurrencyFieldProps;

function FormikCurrencyField({ name, label, ...otherProps }: Props) {
  const [field, { error, touched }] = useField(name);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 0,
        alignItems: 'flex-start',
      }}
    >
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <CurrencyField
        {...field}
        {...otherProps}
        id={name}
        error={!!error && touched}
        helperText={error}
      />
    </Box>
  );
}

export default FormikCurrencyField;
