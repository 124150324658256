import { useMutation, useQuery } from '@tanstack/react-query';
import * as Yup from 'yup';
import api, { PaginatedItems, PaginatedRequestParams } from '../api';
import { queryAllPages } from '../../utils/request-utils';
import { PriceType } from '../../utils/price-class-utils';
import { queryClient } from '../../App';
import {
  ParkingZone,
  PriceClass,
  Vehicle,
  VehicleModel,
} from '../../entities/Vehicle';

export interface GetVehiclesParams extends PaginatedRequestParams {
  search?: string;
  modelId?: string;
  stateTypeSlug?: string;
  statusTypeSlug?: string;
}

export interface CreateUpdateParkingZoneParams {
  name: string;
  location: string;
  displayArea: {
    type: string;
    coordinates: number[][][];
  };
  parkingArea: {
    type: string;
    coordinates: number[][][];
  };
}

export interface CreateUpdatePriceClassBody {
  name: string;
  prices: {
    type: PriceType;
    amount: number;
  }[];
}

export interface GetParkingZonesParams extends PaginatedRequestParams {
  archived?: boolean;
}

export function useGetVehicle(vehicleId?: string) {
  return useQuery({
    queryKey: ['vehicle', vehicleId],
    queryFn: async () => {
      return (await api.getVehicle(vehicleId || '')).data;
    },
    enabled: !!vehicleId,
  });
}

export function useGetVehicles(params?: GetVehiclesParams) {
  return useQuery({
    queryKey: ['vehicles', params],
    queryFn: async () => (await api.getAllVehicles(params)).data,
  });
}

export function getCreateVehicleSchema() {
  return Yup.object({
    modelId: Yup.string().required(),
    variant: Yup.string().required(),
    registrationNumber: Yup.string().required(),
    status: Yup.object({
      typeSlug: Yup.string().required(),
      comment: Yup.string(),
    }).required(),
    priceClassId: Yup.string().required(),
    parkingZoneId: Yup.string(),
    parkingSpaceInfo: Yup.string(),
    fuelCard: Yup.string(),
    fuelCardPin: Yup.number(),
  });
}
const createVehicleInferenceSchema = getCreateVehicleSchema();

export type CreateVehicleParams = Yup.InferType<
  typeof createVehicleInferenceSchema
>;

export function useCreateVehicle({
  onSuccess,
  onError,
}: {
  onSuccess?: (v: Vehicle) => void;
  onError?: (err: unknown) => void;
}) {
  return useMutation({
    mutationFn: (body: CreateVehicleParams) => api.createVehicle(body),
    onSuccess: async response => {
      await queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      onSuccess?.(response.data);
    },
    onError(err) {
      onError?.(err);
    },
  });
}

export function getUpdateVehicleSchema() {
  return Yup.object({
    priceClassId: Yup.string().required(),
    parkingZoneId: Yup.string(),
    parkingSpaceInfo: Yup.string(),
    fuelCard: Yup.string(),
    fuelCardPin: Yup.number(),
    status: Yup.object({
      typeSlug: Yup.string().required(),
      comment: Yup.string(),
    }).required(),
  });
}
const updateVehicleInferenceSchema = getUpdateVehicleSchema();

export type UpdateVehicleParams = Yup.InferType<
  typeof updateVehicleInferenceSchema
>;

export function useUpdateVehicle({
  onSuccess,
  onError,
}: {
  onSuccess?: () => void;
  onError?: (err: unknown) => void;
}) {
  return useMutation({
    mutationFn: ({ id, body }: { id: string; body: UpdateVehicleParams }) =>
      api.updateVehicle(id, body),
    onSuccess: async (response, variables) => {
      queryClient.setQueryData(['vehicle', variables.id], response.data); // TODO parse/validate data
      await queryClient.invalidateQueries({ queryKey: ['vehicles'] });
      onSuccess?.();
    },
    onError(err) {
      onError?.(err);
    },
  });
}

export function useRemoveVehicle() {
  return useMutation({
    mutationFn: (vehicleId: string) => api.deleteVehicle(vehicleId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ['vehicles'] });
    },
  });
}

export function useGetVehicleModels<TData = VehicleModel[]>(
  select?: (data: VehicleModel[]) => TData,
) {
  return useQuery({
    queryKey: ['vehicle-models'],
    queryFn: async () => (await api.getVehiclesModels()).data,
    initialData: [],
    select,
  });
}

export function useGetVehicleActions(id: string) {
  return useQuery({
    queryKey: ['vehicle-actions', id],
    queryFn: async () => (await api.getVehicleActions(id)).data,
    enabled: !!id,
  });
}

export function useGetVehicleActionLogs(
  id: string,
  params: PaginatedRequestParams,
) {
  return useQuery({
    queryKey: ['vehicle-action-logs', id, params],
    queryFn: async () => (await api.getVehicleActionLogs(id, params)).data,
    enabled: !!id,
  });
}

export function useExecuteVehicleAction() {
  return useMutation({
    mutationFn: async (actionId: string) =>
      (await api.executeVehicleAction(actionId)).data,
    onSuccess: log => {
      queryClient.invalidateQueries({
        queryKey: ['vehicle-action-logs', log.vehicle.id],
      });
    },
  });
}

export function useGetPriceClasses<TData = PaginatedItems<PriceClass>>({
  params,
  select,
}: {
  params?: PaginatedRequestParams;
  select?: (data: PaginatedItems<PriceClass>) => TData;
}) {
  return useQuery({
    queryKey: ['price-classes', params],
    queryFn: async () => (await api.getPriceClasses(params)).data,
    select,
  });
}

export function useGetPriceClass(priceClassId?: string) {
  return useQuery({
    queryKey: ['price-class', priceClassId],
    queryFn: async () => (await api.getPriceClass(priceClassId || '')).data,
    enabled: !!priceClassId,
  });
}

export function useDeletePriceClass() {
  return useMutation({
    mutationFn: (priceClassId: string) => api.deletePriceClass(priceClassId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['price-classes'] });
    },
  });
}

export function useCreatePriceClass() {
  return useMutation({
    mutationFn: async (body: CreateUpdatePriceClassBody) =>
      (await api.createPriceClass(body)).data,
  });
}

export function useUpdatePriceClass() {
  return useMutation({
    mutationFn: async ({
      priceClassId,
      body,
    }: {
      priceClassId: string;
      body: CreateUpdatePriceClassBody;
    }) => (await api.updatePriceClass(priceClassId, body)).data,
  });
}

export function useGetParkingZones<TData = ParkingZone[]>({
  params,
  select,
}: {
  params?: GetParkingZonesParams;
  select?: (data: ParkingZone[]) => TData;
} = {}) {
  return useQuery({
    queryKey: ['parking-zones', params],
    queryFn: async () => await queryAllPages(api.getParkingZones, params),
    select,
  });
}

export function useDeleteParkingZone() {
  return useMutation({
    mutationFn: (parkingZoneId: string) => api.deleteParkingZone(parkingZoneId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['parking-zones'] });
    },
  });
}
