import React from 'react';
import {
  DateTimePicker as MuiDateTimePicker,
  LocalizationProvider,
  DateTimePickerProps,
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import { TextFieldProps } from '@mui/material';
import { DATE_TIME, DATE_TIME_UTC_FORMAT } from '../../utils/datetime-utils';
import TextField from './TextField';

interface Props
  extends Omit<DateTimePickerProps<Moment>, 'value' | 'onChange'> {
  value?: string;
  onChange: (value: string) => void;
  placeholder?: string;
  error?: boolean;
  helperText?: string;
}

const DateTimePicker = ({
  value,
  onChange,
  placeholder,
  error,
  helperText,
  ...props
}: Props) => {
  const onDateChange = (value: Moment | null) => {
    onChange(value ? value.format(DATE_TIME_UTC_FORMAT) : '');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDateTimePicker
        {...props}
        value={value ? moment(value, DATE_TIME_UTC_FORMAT) : null}
        onChange={onDateChange}
        views={['day', 'hours', 'minutes']}
        format={DATE_TIME}
        slots={{ textField: TextField as React.FC<TextFieldProps> }}
        slotProps={{
          textField: {
            placeholder,
            error,
            helperText,
          },
          actionBar: { actions: [] },
          day: {
            disableRipple: true,
            sx: {
              borderRadius: '6px',
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '24px',
              '&:hover, :focus': {
                backgroundColor: '#F3F2F0',
                fontWeight: 600,
              },
              '&.Mui-selected': {
                backgroundColor: '#00A399',
                '&:hover, :focus': { backgroundColor: '#00A399' },
              },
              '&.MuiPickersDay-dayOutsideMonth': {
                color: 'inherit',
                opacity: 0.4,
              },
            },
          },
          digitalClockSectionItem: {
            disableRipple: true,
            sx: {
              height: 'auto',
              borderRadius: '6px',
              fontSize: '13px',
              fontWeight: 400,
              lineHeight: '24px',
              py: 0.5,
              '&:hover, :focus': {
                backgroundColor: '#F3F2F0',
                fontWeight: 600,
              },
              '&.Mui-selected': {
                backgroundColor: '#00A399',
                '&:hover, :focus': { backgroundColor: '#00A399' },
              },
            },
          },
        }}
        ampm={false}
        timeSteps={{ minutes: 1 }}
        dayOfWeekFormatter={(day: string) => day.substring(0, 2)}
        showDaysOutsideCurrentMonth
      />
    </LocalizationProvider>
  );
};

export default DateTimePicker;
