import React from 'react';
import { useTranslation } from 'react-i18next';
import { TabHeader } from '../../Common/TabHeader';

const CustomerDetailsNotes = () => {
  const { t } = useTranslation();

  return <TabHeader text={t('customers.tabs.notes')}></TabHeader>;
};

export default CustomerDetailsNotes;
