import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, Route, Routes, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { Close } from '@mui/icons-material';
import { TabHeader } from '../../Common/TabHeader';
import RelatedCustomersList from '../RelatedCustomer/RelatedCustomersList';
import AddIcon from '../../../icons/AddIcon';
import { SecondaryButton } from '../../Common/SecondaryButton';
import CustomerDetailsRelatedAccountAdd from './CustomerDetailsRelatedAccountAdd';
import CustomerDetailsRelatedAccountEdit from './CustomerDetailsRelatedAccountEdit';

const SubPaths = {
  add: 'add',
  edit: ':relatedCustomerId/edit',
};

function BackButton() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <SecondaryButton startIcon={<Close />} onClick={() => navigate(-1)}>
      {t('cancel')}
    </SecondaryButton>
  );
}

const CustomerDetailsRelatedAccounts = () => {
  const { t } = useTranslation();

  return (
    <>
      <TabHeader text={t('customers.tabs.relatedAccounts')}>
        <Routes>
          <Route path={SubPaths.add} element={<BackButton />} />
          <Route path={SubPaths.edit} element={<BackButton />} />
          <Route
            path="*"
            element={
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                startIcon={<AddIcon />}
                component={NavLink}
                to={SubPaths.add}
              >
                {t('relatedCustomers.buttons.add')}
              </Button>
            }
          />
        </Routes>
      </TabHeader>
      <Routes>
        <Route index element={<RelatedCustomersList />} />
        <Route
          path={SubPaths.edit}
          element={<CustomerDetailsRelatedAccountEdit />}
        />
        <Route
          path={SubPaths.add}
          element={<CustomerDetailsRelatedAccountAdd />}
        />
      </Routes>
    </>
  );
};

export default CustomerDetailsRelatedAccounts;
