import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import moment from 'moment';
import { TabHeader } from '../../Common/TabHeader';
import { SecondaryButton } from '../../Common/SecondaryButton';
import { RESERVATIONS } from '../../../utils/routes-constants';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import { useCreateMaintenanceReservation } from '../api';
import AddMaintenanceReservationForm from './AddMaintenanceReservationForm';
import {
  AddMaintenanceReservationState,
  getAddMaintenanceReservationValidationSchema,
} from './addMaintenanceReservationData';

const initialValues = {
  vehicleId: '',
  startDateTime: '',
  endDateTime: '',
};

const AddMaintenanceReservation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { successSnack, apiErrorSnack } = useSnacks();

  const createMaintenanceReservation = useCreateMaintenanceReservation();

  const onSubmit = (values: AddMaintenanceReservationState) => {
    const { startDateTime, endDateTime } = values;

    createMaintenanceReservation.mutate(
      {
        vehicleId: values.vehicleId,
        startDateTime: moment.utc(startDateTime).format(),
        endDateTime: moment.utc(endDateTime).format(),
      },
      {
        onSuccess: () => {
          successSnack(t('reservations.snacks.created'));
          navigate(RESERVATIONS);
        },
        onError: e => apiErrorSnack(e),
      },
    );
  };

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <TabHeader text={t('reservations.form.titles.addMaintenanceReservation')}>
        <SecondaryButton
          startIcon={<Close />}
          onClick={() => navigate(RESERVATIONS)}
        >
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <Formik
        initialValues={initialValues}
        validationSchema={getAddMaintenanceReservationValidationSchema(t)}
        onSubmit={onSubmit}
        enableReinitialize
        initialTouched={{
          startDateTime: true,
        }}
      >
        <AddMaintenanceReservationForm />
      </Formik>
    </Box>
  );
};

export default AddMaintenanceReservation;
