import { Box, TableRow, Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import styled from '@emotion/styled';
import InfoTooltipIcon from './InfoTooltipIcon';

interface Props {
  title?: string;
  children?: ReactNode;
  titleButton?: ReactNode;
  infoTooltipText?: string;
}
const TableWrapper = ({
  children,
  title,
  titleButton,
  infoTooltipText,
}: Props) => {
  return (
    <Box
      sx={{
        height: 'auto',
        maxWidth: '800px',
        marginTop: '40px',
      }}
    >
      <Box
        sx={{
          marginBottom: '16px',
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        {title && <Typography variant="h4">{title}</Typography>}
        {infoTooltipText && <InfoTooltipIcon text={infoTooltipText} />}
        <Box sx={{ marginLeft: 'auto' }}>{titleButton}</Box>
      </Box>

      {children}
    </Box>
  );
};

export const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FAF9F8',
  },
}));

export default TableWrapper;
