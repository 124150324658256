import React from 'react';
import { Box } from '@mui/material';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RelatedCustomerEditForm from '../../../components/Customers/RelatedCustomer/RelatedCustomerEditForm';
import { TabHeader } from '../../../components/Common/TabHeader';
import { SecondaryButton } from '../../../components/Common/SecondaryButton';
import { B2B_USERS } from '../../../utils/b2b-routes-constants';

function EditMyUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <TabHeader text={t('b2b.myUsers.editRelatedCustomer')}>
        <SecondaryButton
          startIcon={<Close />}
          onClick={() => navigate(B2B_USERS)}
        >
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <RelatedCustomerEditForm onSuccess={() => navigate(B2B_USERS)} />
    </Box>
  );
}

export default EditMyUser;
