import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { MuiTelInput } from 'mui-tel-input';
import { FormLabel } from '@mui/material';
import { ErrorHelperText, helperTextProps } from '../TextField';
import { InputFieldProps } from './InputField';

type Props = InputFieldProps;

function PhoneField({ name, label }: Props) {
  const { setFieldValue } = useFormikContext();
  const [field, { error, touched }] = useField(name);

  const [value, setValue] = useState('');
  function handleChange(phoneNumber: string) {
    setValue(phoneNumber);
    setFieldValue(name, phoneNumber);
  }

  return (
    <>
      {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
      <MuiTelInput
        {...field}
        name={name}
        value={value}
        onChange={handleChange}
        defaultCountry={process.env.REACT_APP_COUNTRY}
        size="small"
        preferredCountries={['EE', 'LV', 'LT']}
        error={!!error && touched}
        helperText={error && <ErrorHelperText>{error}</ErrorHelperText>}
        FormHelperTextProps={helperTextProps}
      />
    </>
  );
}

export default PhoneField;
