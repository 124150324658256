import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import TextField, { TextFieldProps } from './TextField';

export type NumberFieldProps = NumericFormatProps<TextFieldProps> & {
  name: string;
  onChange?: (event: { target: { name: string; value: string } }) => void;
};

const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  NumberFieldProps
>(function NumericFormatCustom(props, ref) {
  const { name, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      decimalScale={0}
      getInputRef={ref}
      allowNegative={false}
      onValueChange={({ value }) => {
        onChange?.({
          target: {
            name,
            value,
          },
        });
      }}
      thousandSeparator=" "
      valueIsNumericString
    />
  );
});

function NumberField({ InputProps, ...other }: NumberFieldProps) {
  return (
    <TextField
      {...other}
      InputProps={{
        inputMode: 'numeric',
        // Hacky hack from MUI docs
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumericFormatCustom as any,
        ...InputProps,
      }}
    />
  );
}

export default NumberField;
