import { CircularProgress, CircularProgressProps } from '@mui/material';
import React from 'react';

type Props = CircularProgressProps;

const LoadingIcon = (props: Props) => {
  return <CircularProgress {...props} />;
};

export default LoadingIcon;
