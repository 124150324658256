import React, { useState } from 'react';
import { Alert } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { PaymentElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

/**
 * Should be nested within a `<form>` that handles submits.
 * @see useAddStripePaymentMethod
 */
function StripePaymentMethodElement() {
  const { t } = useTranslation();
  const [hasStripeError, setHasStripeError] = useState(
    process.env.REACT_APP_STRIPE_PK == null,
  );
  return hasStripeError ? (
    <Alert severity="error">{t('b2b.paymentMethod.stripeConfError')}</Alert>
  ) : (
    <ErrorBoundary
      fallback={
        <Alert severity="error">{t('b2b.paymentMethod.stripeConfError')}</Alert>
      }
    >
      <PaymentElement
        onLoadError={e => {
          // eslint-disable-next-line no-console
          console.error(e);
          setHasStripeError(true);
        }}
      />
    </ErrorBoundary>
  );
}

export default StripePaymentMethodElement;
