import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import {
  AddEditCostGroupRequest,
  addEditCostGroupSchema,
  useAddCostGroup,
} from './api';
import CostGroupForm from './CostGroupForm';

function AddCostGroup() {
  const { t } = useTranslation();
  const { successSnack, apiErrorSnack } = useSnacks();

  const addCostCentre = useAddCostGroup();

  const handleSubmit = (values: AddEditCostGroupRequest) => {
    addCostCentre.mutate(addEditCostGroupSchema.cast(values), {
      onSuccess: () => {
        successSnack(t('b2b.wallet.costGroup.snacks.added'));
      },
      onError: e => apiErrorSnack(e),
    });
  };

  return <CostGroupForm onSubmit={handleSubmit} />;
}

export default AddCostGroup;
