import { TFunction } from 'i18next';
import * as Yup from 'yup';
import { ReservationDetails } from '../../../entities/Reservation';

export interface EditReservationState {
  vehicleId: string;
  parkingZoneId: string;
}

export const getEditReservationInitialState = (
  reservation: ReservationDetails,
): EditReservationState => ({
  vehicleId: reservation.vehicle.id,
  parkingZoneId: reservation.pickupParkingZone.id,
});

export const getEditReservationValidationSchema = (t: TFunction) =>
  Yup.object({
    vehicleId: Yup.string().required(t('validationMessages.required')),
    parkingZoneId: Yup.string().required(t('validationMessages.required')),
  });
