import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Typography } from '@mui/material';

function FormErrorsMessage() {
  const { t } = useTranslation();
  const { errors, submitCount } = useFormikContext();

  const fieldsWithError = Object.keys(errors);

  return submitCount > 0 && fieldsWithError.length > 0 ? (
    <Typography color="error">
      {t('error.formHasErrors', { errorCount: fieldsWithError.length })}
    </Typography>
  ) : null;
}

export default FormErrorsMessage;
