import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { InputAdornment } from '@mui/material';
import { moneyString } from '../../entities';
import TextField, { TextFieldProps } from './TextField';

const CURRENCY_SYMBOL = '€';

export type CurrencyFieldProps = NumericFormatProps<TextFieldProps> & {
  name: string;
  onChange?: (event: { target: { name: string; value: string } }) => void;
  isAllowed?: (value: moneyString) => boolean;
};

const NumericFormatCustom = React.forwardRef<
  NumericFormatProps,
  CurrencyFieldProps
>(function NumericFormatCustom(props, ref) {
  const { name, onChange, isAllowed, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      isAllowed={({ value }) => {
        return isAllowed?.(value) ?? true;
      }}
      onValueChange={({ value }) => {
        onChange?.({
          target: {
            name,
            value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      decimalScale={2}
    />
  );
});

function CurrencyField(props: CurrencyFieldProps) {
  return (
    <TextField
      {...props}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">{CURRENCY_SYMBOL}</InputAdornment>
        ),
        inputMode: 'numeric',
        // Hacky hack from MUI docs
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        inputComponent: NumericFormatCustom as any,
        ...props.InputProps,
      }}
    />
  );
}

export default CurrencyField;
