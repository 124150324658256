import React, { useState } from 'react';
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import GoogleMapReact from 'google-map-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ParkingZone } from '../../../entities/Vehicle';
import {
  handleParkingZoneMapApiLoaded,
  hideGoogleLogoStyles,
} from '../../../utils/google-maps-utils';
import { useDeleteParkingZone } from '../../../store/vehicle/queries';
import { useAppDispatch } from '../../../store';
import { openModal } from '../../../store/modal/slice';
import { ModalType } from '../../../utils/modal-utils';

export const ParkingZoneCard = (props: { parkingZone: ParkingZone }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const deleteParkingZone = useDeleteParkingZone();

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => navigate(`${props.parkingZone.id}/edit`);

  const handleDeleteClick = () => {
    dispatch(
      openModal({
        type: ModalType.DELETE_CONFIRMATION_MODAL,
        props: {
          title: 'settings.zones.deleteParkingZone',
          action: () => deleteParkingZone.mutate(props.parkingZone.id),
        },
      }),
    );
  };

  return (
    <Paper
      sx={{
        boxShadow: '0px 1px 1px 0px #0000001A',
        backgroundColor: '#F6F5F4',
        borderRadius: '8px',
      }}
    >
      <Box
        sx={{
          height: 184,
          backgroundColor: '#d5d2d2',
          display: 'grid',
          ...hideGoogleLogoStyles,
        }}
      >
        <GoogleMapReact
          key={props.parkingZone.id}
          options={{
            clickableIcons: false,
            fullscreenControl: false,
          }}
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAPS_API as string,
            /**
               additional libraries must be present in all map instances even if they are not used is this usecase
               https://github.com/google-map-react/google-map-react/blob/HEAD/API.md#important-note
             **/
            libraries: ['drawing'].join(','),
          }}
          draggable={false}
          center={{
            lat: 59.416729,
            lng: 24.798884,
          }}
          defaultZoom={18}
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) =>
            handleParkingZoneMapApiLoaded(map, maps, props.parkingZone)
          }
        ></GoogleMapReact>
        {!props.parkingZone.deleted && (
          <IconButton
            onClick={handleMenuClick}
            size="small"
            disableTouchRipple
            sx={{
              position: 'absolute',
              mt: 1,
              mr: 1,
              justifySelf: 'flex-end',
              height: 32,
              width: 32,
              backgroundColor: '#F6F5F4',
              borderRadius: '7px',
              padding: 0,
            }}
          >
            <MoreVert />
          </IconButton>
        )}
      </Box>
      <Box
        sx={{
          height: 56,
          display: 'flex',
          alignItems: 'center',
          padding: '15px 20px',
        }}
      >
        <Typography variant="h6">{props.parkingZone.name}</Typography>
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleEditClick}>{t('edit')}</MenuItem>
        <MenuItem onClick={handleDeleteClick}>{t('delete')}</MenuItem>
      </Menu>
    </Paper>
  );
};
