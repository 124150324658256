import { TFunction } from 'i18next';
import moment from 'moment';
import { isDate } from 'moment/moment';
import * as Yup from 'yup';
import { DATE_TIME, parseDate } from '../../../utils/datetime-utils';
import {
  ReservationDetails,
  ReservationStatusType,
} from '../../../entities/Reservation';

export interface EditMaintenanceReservationState {
  startDateTime: string;
  endDateTime: string;
}

export const getEditMaintenanceReservationInitialState = (
  reservation: ReservationDetails,
): EditMaintenanceReservationState => {
  const { startDateTime, endDateTime } = reservation;
  return {
    startDateTime,
    endDateTime,
  };
};

export const getEditMaintenanceReservationValidationSchema = (
  t: TFunction,
  statusType: ReservationStatusType,
) => {
  let startDateTime = Yup.date().transform(parseDate);
  if (statusType === 'upcoming') {
    // Start time shouldn't actually be changed for reservations other than upcoming ones,
    // so validate conditionally here.
    startDateTime = startDateTime
      .test(
        'maxStartDateTime',
        (value: Date | undefined, { parent: { endDateTime }, createError }) => {
          if (isDate(endDateTime) && moment(value).isSameOrAfter(endDateTime)) {
            return createError({
              message: t('validationMessages.maxDate', {
                value: moment(endDateTime).format(DATE_TIME),
              }),
            });
          }
          return true;
        },
      )
      .required(t('validationMessages.required'));
  }
  return Yup.object({
    startDateTime,
    endDateTime: Yup.date()
      .transform(parseDate)
      .test(
        'minEndDateTime',
        (
          value: Date | undefined,
          { parent: { startDateTime }, createError },
        ) => {
          if (
            isDate(startDateTime) &&
            moment(value).isSameOrBefore(startDateTime)
          ) {
            return createError({
              message: t('validationMessages.minDate', {
                value: moment(startDateTime).format(DATE_TIME),
              }),
            });
          }
          return true;
        },
      )
      .required(t('validationMessages.required')),
  });
};
