import React from 'react';

const SearchIcon = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.0917 15.9083L14 12.8416C15.2001 11.3453 15.7812 9.446 15.624 7.53434C15.4668 5.62267 14.5831 3.84391 13.1546 2.56379C11.7261 1.28368 9.8615 0.599512 7.9441 0.651979C6.0267 0.704446 4.20226 1.48956 2.84595 2.84587C1.48963 4.20219 0.704522 6.02662 0.652055 7.94402C0.599588 9.86143 1.28375 11.7261 2.56387 13.1545C3.84398 14.583 5.62275 15.4667 7.53441 15.6239C9.44608 15.7812 11.3453 15.2 12.8417 13.9999L15.9083 17.0666C15.9858 17.1447 16.078 17.2067 16.1795 17.249C16.2811 17.2913 16.39 17.3131 16.5 17.3131C16.61 17.3131 16.7189 17.2913 16.8205 17.249C16.922 17.2067 17.0142 17.1447 17.0917 17.0666C17.2419 16.9112 17.3258 16.7035 17.3258 16.4874C17.3258 16.2713 17.2419 16.0636 17.0917 15.9083V15.9083ZM8.16666 13.9999C7.01294 13.9999 5.88512 13.6578 4.92584 13.0168C3.96655 12.3759 3.21888 11.4648 2.77737 10.3989C2.33585 9.33301 2.22034 8.16012 2.44542 7.02856C2.6705 5.897 3.22607 4.8576 4.04187 4.0418C4.85768 3.22599 5.89708 2.67042 7.02864 2.44534C8.16019 2.22026 9.33308 2.33578 10.399 2.77729C11.4649 3.2188 12.3759 3.96647 13.0169 4.92576C13.6579 5.88505 14 7.01286 14 8.16659C14 9.71368 13.3854 11.1974 12.2915 12.2914C11.1975 13.3853 9.71376 13.9999 8.16666 13.9999V13.9999Z"
      fill="#33302F"
    />
  </svg>
);

export default SearchIcon;
