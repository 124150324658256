import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Add } from '@mui/icons-material';
import { Button, debounce } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import {
  GridColDef,
  GridRenderCellParams,
  GridRowParams,
  GridRowsProp,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import moment from 'moment';
import { TabHeader } from '../../Common/TabHeader';
import { useAppDispatch, useAppSelector } from '../../../store';
import { deleteBonusCode, getBonusCodes } from '../../../store/bonusCode/thunk';
import { PaginatedTable } from '../../Common/PaginatedTable';
import { BonusCode, GetBonusCodeParams } from '../../../entities/BonusCode';
import { DataState, ListDataState } from '../../../entities/DataState';
import {
  revertBonusCodes,
  revertDeleteBonusCode,
} from '../../../store/bonusCode/slice';
import { isLoadingFinalized, LoadingStatus } from '../../../utils/query-enums';
import { DATE_ONLY } from '../../../utils/datetime-utils';
import { openModal } from '../../../store/modal/slice';
import { ModalType } from '../../../utils/modal-utils';
import BonusCodesToolbar from './BonusCodesToolbar';
import BonusCodesTableDropdown from './BonusCodesTableDropdown';

const BonusCodes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const bonusCodes: ListDataState<BonusCode> = useAppSelector(
    state => state.bonusCodeReducer.bonusCodes,
  );
  const deleteBonusCodeState: DataState<BonusCode> = useAppSelector(
    state => state.bonusCodeReducer.deleteBonusCode,
  );

  const [paginatedRequestParams, setPaginatedRequestParams] =
    useState<GetBonusCodeParams>({});
  const [rows, setRows] = useState<GridRowsProp>([]);
  const [totalItems, setTotalItems] = useState(0);

  const fetchBonusCodes = useCallback(
    debounce((params: GetBonusCodeParams) => {
      setPaginatedRequestParams(params);
      dispatch(getBonusCodes(params));
    }, 300),
    [],
  );

  useEffect(() => {
    if (bonusCodes.loading === LoadingStatus.SUCCEEDED && bonusCodes.data) {
      const {
        items,
        pagination: { totalItems },
      } = bonusCodes.data;

      setRows(items);
      setTotalItems(totalItems);
    }
  }, [bonusCodes]);

  useEffect(() => {
    if (deleteBonusCodeState.loading === LoadingStatus.SUCCEEDED) {
      dispatch(revertDeleteBonusCode());
      fetchBonusCodes(paginatedRequestParams);
    }
  }, [deleteBonusCodeState.loading]);

  useEffect(() => {
    return () => {
      dispatch(revertBonusCodes());
    };
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'code',
      headerName: t('settings.bonusCodes.code'),
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        params.value,
    },
    {
      field: 'amount',
      headerName: t('settings.bonusCodes.amount'),
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        `${params.value} €`,
    },
    {
      field: 'validFrom',
      headerName: t('settings.bonusCodes.validFrom'),
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        params.value ? moment(params.value).format(DATE_ONLY) : '',
    },
    {
      field: 'validTo',
      headerName: t('settings.bonusCodes.validTo'),
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<string>) =>
        params.value ? moment(params.value).format(DATE_ONLY) : '',
    },
    {
      field: 'quantity',
      headerName: t('settings.bonusCodes.quantity'),
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value,
    },
    {
      field: 'used',
      headerName: t('settings.bonusCodes.used'),
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value,
    },
    {
      field: 'isNewCustomer',
      headerName: t('settings.bonusCodes.isNewCustomer'),
      sortable: false,
      flex: 1,
      valueFormatter: (params: GridValueFormatterParams<boolean>) =>
        params.value ? t('yes') : t('no'),
    },
    {
      field: 'actions',
      headerName: '',
      type: 'actions',
      width: 50,
      sortable: false,
      align: 'right',
      renderCell: (params: GridRenderCellParams) => (
        <BonusCodesTableDropdown
          onEditPriceClass={() => navigate(`${params.row.id}/edit`)}
          onDeleteBonusCode={() =>
            dispatch(
              openModal({
                type: ModalType.DELETE_CONFIRMATION_MODAL,
                props: {
                  title: 'settings.bonusCodes.deleteBonusCode',
                  action: () => dispatch(deleteBonusCode(params.row.id)),
                },
              }),
            )
          }
        />
      ),
    },
  ];

  return (
    <>
      <TabHeader text={t('settings.tabs.codes')}>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          startIcon={<Add />}
          onClick={() => navigate('new')}
        >
          {t('settings.bonusCodes.buttons.addNew')}
        </Button>
      </TabHeader>
      <PaginatedTable
        rows={rows}
        columns={columns}
        totalItems={totalItems}
        isLoading={!isLoadingFinalized(bonusCodes.loading)}
        fetchRows={fetchBonusCodes}
        Toolbar={BonusCodesToolbar}
        onRowClick={(params: GridRowParams) =>
          dispatch(
            openModal({
              type: ModalType.BONUS_CODE_CUSTOMERS_MODAL,
              props: {
                id: params.row.id,
                title: 'settings.bonusCodeCustomers.listOfCustomers',
              },
            }),
          )
        }
      />
    </>
  );
};

export default BonusCodes;
