import { Box, Typography } from '@mui/material';
import React from 'react';
import { NavigationButton } from './NavigationButton';

interface Props {
  text: string;
  link?: string;
  linkText?: string;
}
export const MainHeader = ({ text, link, linkText }: Props) => (
  <>
    {link && linkText && (
      <Box
        style={{
          position: 'fixed',
          zIndex: '1200',
          marginTop: '-42px',
          marginBottom: '12px',
          width: 'fit-content',
        }}
      >
        <NavigationButton to={link} back={true} text={linkText} />
      </Box>
    )}
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <Typography variant="h3" display="inline">
        {text}
      </Typography>
    </div>
  </>
);
