import React, { ReactElement, useCallback, useEffect, useState } from 'react';
import { Box, debounce, Grid, Tooltip } from '@mui/material';
import FullCalendar from '@fullcalendar/react';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'; // a plugin!
import { EventContentArg } from '@fullcalendar/core';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import {
  GetReservationParams,
  ReservationListItem,
} from '../../entities/Reservation';
import { DATE_TIME } from '../../utils/datetime-utils';
import { RESERVATIONS } from '../../utils/routes-constants';
import ReservationsCalendarToolbar from './ReservationsCalendarToolbar';
import { useGetReservations } from './api';

interface EventTooltipProps {
  reservation: ReservationListItem;
}

export const EventTooltip = ({ reservation }: EventTooltipProps) => {
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={6}>
        {t('reservations.registerNumber')}
      </Grid>
      <Grid item xs={6}>
        <Link
          style={{ color: 'white' }}
          to={`${RESERVATIONS}/${reservation.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {reservation.vehicle.registrationNumber}
        </Link>
      </Grid>
      <Grid item xs={6}>
        {t('reservations.zone')}
      </Grid>
      <Grid item xs={6}>
        {reservation.pickupParkingZone.name}
      </Grid>
      <Grid item xs={6}>
        {t('reservations.reservationStart')}
      </Grid>
      <Grid item xs={6}>
        {moment.utc(reservation.startDateTime).format(DATE_TIME)}
      </Grid>

      <Grid item xs={6}>
        {t('reservations.reservationEnd')}
      </Grid>
      <Grid item xs={6}>
        {moment.utc(reservation.endDateTime).format(DATE_TIME)}
      </Grid>
      <Grid item xs={6}>
        {t('reservations.customer')}
      </Grid>
      <Grid item xs={6}>
        {reservation.customer?.name}
      </Grid>
      <Grid item xs={6}>
        {t('reservations.phoneNumber')}
      </Grid>
      <Grid item xs={6}>
        {reservation.customer?.phoneNumber}
      </Grid>
      <Grid item xs={6}>
        {t('reservations.email')}
      </Grid>
      <Grid item xs={6}>
        {reservation.customer?.email}
      </Grid>
    </Grid>
  );
};

export const Event = (args: EventContentArg): ReactElement => {
  const event = args.event;
  const { extendedProps } = event;

  args.backgroundColor = '#FB9263';

  const reservation = extendedProps as ReservationListItem;

  return (
    <Tooltip
      arrow
      placement="right"
      title={<EventTooltip reservation={reservation} />}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
        }}
      >
        {reservation.pickupParkingZone.name}
      </div>
    </Tooltip>
  );
};
const ReservationsCalendar = () => {
  const { t } = useTranslation();

  const [reservationParams, setReservationParams] =
    useState<GetReservationParams>({ page: 1, limit: 100 });

  const [searchFocus, setSearchFocus] = useState(false);

  const { data: reservations } = useGetReservations(reservationParams);

  const fetchReservations = useCallback(
    debounce(
      (params: GetReservationParams) => setReservationParams(params),
      300,
    ),
    [],
  );

  useEffect(() => {
    if (reservationParams.fromDateTime && reservationParams.toDateTime) {
      fetchReservations(reservationParams);
    }
  }, [reservationParams]);

  const reservationsMap = new Map(
    reservations?.items.map((obj: ReservationListItem) => [
      obj.vehicle.id,
      obj,
    ]),
  );

  const resources =
    Array.from(reservationsMap, ([vehicleId, value]) => ({
      id: vehicleId,
      title: value.vehicle.registrationNumber,
      groupId: value.pickupParkingZone.name,
    })) ?? [];

  const reservationItems =
    reservations?.items.map((reservation: ReservationListItem) => {
      const inPast = moment(reservation.endDateTime)
        .utc()
        .isBefore(moment().utc());
      const upcoming = moment(reservation.startDateTime)
        .utc()
        .isAfter(moment().utc());
      const color = reservation.maintenance
        ? 'blue'
        : inPast
        ? 'gray'
        : upcoming
        ? '#FF8400'
        : 'green';
      return {
        id: reservation.id,
        resourceId: reservation.vehicle.id,
        title: reservation.vehicle.registrationNumber,
        start: new Date(reservation.startDateTime),
        end: new Date(reservation.endDateTime),
        extendedProps: reservation,
        backgroundColor: color,
      };
    }) ?? [];

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <ReservationsCalendarToolbar
        params={reservationParams}
        setParams={setReservationParams}
        searchFocus={searchFocus}
        setSearchFocus={setSearchFocus}
      />
      <FullCalendar
        customButtons={{
          myCustomButton: {
            text: 'Add new?',
            click: () => {
              // TODO: for later usage
            },
          },
        }}
        headerToolbar={{
          left: 'prev,next',
          center: 'title',
          right:
            'resourceTimelineDay,resourceTimelineWeek,resourceTimelineMonth',
        }}
        initialView="resourceTimelineMonth"
        views={{
          resourceTimelineDay: { buttonText: t('reservations.calendar.day') },
          resourceTimelineWeek: {
            buttonText: t('reservations.calendar.week'),
            slotDuration: '24:00:00',
            slotLabelFormat: [
              { weekday: 'short', day: '2-digit' }, // lower level of text
            ],
          },
          resourceTimelineMonth: {
            buttonText: t('reservations.calendar.month'),
          },
        }}
        displayEventTime={false}
        resourceGroupField="groupId"
        resourceAreaWidth="12%"
        resourceAreaHeaderContent={t('reservations.calendar.parkingZones')}
        height="auto"
        eventContent={Event}
        schedulerLicenseKey="CC-Attribution-NonCommercial-NoDerivatives"
        plugins={[resourceTimelinePlugin]}
        events={reservationItems}
        resources={resources}
      />
    </Box>
  );
};

export default ReservationsCalendar;
