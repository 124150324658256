import { createAction, createSlice } from '@reduxjs/toolkit';
import { DataState, ListDataState } from '../../entities/DataState';
import { BonusCode } from '../../entities/BonusCode';
import { LoadingStatus } from '../../utils/query-enums';
import {
  createBonusCode,
  deleteBonusCode,
  getBonusCode,
  getBonusCodes,
  updateBonusCode,
} from './thunk';

interface BonusCodeState {
  bonusCodes: ListDataState<BonusCode>;
  bonusCode: DataState<BonusCode>;
  createBonusCode: DataState<BonusCode>;
  updateBonusCode: DataState<BonusCode>;
  deleteBonusCode: DataState<BonusCode>;
}

const initialState: BonusCodeState = {
  bonusCodes: { loading: LoadingStatus.IDLE },
  bonusCode: { loading: LoadingStatus.IDLE },
  createBonusCode: { loading: LoadingStatus.IDLE },
  updateBonusCode: { loading: LoadingStatus.IDLE },
  deleteBonusCode: { loading: LoadingStatus.IDLE },
};

const bonusCodeSlice = createSlice({
  name: 'bonusCodeSlice',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getBonusCodes.pending, state => ({
      ...state,
      bonusCodes: {
        loading: LoadingStatus.PENDING,
      },
    }));
    builder.addCase(getBonusCodes.fulfilled, (state, action) => ({
      ...state,
      bonusCodes: {
        data: action.payload,
        loading: LoadingStatus.SUCCEEDED,
      },
    }));
    builder.addCase(getBonusCodes.rejected, state => ({
      ...state,
      bonusCodes: {
        loading: LoadingStatus.FAILED,
      },
    }));
    builder.addCase(revertBonusCodes, state => ({
      ...state,
      bonusCodes: {
        loading: LoadingStatus.IDLE,
      },
    }));
    builder.addCase(getBonusCode.pending, state => ({
      ...state,
      bonusCode: {
        loading: LoadingStatus.PENDING,
      },
    }));
    builder.addCase(getBonusCode.fulfilled, (state, action) => ({
      ...state,
      bonusCode: {
        data: action.payload,
        loading: LoadingStatus.SUCCEEDED,
      },
    }));
    builder.addCase(getBonusCode.rejected, state => ({
      ...state,
      bonusCode: {
        loading: LoadingStatus.FAILED,
      },
    }));
    builder.addCase(revertBonusCode, state => ({
      ...state,
      bonusCode: {
        loading: LoadingStatus.IDLE,
      },
    }));
    builder.addCase(createBonusCode.pending, state => ({
      ...state,
      createBonusCode: {
        loading: LoadingStatus.PENDING,
      },
    }));
    builder.addCase(createBonusCode.fulfilled, state => ({
      ...state,
      createBonusCode: {
        loading: LoadingStatus.SUCCEEDED,
      },
    }));
    builder.addCase(createBonusCode.rejected, (state, action) => ({
      ...state,
      createBonusCode: {
        error: action.payload,
        loading: LoadingStatus.FAILED,
      },
    }));
    builder.addCase(revertCreateBonusCode, state => ({
      ...state,
      createBonusCode: {
        loading: LoadingStatus.IDLE,
      },
    }));
    builder.addCase(updateBonusCode.pending, state => ({
      ...state,
      updateBonusCode: {
        loading: LoadingStatus.PENDING,
      },
    }));
    builder.addCase(updateBonusCode.fulfilled, state => ({
      ...state,
      updateBonusCode: {
        loading: LoadingStatus.SUCCEEDED,
      },
    }));
    builder.addCase(updateBonusCode.rejected, (state, action) => ({
      ...state,
      updateBonusCode: {
        error: action.payload,
        loading: LoadingStatus.FAILED,
      },
    }));
    builder.addCase(revertUpdateBonusCode, state => ({
      ...state,
      updateBonusCode: {
        loading: LoadingStatus.IDLE,
      },
    }));
    builder.addCase(deleteBonusCode.pending, state => ({
      ...state,
      deleteBonusCode: {
        loading: LoadingStatus.PENDING,
      },
    }));
    builder.addCase(deleteBonusCode.fulfilled, state => ({
      ...state,
      deleteBonusCode: {
        loading: LoadingStatus.SUCCEEDED,
      },
    }));
    builder.addCase(deleteBonusCode.rejected, state => ({
      ...state,
      deleteBonusCode: {
        loading: LoadingStatus.FAILED,
      },
    }));
    builder.addCase(revertDeleteBonusCode, state => ({
      ...state,
      deleteBonusCode: {
        loading: LoadingStatus.IDLE,
      },
    }));
  },
});

export const revertBonusCodes = createAction('REVERT_BONUS_CODES');
export const revertBonusCode = createAction('REVERT_BONUS_CODE');
export const revertCreateBonusCode = createAction('REVERT_CREATE_BONUS_CODE');
export const revertUpdateBonusCode = createAction('REVERT_UPDATE_BONUS_CODE');
export const revertDeleteBonusCode = createAction('REVERT_DELETE_BONUS_CODE');

export const { reducer, actions } = bonusCodeSlice;
