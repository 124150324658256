import React from 'react';

const ArrowLeftIcon = () => (
  <svg
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.33343 4.33338H2.27343L4.47343 2.14004C4.59896 2.01451 4.66949 1.84424 4.66949 1.66671C4.66949 1.48917 4.59896 1.31891 4.47343 1.19338C4.34789 1.06784 4.17763 0.997314 4.00009 0.997314C3.82256 0.997314 3.6523 1.06784 3.52676 1.19338L0.193427 4.52671C0.132733 4.59011 0.0851566 4.66487 0.053427 4.74671C-0.0132517 4.90902 -0.0132517 5.09107 0.053427 5.25338C0.0851566 5.33521 0.132733 5.40997 0.193427 5.47338L3.52676 8.80671C3.58874 8.86919 3.66247 8.91879 3.74371 8.95264C3.82495 8.98648 3.91209 9.00391 4.00009 9.00391C4.0881 9.00391 4.17524 8.98648 4.25648 8.95264C4.33772 8.91879 4.41145 8.86919 4.47343 8.80671C4.53591 8.74473 4.58551 8.671 4.61935 8.58976C4.6532 8.50852 4.67063 8.42138 4.67063 8.33337C4.67063 8.24537 4.6532 8.15823 4.61935 8.07699C4.58551 7.99575 4.53591 7.92202 4.47343 7.86004L2.27343 5.66671H7.33343C7.51024 5.66671 7.67981 5.59647 7.80483 5.47145C7.92986 5.34642 8.00009 5.17685 8.00009 5.00004C8.00009 4.82323 7.92986 4.65366 7.80483 4.52864C7.67981 4.40361 7.51024 4.33338 7.33343 4.33338Z"
      fill="#00A399"
    />
  </svg>
);

export default ArrowLeftIcon;
