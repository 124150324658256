import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, FormLabel } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { PageHeader } from '../../../components/Common/PageHeader';
import AddIcon from '../../../icons/AddIcon';
import RelatedCustomersList from '../../../components/Customers/RelatedCustomer/RelatedCustomersList';
import { useGetMyCostGroups } from '../wallet/api';
import Select, { SelectItem } from '../../../components/Common/Select';

const MyUsersPage = () => {
  const { t } = useTranslation();
  const [costGroupId, setCostGroupId] = useState('');

  const { data: costGroups } = useGetMyCostGroups<SelectItem[]>(
    {},
    ({ items }) => {
      const result: SelectItem[] = [];
      items.forEach(cg => {
        result.push({ value: cg.id, label: cg.name });
      });
      return result;
    },
  );

  function handleCostGroupChange(costGroupId: string) {
    setCostGroupId(costGroupId);
  }

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <PageHeader text={t('b2b.drawer.my-users')}>
        <Button
          variant="contained"
          size="medium"
          color="secondary"
          startIcon={<AddIcon />}
          component={NavLink}
          to="add"
        >
          {t('b2b.myUsers.buttons.addUser')}
        </Button>
      </PageHeader>

      <Box sx={{ mb: 2, maxWidth: 200 }}>
        <FormLabel htmlFor="costGroupSelect">
          {t(`b2b.myUsers.costGroup`)}
        </FormLabel>
        <Select
          id="costGroupSelect"
          value={costGroupId ?? ''}
          onChange={handleCostGroupChange}
          items={costGroups ?? []}
          displayEmpty
        />
      </Box>

      <RelatedCustomersList costGroupId={costGroupId} />
    </Box>
  );
};

export default MyUsersPage;
