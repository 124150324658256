import React, { ReactNode } from 'react';
import { FormLabel } from '@mui/material';
import { Field, FieldProps } from 'formik';
import TextField, { TextFieldProps } from '../TextField';

export interface InputFieldProps extends TextFieldProps {
  name: string;
  label?: string;
  placeholder?: string;
  startAdornment?: ReactNode;
  disabled?: boolean;
  defaultValue?: string;
  multiline?: boolean;
  maxRows?: number;
}

const InputField = ({
  name,
  label,
  placeholder,
  startAdornment,
  disabled,
  defaultValue,
  multiline,
  maxRows,
  helperText,
  ...textFieldProps
}: InputFieldProps) => (
  <>
    {label ? <FormLabel htmlFor={name}>{label}</FormLabel> : null}
    <Field name={name}>
      {({ field, meta: { error, touched } }: FieldProps) => (
        <TextField
          id={name}
          {...field}
          {...textFieldProps}
          multiline={multiline}
          maxRows={maxRows}
          InputProps={{ ...textFieldProps.InputProps, startAdornment }}
          placeholder={placeholder}
          error={!!error && touched}
          helperText={error && touched ? error : helperText}
          disabled={disabled}
          defaultValue={defaultValue}
        />
      )}
    </Field>
  </>
);

export default InputField;
