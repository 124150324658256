import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import { Route, Routes, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MainHeader } from '../../Common/MainHeader';
import { TabMenu, TabMenuItem } from '../../Common/TabMenu';
import { CUSTOMERS, CUSTOMERS_BUSINESS } from '../../../utils/routes-constants';
import { CustomerDetailsView } from '../../../utils/customer-utils';
import { useGetCustomer } from '../../../store/customer/queries';
import PageQueryLoader from '../../Common/PageQueryLoader';
import {
  BusinessCustomerDetail,
  PrivateCustomerDetail,
} from '../../../entities/Customer';
import CustomerDetailsGeneral from './CustomerDetailsGeneral';
import CustomerDetailsFeedback from './CustomerDetailsFeedback';
import CustomerDetailsNotes from './CustomerDetailsNotes';
import CustomerDetailsReservations from './CustomerDetailsReservations';
import CustomerDetailsActivity from './CustomerDetailsActivity';
import CustomerDetailsRelatedAccounts from './CustomerDetailsRelatedAccounts';

interface Props {
  isBusinessCustomer?: boolean;
}

const CustomerDetailsContainer = ({ isBusinessCustomer = false }: Props) => {
  const { customerId } = useParams();
  const { t } = useTranslation();

  const getCustomer = useGetCustomer(
    isBusinessCustomer ? 'business' : 'private',
    customerId || '',
  );
  const { data: customer } = getCustomer;

  const menuItems = useMemo(() => {
    return [
      { titleSlug: 'customers.tabs.general', path: '' },
      isBusinessCustomer
        ? null
        : {
            titleSlug: 'customers.tabs.feedback',
            path: CustomerDetailsView.FEEDBACK,
          },
      {
        titleSlug: 'customers.tabs.reservations',
        path: CustomerDetailsView.RESERVATIONS,
      },
      isBusinessCustomer
        ? {
            titleSlug: 'customers.tabs.relatedAccounts',
            path: CustomerDetailsView.RELATED_ACCOUNTS,
          }
        : null,
      { titleSlug: 'customers.tabs.notes', path: CustomerDetailsView.NOTES },
      {
        titleSlug: 'customers.tabs.activity',
        path: CustomerDetailsView.ACTIVITY,
      },
    ].filter(it => it) as TabMenuItem[];
  }, [isBusinessCustomer]);

  const customerTitle = isBusinessCustomer
    ? (customer as BusinessCustomerDetail)?.companyName
    : (customer as PrivateCustomerDetail)?.name;

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <MainHeader
        text={customerTitle}
        link={isBusinessCustomer ? CUSTOMERS_BUSINESS : CUSTOMERS}
        linkText={t('customers.buttons.allCustomers')}
      />
      <TabMenu menuItems={menuItems} />

      <PageQueryLoader query={getCustomer}>
        {customer && (
          <Routes>
            <Route
              index
              element={
                <CustomerDetailsGeneral
                  customer={customer}
                  isBusinessCustomer={isBusinessCustomer}
                />
              }
            />
            {!isBusinessCustomer && (
              <Route
                path={CustomerDetailsView.FEEDBACK}
                element={<CustomerDetailsFeedback customer={customer} />}
              />
            )}
            <Route
              path={CustomerDetailsView.NOTES}
              element={<CustomerDetailsNotes />}
            />
            <Route
              path={CustomerDetailsView.RESERVATIONS}
              element={
                <CustomerDetailsReservations
                  customer={customer}
                  isBusinessCustomer={isBusinessCustomer}
                />
              }
            />
            <Route
              path={`${CustomerDetailsView.RELATED_ACCOUNTS}/*`}
              element={<CustomerDetailsRelatedAccounts />}
            />
            <Route
              path={CustomerDetailsView.ACTIVITY}
              element={<CustomerDetailsActivity />}
            />
          </Routes>
        )}
      </PageQueryLoader>
    </Box>
  );
};
export default CustomerDetailsContainer;
