import {
  DataGrid,
  DataGridProps,
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
  GridRowsProp,
  GridValidRowModel,
} from '@mui/x-data-grid';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, debounce } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import {
  DEFAULT_GRID_PAGINATION,
  DEFAULT_PAGE_SIZE_LIMIT,
  INITIAL_REQUEST_PARAMS,
} from '../../utils/table-utils';
import {
  PaginatedItems,
  PaginatedRequestParams,
  paramsToSearch,
} from '../../store/api';

export interface PaginatedTableToolbar<T extends PaginatedRequestParams> {
  params: T;
  setParams: Dispatch<SetStateAction<T>>;
  searchFocus: boolean;
  setSearchFocus: Dispatch<SetStateAction<boolean>>;
}

interface Props extends DataGridProps {
  rows: GridRowsProp;
  columns: GridColDef[];
  totalItems: number;
  isLoading: boolean;
  fetchRows: (params: PaginatedRequestParams) => void;
  Toolbar?: (
    toolbar: PaginatedTableToolbar<PaginatedRequestParams>,
  ) => JSX.Element;
  autoPageSize?: boolean;
  onRowClick?: (params: GridRowParams) => void;
  checkboxSelection?: boolean;
  onSelectionChange?: (selectionModel: GridRowSelectionModel) => void;
}

export const PaginatedTable = ({
  rows,
  columns,
  totalItems,
  isLoading,
  fetchRows,
  Toolbar,
  autoPageSize = false,
  onRowClick,
  checkboxSelection = false,
  onSelectionChange,
  ...restProps
}: Props) => {
  const [search] = useSearchParams();
  const [params, setParams] = useState<PaginatedRequestParams>(
    INITIAL_REQUEST_PARAMS,
  );
  const [searchFocus, setSearchFocus] = useState(false);
  const [paginationModel, setPaginationModel] = useState(
    autoPageSize
      ? DEFAULT_GRID_PAGINATION
      : { ...DEFAULT_GRID_PAGINATION, pageSize: DEFAULT_PAGE_SIZE_LIMIT },
  );

  useEffect(() => {
    const paramsFromSearch = Object.fromEntries(search.entries());
    setParams(state => {
      return {
        ...state,
        ...paramsFromSearch,
        page: paginationModel.page + 1,
        limit: paginationModel.pageSize,
      };
    });
  }, [paginationModel]);

  useEffect(() => {
    fetchRows(params);
  }, [params]);

  return (
    <Box height="100%">
      <DataGrid
        sx={{
          '& .MuiDataGrid-row:hover': {
            cursor: typeof onRowClick === 'function' ? 'pointer' : null,
          },
        }}
        slots={Toolbar ? { toolbar: Toolbar } : undefined}
        slotProps={{
          toolbar: Toolbar
            ? { params, setParams, searchFocus, setSearchFocus }
            : undefined,
        }}
        loading={isLoading}
        density="compact"
        // LOL
        rowHeight={69}
        rowCount={totalItems}
        rows={rows}
        onRowClick={onRowClick}
        columns={columns}
        autoPageSize={autoPageSize}
        pageSizeOptions={[DEFAULT_PAGE_SIZE_LIMIT]}
        disableRowSelectionOnClick
        disableColumnMenu
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
        }
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        checkboxSelection={checkboxSelection}
        onRowSelectionModelChange={onSelectionChange}
        {...restProps}
      />
    </Box>
  );
};

/**
 * Set up common things to use PaginatedTable.
 */
export function usePaginatedTable<
  TItem extends GridValidRowModel,
  TRow extends GridValidRowModel = TItem,
>({
  paginatedItems,
  params,
  setParams,
  mapGridRow,
  syncParamsWithUrl = false,
}: {
  paginatedItems: PaginatedItems<TItem> | undefined;
  params: PaginatedRequestParams;
  setParams: (params: PaginatedRequestParams) => void;
  mapGridRow?: (item: TItem) => TRow;
  syncParamsWithUrl?: boolean;
}) {
  const [rows, setRows] = useState<TRow[]>([]);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useSearchParams();

  useEffect(() => {
    if (!syncParamsWithUrl) return;
    // Try setting params from URL query params on mount.
    const paramsFromSearch = Object.fromEntries(search.entries());
    setParams({ ...params, ...paramsFromSearch });
  }, []);

  // Map fetched items to grid rows.
  useEffect(() => {
    if (!paginatedItems) return;
    setRows(
      typeof mapGridRow === 'function'
        ? paginatedItems.items.map(mapGridRow)
        : (paginatedItems.items as unknown as TRow[]),
    );
    setTotalItems(paginatedItems.pagination.totalItems);
  }, [paginatedItems]);

  useEffect(() => {
    if (!syncParamsWithUrl) return;
    // Sync filter params to URL query params to preserve state and allow sharing.
    setSearch(paramsToSearch(params), {
      preventScrollReset: true,
      replace: true,
    });
  }, [params]);

  /**
   * A helper to provide to PaginatedTable fetchRows
   * that debounces any params changes.
   */
  const debounceParams = useCallback(
    debounce(
      (changedParams: PaginatedRequestParams) => setParams(changedParams),
      300,
    ),
    [setParams],
  );

  return {
    rows,
    totalItems,
    debounceParams,
  };
}
