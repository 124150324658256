import React from 'react';

const AvisLogo = () => (
  <svg
    width="86"
    height="40"
    viewBox="0 0 86 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 40H85.0027V0H0V40Z"
      fill="#D4002A"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M76.5273 11.5317H76.7169C76.8275 11.5317 76.9935 11.5272 76.9935 11.3375C76.9935 11.1529 76.8275 11.1439 76.7169 11.1439H76.5273V11.5317ZM76.061 10.7832H76.6983C76.8924 10.7832 77.4654 10.7832 77.4654 11.3652C77.4654 11.7304 77.2526 11.841 77.0449 11.8692L77.5066 12.5849H77.0217L76.6108 11.8918H76.5318V12.5849H76.061V10.7832ZM76.7214 13.0049C77.4095 13.0049 77.9683 12.4184 77.9683 11.7027C77.9683 10.9864 77.4095 10.4 76.7214 10.4C76.0334 10.4 75.474 10.9864 75.474 11.7027C75.474 12.4184 76.0334 13.0049 76.7214 13.0049ZM76.7214 9.98397C77.6686 9.98397 78.4396 10.7556 78.4396 11.7027C78.4396 12.6493 77.6686 13.4209 76.7214 13.4209C75.7743 13.4209 75.0033 12.6493 75.0033 11.7027C75.0033 10.7556 75.7743 9.98397 76.7214 9.98397ZM70.0474 15.0216C69.3407 14.1174 67.7247 13.4926 66.6511 13.4926C65.1819 13.4926 63.685 14.1242 63.685 15.6217C63.685 18.769 72.8939 16.921 72.8939 23.9269C72.8939 28.39 69.3069 30.5083 65.1819 30.5083C62.6114 30.5083 59.8451 29.3879 57.9954 27.5309L60.8859 24.6404C61.7901 25.8268 63.9672 26.4409 65.4365 26.4409C66.8769 26.4409 68.2152 25.763 68.2152 24.3502C68.2152 20.678 59.0272 22.6541 58.9036 15.9885C58.8206 11.482 62.809 9.49121 66.7358 9.49121C68.9959 9.49121 71.3383 10.3988 73.087 12.0227L70.0474 15.0216ZM50.4239 29.9998H55.0981V9.99978H50.4239V29.9998ZM28.5736 9.99978H33.7361L38.5457 24.1493L43.4293 9.99978H48.3518L40.8069 29.9998H36.0678L28.5736 9.99978ZM20.7132 15.6042L18.2838 22.0338H23.1708L20.7132 15.6042ZM18.5784 9.99978H23.2837L31.3626 29.9998H26.3915L24.6682 25.9984L16.8044 26.0046L15.233 29.9998H10L18.5784 9.99978Z"
      fill="white"
    />
  </svg>
);

export default AvisLogo;
