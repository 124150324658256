import React, { ReactNode, useEffect, useState } from 'react';
import {
  Box,
  Drawer as MuiDrawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import VehiclesIcon from '../../icons/VehiclesIcon';
import CustomersIcon from '../../icons/CustomersIcon';
import ReservationsIcon from '../../icons/ReservationsIcon';
import InvoicesIcon from '../../icons/InvoicesIcon';
import SettingsIcon from '../../icons/SettingsIcon';
import { useAppSelector } from '../../store';
import {
  CUSTOMERS,
  INVOICES,
  RESERVATIONS,
  SETTINGS,
  VEHICLES,
} from '../../utils/routes-constants';
import DrawerItemActiveIcon from '../../icons/DrawerItemActiveIcon';
import AvisLogoMini from '../../icons/AvisLogoMini';
import AvisLogo from '../../icons/AvisLogo';
import {
  B2B_COMPANY,
  B2B_INVOICES,
  B2B_RESERVATIONS,
  B2B_USERS,
  B2B_WALLET,
} from '../../utils/b2b-routes-constants';
import CompanyIcon from '../../icons/CompanyIcon';
import WalletIcon from '../../icons/WalletIcon';

const expandedDrawerWidth = 216;
const minimizedDrawerWidth = 64;

interface DrawerListItemProps {
  selected: boolean;
  title: string;
  icon: ReactNode;
  expanded: boolean;
}

const DrawerListItem = ({
  icon,
  selected,
  title,
  expanded,
}: DrawerListItemProps) => (
  <ListItem
    disablePadding
    sx={{
      '& .MuiListItemButton-root:hover': {
        backgroundColor: '#FCFCFB',
      },
      '& .Mui-selected': {
        boxShadow: '0px 1px 2px 0px #0000000D',
        backgroundColor: '#ffffff !important',
      },
    }}
  >
    {selected && (
      <Box sx={{ position: 'absolute', left: expanded ? -16 : -8 }}>
        <DrawerItemActiveIcon />
      </Box>
    )}
    <ListItemButton
      sx={{
        height: 46,
        justifyContent: !expanded ? 'center' : undefined,
      }}
      selected={selected}
    >
      <ListItemIcon sx={{ minWidth: !expanded ? 24 : 40 }}>{icon}</ListItemIcon>
      {expanded && (
        <ListItemText>
          <Typography variant="subtitle1">{title}</Typography>
        </ListItemText>
      )}
    </ListItemButton>
  </ListItem>
);

interface Props {
  isBackoffice?: boolean;
}

const Drawer = ({ isBackoffice = false }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isUpXl = useMediaQuery(theme.breakpoints.up('xl'));
  const [expanded, setExpanded] = useState(true);
  const isAuthenticated = useAppSelector(
    state => !!state.userReducer.authToken,
  );
  const isOnboarded = useAppSelector(state => state.userReducer.isOnboarded);

  useEffect(() => {
    setExpanded(isUpXl);
  }, [isUpXl]);

  const items = isBackoffice
    ? [
        {
          title: t('drawer.vehicles'),
          icon: <VehiclesIcon />,
          route: VEHICLES,
        },
        {
          title: t('drawer.customers'),
          icon: <CustomersIcon />,
          route: CUSTOMERS,
        },
        {
          title: t('drawer.reservations'),
          icon: <ReservationsIcon />,
          route: RESERVATIONS,
        },
        {
          title: t('drawer.invoices'),
          icon: <InvoicesIcon />,
          route: INVOICES,
        },
        {
          title: t('drawer.settings'),
          icon: <SettingsIcon />,
          route: SETTINGS,
        },
      ]
    : [
        {
          title: t('b2b.drawer.my-company'),
          icon: <CompanyIcon />,
          route: B2B_COMPANY,
        },
        {
          title: t('b2b.drawer.wallet'),
          icon: <WalletIcon />,
          route: B2B_WALLET,
        },
        {
          title: t('b2b.drawer.my-users'),
          icon: <CustomersIcon />,
          route: B2B_USERS,
        },
        {
          title: t('b2b.drawer.reservations'),
          icon: <ReservationsIcon />,
          route: B2B_RESERVATIONS,
        },
        {
          title: t('b2b.drawer.invoices'),
          icon: <InvoicesIcon />,
          route: B2B_INVOICES,
        },
      ];

  if (!isAuthenticated || !isOnboarded) return null;
  return (
    <MuiDrawer
      variant="permanent"
      sx={{
        width: expanded ? expandedDrawerWidth : minimizedDrawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: {
          width: expanded ? expandedDrawerWidth : minimizedDrawerWidth,
          bgcolor: '#F6F5F4',
          border: 0,
        },
        '& .MuiPaper-root': {
          maxHeight: '100vh',
        },
      }}
    >
      <Box
        display="fixed"
        sx={{
          height: expanded ? 40 : 56,
          width: expanded ? 85 : minimizedDrawerWidth,
          cursor: 'pointer',
        }}
        component={NavLink}
        to="/"
      >
        {expanded ? <AvisLogo /> : <AvisLogoMini />}
      </Box>
      <Box sx={{ pt: expanded ? 5 : 3, overflow: 'auto' }}>
        <List sx={{ pl: expanded ? 2 : 1, pr: expanded ? 2 : 1 }}>
          {items.map(item => (
            <NavLink
              to={item.route}
              key={item.title}
              style={{ textDecoration: 'none', color: 'unset' }}
            >
              {({ isActive }) => (
                <DrawerListItem
                  selected={isActive}
                  title={item.title}
                  icon={item.icon}
                  expanded={expanded}
                />
              )}
            </NavLink>
          ))}
        </List>
      </Box>
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ pb: 2.5, overflow: 'auto' }}>
        <List sx={{ pl: expanded ? 2 : 1, pr: expanded ? 2 : 1 }}>
          <ListItemButton
            sx={{
              borderRadius: 2,
              border: '1px solid #E1DED9',
              justifyContent: 'center',
            }}
            onClick={() => setExpanded(state => !state)}
          >
            <ListItemIcon
              sx={{
                justifyContent: 'center',
                minWidth: 24,
              }}
            >
              {expanded ? (
                <ArrowBackIosNew sx={{ fontSize: 16 }} />
              ) : (
                <ArrowForwardIos sx={{ fontSize: 16 }} />
              )}
            </ListItemIcon>
          </ListItemButton>
        </List>
      </Box>
    </MuiDrawer>
  );
};

export default Drawer;
