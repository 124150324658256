import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Close } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import { TabHeader } from '../../Common/TabHeader';
import { SecondaryButton } from '../../Common/SecondaryButton';
import { RESERVATIONS } from '../../../utils/routes-constants';
import SelectField from '../../Common/FormikFields/SelectField';
import { useGetParkingZones } from '../../../store/vehicle/queries';
import EditIcon from '../../../icons/EditIcon';
import { SelectItem } from '../../Common/Select';
import { useSnacks } from '../../Common/AvisSnackbarProvider';
import { useGetReservation, useUpdateReservation } from '../api';
import {
  EditReservationState,
  getEditReservationInitialState,
  getEditReservationValidationSchema,
} from './editReservationData';
import AvailableVehicleSelect from './AvailableVehicleSelect';

const EditReservation = () => {
  const { t } = useTranslation();
  const { reservationId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const { apiErrorSnack, successSnack } = useSnacks();

  const { data: reservationDetails } = useGetReservation(reservationId);
  const reservation = reservationDetails?.details;
  const updateReservation = useUpdateReservation();

  if (!reservationId || !reservation) {
    return null;
  }

  const onSubmit = ({ vehicleId, parkingZoneId }: EditReservationState) => {
    updateReservation.mutate(
      {
        id: reservationId,
        body: {
          vehicleId,
          pickupParkingZoneId: parkingZoneId,
          returnParkingZoneId: parkingZoneId,
        },
      },
      {
        onSuccess: () => {
          successSnack(t('reservations.snacks.updated'));
          navigate(`${RESERVATIONS}/${reservationId}`);
        },
        onError: err => apiErrorSnack(err),
      },
    );
  };

  const { data: parkingZones } = useGetParkingZones({
    params: { archived: false },
    select: data => {
      return (
        data?.map<SelectItem>(it => ({
          label: it.name,
          value: it.id,
        })) ?? []
      );
    },
  });

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      <TabHeader text={t('reservations.form.titles.editReservation')}>
        <SecondaryButton
          startIcon={<Close />}
          onClick={() => navigate(`${RESERVATIONS}/${reservationId}`)}
        >
          {t('cancel')}
        </SecondaryButton>
      </TabHeader>
      <Formik
        initialValues={getEditReservationInitialState(reservation)}
        validationSchema={getEditReservationValidationSchema(t)}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {({ values: { vehicleId, parkingZoneId } }) => (
          <Form>
            <Grid
              container
              direction="column"
              sx={{ maxWidth: isDownMd ? '100%' : '50%', marginBottom: 4 }}
              rowGap={2}
            >
              <Grid item>
                <SelectField
                  name="parkingZoneId"
                  items={parkingZones || []}
                  label={t('reservations.parkingZone')}
                  placeholder={t('reservations.form.placeholders.parkingZone')}
                />
              </Grid>
              <Grid item>
                <AvailableVehicleSelect
                  fieldName="vehicleId"
                  startDateTime={reservation.startDateTime}
                  endDateTime={reservation.endDateTime}
                  parkingZoneId={parkingZoneId}
                  initialItem={{
                    value: reservation.vehicle.id,
                    label: reservation.vehicle.registrationNumber,
                  }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              variant="contained"
              size="medium"
              color="secondary"
              startIcon={<EditIcon fill="white" />}
              disabled={!vehicleId}
            >
              {t('reservations.buttons.editReservation')}
            </Button>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default EditReservation;
