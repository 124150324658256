import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import * as Yup from 'yup';
import currency from 'currency.js';
import baseApi from '../../../services/apiService';
import { PaymentMethod } from '../../../entities';
import { PaginatedItems, PaginatedRequestParams } from '../../../store/api';
import { CostGroup } from '../../../entities/Customer';

export const apiPaths = {
  costGroups: '/api/cost-groups',
  myCostGroups: '/api/cost-groups/mine',
  paymentMethods: '/api/payment-methods',
};

export const addEditCostGroupSchema = Yup.object({
  name: Yup.string().required(),
  paymentMethodId: Yup.string().required(),
  dailyAmount: Yup.number()
    .required()
    .transform(value => currency(value).intValue),
});

export type AddEditCostGroupRequest = Yup.InferType<
  typeof addEditCostGroupSchema
>;

export interface CostGroupParams extends PaginatedRequestParams {
  businessCustomerId?: string;
}

export const walletApi = {
  getPaymentMethods: () =>
    baseApi.get<PaymentMethod[]>(apiPaths.paymentMethods),
  deletePaymentMethod: (id: string) =>
    baseApi.delete(`${apiPaths.paymentMethods}/${id}`),
  getCostGroups: (params: CostGroupParams) =>
    baseApi.get<PaginatedItems<CostGroup>>(apiPaths.costGroups, { params }),
  getMyCostGroups: (params: PaginatedRequestParams) =>
    baseApi.get<PaginatedItems<CostGroup>>(apiPaths.myCostGroups, { params }),
  getCostGroup: (id: string) =>
    baseApi.get<CostGroup>(`${apiPaths.costGroups}/${id}`),
  addCostGroup: (body: AddEditCostGroupRequest) =>
    baseApi.post<CostGroup>(apiPaths.costGroups, body),
  editCostGroup: (id: string, body: AddEditCostGroupRequest) =>
    baseApi.put<CostGroup>(`${apiPaths.costGroups}/${id}`, body),
  deleteCostGroup: (id: string) =>
    baseApi.delete(`${apiPaths.costGroups}/${id}`),
};

const COST_GROUPS_KEY = 'cost-groups';
export const PAYMENT_METHODS_KEY = 'payment-methods';

export function useGetPaymentMethods() {
  return useQuery({
    queryKey: [PAYMENT_METHODS_KEY],
    queryFn: async () => {
      return (await walletApi.getPaymentMethods()).data;
    },
  });
}

export function useDeletePaymentMethod() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) =>
      (await walletApi.deletePaymentMethod(id)).data,
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [PAYMENT_METHODS_KEY] }),
  });
}

export function useGetCostGroups<T = PaginatedItems<CostGroup>>(
  params: CostGroupParams,
  select?: (cg: PaginatedItems<CostGroup>) => T,
) {
  return useQuery({
    queryKey: [COST_GROUPS_KEY, params],
    queryFn: async () => {
      return (await walletApi.getCostGroups(params)).data;
    },
    enabled: !!params.businessCustomerId,
    select,
  });
}

export function useGetMyCostGroups<T = PaginatedItems<CostGroup>>(
  params: PaginatedRequestParams,
  select?: (cg: PaginatedItems<CostGroup>) => T,
) {
  return useQuery({
    queryKey: [COST_GROUPS_KEY, params],
    queryFn: async () => {
      return (await walletApi.getMyCostGroups(params)).data;
    },
    select,
  });
}

export function useGetCostGroup(id: string) {
  return useQuery({
    queryKey: [COST_GROUPS_KEY, id],
    queryFn: async () => {
      return (await walletApi.getCostGroup(id)).data;
    },
  });
}

export function useAddCostGroup() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body: AddEditCostGroupRequest) => {
      return (await walletApi.addCostGroup(body)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [COST_GROUPS_KEY] }),
  });
}

export function useEditCostGroup() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({
      id,
      body,
    }: {
      id: string;
      body: AddEditCostGroupRequest;
    }) => {
      return (await walletApi.editCostGroup(id, body)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [COST_GROUPS_KEY] }),
  });
}

export function useDeleteCostGroup() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: string) => {
      return (await walletApi.deleteCostGroup(id)).data;
    },
    onSuccess: () =>
      queryClient.invalidateQueries({ queryKey: [COST_GROUPS_KEY] }),
  });
}
