import React from 'react';
import ReactDOM from 'react-dom/client';
import '@stripe/stripe-js';
import { UnhandledRequestPrint } from 'msw/lib/core/utils/request/onUnhandledRequest';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './i18n';
import './utils/yup';
import { shouldStartMockApi } from './utils/dev-utils';

if (shouldStartMockApi()) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start({
    onUnhandledRequest(req: Request, print: UnhandledRequestPrint) {
      if (
        req.url.match(
          /\/static\/|(\.svg$)|(\.ico$)|(\.png$)|\/manifest.json|stripe.com\/|\.hot-update\./,
        )
      ) {
        return;
      }

      print.warning();
    },
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
