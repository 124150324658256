import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../api';
import { BonusCode, GetBonusCodeParams } from '../../entities/BonusCode';
import { catchRejectionAxiosResponse, RequestError } from '../index';

export interface CreateUpdateBonusCodeBody {
  code: string;
  amount: number;
  validFrom: string;
  validTo: string;
  quantity: number;
  isNewCustomer: boolean;
}

export const getBonusCodes = createAsyncThunk(
  'bonusCodes/getBonusCodes',
  async (params: GetBonusCodeParams) => (await api.getBonusCodes(params)).data,
);

export const getBonusCode = createAsyncThunk(
  'bonusCodes/getBonusCode',
  async (id: string) => (await api.getBonusCode(id)).data,
);

export const createBonusCode = createAsyncThunk<
  BonusCode,
  CreateUpdateBonusCodeBody,
  { rejectValue: RequestError }
>(
  'bonusCodes/createBonusCode',
  async (body: CreateUpdateBonusCodeBody, { rejectWithValue }) =>
    await catchRejectionAxiosResponse(
      api.createBonusCode(body),
      rejectWithValue,
    ),
);

export const updateBonusCode = createAsyncThunk<
  BonusCode,
  { id: string; body: CreateUpdateBonusCodeBody },
  { rejectValue: RequestError }
>(
  'bonusCodes/updateBonusCode',
  async (
    { id, body }: { id: string; body: CreateUpdateBonusCodeBody },
    { rejectWithValue },
  ) =>
    await catchRejectionAxiosResponse(
      api.updateBonusCode(id, body),
      rejectWithValue,
    ),
);

export const deleteBonusCode = createAsyncThunk(
  'bonusCodes/deleteBonusCode',
  async (id: string) => (await api.deleteBonusCode(id)).data,
);
