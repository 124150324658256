import React from 'react';
import { Alert, AlertTitle, List, ListItem } from '@mui/material';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { DATE_TIME } from '../../utils/datetime-utils';
import { VehicleAvailabilityPeriod } from '../../entities/Vehicle';

function ReservedPeriodsAlert({
  reservedPeriods,
  isConflict,
}: {
  reservedPeriods: VehicleAvailabilityPeriod[];
  isConflict: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Alert sx={{ mt: 2 }} severity={isConflict ? 'error' : 'info'}>
      <AlertTitle>
        {t('reservations.form.vehicleHasReservationPeriods')}
      </AlertTitle>
      <List dense sx={{ py: 0, listStyleType: 'disc' }}>
        {reservedPeriods.map(period => (
          // eslint-disable-next-line @docusaurus/no-untranslated-text
          <ListItem key={period.startDateTime}>
            {moment(period.startDateTime).format(DATE_TIME)} -{' '}
            {moment(period.endDateTime).format(DATE_TIME)}
          </ListItem>
        ))}
      </List>
    </Alert>
  );
}

export default ReservedPeriodsAlert;
