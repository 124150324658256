import React from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Button, Grid } from '@mui/material';
import InputField from '../../../components/Common/FormikFields/InputField';
import { fieldOf } from '../../../store/api';
import SelectField from '../../../components/Common/FormikFields/SelectField';
import FormikCurrencyField from '../../../components/Common/FormikFields/FormikCurrencyField';
import {
  AddEditCostGroupRequest,
  addEditCostGroupSchema,
  useGetPaymentMethods,
} from './api';

const initialValues = {
  name: '',
  dailyAmount: 50,
  paymentMethodId: '',
};

const fields = {
  name: fieldOf<AddEditCostGroupRequest>('name'),
  paymentMethodId: fieldOf<AddEditCostGroupRequest>('paymentMethodId'),
  dailyAmount: fieldOf<AddEditCostGroupRequest>('dailyAmount'),
};

function CostGroupForm({
  onSubmit,
  values,
}: {
  onSubmit: (request: AddEditCostGroupRequest) => void;
  values?: AddEditCostGroupRequest;
}) {
  const { t } = useTranslation();
  const { data: paymentMethods } = useGetPaymentMethods();
  return (
    <Formik
      initialValues={values || initialValues}
      onSubmit={onSubmit}
      validateOnMount={false}
      validationSchema={addEditCostGroupSchema}
    >
      <Form>
        <Grid container rowSpacing={2} sx={{ maxWidth: 670, mb: 4 }}>
          <Grid container columnSpacing={3} item>
            <Grid item xs={4}>
              <InputField
                name={fields.name}
                label={t('b2b.wallet.costGroup.name')}
              />
            </Grid>
            <Grid item xs={4}>
              <SelectField
                name={fields.paymentMethodId}
                items={
                  paymentMethods?.map(it => ({
                    value: it.id,
                    label: it.title,
                  })) ?? []
                }
                label={t('b2b.wallet.costGroup.paymentMethod')}
              />
            </Grid>
            <Grid item xs={4}>
              <FormikCurrencyField
                name={fields.dailyAmount}
                label={t('b2b.wallet.costGroup.dailyCompensatedAmount')}
              />
            </Grid>
          </Grid>
        </Grid>

        <Button
          type="submit"
          variant="contained"
          size="medium"
          color="secondary"
        >
          {t('b2b.wallet.costGroup.buttons.saveCostGroup')}
        </Button>
      </Form>
    </Formik>
  );
}

export default CostGroupForm;
