import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  GridColDef,
  GridRowParams,
  GridRowSelectionModel,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import {
  GetReservationParams,
  ReservationListItem,
} from '../../../entities/Reservation';
import {
  PaginatedTable,
  usePaginatedTable,
} from '../../../components/Common/PaginatedTable';
import { DATE_TIME, TIME_WITH_SECONDS } from '../../../utils/datetime-utils';
import { fieldOf } from '../../../store/api';
import NoUserIcon from '../../../icons/NoUserIcon';
import { NoDataPlaceholder } from '../../../components/Common/NoDataPlaceholder';
import ReservationsToolbar from './ReservationsToolbar';
import { useGetCompanyReservations } from './api';

type Props = {
  onSelectionChange: (selected: GridRowSelectionModel) => void;
};

const ReservationsTable = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [reservationParams, setReservationParams] =
    useState<GetReservationParams>({});

  const { data: reservations, isLoading } =
    useGetCompanyReservations(reservationParams);

  const { rows, totalItems, debounceParams } = usePaginatedTable({
    paginatedItems: reservations,
    setParams: setReservationParams,
    params: reservationParams,
    syncParamsWithUrl: true,
    mapGridRow: (r: ReservationListItem) =>
      ({
        id: r.id,
        registerNumber: r.vehicle.registrationNumber,
        zone: r.pickupParkingZone.name,
        reservationStart: r.startDateTime,
        reservationEnd: r.endDateTime,
        price: r.price,
        duration: r.durationSeconds,
        distance: r.distanceMeters,
        customer: r.customer?.name,
        phoneNumber: r.customer?.phoneNumber,
        email: r.customer?.email,
        invoicingGroup: '', // TODO: To be implemented when API is ready
        paymentStatus: '', // TODO: To be implemented when API is ready
      } as ReservationRow),
  });

  const columns: GridColDef[] = useMemo(
    () => [
      {
        field: fieldOf<ReservationRow>('registerNumber'),
        headerName: t('b2b.reservations.registerNumber'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('zone'),
        headerName: t('b2b.reservations.zone'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('reservationStart'),
        headerName: t('b2b.reservations.reservationStart'),
        flex: 1.5,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          moment.utc(params.value).format(DATE_TIME),
      },
      {
        field: fieldOf<ReservationRow>('reservationEnd'),
        headerName: t('b2b.reservations.reservationEnd'),
        flex: 1.5,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          moment.utc(params.value).format(DATE_TIME),
      },
      {
        field: fieldOf<ReservationRow>('customer'),
        headerName: t('b2b.reservations.customer'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('phoneNumber'),
        headerName: t('b2b.reservations.phoneNumber'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('email'),
        headerName: t('b2b.reservations.email'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('invoicingGroup'),
        headerName: t('b2b.reservations.invoicingGroup'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
      {
        field: fieldOf<ReservationRow>('price'),
        headerName: t('b2b.reservations.price'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value ? `${params.value} €` : '',
      },
      {
        field: fieldOf<ReservationRow>('duration'),
        headerName: t('b2b.reservations.duration'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          moment.utc(params.value * 1000).format(TIME_WITH_SECONDS),
      },
      {
        field: fieldOf<ReservationRow>('distance'),
        headerName: t('b2b.reservations.distance'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<number>) =>
          `${params.value || 0}km`,
      },
      {
        field: fieldOf<ReservationRow>('paymentStatus'),
        headerName: t('b2b.reservations.paymentStatus'),
        flex: 1,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          params.value,
      },
    ],
    [],
  );

  if (!isLoading && !reservations?.items?.length) {
    return (
      <NoDataPlaceholder
        icon={<NoUserIcon />}
        title={t('b2b.reservations.noData')}
        titleVariant="h4"
      />
    );
  }

  return (
    <PaginatedTable
      rows={rows}
      columns={columns}
      totalItems={totalItems}
      isLoading={isLoading}
      fetchRows={debounceParams}
      Toolbar={ReservationsToolbar}
      onRowClick={(params: GridRowParams) => navigate(params.row.id)}
      checkboxSelection
      onSelectionChange={props.onSelectionChange}
    />
  );
};

type ReservationRow = {
  id: string;
  registerNumber: string;
  zone: string;
  reservationStart: string;
  reservationEnd: string;
  price: string;
  duration: number;
  distance: number;
  customer: string;
  phoneNumber: string;
  email: string;
  invoicingGroup: string;
  paymentStatus: string;
};

export default ReservationsTable;
