import React from 'react';
import {
  Box,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { QuestionMarkOutlined } from '@mui/icons-material';
import DeleteIcon from '../../../icons/DeleteIcon';
import { useSnacks } from '../../../components/Common/AvisSnackbarProvider';
import NoPaymentMethodIcon from '../../../icons/NoPaymentMethodIcon';
import { B2B_COMPANY_MANAGE_AUTHORIZED_PERSONS } from '../../../utils/b2b-routes-constants';
import { NoDataPlaceholder } from '../../../components/Common/NoDataPlaceholder';
import LoadingIcon from '../../../components/Common/LoadingIcon';
import { useModal } from '../../../components/Modal/hooks';
import {
  AuthorizedPerson,
  useDeleteAuthorizedPerson,
  useGetAuthorizedPersons,
} from './api';

type Props = {
  readOnly?: boolean;
  showPlaceholder?: boolean;
} & TableContainerProps;

const StyledTableRow = styled(TableRow)(() => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#FAF9F8',
  },
}));

function AuthorizedPersons({
  readOnly = false,
  showPlaceholder = false,
  ...tableContainerProps
}: Props) {
  const { t } = useTranslation();
  const { apiErrorSnack, successSnack } = useSnacks();
  const navigate = useNavigate();
  const { confirm } = useModal();

  const { data: persons, isLoading } = useGetAuthorizedPersons({});
  const deleteAuthorizedPerson = useDeleteAuthorizedPerson();

  const handleDelete = ({ id }: AuthorizedPerson) => {
    if (!id) return;
    confirm({
      title: 'b2b.myCompany.authorizedPersons.deleteModalTitle',
      action: async () => {
        try {
          await deleteAuthorizedPerson.mutateAsync(id);
          successSnack(t('b2b.myCompany.authorizedPersons.snacks.removed'));
        } catch (e) {
          apiErrorSnack(e);
        }
      },
    });
  };

  if (isLoading) {
    return (
      <Box sx={{ mt: 2 }}>
        <LoadingIcon />
      </Box>
    );
  }

  return persons && persons.items.length > 0 ? (
    <TableContainer {...tableContainerProps}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t('b2b.myCompany.authorizedPersons.name')}</TableCell>
            <TableCell>
              {t('b2b.myCompany.authorizedPersons.phoneNumber')}
            </TableCell>
            <TableCell>{t('b2b.myCompany.authorizedPersons.email')}</TableCell>
            {!readOnly && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {persons.items.map(item => (
            <StyledTableRow key={item.phoneNumber}>
              <TableCell sx={{ fontWeight: 600 }}>
                {item.name ?? <NoAcceptance />}
              </TableCell>
              <TableCell>{item.phoneNumber}</TableCell>
              <TableCell>{item.email ?? <NoAcceptance />}</TableCell>
              <TableCell>
                {!readOnly && item.acceptance.status === 'confirmed' && (
                  <Tooltip title={t('delete')}>
                    <IconButton onClick={() => handleDelete(item)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : showPlaceholder ? (
    <NoDataPlaceholder
      icon={<NoPaymentMethodIcon />}
      title={t('b2b.myCompany.authorizedPersons.noData')}
      buttonTitle={t('b2b.myCompany.authorizedPersons.add')}
      onClick={() => navigate(B2B_COMPANY_MANAGE_AUTHORIZED_PERSONS)}
    />
  ) : null;
}

function NoAcceptance() {
  const { t } = useTranslation();
  return (
    <Chip
      icon={<QuestionMarkOutlined />}
      label={t('b2b.myCompany.authorizedPersons.unknown')}
    />
  );
}

AuthorizedPersons.defaultProps = {
  readOnly: true,
};

export default AuthorizedPersons;
