import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import GoogleMapReact from 'google-map-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TabHeader } from '../../Common/TabHeader';
import { SecondaryButton } from '../../Common/SecondaryButton';
import {
  convertPolygonToGeoJSON,
  getDisplayAreaOptions,
  getParkingAreaOptions,
  handleNewParkingZoneMapApiLoaded,
  handleParkingZoneMapApiLoaded,
  hideGoogleLogoStyles,
  PolygonType,
} from '../../../utils/google-maps-utils';
import EditIcon from '../../../icons/EditIcon';
import { ParkingZone } from '../../../entities/Vehicle';
import api from '../../../store/api';
import { removeEmptyParams } from '../../../utils/request-utils';
import LoadingIcon from '../../Common/LoadingIcon';

export const AddEditParkingZone = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const { zoneId } = useParams();
  const [existingZone, setExistingZone] = useState<ParkingZone>();

  useEffect(() => {
    if (!zoneId) {
      setIsLoading(false);
      return;
    }
    api.getParkingZone(zoneId).then(result => {
      setExistingZone(result.data);
    });
  }, []);

  const [name, setName] = useState<string>('');
  const [displayArea, setDisplayArea] = useState<google.maps.Polygon>();
  const [parkingArea, setParkingArea] = useState<google.maps.Polygon>();
  const [maps, setMaps] = useState<typeof google.maps>();
  const [drawingManager, setDrawingManager] =
    useState<google.maps.drawing.DrawingManager>();
  const [clearBtn, setClearBtn] = useState<HTMLButtonElement>();
  const [selectedPolygonType, setSelectedPolygonType] =
    useState<PolygonType>('display');

  useEffect(() => {
    if (!existingZone) return;
    setIsLoading(false);
    setName(existingZone.name || '');
  }, [existingZone]);

  const onClear = () => {
    if (selectedPolygonType === 'display') {
      if (displayArea) {
        displayArea.setMap(null);
      }
      setDisplayArea(undefined);
    } else {
      if (parkingArea) {
        parkingArea.setMap(null);
      }
      setParkingArea(undefined);
    }
  };

  useEffect(() => {
    if (!clearBtn) return;
    clearBtn.addEventListener('click', onClear);
    return () => clearBtn.removeEventListener('click', onClear);
  }, [clearBtn, displayArea, parkingArea, selectedPolygonType]);

  const onPolygonCompleted = (polygon: google.maps.Polygon) => {
    if (selectedPolygonType === 'display') {
      if (displayArea) {
        displayArea.setMap(null);
      }
      setDisplayArea(polygon);
    } else {
      if (parkingArea) {
        parkingArea.setMap(null);
      }
      setParkingArea(polygon);
    }
  };

  useEffect(() => {
    if (!drawingManager || !maps) return;
    if (selectedPolygonType === 'parking') {
      drawingManager.setOptions({
        polygonOptions: getParkingAreaOptions({
          isArchived: false,
          isSatellite: true,
        }),
      });
    } else {
      drawingManager.setOptions({
        polygonOptions: getDisplayAreaOptions({
          isArchived: false,
          isSatellite: true,
        }),
      });
    }
    const addListener = maps.event.addListener(
      drawingManager,
      'polygoncomplete',
      onPolygonCompleted,
    );
    return () => maps.event.removeListener(addListener);
  }, [drawingManager, parkingArea, displayArea, selectedPolygonType]);

  const onFeatureAdded = (feature: google.maps.Polygon, type: PolygonType) => {
    if (type === 'display') {
      setDisplayArea(feature);
    } else {
      setParkingArea(feature);
    }
  };

  const [isError, setIsError] = useState(false);

  const onSubmit = () => {
    if (!name || !displayArea || !parkingArea) {
      setIsError(true);
      return;
    }
    if (existingZone) {
      api
        .updateParkingZone(
          existingZone.id,
          removeEmptyParams({
            name,
            location: '0784', // TODO
            displayArea: convertPolygonToGeoJSON(displayArea),
            parkingArea: convertPolygonToGeoJSON(parkingArea),
          }),
        )
        .then(() => {
          navigate('../..', { relative: 'path' });
        });
    } else {
      api
        .createParkingZone(
          removeEmptyParams({
            name,
            location: '0784', // TODO
            displayArea: convertPolygonToGeoJSON(displayArea),
            parkingArea: convertPolygonToGeoJSON(parkingArea),
          }),
        )
        .then(() => {
          navigate('..', { relative: 'path' });
        });
    }
  };

  return (
    <>
      {isLoading && <LoadingIcon />}
      <Box
        display="flex"
        flexDirection="column"
        flex={1}
        visibility={isLoading ? 'hidden' : 'visible'} // In edit mode map is not correctly rendered if it's conditionally rendered
      >
        <TabHeader
          text={
            existingZone
              ? t('settings.zones.edit.title')
              : t('settings.zones.add.title')
          }
        >
          <Box display="flex" gap={2}>
            <Button
              variant="contained"
              size="medium"
              color="secondary"
              startIcon={existingZone ? <EditIcon fill="white" /> : <Add />}
              onClick={onSubmit}
            >
              {existingZone
                ? t('settings.zones.edit.button')
                : t('settings.zones.add.button')}
            </Button>
            <SecondaryButton startIcon={<Close />} onClick={() => navigate(-1)}>
              {t('cancel')}
            </SecondaryButton>
          </Box>
        </TabHeader>
        <Typography variant="subtitle2" sx={{ mt: 2 }}>
          {t('settings.zones.addEdit.label.name')}
        </Typography>
        <TextField
          fullWidth
          variant="outlined"
          error={isError && !name}
          size="small"
          placeholder={t('settings.zones.addEdit.placeholder.name')}
          value={name}
          onChange={event => setName(event.target.value)}
        />
        <Typography
          variant="body2"
          fontSize="16px"
          lineHeight="24px"
          sx={{
            mt: 3,
            color:
              isError && (!displayArea || !parkingArea)
                ? theme.palette.error.main
                : undefined,
          }}
        >
          {t('settings.zones.addEdit.label.map')}
        </Typography>
        <RadioGroup
          row
          value={selectedPolygonType}
          onChange={event =>
            setSelectedPolygonType(event.target.value as PolygonType)
          }
        >
          <FormControlLabel
            value="display"
            control={<Radio />}
            label={t('settings.zones.addEdit.label.displayArea')}
          />
          <FormControlLabel
            value="parking"
            control={<Radio />}
            label={t('settings.zones.addEdit.label.parkingArea')}
          />
        </RadioGroup>
        <Box
          display="flex"
          flexDirection="column"
          flex={1}
          sx={hideGoogleLogoStyles}
        >
          <GoogleMapReact
            key={existingZone?.id}
            options={{
              clickableIcons: false,
              fullscreenControl: false,
              mapTypeId: 'hybrid',
            }}
            bootstrapURLKeys={{
              key: process.env.REACT_APP_GOOGLE_MAPS_API as string,
              libraries: ['drawing'].join(','),
            }}
            center={{
              lat: 59.416729,
              lng: 24.798884,
            }}
            defaultZoom={12}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => {
              setMaps(maps);
              if (existingZone) {
                handleParkingZoneMapApiLoaded(
                  map,
                  maps,
                  existingZone,
                  true,
                  onFeatureAdded,
                );
              }
              handleNewParkingZoneMapApiLoaded(
                map,
                maps,
                setClearBtn,
                setDrawingManager,
              );
            }}
          ></GoogleMapReact>
        </Box>
      </Box>
    </>
  );
};
